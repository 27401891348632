import {
  getNfFormData,
  getDnFormData,
  getOldDnFormData,
  getSdFormData,
  getNeaFormData,
  getScrFormData,
} from './data/'

export const getFormData = (templatePath: string, data: any, data2: any, optionList?: any) => {
  switch (templatePath) {
    case '/templates/NF_ver2.docx':
    case '/templates/NF_ver2_draft.docx':
      return getNfFormData(data, data2, optionList)
    case '/templates/DN_template.docx':
      return getDnFormData(data, optionList)
    case '/templates/DN_template_old.docx':
      return getOldDnFormData(data, optionList)
    case '/templates/SD_template.docx':
      return getSdFormData(data, optionList)
    case '/templates/SCR_template.docx':
      return getScrFormData(data, optionList)
    case '/templates/NEA_template.docx':
      return getNeaFormData(data, optionList)
  }
}
