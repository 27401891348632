import { useContext, useEffect, useState }            from 'react'
import { Control, UseFormGetValues, UseFormSetValue } from 'react-hook-form'
import uuid                                           from 'react-uuid'
import FormContainer                                  from '@components/form/container'
import FormController                                 from '@components/form/controller'
import FormField                                      from '@components/form/field'
import {
    FormNeaModel,
    NEAExcelFormatingRule,
    NeaGeneralOptions
}                                                     from '@services/model/form/form.NEA.model'
import FormFileUpload                                 from '@components/form/fileUpload'
import FormFreeText                                   from '@components/form/freeText'
import FormSelect                                     from '@components/form/select'
import { InitFileModel }                              from '@services/model/file.model'
import useGeneralOptions                              from '@hooks/useGeneralOptions_to_be_used'
import { GlobalContext }                              from '@providers/globalStore'
import FormMultipleSelect                             from '@components/form/multipleSelect'
import { FormStatusEnum }                             from '@services/model/form/form.model'
import FormDateTimePicker                             from '@components/form/dateTimePicker'
import { filter, find, map }                          from 'lodash'

export default ({
                    control,
                    formStatus,
                    watch,
                    neaGeneralOptions,
                    setValue,
                    getValues
                }: {
    control: Control<FormNeaModel>
    formStatus: string
    watch: any
    neaGeneralOptions?: NeaGeneralOptions
    setValue: UseFormSetValue<FormNeaModel>
    getValues: UseFormGetValues<FormNeaModel>
}) => {
    const UUID = uuid()

    const readonly               = true
    const { getOptionsByKey }    = useGeneralOptions()
    const { state: globalState } = useContext(GlobalContext)

    const [disableUpload, setDisableUplaod]               = useState<boolean>(true)
    const [excelValidationRules, setExcelValidationRules] = useState<NEAExcelFormatingRule[]>([])

    const [isHighSpeedRoadContract, setIsHighSpeedRoadContract] = useState(false)
    const [isRoutineInspection, setIsRoutineInspection] = useState(false)

  const TeamList = (typeOfMmWorks) => {
    var teamList
    var districtList

    var teamNumList = []
    var districtNumList = []

    //teamNumList = useState('')
    switch (typeOfMmWorks) {
      case 'Maintain Road Markings and Road Studs':
        var teamStr = ['Road-SK', 'Road-TKO1', 'Road-TKO2']
        var districtStr = ['Sai Kung', 'Tseung Kwan O']
        teamList =
          globalState.teamList?.filter((x) => {
            if (teamStr?.includes(x.value)) {
              //   console.log("pushing to team : ", x.key)
              //setTeamNumList(prevTeam => prevTeam.concat(x.key));
              teamNumList = teamNumList.concat(x.key)
              //      console.log(teamNumList);
              return true
            }
            return false
          }) || []

        districtList =
          globalState.districtList?.filter((x) => {
            if (districtStr?.includes(x.value)) {
              // console.log("pushing to district : ", x.key)
              //setDistrictNumList(districtNumList => districtNumList.concat(x.key))
              districtNumList = districtNumList.concat(x.key)
              //   console.log(districtNumList)
              return true
            }
            return false
          }) || []

        break
      case 'Maintain Road Drainage System':
        var teamStr = ['Road-SK', 'Road-TKO1', 'Road-TKO2']
        var districtStr = ['Sai Kung', 'Tseung Kwan O']
        teamList =
          globalState.teamList?.filter((x) => {
            if (teamStr?.includes(x.value)) {
              // console.log("pushing to team : ", x.key)

              //setTeamNumList(prevTeam => prevTeam.concat(x.key));
              teamNumList = teamNumList.concat(x.key)

              return true
            }
            return false
          }) || []

        districtList =
          globalState.districtList?.filter((x) => {
            if (districtStr?.includes(x.value)) {
              //console.log("pushing to district : ", x.key)
              //setDistrictNumList(districtNumList => districtNumList.concat(x.key))
              districtNumList = districtNumList.concat(x.key)
              return true
            }
            return false
          }) || []

        break
      case 'Conduct Routine Inspections and Undertake Associated General Road Maintenance Works':
        var teamStr = ['Road-SK', 'Road-TKO1', 'Road-TKO2']
        var districtStr = ['Sai Kung', 'Tseung Kwan O']
        teamList =
          globalState.teamList?.filter((x) => {
            if (teamStr?.includes(x.value)) {
              // console.log("pushing to team : ", x.key)

              //setTeamNumList(prevTeam => prevTeam.concat(x.key));
              teamNumList = teamNumList.concat(x.key)

              return true
            }
            return false
          }) || []

        districtList =
          globalState.districtList?.filter((x) => {
            if (districtStr?.includes(x.value)) {
              // console.log("pushing to district : ", x.key)
              //setDistrictNumList(districtNumList => districtNumList.concat(x.key))
              districtNumList = districtNumList.concat(x.key)
              return true
            }
            return false
          }) || []

        break
      case 'Conduct Detailed Inspections of Roads and Undertake Associated General Road Maintenance Works':
        var teamStr = ['Road-SK', 'Road-TKO1', 'Road-TKO2']
        var districtStr = ['Sai Kung', 'Tseung Kwan O']
        teamList =
          globalState.teamList?.filter((x) => {
            if (teamStr?.includes(x.value)) {
              //  console.log("pushing to team : ", x.key)

              //setTeamNumList(prevTeam => prevTeam.concat(x.key));
              teamNumList = teamNumList.concat(x.key)

              return true
            }
            return false
          }) || []

        districtList =
          globalState.districtList?.filter((x) => {
            if (districtStr?.includes(x.value)) {
              // console.log("pushing to district : ", x.key)
              //setDistrictNumList(districtNumList => districtNumList.concat(x.key))
              districtNumList = districtNumList.concat(x.key)
              return true
            }
            return false
          }) || []

        break
      case 'Conduct Inspection of Stepping ≧5mm and ≦10mm on Paver Footways':
        var teamStr = ['Road-SK', 'Road-TKO1', 'Road-TKO2']
        teamList =
          globalState.teamList?.filter((x) => {
            if (teamStr?.includes(x.value)) {
              //  console.log("pushing to team : ", x.key)

              //setTeamNumList(prevTeam => prevTeam.concat(x.key));
              teamNumList = teamNumList.concat(x.key)

              return true
            }
            return false
          }) || []

        districtList =
          globalState.districtList?.filter((x) => {
            if (districtStr?.includes(x.value)) {
              //  console.log("pushing to district : ", x.key)
              //setDistrictNumList(districtNumList => districtNumList.concat(x.key))
              districtNumList = districtNumList.concat(x.key)
              return true
            }
            return false
          }) || []

        break
      case 'Conduct Inspections of Highway Structures and Undertake Structural Maintenance':
        var teamStr = ['Structure-E1', 'Structure-E3']
        var districtStr = ['Sai Kung', 'Tseung Kwan O', 'Sha Tin', 'Tai Po', 'North']
        teamList =
          globalState.teamList?.filter((x) => {
            if (teamStr?.includes(x.value)) {
              // console.log("pushing to team : ", x.key)

              //setTeamNumList(prevTeam => prevTeam.concat(x.key));
              teamNumList = teamNumList.concat(x.key)

              return true
            }
            return false
          }) || []

        districtList =
          globalState.districtList?.filter((x) => {
            if (districtStr?.includes(x.value)) {
              //   console.log("pushing to district : ", x.key)
              //setDistrictNumList(districtNumList => districtNumList.concat(x.key))
              districtNumList = districtNumList.concat(x.key)
              return true
            }
            return false
          }) || []

        break
      case 'Conduct Routine Maintenance Inspection For and Maintain Roadside Slopes':
        var teamStr = ['Slope-E1', 'Slope-E2', 'Slope-E3']
        var districtStr = ['Sai Kung', 'Tseung Kwan O', 'Sha Tin', 'Island']
        teamList =
          globalState.teamList?.filter((x) => {
            if (teamStr?.includes(x.value)) {
              //  console.log("pushing to team : ", x.key)

              //setTeamNumList(prevTeam => prevTeam.concat(x.key));
              teamNumList = teamNumList.concat(x.key)

              return true
            }
            return false
          }) || []

        districtList =
          globalState.districtList?.filter((x) => {
            if (districtStr?.includes(x.value)) {
              // console.log("pushing to district : ", x.key)
              //setDistrictNumList(districtNumList => districtNumList.concat(x.key))
              districtNumList = districtNumList.concat(x.key)
              return true
            }
            return false
          }) || []

        break
      case 'Conduct Inspections of Tunnel Segments and Undertake Structural Maintenance':
        var teamStr = ['Road-T1', 'Road-T2']
        var districtStr = ['Tseung Kwan O']
        teamList =
          globalState.teamList?.filter((x) => {
            if (teamStr?.includes(x.value)) {
              //  console.log("pushing to team : ", x.key)

              //setTeamNumList(prevTeam => prevTeam.concat(x.key));
              teamNumList = teamNumList.concat(x.key)

              return true
            }
            return false
          }) || []

        districtList =
          globalState.districtList?.filter((x) => {
            if (districtStr?.includes(x.value)) {
              //  console.log("pushing to district : ", x.key)
              //setDistrictNumList(districtNumList => districtNumList.concat(x.key))
              districtNumList = districtNumList.concat(x.key)
              return true
            }
            return false
          }) || []

        break
      case 'Maintain Vegetation on Vegetated Highway Registered Slopes and Designated Planted Areas':
        var teamStr = ['Landscape-NTE', 'Landscape-NTW']
        var districtStr = [
          'Sai Kung',
          'Tseung Kwan O',
          'Sha Tin',
          'Yuen Long',
          'Tuen Mun',
          'Tsuen Wan',
          'Kwai Chung',
          'Tsing Yi',
        ]
        teamList =
          globalState.teamList?.filter((x) => {
            if (teamStr?.includes(x.value)) {
              //  console.log("pushing to team : ", x.key)
              //setTeamNumList(prevTeam => prevTeam.concat(x.key));
              teamNumList = teamNumList.concat(x.key)
              return true
            }
            return false
          }) || []

        districtList =
          globalState.districtList?.filter((x) => {
            if (districtStr?.includes(x.value)) {
              // console.log("pushing to district : ", x.key)
              //setDistrictNumList(districtNumList => districtNumList.concat(x.key))
              districtNumList = districtNumList.concat(x.key)
              return true
            }
            return false
          }) || []

        break

      default:
        teamList = globalState.teamList
        districtList = globalState.districtList
        break
    }
    // isMounted.current = true
    if (teamNumList.length > 0 && districtNumList.length > 0) {
      setValue('baseForm.teamId', teamNumList)
      setValue('baseForm.districtId', districtNumList)
    }
  }

    useEffect(() => {
        if (
            getValues('baseForm.contractNoId') !== null &&
            getValues('typeOfMmWorks') !== null &&
            getValues('baseForm.districtId') !== null &&
            getValues('witnessedBy') !== null &&
            getValues('monthYearOfPerformanceStandard')
        ) {
            setDisableUplaod(false)
        }

        const typeOfMmWorks = neaGeneralOptions?.typeOfMmWorks?.find(x => x.id === getValues('typeOfMmWorks'))
        setIsRoutineInspection(typeOfMmWorks?.name === 'Conducting Routine Inspections and Undertake Associated General Road Maintenance Works')
        setIsHighSpeedRoadContract(globalState.contractList?.find(
            contract => contract.id === getValues('baseForm.contractNoId'))?.highSpeedRoad ?? false)
    }, [])

  useEffect(() => {
    const subscription = watch((data) => {
      if (
        data.baseForm?.contractNoId &&
        data.typeOfMmWorks &&
        data.baseForm?.districtId &&
        data.witnessedBy &&
        data.monthYearOfPerformanceStandard
      ) {
        if (disableUpload) {
          setDisableUplaod(false)
        }
      } else {
        if (!disableUpload) {
          setDisableUplaod(true)
        }
      }
    })

    return () => subscription.unsubscribe()
  }, [watch])

  const contractNoList =
    globalState.contractList?.map((x) => {
      return {
        key: x.id,
        value: x.contractNo,
      }
    }) || []

  console.log(
    'mm work = req',
    getValues('typeOfMmWorks') ===
      find(getOptionsByKey('Form EA - Category'), {
        value:
          'Conducting Routine Inspections and Undertake Associated General Road Maintenance Works',
      })?.key,
  )

  console.log(
    'req contracId',
    map(
      filter(
        globalState.contractList,
        ({ contractNo }) => contractNo === '08/HY/2021' || contractNo === '05/HY/2018',
      ),
      'id',
    ),
  )

  return (
    <FormContainer fkey={`${UUID}-nea-a`}>
      <FormField fieldName="Contract No.">
        <FormController
          controllerProps={{
            name: 'baseForm.contractNoId',
            control,
            rules: { required: true },
          }}>
          <FormSelect fullWidth options={contractNoList} uneditable={true} />
        </FormController>
      </FormField>
      <FormField fieldName="Type of M&M Works">
        <FormController
          controllerProps={{
            name: 'typeOfMmWorks',
            control,
            rules: { required: true },
          }}>
            <FormSelect fullWidth
                        options={ getOptionsByKey('Form EA - Category') }
                        additionalChangeAction={ (_, newValue) => {
                            if (newValue && newValue.key) {
                                const typeOfMmWorks       = neaGeneralOptions?.typeOfMmWorks?.find(x => x.id === newValue.key)
                                const isRoutineInspection = typeOfMmWorks?.name === 'Conducting Routine Inspections and Undertake Associated General Road Maintenance Works'
                                setExcelValidationRules(typeOfMmWorks?.excelValidationRules || [])
                                setIsRoutineInspection(isRoutineInspection)
                                setValue('neaExcels', isRoutineInspection ? [InitFileModel] : [])
                                setValue('excludedDays', [])
                                setValue('typeOfMmWorksValue', typeOfMmWorks?.name ?? 'UNDEFINED')
                                TeamList(newValue.value)
                            }
                        } } />
        </FormController>
      </FormField>

      <FormField fieldName="Team">
        <FormController
          controllerProps={{
            name: 'baseForm.teamId',
            control,
          }}>
          <FormMultipleSelect options={globalState.teamList || []} />
        </FormController>
      </FormField>
      <FormField fieldName="District">
        <FormController
          controllerProps={{
            name: 'baseForm.districtId',
            control,
          }}>
          <FormMultipleSelect options={globalState.districtList || []} />
        </FormController>
      </FormField>

      <FormField fieldName="Assign IOW list">
        <FormController
          controllerProps={{
            name: 'notifyUserList',
            control: control,
          }}>
          <FormMultipleSelect options={globalState.userMetaList || []} uneditable={readonly} />
        </FormController>
      </FormField>

      <FormField fieldName="Witnessed by">
        <FormController
          controllerProps={{
            name: 'witnessedBy',
            control,
            rules: { required: true },
          }}>
          <FormFreeText textfieldProps={{ fullWidth: true }} />
        </FormController>
      </FormField>

        {
            (isHighSpeedRoadContract && isRoutineInspection) &&
            <FormField fieldName='Excluded Days'>
                <FormController
                    controllerProps={ {
                        name: 'excludedDays',
                        control
                    } }>
                    <FormMultipleSelect limit={ 4 }
                                        options={ ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']
                                            .map((value, i) => ({ key: i + 1, value })) } />
                </FormController>
            </FormField>
        }

      <FormField fieldName="Month/Year of Performance Standard">
        <FormController
          controllerProps={{
            name: 'monthYearOfPerformanceStandard',
            control,
            rules: { required: true },
          }}>
          <FormDateTimePicker type="month" />
        </FormController>
      </FormField>

        {
            (!isHighSpeedRoadContract || !isRoutineInspection) &&
            <FormField fieldName='Document Upload'>
                <FormController
                    controllerProps={ {
                        name : 'neaExcels',
                        control,
                        rules: { required: true }
                    } }>
                    <FormFileUpload height={ 125 }
                                    acceptFiles={ [
                                        'application/vnd.ms-excel',
                                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                                    ] }
                                    disabled={
                                        (formStatus !== FormStatusEnum.FORM_NEA_DRAFT &&
                                         formStatus !== FormStatusEnum.FORM_NEA_SAMPLE_SELECTED_RESULT_REJECTED) ||
                                        disableUpload
                                    }
                    />
                </FormController>
            </FormField>
        }
    </FormContainer>
  )
}