import FormContainer from '@components/form/container'
import FormController from '@components/form/controller'
import FormField from '@components/form/field'
import FormTypography from '@components/form/typography'
import { FormPartComponents } from '@models/common'
import { TextField } from '@mui/material'
import { FormStatusEnum } from '@services/model/form/form.model'
import { FormScrModel } from '@services/model/form/form.SCR.model'
import { t } from 'i18next'
import { Control } from 'react-hook-form'

export default ({
  control,
  formStatus
}: {
  control: Control<FormScrModel>
  formStatus: string
}) => {
  const editable =
    formStatus === FormStatusEnum.FORM_SCR_DRAFT || formStatus === FormStatusEnum.FORM_SCR_REJECTED

  return  (
    <>
     <FormContainer fkey={`scr-g`}>
       <FormField fieldName="Remarks">
         <FormController
           controllerProps={{
             name: 'remarks',
             control: control,
           }}>
           {editable ? (
             <TextField
               fullWidth
               multiline
               rows={7}
               placeholder={t('Please fill in information')}
             />
           ) : (
             <FormTypography />
           )}
         </FormController>
       </FormField>
     </FormContainer>
    </>
  )
}
