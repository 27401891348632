import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'react-chartjs-2'
import { pieChartProps } from './BasicPieChart'

ChartJS.register(ArcElement, Tooltip, Legend)

const PieChart = (props: pieChartProps) => {
    let datasets = props.datasets.map((dataset) => {
        return {
            label: dataset.name,
            data: dataset.data,
            ...dataset.dataConfig,
        }
    })
    return (
        <Pie
            options={props.options}
            data={{
                labels: props.labels,
                datasets: [...datasets],
            }}
        />
    )
}
export default PieChart
