import { KeyValPair } from '@models/common'

export type ActionType =
  | {
      type: 'changeOperator'
      key: string
      operator: string
    }
  | {
      type: 'changeVal'
      key: string
      value: any
    }
  | {
      type: 'changeStrVal'
      key: string
      value: any
    }
  | {
      type: 'remove'
      key: string
    }
  | {
      type: 'removeAll'
    }
  | {
      type: 'setIsInitialized'
    }
  | {
      type: 'setCanReload'
    }

export interface SearchPanelState {
  filterResult?: any
  isInitialized: boolean
  canReload: boolean
}

function SearchPanelReducer(state: SearchPanelState, action: ActionType): SearchPanelState {
  switch (action.type) {
    case 'changeOperator':
      return {
        ...state,
        [action.key]: {
          ...state?.[action.key],
          operator: action.operator,
        },
        filterResult: {
          ...state.filterResult,
          [action.key]: {
            ...state?.filterResult?.[action.key],
            operator: action.operator,
          },
        },
      }
    case 'changeVal':
      return {
        ...state,
        [action.key]: {
          ...state?.[action.key],
          value: action.value,
        },
        filterResult: {
          ...state.filterResult,
          [action.key]: {
            operator: state?.filterResult?.[action.key]?.operator || '=',
            value:
              action.value === null || action.value === undefined
                ? undefined
                : Array.isArray(action.value)
                ? [...(action.value as KeyValPair[]).map((x) => x.key)]
                : typeof action.value === 'object' && 'key' in action.value
                ? action.value.key
                : action.value,
          },
        },
      }
    case 'changeStrVal':
      return {
        ...state,
        [action.key]: {
          ...state?.[action.key],
          value: action.value,
        },
        filterResult: {
          ...state.filterResult,
          [action.key]: {
            operator: state?.filterResult?.[action.key]?.operator || '~',
            value:
              action.value === null || action.value === undefined
                ? undefined
                : Array.isArray(action.value)
                ? [...(action.value as KeyValPair[]).map((x) => x.value)]
                : typeof action.value === 'object' && 'key' in action.value
                ? action.value.key
                : typeof action.value === 'object' && 'value' in action.value
                ? action.value.value
                : action.value,
          },
        },
      }
    case 'remove':
      return {
        ...state,
        [action.key]: undefined,
      }
    case 'removeAll':
      return {
        isInitialized: state.isInitialized,
        canReload: state.canReload
      }
    case 'setIsInitialized':
      return {
        ...state,
        isInitialized: true,
      }
    case 'setCanReload':
      return {
        ...state,
        canReload: true,
      }
    default:
      return state
  }
}

export default SearchPanelReducer
