import SearchPanel from '@components/searchPanel'
import { Grid } from '@mui/material'
import * as common from '@common/common'
import { GlobalContext } from '@providers/globalStore'
import { InitialSearchPanelState, SearchPanelContext } from '@providers/SearchPanelProvider'
import SearchPanelReducer, { SearchPanelState } from '@reducers/searchPanelReducer'
import moment from 'moment'
import { Fragment, useContext, useReducer, useRef, useState } from 'react'
import { NavigateFunction } from 'react-router-dom'
import useTableView from '@hooks/useTableView'
import { useTranslation } from 'react-i18next'
import FormStatusLabel from '@components/FormStatusLabel'
import GetValueWithKey from '@utils/getValueWithKey'

import { FormNeaListModel, NeaFilterOptions } from '@services/model/form/form.NEA.model'
import FormNEAService from '@services/formService/form.NEA.service'
import { NavigateTo } from '@utils/navigate'
import { KeyValPair } from '@models/common'
import { RightsCategory } from '@services/model/form/form.model'

const AllRecord = (props: { showMyRecord?: boolean }) => {
  const refMounted = useRef(false)
  const { state: globalState } = useContext(GlobalContext)
  const [state, dispatch] = useReducer<React.Reducer<SearchPanelState, any>>(
    SearchPanelReducer,
    InitialSearchPanelState,
  )

  const { t } = useTranslation()
  const [extraListInfo, setExtraListInfo] = useState<NeaFilterOptions>({
    submittedByList: [],
    approvedByList: [],
    formIdList: [],
    workOrderIdList: [],
  })

  const getExtraListInfo = async () => {
    let extraInfo = await FormNEAService.GetNeaFilterOption(props.showMyRecord)
    setExtraListInfo(extraInfo)
    refMounted.current = true
  }

  const [TableView, reload] = useTableView<FormNeaListModel>({
    headers: [
      {
        key: 'formStatusShortName',
        desc: t('General Status', { ns: common.i18nFormNS }),
        renderCell: (record: FormNeaListModel) => (
          <Fragment>
            <FormStatusLabel
              label={record.formStatusShortName}
              color={record.formStatusColor || ''}
            />
          </Fragment>
        ),
      },
      {
        key: 'formStatus',
        desc: t('Submission Status', { ns: common.i18nFormNS }),
        renderCell: (record: FormNeaListModel) => <Fragment>{record.formStatus}</Fragment>,
      },
      {
        key: 'formId',
        desc: t('Form ID', { ns: common.i18nFormNS }),
        renderCell: (record: FormNeaListModel) => <Fragment>{record.reportNo}</Fragment>,
      },
      {
        key: 'districtId',
        desc: t('District', { ns: common.i18nFormNS }),
        renderCell: (record: FormNeaListModel) => (
          <Fragment>
            {GetValueWithKey(record.districtId[0], globalState.districtList ?? [])}
          </Fragment>
        ),
        mobileHidden: true,
      },
      {
        key: 'dateOfAudit',
        desc: t('Date of Audit', { ns: common.i18nFormNS }),
        renderCell: (record: FormNeaListModel) => (
          <Fragment>
            {record.dateOfAudit ? moment(record.dateOfAudit).format('D MMMM YYYY') : ''}
          </Fragment>
        ),
      },
      {
        key: 'submittedBy',
        desc: t('Submitted By', { ns: common.i18nFormNS }),
        renderCell: (record: FormNeaListModel) => (
          <Fragment>{GetValueWithKey(record.submittedBy, globalState.userMetaList ?? [])}</Fragment>
        ),
        mobileHidden: true,
      },
      {
        key: 'approvedBy',
        desc: t('Approved By', { ns: common.i18nFormNS }),
        renderCell: (record: FormNeaListModel) => (
          <Fragment>{GetValueWithKey(record.approvedBy, globalState.userMetaList ?? [])}</Fragment>
        ),
        mobileHidden: true,
      },
      {
        key: 'updatedAt',
        desc: t('Last Update', { ns: common.i18nFormNS }),
        renderCell: (record: FormNeaListModel) => (
          <Fragment>
            {record.updatedAt ? moment(record.updatedAt).format(moment.defaultFormat) : ''}
          </Fragment>
        ),
        mobileHidden: true,
      },
    ],
    onRowClick: function (navigate: NavigateFunction, record: FormNeaListModel) {
      NavigateTo(navigate, '/notification-of-engineer-audit/:id', { id: record.formId })
    },
    mountedRef: refMounted,
    reloadCallback: async (pagination: object, sorting: object | undefined, cancelToken: any) => {
      if (refMounted.current) {
        sessionStorage.setItem('NEA', JSON.stringify(state.filterResult))
        let resp = await FormNEAService.GetNeaList(
          {
            ...state.filterResult,
            sorting: sorting,
            pagination: pagination,
          },
          cancelToken,
          props.showMyRecord || false,
        )
        return resp
      }
    },
  })

  return (
    <Grid component="main" container padding={1}>
      <SearchPanelContext.Provider value={{ state, dispatch }}>
        <SearchPanel
          dispatch={dispatch}
          addUrl="/booking/new"
          onSearch={reload}
          onInitReload={reload}
          onToggleFilterDrawer={getExtraListInfo}
          mountedRef={refMounted}
          excludeStatus={true}
          criteria={[
            {
              key: 'formStatus',
              desc: 'Submission Status',
              type: 'NumberSelectionFilter',
              multiple: true,
              valueList:
                globalState.formStatusList
                  ?.filter((x) => x.actionCode.includes(RightsCategory.FORM_NEA))
                  .map((x) => {
                    return {
                      key: x.id,
                      value: x.actionName,
                    }
                  }) || [],
              defaultValue:
                sessionStorage?.NEA !== 'undefined' && sessionStorage?.NEA !== undefined
                  ? JSON.parse(sessionStorage?.NEA)?.formStatus?.value
                  : [],
            },
            {
              key: 'formId',
              desc: 'Form ID',
              type: 'StringSelectionFilter',
              multiple: true,
              valueList: extraListInfo.formIdList,
              defaultValue:
                sessionStorage?.NEA !== 'undefined' && sessionStorage?.NEA !== undefined
                  ? JSON.parse(sessionStorage?.NEA)?.formId?.value
                  : [],
            },
            {
              key: 'districtId',
              desc: 'District',
              type: 'NumberSelectionFilter',
              multiple: true,
              valueList: globalState.districtList,
              defaultValue:
                sessionStorage?.NEA !== 'undefined' && sessionStorage?.NEA !== undefined
                  ? JSON.parse(sessionStorage?.NEA)?.districtId?.value
                  : [],
            },
            {
              key: 'dateOfAudit',
              desc: 'Date of Audit',
              type: 'DateFilter',
              defaultOperator:
                sessionStorage?.NEA !== 'undefined' && sessionStorage?.NEA !== undefined
                  ? JSON.parse(sessionStorage?.NEA)?.dateOfAudit?.operator
                  : 'in',
              defaultFrom:
                sessionStorage?.NEA !== 'undefined' && sessionStorage?.NEA !== undefined
                  ? JSON.parse(sessionStorage?.NEA)?.dateOfAudit?.value?.min
                  : '',
              defaultTo:
                sessionStorage?.NEA !== 'undefined' && sessionStorage?.NEA !== undefined
                  ? JSON.parse(sessionStorage?.NEA)?.dateOfAudit?.value?.max
                  : '',
            },
            {
              key: 'submittedBy',
              desc: 'Submitted By',
              type: 'StringSelectionFilter',
              multiple: true,
              valueList: globalState.userMetaList?.filter((x) =>
                extraListInfo.submittedByList?.includes(x.key),
              ),
              defaultValue:
                sessionStorage?.NEA !== 'undefined' && sessionStorage?.NEA !== undefined
                  ? JSON.parse(sessionStorage?.NEA)?.submittedBy?.value
                  : [],
            },
            {
              key: 'approvedBy',
              desc: 'Approved By',
              type: 'StringSelectionFilter',
              multiple: true,
              valueList: globalState.userMetaList?.filter((x) =>
                extraListInfo.approvedByList?.includes(x.key),
              ),
              defaultValue:
                sessionStorage?.NEA !== 'undefined' && sessionStorage?.NEA !== undefined
                  ? JSON.parse(sessionStorage?.NEA)?.approvedBy?.value
                  : [],
            },
            {
              key: 'updatedAt',
              desc: 'Last Update',
              type: 'DateFilter',
              defaultOperator:
                sessionStorage?.NEA !== 'undefined' && sessionStorage?.NEA !== undefined
                  ? JSON.parse(sessionStorage?.NEA)?.updatedAt?.operator
                  : 'in',
              defaultFrom:
                sessionStorage?.NEA !== 'undefined' && sessionStorage?.NEA !== undefined
                  ? JSON.parse(sessionStorage?.NEA)?.updatedAt?.value?.min
                  : '',
              defaultTo:
                sessionStorage?.NEA !== 'undefined' && sessionStorage?.NEA !== undefined
                  ? JSON.parse(sessionStorage?.NEA)?.updatedAt?.value?.max
                  : '',
            },
          ]}
        />
        <TableView />
      </SearchPanelContext.Provider>
    </Grid>
  )
}

export default AllRecord
