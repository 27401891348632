import FormBinaryRadio from '@components/form/binaryRadio'
import FormContainer from '@components/form/container'
import FormController from '@components/form/controller'
import FormDateTimePicker from '@components/form/dateTimePicker'
import FormField from '@components/form/field'
import FormSelect from '@components/form/select'
import { CdrOptions, FormSDModel, InitSDForm } from '@services/model/form/form.SD.model'
import { ArrayPath, Control, FieldArray, useFieldArray, UseFieldArrayReturn, UseFormSetValue } from 'react-hook-form'
import { FormStatusEnum } from '@services/model/form/form.model'
import { Fragment, useContext, useEffect, useState } from 'react'
import uuid from 'react-uuid'
import FormTypography from '@components/form/typography'
import FormMultipleSelect from "@components/form/multipleSelect";
import {GetTeamOptions} from "@utils/teamOptions";
import {GetDistrictOptions} from "@utils/districtOptions";
import {GetWorksOrderOptions} from "@utils/worksOrderOptions";

export default ({
  control,
  options,
  setEditPartB,
  watch,
  userInfo,
  useWatch,
  setValue,
  getValues,
  readOnly,
  handleGetWorksOrderNo,
  cdrOptions,
  setDisableNav,
  globalState
}: {
  control: Control<FormSDModel>
  options: any
  setEditPartB: any
  watch: any
  userInfo: any
  useWatch: any
  setValue: UseFormSetValue<FormSDModel>
  getValues: any
  readOnly: boolean
  handleGetWorksOrderNo: any
  cdrOptions: CdrOptions | undefined
  setDisableNav: any
  globalState: any
}) => {
  const UUID = uuid()
  let index = 0

  console.log("globalState.contractList", globalState.contractList)
  useEffect(() => {
    console.log('useEffect')
    const subscription = watch((data) => {
      if (
        data.baseForm?.contractNoId &&
        data.formDate &&
        data.baseForm?.workOrderId &&
        data.cdrRef === true 
      ) {
        handleGetWorksOrderNo()
      }

      if(
        data.baseForm?.contractNoId &&
        data.formDate &&
        data.cdrRef === false &&
        data.baseForm?.workOrderId !== null && data.baseForm?.workOrderId !== undefined
      ){
        setDisableNav(false)
      }else if(
        data.baseForm?.contractNoId &&
        data.formDate &&
        data.cdrRef &&
        data.baseForm?.workOrderId !== null && data.baseForm?.workOrderId !== undefined && 
        data.cdrId !== 0 &&
        data.itemNo !== undefined && data.itemNo !== null
      ){
        setDisableNav(false)
      }else{
        setDisableNav(true)
      }
    })

    return () => subscription.unsubscribe()
  }, [watch])

  const contractNoList =
    globalState.contractList?.map((x) => {
      return {
        key: x.id,
        value: x.contractNo,
      }
    }) ?? []

  const PartAWorksOrderNo =  () => {
    const [contractNo, teamId, districtId] = useWatch({
      name: ['baseForm.contractNoId', 'baseForm.teamId', 'baseForm.districtId'],
      control,
    })

      return (<>
        <FormField fieldName="Works Order No" index={index++}>
            {readOnly ?  <FormTypography value={getValues('baseForm.workOrderNo')}/> : <FormController
              controllerProps={{
                name: 'baseForm.workOrderId',
                control: control,
              }}>
              <FormSelect
                fullWidth
                options={
                  GetWorksOrderOptions(
                    globalState.worksOrderList|| [],
                    teamId, 
                    districtId)
                }
                uneditable={readOnly}
              />
            </FormController>}
          </FormField>
          </>)
  }

  const PartAcdrRef = () => {
    const cdrRef = useWatch({
      name: 'cdrRef',
      control,
    })

    if (cdrRef) {
      return (
        <>
          <Fragment>
            <FormField fieldName="Contractor's Daily Report No." index={index++}>
              <FormController
                controllerProps={{
                  name: 'cdrId',
                  control: control,
                }}>
                <FormSelect
                  fullWidth
                  options={
                    cdrOptions?.cdrList?.map((x) => {
                      return {
                        key: x.id,
                        value: x.cdrNo,
                      }
                    }) || []
                  }
                  uneditable={readOnly}
                />
              </FormController>
            </FormField>
            <FormField fieldName="Item No." index={index++}>
              <FormController
                controllerProps={{
                  name: 'itemNo',
                  control: control,
                }}>
                <FormSelect
                  fullWidth
                  options={
                    cdrOptions?.cdrList
                      ?.find((cdr) => cdr.id === getValues('cdrId'))
                      ?.relatedWorkOrder[0]?.items?.map((x) => {
                        return {
                          key: x.id,
                          value: x.name,
                        }
                      }) || []
                  }
                  uneditable={readOnly}
                  additionalChangeAction={(_, selected) => {
                    let itemDetails = cdrOptions?.cdrList
                      ?.find((cdr) => cdr.id === getValues('cdrId'))
                      ?.relatedWorkOrder[0]?.items?.find((x) => x.id === selected?.key)
                    console.log('itemDetails', itemDetails)
                    setValue('formSdCrw.locationDetails', itemDetails?.locationDetails)
                    setValue('formSdCrw.proposedActivities', itemDetails?.proposedActivities)
                    setValue('formSdCrw.noOfWorker', itemDetails?.noOfWorker)
                    setValue('formSdCrw.lsgOnSite', itemDetails?.lsgOnSite)
                    setValue('formSdCrw.essentialOperation', itemDetails?.essentialOperation)
                    setValue(
                      'formSdCrw.hiddenWorkOtherThanLsg',
                      itemDetails?.hiddenWorkOtherThanLsg,
                    )
                    setValue('formSdCrw.inSituTest', itemDetails?.inSituTest)
                    setValue('formSdCrw.dayNightWorks', itemDetails?.dayNightWorks)
                    setValue('formSdCrw.iosWsRemarks', itemDetails?.iosWsRemarks)
                  }}
                />
              </FormController>
            </FormField>
          </Fragment>
        </>
      )
    }

    return (
      <></>
    )
  }

  return (
    <FormContainer fkey={`${UUID}-sd-a`}>
      <FormField fieldName="Contract No." index={index++}>
        <FormController
          controllerProps={{
            name: 'baseForm.contractNoId',
            control: control,
          }}>
          <FormSelect fullWidth options={contractNoList} uneditable={true} />
        </FormController>
      </FormField>
      <FormField fieldName="Site Diary No.">
        <FormController
          controllerProps={{
            name: 'sdNo',
            control: control,
          }}>
          <FormTypography />
        </FormController>
      </FormField>
      <FormField fieldName="Date"  index={index++}>
        <FormController
          controllerProps={{
            name: 'formDate',
            control: control,
          }}>
          <FormDateTimePicker uneditable={readOnly} />
        </FormController>
      </FormField>
        <FormField fieldName="Team" index={index++}>
            <FormController
                controllerProps={{
                    name: 'baseForm.teamId',
                    control,
                }}>
                <FormMultipleSelect
                    options={GetTeamOptions(userInfo.teams)}
                    uneditable={readOnly}
                    additionalChangeAction={(event) => {
                      setValue("baseForm.workOrderId", undefined)
                    }}
                />
            </FormController>
        </FormField>

        <FormField fieldName="District" index={index++}>
            <FormController
                controllerProps={{
                    name: 'baseForm.districtId',
                    control,
                }}>
                <FormMultipleSelect
                    options={GetDistrictOptions(userInfo.districts)}
                    uneditable={readOnly}
                    additionalChangeAction={(event) => {
                      setValue("baseForm.workOrderId", undefined)
                    }}
                />
            </FormController>
        </FormField>

      <FormField fieldName="Contractor's Daily Report Ref." index={index++}>
        <FormController
          controllerProps={{
            name: 'cdrRef',
            control: control,
          }}>
          <FormBinaryRadio
            additionalChangeAction={(event: any) => {
              setEditPartB(event.target.value === 'false')
              if (event.target.value === 'false') {
                setValue('formSdCrw', {})
                setValue('cdrId', 0)
                setValue('itemNo', undefined)
              }
            }}
            uneditable={readOnly}
          />
        </FormController>
      </FormField>
      <PartAWorksOrderNo/>
      <PartAcdrRef />
    </FormContainer>
  )
}
