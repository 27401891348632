import { NavigateFunction } from 'react-router-dom'

/**
 * util function to navigate to page with path
 * @param navigate navigate function from `useNavigate`
 * @param path path to redirect to
 * @param params params object
 * ```
 * { id: 1 }
 * ```
 * @param query query object
 * ```
 * { step: 1 }
 * ```
 */
const NavigateTo = (
  navigate: NavigateFunction,
  path: string,
  params?: Record<string, string | number | undefined>,
  query?: Record<string, string | number>,
): void => {
  let q = ''
  if (query) {
    q += '?'
    for (const [key, value] of Object.entries(query)) {
      q += `${key}=${value}`
    }
  }
  if (params) {
    let _path = path
    // has param(s)
    for (const [param, value] of Object.entries(params)) {
      if (value) {
        _path = _path.replace(`:${param}`, value.toString())
      } else if (param === 'id') {
        _path = _path.replace('/:id', '')
      } else {
        console.warn(`error navigating, value [${param}] is undefined`)
        _path = _path.replace(`/:${param}`, '')
      }
    }
    navigate(`${_path}${q}`)
  } else {
    // no param
    navigate(`${path}${q}`)
  }
}

export { NavigateTo }
