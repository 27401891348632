import FormStateReducer, { FormStateActionType } from '@reducers/formStateReducer'
import { createContext, Dispatch, useReducer } from 'react'

export interface FormStateProps {
  isDirty: boolean
  formStack: {
    form: any
    formType: string
  }[]
  form?: any
  formType?: string
  isLoading: boolean
}

const initialFormState: FormStateProps = {
  isDirty: false,
  formStack: [],
  form: undefined,
  formType: undefined,
  isLoading: false,
}

export const FormContext = createContext<{
  state: FormStateProps
  dispatch: Dispatch<FormStateActionType>
}>({
  state: initialFormState,
  dispatch: () => undefined,
})

export const FormStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(FormStateReducer, initialFormState)

  // TODO: add type checker for form function "get" instead of dispatch({ type: 'get' })
  function get<T>(form: any): form is T {
    return form
  }

  return <FormContext.Provider value={{ state, dispatch }}>{children}</FormContext.Provider>
}
