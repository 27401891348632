import AddIcon from '@mui/icons-material/Add'

import {
  Box,
  Typography,
  Grid,
  TableCell,
  styled,
} from '@mui/material'
import SearchPanel from '@components/searchPanel'
import * as common from '@common/common'
import { InitialSearchPanelState, SearchPanelContext } from '@providers/SearchPanelProvider'
import SearchPanelReducer, { SearchPanelState } from '@reducers/searchPanelReducer'
import { Fragment, useContext, useEffect, useReducer, useRef, useState } from 'react'
import { NavigateFunction } from 'react-router-dom'
import useTableView from '@hooks/useTableView'
import { useTranslation } from 'react-i18next'
import { UserFilterOptions, UserListModel } from '@services/model/admin.model'
import AdminService from '@services/admin.service'
import { NavigateTo } from '@utils/navigate'
import { useNavigate } from 'react-router-dom'
import { WorkGroup } from '@services/model/workGroup.model'
import ContractService from '@services/contract.service'

const LinkTableAddButton = styled(Box)<{
    state: {
      disableAddButton?: boolean
      uneditable?: boolean
      color?: string
      hoverBackgroundColor?: string
      backgroundColor?: string
    }
  }>(({ state }) => ({
    width: 40,
    height: 40,
    display: state.uneditable ? 'none' : 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    position: 'relative',
    zIndex: 1000,
    backgroundColor: state.disableAddButton ? '#eeeeee' : state.color ?? '#23e9c0',
    ...(!state.disableAddButton && {
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: state.hoverBackgroundColor ? state.hoverBackgroundColor : 'grey',
        '#add-button-hover-text': {
          opacity: '100% !important',
          width: '200px',
          transition: 'opacity 500ms !important',
        },
      },
    }),
  }))

const WorkGroupsList = (props: { showMyRecord?: boolean }) => {
  const refMounted = useRef(true)
  const [state, dispatch] = useReducer<React.Reducer<SearchPanelState, any>>(
    SearchPanelReducer,
    InitialSearchPanelState,
  )

  const { t } = useTranslation()
  const navigate = useNavigate()

  const [extraListInfo, setExtraListInfo] = useState<UserFilterOptions>({
    organizationIdList: [],
  })
  const getExtraListInfo = async () => {
    let extraInfo = await AdminService.GetUserFilterOption()
    setExtraListInfo(extraInfo)
    refMounted.current = true
  }

  const [TableView, reload] = useTableView<WorkGroup>({
    headers: [
      {
        key: 'workGroupName',
        desc: t('Name', { ns: common.i18nFormNS }),
        renderCell: (record: WorkGroup) => <Fragment>{record.workGroupName}</Fragment>,
      },
      {
        key: 'workGroupCode',
        desc: t('Code', { ns: common.i18nFormNS }),
        renderCell: (record: WorkGroup) => <Fragment>{record.workGroupCode}</Fragment>,
      },
      {
        key: 'contractNoId',
        desc: t('Name', { ns: common.i18nFormNS }),
        renderCell: (record: WorkGroup) => <Fragment>{record.contractNoId}</Fragment>,
      },
    ],
    onRowClick: function (navigate: NavigateFunction, record: WorkGroup) {
      NavigateTo(navigate, '/settings/workgroups/:id', { id: record.uuid } )
    },
    mountedRef: refMounted,
    reloadCallback: async (pagination: object, sorting: object | undefined, cancelToken: any) => {
      var list = await ContractService.GetContractWorkGroupList()

      return {list: list, totalCount: list.length}
    },
  })

  const handleAddWorkgroup = (event) => {
    NavigateTo(navigate, '/settings/workgroups')
  }
  
  return (
    <Grid component="main" container padding={1}>
      <SearchPanelContext.Provider value={{ state, dispatch }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            p: 1,
            m: 1,
            bgcolor: 'background.paper',
            borderRadius: 1,
            width: '100%',
          }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {/* <Box sx={{ padding: '5px 10px ' }}>
              <LinkTableAddButton
                state={{
                  disableAddButton: false, // add logic
                  uneditable: false, // add logic
                  color: '#cccccc',
                  hoverBackgroundColor: '#000000',
                }}
                onClick={handleAddWorkgroup}>
                <AddIcon
                  id="link-table-add-icon"
                  sx={{
                    height: '100%',
                    width: '100%',
                    color: '#ffffff',
                  }}
                />
                <Box
                  id="add-button-hover-text"
                  style={{
                    position: 'absolute',
                    right: 0,
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    boxShadow: '0 3px 6px rgb(0 0 0 / 16%)',
                    borderTopRightRadius: 10,
                    borderBottomRightRadius: 10,
                    padding: 10,
                    paddingRight: 70,
                    opacity: 0,
                  }}>
                  <Typography>{t('Add a New Workgroup')}</Typography>
                </Box>
              </LinkTableAddButton>
            </Box> */}
            <Box>
              <SearchPanel
                dispatch={dispatch}
                addUrl="/booking/new"
                onSearch={reload}
                onInitReload={reload}
                onToggleFilterDrawer={getExtraListInfo}
                mountedRef={refMounted}
                excludeStatus={true}
                criteria={[]}
              />
            </Box>
          </Box>
        </Box>
        <TableView />
      </SearchPanelContext.Provider>
    </Grid>
  )
}

export default WorkGroupsList
