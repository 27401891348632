export default function getErrorMessage (error: {
    id: string
    message: string
    code: number
    traceId: string
    svcName: string
  }){
    const errorMessageMapper = [
    {
      key: 404,
      value: 'Record not found.'
    },
    {
      key: 400,
      value: 'Duplicated record.'
    },
    {
      key: 2000,
      value: 'Invalid parameter.'
    },
    {
      key: 2001,
      value: 'Invalid query.'
    },
    {
      key: 2002,
      value: 'Invalid body.'
    },
    {
      key: 3001,
      value: 'Cannot connect to database.'
    },
    {
      key: 3002,
      value: 'Cannot connect to database.'
    },
    {
      key: 3003,
      value: 'Cannot connect to database.'
    },
    {
      key: 4003,
      value: 'File corrupted.'
    },
    {
      key: 4004,
      value: 'File unsupportec.'
    },
    {
      key: 51000,
      value: 'There is error in Form category.'
    },
    {
      key: 51001,
      value: 'There is error in Form status.'
    },
    {
      key: 51002,
      value: 'There is error in Form status history.'
    },
    {
      key: 51003,
      value: 'There is error in Form model transfer.'
    },
    {
      key: 51004,
      value: 'There is error in Form task.'
    },
    {
      key: 51005,
      value: 'There is error in Form action.'
    },
    {
      key: 51006,
      value: 'There is error in Form notification.'
    },
    {
      key: 51007,
      value: 'There is error in Form option.'
    },
    {
      key: 52001,
      value: 'User error.'
    },
    {
      key: 52002,
      value: 'Group error.'
    },
    {
      key: 52003,
      value: 'Organization error.'
    },
    {
      key: 53000,
      value: 'Notifiication error.'
    },
    {
      key: 54000,
      value: 'File error.'
    },
    {
      key: 6001,
      value: 'Internal server error.'
    },
    {
      key: 6002,
      value: 'User authentication error.'
    },
  ]

  return errorMessageMapper.find((x) => x.key === error.code)?.value ?? error.message

}