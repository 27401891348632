import { KeyValPair } from '@models/common'
import { GlobalContext } from '@providers/globalStore'
import { useContext } from 'react'

// TODO: remove later
import cdr from '../mockData/masterData/form.CDR.json'
import ei from '../mockData/masterData/form.EI.json'

import hwr from '../mockData/masterData/form.HWR.json'
import nea from '../mockData/masterData/form.NEA.json'
import ea from '../mockData/masterData/form.EA.json'
import qs from '../mockData/masterData/form.QS.json'
import woi from '../mockData/masterData/form.WOI.json'
import two from '../mockData/masterData/form._TWO.json'

function useGeneralOptions(): {
  getOptionsByKey: (key: string) => KeyValPair[]
} {
  const { state } = useContext(GlobalContext)
  const getOptionsByKey = (key: string) => {
    if (state.generalOptionGroupList) {
        return (
            state.generalOptionGroupList
                .find(x => x.name.toLowerCase() === key.toLowerCase())
                ?.generalOptions?.map(x => {
                    return { key: x.id, value: x.name, status: x.status, parentId: x.parentId }
                }) || []
        )
    }
    return []
  }

  return { getOptionsByKey }
}

export default useGeneralOptions
