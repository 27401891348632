import { Button, DialogContent, Stack } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import * as React from 'react'

export type CustomDialogProps = {
  loading?: boolean
  title: string
  children: JSX.Element | React.ReactNode
  isOpen: any
  handleClose: any
  onSubmit: any
}

const CustomDialog = (props: CustomDialogProps) => {
  return (
    <Dialog open={props.isOpen}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>{props.children}</DialogContent>
      <DialogActions>
        <Stack spacing={2} direction="row" sx={{ m: 2 }}>
          <Button
            disabled={props.loading}
            type="submit"
            fullWidth
            variant="contained"
            color="success"
            onClick={props.onSubmit}>
            submit
          </Button>

          <Button
            disabled={props.loading}
            type="reset"
            fullWidth
            variant="contained"
            color="warning"
            onClick={props.handleClose}>
            cancel
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

export default CustomDialog
