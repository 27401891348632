import { GlobalStateProvider } from '@providers/globalStore'
import { Suspense, useRef } from 'react'
import { BrowserRouter } from 'react-router-dom'
import CustomSpinner from './components/CustomSpinner'
import MainRoutes from './routes/routes'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { FormStateProvider } from '@providers/formStateProvider'
import moment from 'moment'
import { ReactKeycloakProvider } from '@react-keycloak/web'

import keycloak from './keycloak'
import jwt_decode from 'jwt-decode'
import UserService from '@services/user.service'
import useLocalStorage from '@hooks/useLocalStorage'
import { InitUserInfo, UserInfo } from '@services/model/user.model'

// Default Moment Format
moment.defaultFormat = 'D MMMM YYYY, HH:mm'

export default function App() {
  const [loginUser, setLoginUser] = useLocalStorage<UserInfo | undefined>('usr', undefined)
  const [contractNoId, setContractNoId] = useLocalStorage<string | undefined>('contract', undefined)
  const [workGroup, setWorkGroup] = useLocalStorage<string | undefined>('workgroup', undefined)
  const [email, setEmail] = useLocalStorage<string | undefined>('email', undefined)
  const isMounted = useRef(false)

  const eventLogger = (event: unknown, error: unknown) => {
    if (error) {
      console.log('keycloak error', event, error)
      setLoginUser({ ...InitUserInfo })
      localStorage.clear()
      location.href = '/'
      keycloak.logout()
    }
  }

  const tokenLogger = (tokens: any) => {
    var decoded = jwt_decode(tokens.token) as any
    var email = decoded.preferred_username as any
    if (email && !isMounted.current) {
      isMounted.current = true
      console.log("tokennLoggetr", tokenLogger)
      setEmail(email)
      UserService.GetUserInfo(email).then((userInfo: UserInfo) => {
        setLoginUser(userInfo)
      })
    }
  }

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{
        onLoad: 'login-required',
        checkLoginIframe: false,
      }}
      onEvent={eventLogger}
      onTokens={tokenLogger}>
      <GlobalStateProvider>
        <FormStateProvider>
          <BrowserRouter>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Suspense fallback={<CustomSpinner />}>
                <MainRoutes />
              </Suspense>
            </LocalizationProvider>
          </BrowserRouter>
        </FormStateProvider>
      </GlobalStateProvider>
    </ReactKeycloakProvider>
  )
}
