import apiGateway from '@utils/axiosInterceptor'
import { NotificationModel } from './model/notification.model'

const GetNotificationList = async (req: any) => {
  return await apiGateway
    .post<NotificationModel[]>(`/api/v1/notifications/list`, req)
    .then((rep) => {
      return rep.data
    })
}

const BatchMarkNotificationRead = async (ids: string[]) => {
  return await apiGateway
    .patch(`/api/v1/notifications`, {
      notifications: ids,
    })
    .then((rep) => {
      return rep.data
    })
}

const NotificationService = {
  GetNotificationList,
  BatchMarkNotificationRead,
}

export default NotificationService
