import FormContainer from '@components/form/container'
import FormController from '@components/form/controller'
import FormField from '@components/form/field'
import { Control } from 'react-hook-form'
import { FormWoiModel, WoiGeneralOptions } from '@services/model/form/form.WOI.model'
import FormTypography from '@components/form/typography'
import { useTranslation } from 'react-i18next'

export default ({ control }: { control: Control<FormWoiModel> }) => {
  const { t } = useTranslation()

  return (
    <FormContainer fkey={`woi-f`}>
      <FormField fieldName="Liquidated Damage ($/day)">
        <FormController
          controllerProps={{
            name: 'liquidatedDamage',
            control,
          }}>
          <FormTypography />
        </FormController>
      </FormField>
      <FormField fieldName=" Minimum Liquidated Damage">
        <FormController
          controllerProps={{
            name: 'minimumLiquidatedDamage',
            control,
          }}>
          <FormTypography />
        </FormController>
      </FormField>
      <FormField fieldName="Issue Letter for LD Imposation">
        <FormController
          controllerProps={{
            name: 'issueLetterForLdImposation',
            control,
          }}>
          <FormTypography type="binary" />
        </FormController>
      </FormField>
    </FormContainer>
  )
}
