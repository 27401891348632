import { KeyValPair } from '@models/common'
import {
  Button,
  IconButton,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material'
import GetValueWithKey from '@utils/getValueWithKey'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Fragment } from 'react'

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 600,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))

export default function TableBox(props: { columnValue: number[] | string[]; list?: KeyValPair[]; itemShown?: number }) {
  if (props.columnValue === null) {
    return <></>
  } else if (props.columnValue.length > 1) {
    const remainingItems = props.columnValue.map((x) => GetValueWithKey(x, props.list ?? []))
    const shownItems = props.itemShown ? props.columnValue.slice(0, props.itemShown).map((x) => GetValueWithKey(x, props.list ?? [])) : props.columnValue.slice(0, 1).map((x) => GetValueWithKey(x, props.list ?? []))
    return (
      <>
        {shownItems.join(', ')}
        <HtmlTooltip
          title={
            <Fragment>
              <Typography color="inherit">{remainingItems.join(', ')}</Typography>
            </Fragment>
          }>
          <IconButton sx={{ marginLeft: '2px', paddingX: '0px' }}>
            <InfoOutlinedIcon fontSize="small" />
          </IconButton>
        </HtmlTooltip>
      </>
    )
  } else {
    return <>{GetValueWithKey(props.columnValue[0], props.list ?? [])} </>
  }
}
