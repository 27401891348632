import { FormStatusEnum } from "@services/model/form/form.model"
import { GetFormStatusId } from "@utils/getFormStatusId"
import { templateMapping, templateMappingV2 } from "@utils/mmValue"
import { chunk, find, findLast, get, size, upperCase } from "lodash"
import moment from "moment"

export const getEaMatchedTemplates = (contractNoId: number, mmValue: string) => {
    let templateType: string[] = []
     // If contract name is 05/HY/2018(id: 7) and 08/HT/2021(id: 8), use templateMappingV2
    const mapping = contractNoId === 7 || contractNoId === 8 ? templateMappingV2 : templateMapping
    Object.keys(mapping).forEach((type) => {
      if (mapping[type].includes(mmValue)) {
        templateType.push(type)
      }
    })

    return templateType
}

export const getEaFormData = (data: any, mmType: string, optionList?: any) => {
    const { globalState , eaCategoryList = [], roadType = [] } = optionList

    const mmValue = get(data, 'typeOfMmWorksValue', '')
    const reviewPrelimResult = findLast(
        get(data, 'baseForm.formStatusHistories'),
        ({ formStatusId }) => {
          return GetFormStatusId(globalState?.formStatusList, [
            FormStatusEnum.FORM_EA_REVIEW_PRELIM_RESULT,
          ])?.includes(formStatusId)
        },
      )
      const endorsePrelimResult = findLast(
        get(data, 'baseForm.formStatusHistories'),
        ({ formStatusId }) => {
          return GetFormStatusId(globalState?.formStatusList, [
            FormStatusEnum.FORM_EA_ENDORSE_PRELIM_RESULT,
          ])?.includes(formStatusId)
        },
      )
      const reviewAuditAppeal = findLast(
        get(data, 'baseForm.formStatusHistories'),
        ({ formStatusId }) => {
          return GetFormStatusId(globalState?.formStatusList, [
            FormStatusEnum.FORM_EA_REVIEW_AUDIT_APPEAL,
          ])?.includes(formStatusId)
        },
      )
      const endorseAuditAppeal = findLast(
        get(data, 'baseForm.formStatusHistories'),
        ({ formStatusId }) => {
          return GetFormStatusId(globalState?.formStatusList, [
            FormStatusEnum.FORM_EA_ENDORSE_AUDIT_APPEAL,
          ])?.includes(formStatusId)
        },
      )
      const auditResultIssued = findLast(
        get(data, 'baseForm.formStatusHistories'),
        ({ formStatusId }) => {
          return GetFormStatusId(globalState?.formStatusList, [
            FormStatusEnum.FORM_EA_AUDIT_RESULT_ISSUED,
          ])?.includes(formStatusId)
        },
      )

      const auditingOffier = globalState.userMetaFullList?.find(
        (x) => x.userId === get(data, 'formNea.auditingOfficer'),
      )
      const checkingOfficer = globalState.userMetaFullList?.find(
        (x) => x.userId === get(data, 'formNea.checkingOfficer'),
      )

      return {
        ...data,
        mmType: mmType,
        mmTypeTitle: upperCase(mmValue),
        contractor: get(
          find(globalState?.contractList, { id: get(data, 'baseForm.contractNoId') }),
          'contractor',
          '',
        ),
        contract_title: get(
          find(globalState?.contractList, { id: get(data, 'baseForm.contractNoId') }),
          'contractTitle',
          '',
        ).replace('Highways Department Term Contract', ''),
        contract_no: get(
          find(globalState?.contractList, { id: get(data, 'baseForm.contractNoId') }),
          'contractNo',
          '',
        ),
        works_order_no: get(data, 'baseForm.workOrderNo', ''),
        monthYearOfPerformanceStandard: get(data, 'formNea.monthYearOfPerformanceStandard')
          ? moment(get(data, 'formNea.monthYearOfPerformanceStandard'))
              .format('YYYY MMMM')
              .toString() ?? ''
          : '',
        auditingOfficer: `${auditingOffier?.userName ?? ''} (${auditingOffier?.position ?? ''})`,
        checkingOfficer: `${checkingOfficer?.userName ?? ''} (${checkingOfficer?.position ?? ''})`,
        actualCheckingDate: get(data, 'actualCheckingDate')
          ? moment(get(data, 'actualCheckingDate')).format(moment.defaultFormat).toString() ?? ''
          : '',
        defectiveNo: size(get(data, 'formDeaList', [])),
        defective: get(data, 'defective', false) ? 1 : 0,
        definition:
          eaCategoryList?.find((x) => x.typeOfMmWorks === get(data, 'formNea.typeOfMmWorks'))
            ?.defectDefinition ?? '',
        otherInfo: get(data, 'otherInfo', ''),
        district: get(data, 'baseForm.districtId', [])
          .map((district) => {
            return get(find(globalState.districtList, { key: district }), 'value')
          })
          .join(','),
        neaStructureNo: get(data, 'neaStructureNo', ''),
        neaShapeLength: ['detailedInspection'].includes(mmType)
          ? get(data, 'neaShapeArea', '')
          : get(data, 'neaShapeLength', ''),
        neaLocation: get(data, 'neaLocation', ''),
        neaAuditCode: get(data, 'neaAuditCode', ''),
        neaGeoRegistrationNo: get(data, 'neaGeoRegistrationNo', ''),
        neaHydRegistrationNo: get(data, 'neaHydRegistrationNo', ''),
        neaVegetationId: get(data, 'neaVegetationId', ''),
        neaSampleNo: get(data, 'neaSampleNo', ''),
        planAreaOfStructure: get(data, 'planAreaOfStructure', ''),
        roadType: get(find(roadType, { key: get(data, 'neaRoadTypeId') }), 'value'),
        viableToConductInspection: get(data, 'viableToConductInspection', '') ? 'Available' : '-',

        reviewPrelimResultBy: get(
          find(globalState.userMetaFullList, { userId: get(reviewPrelimResult, 'actionBy') }),
          'userName',
          '',
        ),
        reviewPrelimResultDatetime: get(reviewPrelimResult, 'actionAt')
          ? moment(get(reviewPrelimResult, 'actionAt')).format(moment.defaultFormat).toString() ??
            ''
          : '',
        reviewPrelimResultPost: get(
          find(globalState.userMetaFullList, { userId: get(reviewPrelimResult, 'actionBy') }),
          'position',
          '',
        ),

        endorsePrelimResultBy: get(
          find(globalState.userMetaFullList, { userId: get(endorsePrelimResult, 'actionBy') }),
          'userName',
          '',
        ),
        endorsePrelimResultDatetime: get(endorsePrelimResult, 'actionAt')
          ? moment(get(endorsePrelimResult, 'actionAt')).format(moment.defaultFormat).toString() ??
            ''
          : '',
        endorsePrelimResultPost: get(
          find(globalState.userMetaFullList, { userId: get(endorsePrelimResult, 'actionBy') }),
          'position',
          '',
        ),

        reviewAuditAppealBy: get(
          find(globalState.userMetaFullList, { userId: get(reviewAuditAppeal, 'actionBy') }),
          'userName',
          '',
        ),
        reviewAuditAppealDatetime: get(reviewAuditAppeal, 'actionAt')
          ? moment(get(reviewAuditAppeal, 'actionAt')).format(moment.defaultFormat).toString() ?? ''
          : '',

        endorseAuditAppealBy: get(
          find(globalState.userMetaFullList, { userId: get(endorseAuditAppeal, 'actionBy') }),
          'userName',
          '',
        ),
        endorseAuditAppealDatetime: get(endorseAuditAppeal, 'actionAt')
          ? moment(get(endorseAuditAppeal, 'actionAt')).format(moment.defaultFormat).toString() ??
            ''
          : '',

        auditResultIssuedBy: get(
          find(globalState.userMetaFullList, { userId: get(auditResultIssued, 'actionBy') }),
          'userName',
          '',
        ),
        auditResultIssuedDatetime: get(auditResultIssued, 'actionAt')
          ? moment(get(auditResultIssued, 'actionAt')).format(moment.defaultFormat).toString() ?? ''
          : '',
        auditResultIssuedPost: get(
          find(globalState.userMetaFullList, { userId: get(auditResultIssued, 'actionBy') }),
          'position',
          '',
        ),

        eaImages: chunk(get(data, 'eaImages', []), 2)?.map((image, index) => {
          return {
            index: index * 2 + 1,
            file: image,
          }
        }),

        formDeaList: get(data, 'formDeaList', []).map(
          ({ images = [], defectsIdentified = 0, ...res }) => {
            const defect = find(
              globalState.generalOptionGroupList
                ?.find((x) => x.name === 'Form EA - Category')
                ?.generalOptions?.find((x) => x.id === get(data, 'formNea.typeOfMmWorks'))
                ?.defectOptions,
              { id: defectsIdentified },
            )
            return {
              ...res,
              defectsIdentified: `${get(defect, 'defectCode', '')} - ${get(
                defect,
                'description',
                '',
              )}`,
              images: images === null ? [] : images,
            }
          },
        ),

        auditLocationPlanImages: get(data, 'auditLocationPlanImages', []),
        eaAppealImages: get(data, 'eaAppealImages', []),
        appealInfo: get(data, 'appealInfo', ''),
        summaryMapBase64: get(data, 'summaryMapBase64', ''),
      }
}
