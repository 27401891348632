import { Category } from '@mui/icons-material'
import { useMediaQuery, useTheme } from '@mui/material'
import { GlobalContext } from '@providers/globalStore'
import { RightsCategory } from '@services/model/form/form.model'
import { useContext } from 'react'
import { cloneDeep } from 'lodash'

import NavGroup from './nav/navGroup'

export interface SideMenuChildren {
  id: string
  title: string
  category?: string
  caption?: string
  type: 'item' | 'sub-item' | 'collapse'
  url?: string
  urlPattern?: string

  // Here we use mui icons
  acronym?: string
  colorCode?: string
  children?: SideMenuChildren[]

  // hidden field will not be used for type collapse
  hidden?: boolean
}

export interface SideMenu {
  id: string
  title: string
  caption: string
  hidden?: boolean
  type: 'group'
  children: SideMenuChildren[]
}

const allRights = true

const module_1: SideMenu = {
  id: 'm_1',
  title: 'Module (i)',
  caption: 'Engineer Inspection Records for Management and Maintenance (M&M) works',
  type: 'group',
  children: [
    {
      id: 'engineer-inspection-assignment',
      title: 'Engineer Inspection Assignment',
      type: 'collapse',
      category: RightsCategory.FORM_EIA,
      acronym: 'EIA',
      urlPattern: '/engineer-inspection-assignment',
      url: '/engineer-inspection-assignment/overview',
      colorCode: '#DFA400',
      children: [
        {
          id: 'eia-dashboard',
          title: 'Dashboard',
          type: 'sub-item',
          url: '/engineer-inspection-assignment/dashboard',
          colorCode: '#DFA400',
        },
        {
          id: 'eia-my-action',
          title: 'My action',
          type: 'sub-item',
          url: '/engineer-inspection-assignment/my-action',
          colorCode: '#DFA400',
        },
        {
          id: 'eia-all-record',
          title: 'All record',
          type: 'sub-item',
          url: '/engineer-inspection-assignment/all-record',
          colorCode: '#DFA400',
        },
      ],
    },
    {
      id: 'engineer-inspection',
      title: 'Engineer Inspection',
      type: 'collapse',
      category: RightsCategory.FORM_EI,
      acronym: 'EI',
      urlPattern: '/engineer-inspection',
      url: '/engineer-inspection/overview',
      colorCode: '#FFCA61',
      children: [
        {
          id: 'ei-dashboard',
          title: 'Dashboard',
          type: 'sub-item',
          url: '/engineer-inspection/dashboard',
          colorCode: '#FFCA61',
        },
        {
          id: 'ei-my-action',
          title: 'My action',
          type: 'sub-item',
          url: '/engineer-inspection/my-action',
          colorCode: '#FFCA61',
        },
        {
          id: 'ei-all-record',
          title: 'All record',
          type: 'sub-item',
          url: '/engineer-inspection/all-record',
          colorCode: '#FFCA61',
        },
      ],
    },
    {
      id: 'notification-form-of-defect',
      title: 'Notification Form of Defect',
      type: 'collapse',
      category: RightsCategory.FORM_NF,
      acronym: 'NF',
      urlPattern: '/notification-form-of-defect',
      url: '/notification-form-of-defect/overview',
      colorCode: '#98DFF6',
      children: [
        {
          id: 'nf-dashboard',
          title: 'Dashboard',
          type: 'sub-item',
          url: '/notification-form-of-defect/dashboard',
          colorCode: '#98DFF6',
        },
        {
          id: 'nf-my-action',
          title: 'My action',
          type: 'sub-item',
          url: '/notification-form-of-defect/my-action',
          colorCode: '#98DFF6',
        },
        {
          id: 'nf-all-record',
          title: 'All record',
          type: 'sub-item',
          url: '/notification-form-of-defect/all-record',
          colorCode: '#98DFF6',
        },
      ],
    },
    {
      id: 'default-notice-for-non-compliance',
      title: 'Default Notice for Non-Compliance',
      type: 'collapse',
      acronym: 'DN',
      category: RightsCategory.FORM_DN,
      urlPattern: '/default-notice-for-non-compliance',
      url: '/default-notice-for-non-compliance/overview',
      colorCode: '#FF6464',
      children: [
        {
          id: 'dn-dashboard',
          title: 'Dashboard',
          type: 'sub-item',
          url: '/default-notice-for-non-compliance/dashboard',
          colorCode: '#FF6464',
        },
        {
          id: 'dn-my-action',
          title: 'My action',
          type: 'sub-item',
          url: '/default-notice-for-non-compliance/my-action',
          colorCode: '#FF6464',
        },
        {
          id: 'dn-all-record',
          title: 'All record',
          type: 'sub-item',
          url: '/default-notice-for-non-compliance/all-record',
          colorCode: '#FF6464',
        },
      ],
    },
    {
      id: 'application-of-default-notice-for-non-compliance',
      title: 'Default Notice for Non-Compliance Application',
      type: 'collapse',
      category: RightsCategory.FORM_DNA,
      acronym: 'DNA',
      colorCode: '#FFA264',
      urlPattern: '/application-of-default-notice-for-non-compliance',
      url: '/application-of-default-notice-for-non-compliance/overview',
      children: [
        {
          id: 'dna-dashboard',
          title: 'Dashboard',
          type: 'sub-item',
          url: '/application-of-default-notice-for-non-compliance/dashboard',
          colorCode: '#FFA264',
        },
        {
          id: 'dna-my-action',
          title: 'My action',
          type: 'sub-item',
          url: '/application-of-default-notice-for-non-compliance/my-action',
          colorCode: '#FFA264',
        },
        {
          id: 'dna-all-record',
          title: 'All record',
          type: 'sub-item',
          url: '/application-of-default-notice-for-non-compliance/all-record',
          colorCode: '#FFA264',
        },
      ],
    },
  ],
}

const module_2: SideMenu = {
  id: 'm_2',
  title: 'Module (ii)',
  caption: 'Engineer Audit Record for M&M works',
  type: 'group',
  children: [
    {
      id: 'notification-of-engineer-audit',
      title: 'Notification of Engineer Audit',
      type: 'collapse',
      category: RightsCategory.FORM_NEA,
      acronym: 'NEA',
      urlPattern: '/notification-of-engineer-audit',
      url: '/notification-of-engineer-audit/overview',
      colorCode: '#6BC950',
      children: [
        {
          id: 'nea-dashboard',
          title: 'Dashboard',
          type: 'sub-item',
          url: '/notification-of-engineer-audit/dashboard',
          colorCode: '#6BC950',
        },
        {
          id: 'nea-my-action',
          title: 'My action',
          type: 'sub-item',
          url: '/notification-of-engineer-audit/my-action',
          colorCode: '#6BC950',
        },
        {
          id: 'nea-all-record',
          title: 'All record',
          type: 'sub-item',
          url: '/notification-of-engineer-audit/all-record',
          colorCode: '#6BC950',
        },
      ],
    },
    {
      id: 'engineer-audit',
      title: 'Engineer Audit',
      type: 'collapse',
      category: RightsCategory.FORM_EA,
      acronym: 'EA',
      urlPattern: '/engineer-audit',
      url: '/engineer-audit/overview',
      colorCode: '#E65100',
      children: [
        {
          id: 'ea-dashboard',
          title: 'Dashboard',
          type: 'sub-item',
          url: '/engineer-audit/dashboard',
          colorCode: '#E65100',
        },
        {
          id: 'ea-my-action',
          title: 'My action',
          type: 'sub-item',
          url: '/engineer-audit/my-action',
          colorCode: '#E65100',
        },
        {
          id: 'ea-all-record',
          title: 'All record',
          type: 'sub-item',
          url: '/engineer-audit/all-record',
          colorCode: '#E65100',
        },
      ],
    },
    {
      id: 'quarterly-summary',
      title: 'Quarterly Summary',
      type: 'collapse',
      category: RightsCategory.FORM_QS,
      acronym: 'QS',
      urlPattern: '/quarterly-summary',
      url: '/quarterly-summary/overview',
      colorCode: '#5DC3D4',
      children: [
        {
          id: 'qs-dashboard',
          title: 'Dashboard',
          type: 'sub-item',
          url: '/quarterly-summary/dashboard',
          colorCode: '#5DC3D4',
        },
        {
          id: 'qs-my-action',
          title: 'My action',
          type: 'sub-item',
          url: '/quarterly-summary/my-action',
          colorCode: '#5DC3D4',
        },
        {
          id: 'qs-all-record',
          title: 'All record',
          type: 'sub-item',
          url: '/quarterly-summary/all-record',
          colorCode: '#5DC3D4',
        },
      ],
    },
    {
      id: 'defect-identified-in-engineer-audit',
      title: 'Defect Identified in Engineer Audit',
      type: 'collapse',
      category: RightsCategory.FORM_DEA,
      acronym: 'DEA',
      urlPattern: '/defect-identified-in-engineer-audit',
      url: '/defect-identified-in-engineer-audit/',
      colorCode: '#E65100',
      hidden: true,
    },
  ],
}

const module_3: SideMenu = {
  id: 'm_3',
  title: 'Module (iii)',
  caption: 'Site Supervision Record',
  type: 'group',
  children: [
    {
      id: 'contractor-daily-report',
      title: 'Contractor Daily Report',
      type: 'collapse',
      category: RightsCategory.FORM_CDR,
      acronym: 'CDR',
      urlPattern: '/contractor-daily-report',
      url: '/contractor-daily-report/overview',
      colorCode: '#2ecd6f',
      children: [
        {
          id: 'cdr-dashboard',
          title: 'Dashboard',
          type: 'sub-item',
          url: '/contractor-daily-report/dashboard',
          colorCode: '#2ecd6f',
        },
        {
          id: 'cdr-my-action',
          title: 'My action',
          type: 'sub-item',
          url: '/contractor-daily-report/my-action',
          colorCode: '#2ecd6f',
        },
        {
          id: 'cdr-all-record',
          title: 'All record',
          type: 'sub-item',
          url: '/contractor-daily-report/all-record',
          colorCode: '#2ecd6f',
        },
      ],
    },
    {
      id: 'site-diary',
      title: 'Site Diary',
      type: 'collapse',
      category: RightsCategory.FORM_SD,
      acronym: 'SD',
      colorCode: '#d59bee',
      urlPattern: '/site-diary',
      url: '/site-diary/overview',
      children: [
        {
          id: 'sd-dashboard',
          title: 'Dashboard',
          type: 'sub-item',
          url: '/site-diary/dashboard',
          colorCode: '#d59bee',
        },
        {
          id: 'sd-my-action',
          title: 'My action',
          type: 'sub-item',
          url: '/site-diary/my-action',
          colorCode: '#d59bee',
        },
        {
          id: 'sd-all-record',
          title: 'All record',
          type: 'sub-item',
          url: '/site-diary/all-record',
          colorCode: '#d59bee',
        },
      ],
    },
    {
      id: 'site-check-record',
      title: 'Site Check Record',
      type: 'collapse',
      category: RightsCategory.FORM_SCR,
      acronym: 'SCR',
      colorCode: '#96a8ec',
      urlPattern: '/site-check-record',
      url: '/site-check-record/overview',
      children: [
        {
          id: 'scr-dashboard',
          title: 'Dashboard',
          type: 'sub-item',
          url: '/site-check-record/dashboard',
          colorCode: '#96a8ec',
        },
        {
          id: 'scr-my-action',
          title: 'My action',
          type: 'sub-item',
          url: '/site-check-record/my-action',
          colorCode: '#96a8ec',
        },
        {
          id: 'scr-all-record',
          title: 'All record',
          type: 'sub-item',
          url: '/site-check-record/all-record',
          colorCode: '#96a8ec',
        },
      ],
    },
  ],
}

const module_4: SideMenu = {
  id: 'm_4',
  title: 'Module (iv)',
  caption: 'Hidden Works Record',
  type: 'group',
  children: [
    {
      id: 'hidden-works-record',
      title: 'Hidden Works Record',
      type: 'collapse',
      category: RightsCategory.FORM_HWR,
      acronym: 'HWR',
      colorCode: '#d0e9d0',
      urlPattern: '/hidden-works-record',
      url: '/hidden-works-record/overview',
      children: [
        {
          id: 'hwr-dashboard',
          title: 'Dashboard',
          type: 'sub-item',
          url: '/hidden-works-record/dashboard',
          colorCode: '#d0e9d0',
        },
        {
          id: 'hwr-my-action',
          title: 'My action',
          type: 'sub-item',
          url: '/hidden-works-record/my-action',
          colorCode: '#d0e9d0',
        },
        {
          id: 'hwr-all-record',
          title: 'All record',
          type: 'sub-item',
          url: '/hidden-works-record/all-record',
          colorCode: '#d0e9d0',
        },
      ],
    },
  ],
}

const module_6: SideMenu = {
  id: 'm_6',
  title: 'Module (vi)',
  caption: 'Works Order Information System',
  type: 'group',
  children: [
    {
      id: 'temporary-works-order',
      title: 'Temporary Works Order',
      type: 'collapse',
      category: RightsCategory.FORM_TWO,
      acronym: 'TWO',
      colorCode: '#75E5CA',
      urlPattern: '/temporary-works-order',
      url: '/temporary-works-order/overview',
      children: [
        {
          id: 'two-dashboard',
          title: 'Dashboard',
          type: 'sub-item',
          url: '/temporary-works-order/dashboard',
          colorCode: '#75E5CA',
        },
        {
          id: 'two-my-action',
          title: 'My action',
          type: 'sub-item',
          url: '/temporary-works-order/my-action',
          colorCode: '#75E5CA',
        },
        {
          id: 'two-all-record',
          title: 'All record',
          type: 'sub-item',
          url: '/temporary-works-order/all-record',
          colorCode: '#75E5CA',
        },
      ],
    },
    {
      id: 'works-order-information',
      title: 'Works Order Information',
      type: 'collapse',
      category: RightsCategory.FORM_WOI,
      acronym: 'WOI',
      colorCode: '#b2a3b5',
      urlPattern: '/works-order-information',
      url: '/works-order-information/overview',
      children: [
        {
          id: 'woi-dashboard',
          title: 'Dashboard',
          type: 'sub-item',
          url: '/works-order-information/dashboard',
          colorCode: '#b2a3b5',
        },
        {
          id: 'woi-my-action',
          title: 'My action',
          type: 'sub-item',
          url: '/works-order-information/my-action',
          colorCode: '#b2a3b5',
        },
        {
          id: 'woi-all-record',
          title: 'All record',
          type: 'sub-item',
          url: '/works-order-information/all-record',
          colorCode: '#b2a3b5',
        },
      ],
    },
    {
      id: 'works-order-update',
      title: 'Works Order Update',
      type: 'collapse',
      category: RightsCategory.FORM_WOU,
      acronym: 'WOU',
      colorCode: '#f2debd',
      urlPattern: '/works-order-update',
      url: '/works-order-update/overview',
      children: [
        {
          id: 'wou-dashboard',
          title: 'Dashboard',
          type: 'sub-item',
          url: '/works-order-update/dashboard',
          colorCode: '#f2debd',
        },
        {
          id: 'wou-my-action',
          title: 'My action',
          type: 'sub-item',
          url: '/works-order-update/my-action',
          colorCode: '#f2debd',
        },
        {
          id: 'wou-all-record',
          title: 'All record',
          type: 'sub-item',
          url: '/works-order-update/all-record',
          colorCode: '#f2debd',
        },
      ],
    },
  ],
}

const module_5: SideMenu = {
  id: 'm_5',
  title: 'Module (v)',
  caption: 'Report on Completion of Works',
  type: 'group',
  children: [
    {
      id: 'completion-report',
      title: 'Completion Report',
      type: 'collapse',
      category: RightsCategory.FORM_CR,
      acronym: 'CR',
      colorCode: '#ff9100',
      urlPattern: '/completion-report',
      url: '/completion-report/overview',
      children: [
        {
          id: 'cr-dashboard',
          title: 'Dashboard',
          type: 'sub-item',
          url: '/completion-report/dashboard',
          colorCode: '#ff9100',
        },
        {
          id: 'cr-my-action',
          title: 'My action',
          type: 'sub-item',
          url: '/completion-report/my-action',
          colorCode: '#ff9100',
        },
        {
          id: 'cr-all-record',
          title: 'All record',
          type: 'sub-item',
          url: '/completion-report/all-record',
          colorCode: '#ff9100',
        },
      ],
    },
  ],
}


export const menuItems: SideMenu[] = [
  module_1,
  module_2,
  module_3,
  module_4,
  module_5,
  module_6,
]

export type MenuListProps = {
  isMobile?: boolean
}

const MenuList = (props: MenuListProps) => {
  const globalcontext = useContext(GlobalContext)
  const theme = useTheme()
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'))
  const { hasRightByCatAndCode } = useContext(GlobalContext)

  const navItems = cloneDeep(menuItems).map((item) => {
    item.children.map((x) => {
      if (x.category) {
        x.hidden = !hasRightByCatAndCode(x.category, ['C', 'R'])
      } else {
        //x.hidden = true
      }
    })

    if (props.isMobile) {
      item.title = ''
      item.caption = ''
    }

    item.hidden = item.children.filter((x) => x.hidden === true)?.length === item.children.length

    return (
      <NavGroup item={item} leftDrawerOpened={globalcontext.state.leftDrawerOpened} key={item.id} />
    )
  })

  return <>{matchUpMd ? navItems : navItems}</>
}

export default MenuList
