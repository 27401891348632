import { Fragment, useContext, useEffect, useRef, useState } from 'react'

import { useForm, useWatch } from 'react-hook-form'
import { GlobalContext } from '@providers/globalStore'
import FormField from '@components/form/field'
import FormContainer from '@components/form/container'
import { useTranslation } from 'react-i18next'
import FormMultipleSelect from '@components/form/multipleSelect'
import FormSelect from '@components/form/select'
import FormController from '@components/form/controller'
import { NavigateTo } from '@utils/navigate'
import uuid from 'react-uuid'
import { UserFormModel, InitUserForm } from '@services/model/admin.model'
import AdminService from '@services/admin.service'
import { useNavigate, useParams } from 'react-router-dom'
import FormFreeText from '@components/form/freeText'
import useAPIFetch from '@hooks/useAPIFetch'
import { useSnackbar } from 'notistack'
import { DevTool } from '@hookform/devtools'
import { styled, SxProps, Theme, useTheme } from '@mui/material/styles'
import FormBinarySelect from '@components/form/binarySelect'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Box, Typography, Divider, Button, Grid, Stack, useMediaQuery } from '@mui/material'
import { GetOrganizationOptions } from '@utils/organizationOptions'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import DialogButton from '@components/button/DialogButton'
import FormDialog from '@components/form/dialog_v2'
import ValidationToast from '@components/form/ValidationToast'
import FormTable from '@components/form/table'
import UserService from '@services/user.service'
import { KeyValPair } from '@models/common'
import LoadingPopup from '@components/form/LoadingDialog'
import { get, map } from 'lodash'

const UUID = uuid()
let readonly = false

type DialogContextProps = {
  isOpen: boolean
  title?: JSX.Element
  toolbarStyle?: SxProps<Theme>
  children?: JSX.Element
  buttons?: JSX.Element
}

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'block',
  margin: theme.spacing(1),
  paddingTop: theme.spacing(0),
  paddingBottom: theme.spacing(1),
  [theme.breakpoints.up('md')]: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
}))

const StyledDivider = styled(Divider)(() => ({
  '&::before, &::after': {
    borderColor: 'black',
  },
}))

const ButtonGroupBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  pt: 2,
}))

const SwitcherButton = styled(Button)(() => ({
  color: '#000000',
  '&:hover': {
    backgroundColor: '#e6e6e6',
  },
}))

export default function UserForm() {
  const { state: globalState, userInfo, dispatch: globalAction } = useContext(GlobalContext)
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const theme = useTheme()
  const { id: userId } = useParams<string>()
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))

  const validationSchema = yup
    .object()
    .shape({
      email: yup.string().required(t('Email is required')),
      firstName: yup.string().required(t('Full name is required')),
      position: yup.string().required(t('Position is required')),
      organizationId: yup.number().required(t('Organization is required')),
      // teams: yup.array().nullable().min(1).required(t('Teams is required')),
      // districts: yup.array().nullable().min(1).required(t('Districts is required')),
      // roles: yup.array().nullable().min(1).required(t('Roles is required')),
    })
    .required()

  const { control, getValues, reset, trigger } = useForm<UserFormModel>({
    defaultValues: { ...InitUserForm },
    mode: 'all',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    resolver: yupResolver(validationSchema),
  })

  const isMounted = useRef(false)
  const { setRequest, isLoading } = useAPIFetch()
  const { setRequest: setOptionsRequest, isLoading: isLoadingRequest } = useAPIFetch()

  const InitDialogContent: DialogContextProps = {
    isOpen: false,
    title: <></>,
  }
  const [dialogContext, setDialogContext] = useState<DialogContextProps>(InitDialogContent)
  const [teamList, setTeamList] = useState<KeyValPair[]>([])
  const [districtList, setDistrictList] = useState<KeyValPair[]>([])
  const [roleList, setRoleList] = useState<KeyValPair[]>([])

  const handleDialogClose = () => {
    setDialogContext(InitDialogContent)
  }

  const reloadOptions = async () => {
    globalState.contractList?.map((c) => {
      setOptionsRequest({
        callback: async () => {
          const res = await UserService.GetFullGroupTypeList()
          const teams = res
            .filter((r) => r.name.toLowerCase() === 'team')
            ?.map(({ groups = [], contractNoId = '' }) =>
              groups
                .filter((group) => group.status)
                .map(({ id, name, status }) => ({
                  key: id,
                  value: name,
                  status: status,
                  contractNoId: contractNoId,
                })),
            )
            ?.flat()
          setTeamList(teams)

          const districts = res
            .filter((r) => r.name.toLowerCase() === 'district')
            ?.map(({ groups = [], contractNoId = '' }) =>
              groups
                .filter((group) => group.status)
                .map(({ id, name, status }) => ({
                  key: id,
                  value: name,
                  status: status,
                  contractNoId: contractNoId,
                })),
            )
            ?.flat()
          setDistrictList(districts)

          let roles: KeyValPair[] = []
          for (let i in globalState.contractList) {
            const contractId = globalState.contractList[i].id
            const res2 = await UserService.GetRoleMetaListByContract(contractId)

            const temp = res2.map((r) => ({ ...r, contractNoId: contractId }))
            roles = roles.concat(temp)
          }

          setRoleList(roles)
        },
      })
    })
  }

  const reload = async () => {
    setRequest({
      callback: async () => {
        if (userId) {
          await AdminService.GetUserForm(userId)
            .then(async (f) => {
              if (f) reset(f)
            })
            .catch((err) => {
              enqueueSnackbar(err.response.data.message, {
                variant: 'error',
                autoHideDuration: null,
              })
            })
        }
        isMounted.current = true
      },
    })
  }

  useEffect(() => {
    reloadOptions()
  }, [])

  useEffect(() => {
    reloadOptions()
  }, [globalState])

  useEffect(() => {
    if (userId == null) {
      reset({ ...InitUserForm })
    }

    reload()
  }, [userId])

  const handleBack = async (event: any) => {
    NavigateTo(navigate, '/admin')
  }

  const onClickChangePassword = () => {
    setDialogContext({
      isOpen: true,
      title: <Typography variant="body1">Reset Password</Typography>,
      children: <Typography>Are you sure you need to reset password?</Typography>,
      buttons: (
        <Stack {...(!matchDownMd && { direction: 'row' })} spacing={2}>
          <DialogButton
            disabled={isLoading || isLoadingRequest}
            onClick={async (event) => {
              setRequest({
                callback: async () => {
                  await AdminService.resetUserPassword(getValues('email'))
                    .then(async (f) => {
                      enqueueSnackbar('Password Changed!', {
                        variant: 'success',
                        autoHideDuration: null,
                      })
                    })
                    .catch((err) => {
                      enqueueSnackbar(err.response.data.message, {
                        variant: 'error',
                        autoHideDuration: null,
                      })
                    })
                },
              })
              handleDialogClose()
            }}>
            {t('Confirm')}
          </DialogButton>
          <DialogButton onClick={handleDialogClose}>{t('Cancel')}</DialogButton>
        </Stack>
      ),
    })
  }

  const handleSubmitDialog = async () => {
    if (!userId) {
      setDialogContext({
        isOpen: true,
        title: <Typography variant="body1">Create User</Typography>,
        children: <Typography>You are going to create user.</Typography>,
        buttons: (
          <Stack {...(!matchDownMd && { direction: 'row' })} spacing={2}>
            <DialogButton
              disabled={isLoading}
              onClick={async (event) => {
                if (!(await trigger())) {
                  handleDialogClose()
                  return
                }
                var ff = getValues()
                console.log('ff', ff)
                if (ff.status === undefined) {
                  ff.status = true
                }
                setRequest({
                  callback: async () => {
                    await AdminService.SaveCreateUser(ff).then((resp) => {
                      enqueueSnackbar(t('Record Saved'), { variant: 'success' })
                      NavigateTo(navigate, '/admin')
                    })
                  },
                })
                handleDialogClose()
              }}>
              {t('Confirm')}
            </DialogButton>
            <DialogButton onClick={handleDialogClose}>{t('Cancel')}</DialogButton>
          </Stack>
        ),
      })
    } else {
      if (!(await trigger())) {
        console.log('in trigger')
        handleDialogClose()
        return
      }
      var ff = getValues()
      console.log('ff', ff)
      if (ff.status === undefined) {
        ff.status = true
      }
      setRequest({
        callback: async () => {
          await AdminService.SaveCreateUser(ff).then((resp) => {
            enqueueSnackbar(t('Record Saved'), { variant: 'success' })
            NavigateTo(navigate, '/admin')
          })
        },
      })
    }
  }

  return (
    <Fragment>
      <Box
        sx={{
          position: 'relative',
        }}>
        <LoadingPopup open={isLoading || isLoadingRequest} />
        <StyledBox>
          <Box
            sx={{
              marginLeft: 'auto',
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            <Typography
              variant={matchDownMd ? 'h6' : 'h4'}
              sx={{ fontWeight: 'bold', margin: theme.spacing(6) }}>
              {!userId ? t('Add User') : t('Edit User')}
            </Typography>
            {userId && (
              <Button
                variant="outlined"
                sx={{ fontWeight: 'bold', margin: theme.spacing(6) }}
                onClick={onClickChangePassword}>
                {t('Reset Password')}
              </Button>
            )}
          </Box>
          <FormContainer fkey={`${UUID}-user-a`}>
            <FormField fieldName="Email">
              <FormController
                controllerProps={{
                  name: 'email',
                  control,
                }}>
                <FormFreeText
                  textfieldProps={{ fullWidth: true }}
                  uneditable={readonly || !!userId}
                />
              </FormController>
            </FormField>

            <FormField fieldName="Full Name">
              <FormController
                controllerProps={{
                  name: 'firstName',
                  control,
                }}>
                <FormFreeText textfieldProps={{ fullWidth: true }} uneditable={readonly} />
              </FormController>
            </FormField>

            <FormField fieldName="Position">
              <FormController
                controllerProps={{
                  name: 'position',
                  control,
                }}>
                <FormFreeText textfieldProps={{ fullWidth: true }} uneditable={readonly} />
              </FormController>
            </FormField>
            <FormField fieldName="Organisation">
              <FormController
                controllerProps={{
                  name: 'organizationId',
                  control,
                }}>
                <FormSelect options={GetOrganizationOptions() || []} uneditable={readonly} />
              </FormController>
            </FormField>

            {userId ? (
              <FormField fieldName="Status">
                <FormController
                  controllerProps={{
                    name: 'status',
                    control,
                  }}>
                  <FormBinarySelect
                    binaryOptionNames={{ yes: t('Enabled'), no: t('Disabled') }}
                    uneditable={readonly}
                  />
                </FormController>
              </FormField>
            ) : (
              <></>
            )}

            <FormTable
              columns={[
                {
                  field: 'contractNoId',
                  displayName: 'Contract',
                  type: 'selectWithSearch',
                  width: '150px',
                  options: globalState.contractList?.map(({ id, contractNo }) => {
                    return { key: id, value: contractNo }
                  }),
                  nonDuplicates: true,
                  renderKey: true,
                },
                {
                  field: 'role',
                  displayName: 'Role',
                  type: 'multipleSelect',
                  options: roleList || [],
                  optionsFilterBy: 'contractNoId',
                  width: '250px',
                },
                {
                  field: 'teams',
                  displayName: 'Team',
                  type: 'multipleSelect',
                  options: teamList || [],
                  optionsFilterBy: 'contractNoId',
                  width: '250px',
                },
                {
                  field: 'districts',
                  displayName: 'District',
                  type: 'multipleSelect',
                  options: districtList || [],
                  optionsFilterBy: 'contractNoId',
                  width: '250px',
                },
              ]}
              height="80"
              fieldArrayProps={{
                name: 'contractRole',
                control,
              }}
              rowFilter={(row, index) => userInfo.allRights || (!userInfo.allRights && get(row, 'contractNoId') === globalState.contractNoId)}
              removeAddButton={!userInfo.allRights}
            />
          </FormContainer>
          <Grid>
            <StyledDivider>{t('')}</StyledDivider>
            <ButtonGroupBox>
              <SwitcherButton
                disabled={false}
                onClick={handleBack}
                startIcon={<ArrowBackIosNewIcon />}>
                {!userId ? t('Back') : t('Back')}
              </SwitcherButton>
              <Box sx={{ flex: '1 1 auto' }} />
              <SwitcherButton
                endIcon={<ArrowForwardIosIcon />}
                onClick={handleSubmitDialog}
                disabled={false}>
                {!userId ? t('Submit') : t('Save')}
              </SwitcherButton>
            </ButtonGroupBox>
          </Grid>
          <DevTool control={control} />
        </StyledBox>
      </Box>
      <FormDialog
        key={'dialog'}
        open={dialogContext.isOpen}
        onClose={handleDialogClose}
        toolbarStyle={dialogContext.toolbarStyle}
        title={dialogContext.title}
        buttons={dialogContext.buttons}
        children={dialogContext.children}
      />
      <ValidationToast control={control} />
    </Fragment>
  )
}
