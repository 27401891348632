import {
  FormCdrModel,
  InitCDRForm,
  InitWorkActivity,
  WorkActivity,
  CDRGeneralOptions,
} from '@services/model/form/form.CDR.model'
import { Fragment, useContext, useEffect, useState, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useFieldArray, useForm } from 'react-hook-form'
import { GlobalContext } from '@providers/globalStore'
import { FormPartComponents } from '@models/common'
import { useTranslation } from 'react-i18next'
import useQuery from '@hooks/useQuery'
import { QueryStepParser } from '@utils/queryStepParser'
import useAPIFetch from '@hooks/useAPIFetch'
import FormCDRService from '@services/formService/form.CDR.service'
import { ContractNo, InitWorkActivityDetail } from '@services/model/contract.model'
import { useSnackbar } from 'notistack'
import { NavigateTo } from '@utils/navigate'
import { DevTool } from '@hookform/devtools'
import useFormSwitcher from '@hooks/useFormSwitcher'
import FormService from '@services/form.service'
import useGeneralOptions from '@hooks/useGeneralOptions'
import CDRPartA from './part-a'
import CDRPartB from './part-b'
import CDRPartC from './part-c'
import CustomDialog from '@components/CustomDialog'
import { FormStatusEnum } from '@services/model/form/form.model'
import { FormContext } from '@providers/formStateProvider'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import ValidationToast from '@components/form/ValidationToast'
import getFormValidationSchema from '@utils/formValidationSchema'
import { useMediaQuery, useTheme } from '@mui/material'
import { find, get } from 'lodash'
import useLocalStorage from '@hooks/useLocalStorage'

const CDRForm = () => {
  const { state: globalState, userInfo, dispatch: globalAction } = useContext(GlobalContext)
  const { state: formState, dispatch: formStateAction } = useContext(FormContext)

  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const isMounted = useRef(false)

  // extracting id from params
  let { id: formId } = useParams<string>()

  // extracting step from query
  const query = useQuery()
  const step = QueryStepParser(query.get('step'))
  const { t } = useTranslation()
  const [storageContractId, setStorageContractId] = useLocalStorage<string | undefined>(
    'contract',
    undefined,
  )
  const { setRequest, isLoading } = useAPIFetch()
  const { setRequest: setOptionRequest, isLoading: isLoadingOption } = useAPIFetch()
  const theme = useTheme()
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'))

  const [contractInfo, setContractInfo] = useState<ContractNo>()

  const validationSchemaConditions = [
    {
      formStatusList: [FormStatusEnum.FORM_CDR_DRAFT],
      validationSchema: yup.object().shape({
        baseForm: yup.object().shape({
          contractNoId: yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .moreThan(0, t('Contract No. is required'))
            .required(),
          teamId: yup.array().nullable().min(1).required(t('Team is required')),
          districtId: yup.array().nullable().min(1).required(t('District is required')),
        }),
        formDate: yup.date().required(t('Date is required')),
        shiftType: yup.number().when(['baseForm'], {
          is: (baseForm) =>
            globalState.contractList?.find((contract) => contract.id === baseForm.id)
              ?.highSpeedRoad,
          then: yup.number().required(t('Shift Type is required')),
          otherwise: yup.number().notRequired(),
        }),
        typeOfWorks: yup.boolean().required(t('Type of Works is required')),
        weatherId: yup.number().required(t('Weather is required')),
        workActivities: yup
          .array()
          .when('typeOfWorks', {
            is: (typeOfWorks) => typeOfWorks,
            then: yup.array().of(
              yup.object().shape({
                siteActive: yup.boolean().required(t('Site activity is required')),
                reasonForNoActivity: yup.number().when('siteActive', {
                  is: false,
                  then: yup.number().required(t('Reason for no activity  is required')),
                  otherwise: yup.number().nullable(),
                }),
                workActivityDetails: yup.array().when('siteActive', {
                  is: true,
                  then: yup
                    .array()
                    .of(
                      yup.object().shape({
                        roadNamenLocDetails: yup
                          .string()
                          .required(t('Road name & location details is required.')),
                        proposedActivity: yup
                          .string()
                          .required(t('Proposed activity is required.')),
                        dayNightWorks: yup.number().required(t('Day/Night works is required')),
                        noOfWorker: yup.number().nullable().required(t('No.of worker is required')),
                        lsgOnSite: yup.boolean().required(t('LSG on site is required')),
                      }),
                    )
                    .min(
                      1,
                      t(
                        'At least 1 Work Activity Details is required when site activity is active. ',
                      ),
                    )
                    .required(
                      t(
                        'At least 1 Work Activity Details is required when site activity is active. ',
                      ),
                    ),
                  otherwise: yup.array().nullable(),
                }),
                siteIdlingReason: yup.number().when('reasonForNoActivity', {
                  is: 102,
                  then: yup.number().required(t('Site idle reason is required')),
                  otherwise: yup.number().nullable(),
                }),
                otherInfo: yup.string().when('siteIdlingReason', {
                  is: 107,
                  then: yup.string().required(t('Other is required')),
                  otherwise: yup.string().nullable(),
                }),
              }),
            ),
            otherwise: yup.array().of(
              yup.object().shape({
                siteActive: yup.boolean().required(t('Site activity is required')),
                reasonForNoActivity: yup.number().when('siteActive', {
                  is: false,
                  then: yup.number().required(t('Reason for no activity  is required')),
                  otherwise: yup.number().nullable(),
                }),
                workActivityDetails: yup.array().when('siteActive', {
                  is: true,
                  then: yup
                    .array()
                    .of(
                      yup.object().shape({
                        roadNamenLocDetails: yup
                          .string()
                          .required(t('Road name & location details is required.')),
                        proposedActivity: yup
                          .string()
                          .required(t('Proposed activity is required.')),
                        dayNightWorks: yup.number().required(t('Day/Night works is required')),
                        noOfWorker: yup.number().nullable().required(t('No.of worker is required')),
                        lsgOnSite: yup.boolean().required(t('LSG on site is required')),
                        essentialOperation: yup
                          .boolean()
                          .required(t('Essential Operation is required')),
                        hiddenWorkOtherThanLsg: yup
                          .boolean()
                          .required(t('Hidden Work other than LSG is required')),
                        inSituTest: yup.boolean().required(t('In situ test is required')),
                      }),
                    )
                    .min(
                      1,
                      t(
                        'At least 1 Work Activity Details is required when site activity is active. ',
                      ),
                    )
                    .required(
                      t(
                        'At least 1 Work Activity Details is required when site activity is active. ',
                      ),
                    ),
                  otherwise: yup.array().nullable(),
                }),
                siteIdlingReason: yup.number().when('reasonForNoActivity', {
                  is: 102,
                  then: yup.number().required(t('Site idle reason is required')),
                  otherwise: yup.number().nullable(),
                }),
                otherInfo: yup.string().when('siteIdlingReason', {
                  is: 107,
                  then: yup.string().required(t('Other is required')),
                  otherwise: yup.string().nullable(),
                }),
              }),
            ),
          })
          .nullable(),
      }),
    },
    {
      formStatusList: [FormStatusEnum.FORM_CDR_SUBMITTED, FormStatusEnum.FORM_CDR_ACKNOWLEDGED],
      validationSchema: yup.object(),
    },
  ]

  const { control, watch, setValue, getValues, reset, trigger } = useForm<FormCdrModel>({
    defaultValues: { ...InitCDRForm },
    resolver: (data, context, options) => {
      const validatorSchema = getFormValidationSchema(
        data.baseForm.formStatus,
        validationSchemaConditions,
      )
      return yupResolver(validatorSchema)(data, context, options)
    },
    mode: 'all',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
  })

  const { fields: workActivities } = useFieldArray({
    control,
    name: 'workActivities',
  })

  //#region dialog
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [cdrGeneralOptions, setCDRGeneralOptions] = useState<CDRGeneralOptions>(
    globalState.formOptionsList?.find(({ key }) => key === 'CDR')?.value ?? {},
  )

  const { getOptionsByKey } = useGeneralOptions() // TODO remove this after the demo

  const reloadEIAGeneralOptions = async (team?: number, district?: number) => {
    if (team !== undefined && district !== undefined) {
      setOptionRequest({
        callback: async () => {
          await FormCDRService.GetGeneralOptions(team, district).then(async (list) => {
            if (list) setCDRGeneralOptions(list)
          })
        },
      })
    }
  }

  const reload = async () => {
    setRequest({
      callback: async () => {
        if (formId) {
          await FormCDRService.GetCdrForm(formId)
            .then(async (f) => {
              if (f) reset(f)

              reloadEIAGeneralOptions(f.baseForm.teamId[0], f.baseForm.districtId[0])

              if (f.baseForm.contractNoId && f.baseForm.contractNoId !== globalState.contractNoId) {
                globalAction({
                  type: 'selectContract',
                  contractNoId: f.baseForm.contractNoId,
                })

                globalAction({
                  type: 'selectWorkGroup',
                  workGroupId: get(
                    find(globalState.contractList, { id: f.baseForm.contractNoId }),
                    'workGroups.0.id',
                    0,
                  ),
                })
              }

              setContractInfo(
                globalState.contractList?.find(
                  (contract) => contract.id === f.baseForm.contractNoId,
                ),
              )

              globalAction({
                type: 'changeFormStatus',
                formStatus: f.baseForm.formStatusName,
              })
            })
            .catch((err) => {
              enqueueSnackbar(err.response.data.message, {
                variant: 'error',
                autoHideDuration: null,
              })
              NavigateTo(navigate, '/contractor-daily-report/all-record')
            })
        }

        isMounted.current = true
      },
    })
  }

  useEffect(() => {
    if (!isMounted.current) {
      if (formState.form && formState.formType === 'CDR') {
        formStateAction({ type: 'pop', targetFormType: 'CDR' })

        reset({ ...formState.form })
      } else if (formId) {
        reload()
      } else {
        setValue('baseForm.contractNoId', storageContractId ? parseInt(storageContractId) : 0)
        setContractInfo(
          globalState.contractList?.find(
            (contract) => contract.id === getValues('baseForm.contractNoId'),
          ),
        )
      }

      setValue('skipCheckActivitiesDuplicated', false)

      globalAction({
        type: 'changeFormStatus',
        formStatus: getValues('baseForm.formStatusName'),
      })
    }

    return () => {
      globalAction({
        type: 'changeFormStatus',
        formStatus: undefined,
      })
    }
  }, [formId, formStateAction, globalAction])

  const handleOnSubmit = async (
    event: any,
    isReject: boolean,
    notifyList?: string[],
    signatureBase64?: string,
    submissionComment?: string,
  ) => {
    if (!(await trigger())) return
    setValue('signatureBase64', signatureBase64)
    var ff = getValues()
    ff.signatureBase64 = signatureBase64
    ff.notifyUserList = notifyList
    ff.submissionComment = submissionComment
    ff.skipCheckActivitiesDuplicated =
      ff.skipCheckActivitiesDuplicated === undefined ? false : ff.skipCheckActivitiesDuplicated
    setRequest({
      callback: async () => {
        await FormCDRService.ApproveCdrForm(ff)
          .then((resp) => {
            setValue('baseForm.formId', resp.formId)
            setValue('baseForm.formStatus', resp.formStatus)
            NavigateTo(navigate, '/contractor-daily-report/all-record')
            enqueueSnackbar(t('Record Submitted'), { variant: 'success' })
          })
          .catch((err) => {
            if (err.response.data.code === 1001) {
              return setDialogOpen(true)
            } else {
              enqueueSnackbar(err.response.data.message, {
                variant: 'error',
                autoHideDuration: null,
              })
            }
          })
      },
    })
  }

  const handleOnSave = async () => {
    setRequest({
      callback: async () => {
        await FormCDRService.SaveCdrForm(getValues()).then((resp) => {
          setValue('baseForm.formId', resp.formId)
          setValue('baseForm.formStatus', resp.formStatus)
          NavigateTo(navigate, '/contractor-daily-report/all-record')

          formId = resp.formId

          enqueueSnackbar(t('Record Saved'), { variant: 'success' })
        })
      },
    })
  }

  const handleOnDelete = async () => {
    setRequest({
      callback: async () => {
        await FormCDRService.DeleteCdrForm(getValues())
          .then(() => {
            enqueueSnackbar(t('Record Archived'), { variant: 'success' })
            NavigateTo(navigate, '/contractor-daily-report/all-record')
          })
          .catch((err) => {
            enqueueSnackbar(err.response.data.message, { variant: 'error', autoHideDuration: null })
          })
      },
    })
  }

  const [formPermission, formStatus, contractNoId] = watch([
    'baseForm.formPermission',
    'baseForm.formStatus',
    'baseForm.contractNoId',
  ])

  const handleGetWorksOrderReference = async () => {
    if (formStatus === FormStatusEnum.FORM_CDR_DRAFT && getValues('firstPageData') === true) {
      if (
        getValues('baseForm.teamId').length === 0 ||
        getValues('baseForm.districtId').length === 0
      )
        return enqueueSnackbar('Please select the team and district', { variant: 'error' })

      if (contractInfo?.highSpeedRoad && !getValues('shiftType'))
        return enqueueSnackbar('Please select the shift type', { variant: 'error' })

      await FormCDRService.GetWorkOrderList({
        contractNoId: getValues('baseForm.contractNoId'),
        formDate: getValues('formDate'),
        teamId: getValues('baseForm.teamId')[0] ?? getValues('baseForm.teamId'),
        districtId: getValues('baseForm.districtId')[0] ?? getValues('baseForm.districtId'),
        typeOfWorks: getValues('typeOfWorks'),
        shiftType: getValues('shiftType'),
      })
        .then((req) => {
          setValue(
            'worksOrderReference',
            req.workActivities === undefined || req.workActivities.length === 0
              ? []
              : req.workActivities?.map((x) => x.workOrderNo),
          )
        })
        .catch((err) =>
          enqueueSnackbar(
            err?.response?.data?.message ??
              t('Error in fetching Work order data, please try again'),
            { variant: 'error' },
          ),
        )
    }
  }

  const handleOnLoadNextPageData = async () => {
    if (formStatus === FormStatusEnum.FORM_CDR_DRAFT && getValues('firstPageData') === true) {
      setRequest({
        callback: async () => {
          let workOrderListReq = getValues('excelRef')
            ? {
                // formId,
                contractNoId: getValues('baseForm.contractNoId'),
                formDate: getValues('formDate'),
                teamId: getValues('baseForm.teamId')[0] ?? getValues('baseForm.teamId'),
                districtId: getValues('baseForm.districtId')[0] ?? getValues('baseForm.districtId'),
                typeOfWorks: getValues('typeOfWorks'),
                skipEmptyValidation: false,
                skipUnmatchValidation: false,
                cdrExcelFile: { bytesBase64: getValues('cdrExcelFile')?.[0]?.bytesBase64 ?? '' },
                shiftType: getValues('shiftType'),
                highSpeedRoad: find(globalState.contractList, {
                  id: getValues('baseForm.contractNoId'),
                })?.highSpeedRoad,
              }
            : {
                // formId,
                contractNoId: getValues('baseForm.contractNoId'),
                formDate: getValues('formDate'),
                teamId: getValues('baseForm.teamId')[0] ?? getValues('baseForm.teamId'),
                districtId: getValues('baseForm.districtId')[0] ?? getValues('baseForm.districtId'),
                typeOfWorks: getValues('typeOfWorks'),
                shiftType: getValues('shiftType'),
                highSpeedRoad: find(globalState.contractList, {
                  id: getValues('baseForm.contractNoId'),
                })?.highSpeedRoad,
              }

          const list = await FormCDRService.GetWorkOrderList(workOrderListReq)
            .then((req) => {
              if (req.workActivities === undefined || req.workActivities?.length === 0) {
                enqueueSnackbar(t('No works activity under this district and team'), {
                  variant: 'error',
                })
                setDisableNav(true)
              }
              return req.workActivities
            })
            .catch((err) => {
              if (err.response.data.message)
                enqueueSnackbar(err.response.data.message, { variant: 'error' })
              else
                enqueueSnackbar(t('Error in fetching Work order data, please try again'), {
                  variant: 'error',
                })
              setDisableNav(true)
            })

          if (list) {
            let workOrders: WorkActivity[] = []
            list.map((workOrder) => {
              // As the api will workActivities when it was created by this cdr form
              let wa: WorkActivity = { ...InitWorkActivity }
              wa.workOrderId = workOrder.workOrderId
              wa.workOrderNo = workOrder.workOrderNo
              wa.emergencySerialNo = workOrder.emergencySerialNo
              wa.siteActive = workOrder.siteActive
              wa.reasonForNoActivity = workOrder.reasonForNoActivity
              wa.siteIdlingReason = workOrder.siteIdlingReason
              wa.otherInfo = workOrder.otherInfo
              wa.workActivityDetails = workOrder.workActivityDetails

              workOrders.push(wa)
            })
            setValue('firstPageData', false)
            setValue('workActivities', workOrders)
          }
        },
      })
      reset(getValues())
    }
  }

  const [disableNav, setDisableNav] = useState<boolean>(true) //for part A onNext disable
  const [drawerCanOpen, setDrawerCanOpen] = useState<boolean>(false) ///for part a drawer open

  const partA: FormPartComponents = {
    partTitle: t('General Information'),
    component: (
      <CDRPartA
        control={control}
        getValues={getValues}
        globalState={globalState}
        watch={watch}
        userInfo={userInfo}
        setDisableNav={setDisableNav}
        setValue={setValue}
        formStatus={formStatus}
        options={cdrGeneralOptions}
        matchUpMd={matchUpMd}
        contractInfo={contractInfo}
        handleGetWorksOrderReference={handleGetWorksOrderReference}
        drawerCanOpen={drawerCanOpen}
        setDrawerCanOpen={setDrawerCanOpen}
      />
    ),
    onNextDisabled: disableNav,
    onNext: handleOnLoadNextPageData,
  }

  const partB: FormPartComponents = {
    partTitle: t('Works Activity'),
    component: (
      <CDRPartB
        control={control}
        getOptionsByKey={getOptionsByKey}
        getValues={getValues}
        globalState={globalState}
        InitWorkActivityDetail={InitWorkActivityDetail}
        setValue={setValue}
        workActivities={workActivities}
        formStatus={formStatus}
        options={cdrGeneralOptions}
      />
    ),
  }

  const partC: FormPartComponents = {
    partTitle: t('Submission Confirmation'),
    component: (
      <CDRPartC
        control={control}
        getValues={getValues}
        globalState={globalState}
        formPermission={formPermission}
        handleOnComplete={() => handleOnComplete()}
      />
    ),
  }

  const onLoadNotifyList = async (isRejcet: boolean) => {
    var defaultNotifyList = await FormService.GetNotifyList(
      'CDR',
      getValues('baseForm.formId'),
      isRejcet,
      getValues('baseForm.districtId'),
      getValues('baseForm.teamId'),
    )
    return defaultNotifyList
  }

  const onLoadHistoryList = async () => {
    var historyList = await FormService.GetHistoryList(getValues('baseForm.formId'))
    return historyList
  }

  const [FormSwitcher, handleOnComplete] = useFormSwitcher({
    title: t('CONTRACTOR DAILY REPORT'),
    components: [partA, partB, partC],
    formOnLoadCommentList: async () => {
      return await FormService.GetCommentList(getValues('baseForm.formId'))
    },
    formOnSubmitComment: async (comment) => {
      await FormService.SubmitComment(getValues('baseForm.formId'), comment)
    },
    formOnLoadNotifyList: onLoadNotifyList,
    formOnLoadFormHistoryList: onLoadHistoryList,
    formOnSubmit: handleOnSubmit,
    formOnDelete: handleOnDelete,
    formOnSave: handleOnSave,
    startStep: step,
    isLoading: isLoading || isLoadingOption,
    disableSave: !formPermission?.canUpdate,
    disableDelete: !formPermission?.canDelete,
    disableComment: getValues('baseForm.formId') ? false : true,
    approveRequired: formPermission?.workflowRequired,
    endOfFlow: formPermission?.endOfFlow,
  })

  return (
    <Fragment>
      <FormSwitcher />
      <DevTool control={control} />
      <ValidationToast control={control} />
      <CustomDialog
        title="Works Order No. Duplicated"
        children={
          <Fragment>
            You have submitted this work order no. today. Are you sure you need to submit again?
          </Fragment>
        }
        isOpen={dialogOpen}
        handleClose={() => setDialogOpen(false)}
        onSubmit={() => {
          setDialogOpen(false)
          setValue('skipCheckActivitiesDuplicated', true)
          handleOnComplete()
        }}
      />
    </Fragment>
  )
}

export default CDRForm
