import FormContainer from '@components/form/container'
import FormController from '@components/form/controller'
import FormField from '@components/form/field'
import { Control } from 'react-hook-form'
import { FormWouModel, WouGeneralOptions } from '@services/model/form/form.WOU.model'
import { useTranslation } from 'react-i18next'
import FormSelect from '@components/form/select'
import FormFreeText from '@components/form/freeText'
import FormBinarySelect from '@components/form/binarySelect'
import FormFileUpload from '@components/form/fileUpload'
import FormTypography from '@components/form/typography'

export default ({
  control,
  uneditable
}: {
  control: Control<FormWouModel>
  uneditable: boolean
}) => {
  const { t } = useTranslation()

  return (
    <FormContainer fkey={`wou-c`}>
      <FormField fieldName="Letter of Slow Progress Issued">
        <FormController
          controllerProps={{
            name: 'letterOfSlowProgressIssued',
            control,
          }}>
          <FormBinarySelect uneditable={uneditable}/>
        </FormController>
      </FormField>
      <FormField fieldName="Slow Progress Letter EDMS Ref. No">
        <FormController
          controllerProps={{
            name: 'slowProgressNumber',
            control,
          }}>
          <FormFreeText textfieldProps={{ type: 'string' }} uneditable={uneditable} />
        </FormController>
      </FormField>
      <FormField fieldName="Letter of Overdue Progress Issued">
        <FormController
          controllerProps={{
            name: 'letterOfOverdueProgressIssued',
            control,
          }}>
          <FormBinarySelect uneditable={uneditable} />
        </FormController>
      </FormField>
      <FormField fieldName="Attachment for Letter of Overdue Progress">
        <FormController
          controllerProps={{
            name: 'attachmentForLetterOfOverdueProgress',
            control,
          }}>
          <FormFileUpload
            height="125"
            acceptFiles={[
              'application/pdf',
            ]}
            uneditable={uneditable}
          />
        </FormController>
      </FormField>
    </FormContainer>
  )
}
