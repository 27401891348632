import { Fragment, useContext }            from 'react'
import { Control }                         from 'react-hook-form'
import uuid                                from 'react-uuid'
import { GlobalContext }                   from '@providers/globalStore'
import { FormNeaModel, NeaGeneralOptions } from '@services/model/form/form.NEA.model'
import { FormStatusEnum }                  from '@services/model/form/form.model'
import { UserInfo }                        from '@services/model/user.model'
import FormContainer                       from '@components/form/container'
import FormController                      from '@components/form/controller'
import FormField                           from '@components/form/field'
import FormSelect                          from '@components/form/select'
import FormMultipleSelect                  from '@components/form/multipleSelect'
import FormDateTimePicker                  from '@components/form/dateTimePicker'
import FormTypography                      from '@components/form/typography'
import FormContentBlock                    from '@components/form/contentBlock'
import { GetWorksOrderOptions }            from '@utils/worksOrderOptions'
import { map } from 'lodash'

export default ({
                    control,
                    formStatus,
                    getValues,
                    neaGeneralOptions,
                    userInfo,
                    watch
                }: {
    control: Control<FormNeaModel>
    formStatus: string
    getValues: any
    neaGeneralOptions?: NeaGeneralOptions
    userInfo: UserInfo,
    watch: any
}) => {
  const UUID = uuid()

  const readonly = true
  const { state: globalState } = useContext(GlobalContext)

  const [districtId, teamId, contractNoId] = watch(['baseForm.districtId', 'baseForm.teamId', 'baseForm.contractNoId'])

  const userRoleRight = userInfo?.categoryRights?.filter((x) => x?.contractNoId === contractNoId)?.filter(category => category.category === formStatus)
  let readOnlyRole = !userInfo.allRights && !map(userRoleRight, 'rights')?.some(r => r?.includes("U"))

  let defaultDistrictId = 0
  if (districtId && districtId.length > 0) {
    defaultDistrictId = districtId[0]
  }

  const meetupLocationList =
    neaGeneralOptions?.meetUpLocations?.map((x) => {
      return {
        key: x.id,
        value: x.meetUpLocation,
      }
    }) || []

  return (
    <FormContainer fkey={`${UUID}-nea-c`}>
    {formStatus === FormStatusEnum.FORM_NEA_DRAFT ? (
      <FormContentBlock />
    ) : (
      <Fragment>
        <FormField fieldName="EA Notification No.">
          <FormController
            controllerProps={{
              name: 'neaNotiticationNo',
              control,
            }}>
            <FormTypography />
          </FormController>
        </FormField>
        <FormField fieldName="Date of Audit">
          <FormController
            controllerProps={{
              name: 'dateOfAudit',
              control,
            }}>
            <FormDateTimePicker
              type="datetime"
              disabled={
                (formStatus !== FormStatusEnum.FORM_NEA_SAMPLE_SELECTED &&
                formStatus !== FormStatusEnum.FORM_NEA_ENDORSEMENT_REJECTED ) ||
                readOnlyRole
              }
            />
          </FormController>
        </FormField>
        <FormField fieldName="Team">
          <FormController
            controllerProps={{
              name: 'baseForm.teamId',
              control,
            }}>
            <FormMultipleSelect options={globalState.teamList || []} uneditable={readonly}/>
          </FormController>
        </FormField>
        <FormField fieldName="District">
          <FormController
            controllerProps={{
              name: 'baseForm.districtId',
              control,
            }}>
            <FormMultipleSelect
              options={globalState.districtList || []}
              uneditable={readonly}
            />
          </FormController>
        </FormField>

          <FormField fieldName='Meetup Location'>
              {
                  (formStatus === FormStatusEnum.FORM_NEA_SAMPLE_SELECTED ||
                   formStatus === FormStatusEnum.FORM_NEA_ENDORSEMENT_REJECTED) &&
                  meetupLocationList.length > 1 ? (
                      <FormController controllerProps={ { control, name: 'meetupLocationId' } }>
                          <FormSelect options={ meetupLocationList } />
                      </FormController>
                  ) : (
                      <FormTypography value={
                          meetupLocationList.length === 1
                          ? meetupLocationList?.[0]?.value
                          : meetupLocationList?.find((x) => x.key === getValues('meetupLocationId'))?.value
                      } />
                  ) }
          </FormField>

        <FormField fieldName="Assign Auditing Officer">
          <FormController
            controllerProps={{
              name: 'auditingOfficer',
              control,
            }}>
            { ['Sys-SA', 'Contract-Admin'].includes(userInfo.defaultRoleName ?? '') ||
              formStatus === FormStatusEnum.FORM_NEA_SAMPLE_SELECTED ||
              formStatus === FormStatusEnum.FORM_NEA_ENDORSEMENT_REJECTED ? (
              <FormSelect
                fullWidth
                options={
                  neaGeneralOptions?.auditingOfficer?.map((x) => {
                    return {
                      key: x.id,
                      value: x.name,
                    }
                  }) || []
                }
                disabled={ readOnlyRole && !['Sys-SA', 'Contract-Admin'].includes(userInfo.defaultRoleName ?? '') }
              />
            ) : (
              <FormTypography
                type="select"
                options={
                  neaGeneralOptions?.auditingOfficer?.map((x) => {
                    return {
                      key: x.id,
                      value: x.name,
                    }
                  }) || []
                }
              />
            )}
          </FormController>
        </FormField>
        <FormField fieldName="Assign Checking Officer">
          <FormController
            controllerProps={{
              name: 'checkingOfficer',
              control,
            }}>
            { ['Sys-SA', 'Contract-Admin'].includes(userInfo.defaultRoleName ?? '') ||
              formStatus === FormStatusEnum.FORM_NEA_SAMPLE_SELECTED ||
              formStatus === FormStatusEnum.FORM_NEA_ENDORSEMENT_REJECTED ? (
              <FormSelect
                fullWidth
                options={
                  neaGeneralOptions?.checkingOfficer?.map((x) => {
                    return {
                      key: x.id,
                      value: x.name,
                    }
                  }) || []
                }
                disabled={ readOnlyRole && !['Sys-SA', 'Contract-Admin'].includes(userInfo.defaultRoleName ?? '') }
              />
            ) : (
              <FormTypography
                type="select"
                options={
                  neaGeneralOptions?.checkingOfficer?.map((x) => {
                    return {
                      key: x.id,
                      value: x.name,
                    }
                  }) || []
                }
              />
            )}
          </FormController>
        </FormField>

          <FormField fieldName='Works Order No.'>
              {
                  formStatus === FormStatusEnum.FORM_NEA_SAMPLE_SELECTED || formStatus === FormStatusEnum.FORM_NEA_ENDORSEMENT_REJECTED
                  ?
                  <FormController controllerProps={ { control, name: 'baseForm.workOrderId' } }>
                      <FormSelect fullWidth
                                  options={
                          GetWorksOrderOptions(globalState.worksOrderList?.filter(wo => wo.typeOfWorks) ?? [],
                                               teamId,
                                               districtId)
                                  }
                                  disabled={ readOnlyRole } />
                  </FormController>
                  :
                  <FormTypography value={ getValues('baseForm.workOrderNo') } />
              }
          </FormField>
      </Fragment>
    )}
  </FormContainer>
  )
}
