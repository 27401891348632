import SearchPanel from '@components/searchPanel'
import { Grid } from '@mui/material'
import * as common from '@common/common'
import { GlobalContext } from '@providers/globalStore'
import { InitialSearchPanelState, SearchPanelContext } from '@providers/SearchPanelProvider'
import SearchPanelReducer, { SearchPanelState } from '@reducers/searchPanelReducer'
import moment from 'moment'
import { useContext, useReducer, useRef, useState } from 'react'
import { NavigateFunction } from 'react-router-dom'
import useTableView from '@hooks/useTableView'
import { useTranslation } from 'react-i18next'

import { FormDEAListModel } from '@services/model/form/form.DEA.model'
import FormDEAService from '@services/formService/form.DEA.service'
import { NavigateTo } from '@utils/navigate'
import useGeneralOptions from '@hooks/useGeneralOptions' // TODO: remove later

const AllRecord = () => {
  const refMounted = useRef(true)
  const { state: globalState, userInfo } = useContext(GlobalContext)
  const { getOptionsByKey } = useGeneralOptions()
  const [state, dispatch] = useReducer<React.Reducer<SearchPanelState, any>>(
    SearchPanelReducer,
    InitialSearchPanelState,
  )
  const [isTableView, setIsTableView] = useState<boolean>(true)

  const { t } = useTranslation()

  const [TableView, reload] = useTableView<FormDEAListModel>({
    headers: [
      {
        key: 'status',
        desc: t('General Status', { ns: common.i18nFormNS }),
      },
      {
        key: 'submissionStatus',
        desc: t('Submission Status', { ns: common.i18nFormNS }),
      },
      {
        key: '"DEA_no"',
        desc: t('Form ID', { ns: common.i18nFormNS }),
      },
      {
        key: 'action_by',
        desc: t('Submitted By', { ns: common.i18nFormNS }),
      },
      {
        key: 'created_by',
        desc: t('Approved By', { ns: common.i18nFormNS }),
      },
      {
        key: 'updated_at',
        desc: t('Last Update', { ns: common.i18nFormNS }),
      },
    ],
    onRowClick: function (navigate: NavigateFunction, record: FormDEAListModel) {
      NavigateTo(navigate, '/defect-identified-in-engineer-audit/:id', { id: record.formId })
    },
    mountedRef: refMounted,
    reloadCallback: async (pagination: object, sorting: object | undefined, cancelToken: any) => {
      return await FormDEAService.GetDEAList(
        {
          ...state.filterResult,
          sorting: sorting,
          pagination: pagination,
        },
        cancelToken,
        false,
      )
    },
  })

  return (
    <Grid component="main" container padding={1}>
      <SearchPanelContext.Provider value={{ state, dispatch }}>
        <SearchPanel
          dispatch={dispatch}
          addUrl="/booking/new"
          onSearch={reload}
          onInitReload={reload}
          mountedRef={refMounted}
          excludeStatus={true}
          criteria={[
            {
              key: 'bookingDate',
              desc: t('bookingDate', { ns: common.i18nFormNS }),
              type: 'DateFilter',
              disableOperator: true,
              defaultFrom: moment().add(1, 'day').format('DD-MM-YYYY'),
              defaultTo: moment().add(1, 'day').format('DD-MM-YYYY'),
              defaultOperator: '=',
            },
            {
              key: 'formDate',
              desc: t('formDate'),
              type: 'DateFilter',
            },
            {
              key: 'toDate',
              desc: t('toDate'),
              type: 'DateFilter',
            },
          ]}
        />
        <TableView />
      </SearchPanelContext.Provider>
    </Grid>
  )
}

export default AllRecord
