import moment from 'moment'
import { GetFormStatusId } from '@utils/getFormStatusId'
import { find, findLast, get } from 'lodash'
import { FormStatusEnum } from '@services/model/form/form.model'

export const getSdFormData = (data: any, optionList?: any) => {
  const {
    globalState,
    sdGeneralOptions,
    resourceType = [],
    constructionPlant = [],
    wipType = [],
    workSCompletionList = [],
    essentialOperationList = [],
    inSituTestList = [],
    inSituSampleList = [],
    sitePhotoCategory = [],
  } = optionList
  const { tradeList = [], idleCodeList = [] } = sdGeneralOptions

  const sdDraft = findLast(get(data, 'baseForm.formStatusHistories'), ({ formStatusId }) => {
    return GetFormStatusId(globalState?.formStatusList, [FormStatusEnum.FORM_SD_DRAFT])?.includes(
      formStatusId,
    )
  })
  const sdReview = findLast(get(data, 'baseForm.formStatusHistories'), ({ formStatusId }) => {
    return GetFormStatusId(globalState?.formStatusList, [FormStatusEnum.FORM_SD_REVIEW])?.includes(
      formStatusId,
    )
  })
  const sdApproved = findLast(get(data, 'baseForm.formStatusHistories'), ({ formStatusId }) => {
    return GetFormStatusId(globalState?.formStatusList, [
      FormStatusEnum.FORM_SD_APPROVED,
    ])?.includes(formStatusId)
  })
  const sdContractorReview = findLast(
    get(data, 'baseForm.formStatusHistories'),
    ({ formStatusId }) => {
      return GetFormStatusId(globalState?.formStatusList, [
        FormStatusEnum.FORM_SD_CONTRACTOR_REVIEW,
      ])?.includes(formStatusId)
    },
  )

  return {
    contractor:
      globalState?.contractList?.find((x) => x.id === data['baseForm']['contractNoId'])
        ?.contractor ?? '',
    contractTitle:
      globalState?.contractList?.find((x) => x.id === data['baseForm']['contractNoId'])
        ?.contractTitle ?? '',
    contract_no:
      globalState?.contractList?.find((x) => x.id === data['baseForm']['contractNoId'])
        ?.contractNo ?? '',
    works_order_no: data['baseForm']['workOrderNo'] ?? '' ?? '',
    sd_no: get(data, 'sdNo', ''),
    dayNightWorks: get(data, 'formSdCrw.dayNightWorks', ''),
    siteProgressForLabour: get(data, 'siteProgressForLabour', []).map(
      ({ tradeId, resourceTypeId, ...rest }) => ({
        ...rest,
        trade: get(find(tradeList, { id: tradeId }), 'trade', ''),
        resourceType: get(find(resourceType, { key: resourceTypeId }), 'value', ''),
      }),
    ),
    siteProgressForPlant: get(data, 'siteProgressForPlant', []).map(
      ({ idleCodeId, noOfPlant, noOfIdlePlant, resourceTypeId, ...rest }) => ({
        ...rest,
        idleCode: get(find(idleCodeList, { id: idleCodeId }), 'name', ''),
        noOfTotalPlant: noOfIdlePlant + noOfPlant,
        noOfPlant: noOfPlant,
        noOfIdlePlant: noOfIdlePlant,
        resourceType: get(find(constructionPlant, { key: resourceTypeId }), 'value', ''),
      }),
    ),
    formDate:
      data['formDate'] === undefined
        ? ''
        : moment(data['formDate']).format(moment.defaultFormat).toString() ?? '',
    remarks: get(data, 'remarks', ''),
    instructionToContractor: get(data, 'instructionToContractor', ''),
    contactorRepesentativeComment: get(data, 'contactorRepesentativeComment', ''),
    actualActivities: get(find(wipType, { key: get(data, 'wipTypeSubOptionId', 0) }), 'value', ''),
    workCompletion: get(
      find(workSCompletionList, { key: get(data, 'workCompletionId') }),
      'value',
      '',
    ),
    essentialOperation: get(
      find(essentialOperationList, { key: get(data, 'essentialOperation') }),
      'value',
      '',
    ),
    inSituTest: get(find(inSituTestList, { key: get(data, 'inSituTest') }), 'value', ''),
    inSituSample: get(find(inSituSampleList, { key: get(data, 'inSituSample') }), 'value', ''),
    hiddenWorkCheck: get(data, 'hiddenWorkCheck', '') ? 'Yes' : 'No',
    hiddenWorkRefs: get(data, 'hiddenWorkRefs', []),
    sdPhotos: get(data, 'sdPhotos', []).map(({ photoCategoryId, ...rest }) => ({
      ...rest,
      photoCategory: get(find(sitePhotoCategory, { key: photoCategoryId }), 'value', ''),
    })),
    draftBy:
      globalState.userMetaFullList?.find((x) => x.userId === sdDraft?.actionBy)?.userName ?? '',
    draftByPost:
      globalState.userMetaFullList?.find((x) => x.userId === sdDraft?.actionBy)?.position ?? '',
    reviewBy:
      globalState.userMetaFullList?.find((x) => x.userId === sdReview?.actionBy)?.userName ?? '',
    reviewByPost:
      globalState.userMetaFullList?.find((x) => x.userId === sdReview?.actionBy)?.position ?? '',
    approvedBy:
      globalState.userMetaFullList?.find((x) => x.userId === sdApproved?.actionBy)?.userName ?? '',
    approvedByPost:
      globalState.userMetaFullList?.find((x) => x.userId === sdApproved?.actionBy)?.position ?? '',
    contractorReviewBy:
      globalState.userMetaFullList?.find((x) => x.userId === sdContractorReview?.actionBy)
        ?.userName ?? '',
    contractorReviewByPost:
      globalState.userMetaFullList?.find((x) => x.userId === sdContractorReview?.actionBy)
        ?.position ?? '',
    summaryMapBase64: get(data, 'summaryMapBase64', ''),
  }
}
