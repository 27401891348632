import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { Box, useTheme } from '@mui/system'
import { useContext, useEffect, useState } from 'react'
import { GlobalContext } from '@providers/globalStore'
import moment from 'moment'
import { useForm } from 'react-hook-form'
import { DashboardChart } from '@services/model/base.model'
import FormController from '@components/form/controller'
import FormMultipleSelect from '@components/form/multipleSelect'
import FormDateTimePicker from '@components/form/dateTimePicker'
import useAPIFetch from '@hooks/useAPIFetch'
import { ScrDashboardReq } from '@services/model/form/form.SCR.model'
import FormScrService from '@services/formService/form.SCR.service'
import { useSnackbar } from 'notistack'
import SCRDashboard from '@components/dashboard/SCR'

export default function Dashboard() {
  const { state: globalState, userInfo } = useContext(GlobalContext)
  const { setRequest, isLoading } = useAPIFetch()
  const [ dashboardData, setDashboardData ] = useState<DashboardChart>()
  const theme = useTheme()
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'))
  const { enqueueSnackbar } = useSnackbar()

  const { control, watch, handleSubmit, setValue, getValues } = useForm<ScrDashboardReq>({
    defaultValues: {
      workOrderId: {operator: "=", value: []},
      districtId: {operator: "=", value: []},
    },
  })


  return (
    <>
      <SCRDashboard />
      {/* <Box sx={{ padding: matchUpMd ? '80px' : '10px', gap: '12px', display: 'flex', flexDirection: 'column' }}>
        <Box
          display="flex"
          justifyContent="flex-start"
          gap="20px"
          alignItems="center"
          sx={{
            width: '100%'
          }}>
          <img src="/img/dashboard.svg" alt="back" />
          <Typography sx={{ fontSize: '2.125rem', fontWeight: 'bold', color: '#707070' }}>
            Site Check Record - Dashboard
          </Typography>
        </Box>
        <Paper sx={{padding: matchUpMd ? '24px' : '4px'}}>
          <Box
            display="flex"
            gap="20px"
            alignItems="center"
            sx={{
              width: '100%',
              marginBottom: "12px",
              flexDirection: matchUpMd ? 'row' : 'column'
            }}
            justifyContent="space-between"
            >
            <Typography sx={{ fontSize: '1.5rem', fontWeight: 'bold', color: '#707070' }}>
              M&M Works in Site Check Records 
            </Typography>
            <Box sx={{ gap: '20px', display: 'flex', flexDirection: 'row' }}>
              <FormController
                  controllerProps={{
                    name: 'districtId.value',
                    control,
                  }}>
                <FormMultipleSelect
                  options={globalState.districtList || []}
                />
              </FormController>
              <FormController
                  controllerProps={{
                    name: 'startDate',
                    control,
                  }}>
                <FormDateTimePicker 
                  type= 'date' 
                  filterDate={(day) => {
                    return moment(day).day() === 1
                  }}
                />
              </FormController>
            </Box>
          </Box>
          {<Box sx={{ width: '100%', gap: '12px', display: 'flex', flexDirection: 'column'  }}>
            <Box sx={{ width: matchUpMd ? '1482px': '100%', overflow: matchUpMd ?'auto' : 'scroll'}}>
            <Table>
                <TableHead sx={{ backgroundColor: '#CCCCCC' }}>
                  <TableRow>
                    <TableCell >Team</TableCell>
                    <TableCell  colSpan={3}>15 May 2023 - 21 May 2023</TableCell>
                    <TableCell  colSpan={3}>22 May 2023 - 28 May 2023</TableCell>
                    <TableCell  colSpan={3}>29 May 2023 - 4 Jun 2023</TableCell>
                    <TableCell  colSpan={3}>5 Jun 2023 - 11 Jun 2023</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell key={`form-table-header-team-2`}></TableCell>
                          <TableCell>Received</TableCell>
                          <TableCell>Checked</TableCell>
                          <TableCell>Checked {'>'}= 25%</TableCell>
                          <TableCell>Received</TableCell>
                          <TableCell>Checked</TableCell>
                          <TableCell>Checked {'>'}= 25%</TableCell>
                          <TableCell>Received</TableCell>
                          <TableCell>Checked</TableCell>
                          <TableCell>Checked {'>'}= 25%</TableCell>
                          <TableCell>Received</TableCell>
                          <TableCell>Checked</TableCell>
                          <TableCell>Checked {'>'}= 25%</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Road</TableCell>
                    <TableCell>24</TableCell>
                    <TableCell>24</TableCell>
                    <TableCell>Y</TableCell>
                    <TableCell>27</TableCell>
                    <TableCell>27</TableCell>
                    <TableCell>Y</TableCell>
                    <TableCell>28</TableCell>
                    <TableCell>28</TableCell>
                    <TableCell>Y</TableCell>
                    <TableCell>51</TableCell>
                    <TableCell>35</TableCell>
                    <TableCell>N</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Structure</TableCell>
                    <TableCell>9</TableCell>
                    <TableCell>9</TableCell>
                    <TableCell>Y</TableCell>
                    <TableCell>8</TableCell>
                    <TableCell>8</TableCell>
                    <TableCell>Y</TableCell>
                    <TableCell>5</TableCell>
                    <TableCell>5</TableCell>
                    <TableCell>Y</TableCell>
                    <TableCell>14</TableCell>
                    <TableCell>4</TableCell>
                    <TableCell>Y</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Slope</TableCell>
                    <TableCell>5</TableCell>
                    <TableCell>5</TableCell>
                    <TableCell>Y</TableCell>
                    <TableCell>9</TableCell>
                    <TableCell>9</TableCell>
                    <TableCell>Y</TableCell>
                    <TableCell>13</TableCell>
                    <TableCell>13</TableCell>
                    <TableCell>Y</TableCell>
                    <TableCell>8</TableCell>
                    <TableCell>6</TableCell>
                    <TableCell>Y</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Landscape</TableCell>
                    <TableCell>3</TableCell>
                    <TableCell>3</TableCell>
                    <TableCell>Y</TableCell>
                    <TableCell>3</TableCell>
                    <TableCell>3</TableCell>
                    <TableCell>Y</TableCell>
                    <TableCell>8</TableCell>
                    <TableCell>8</TableCell>
                    <TableCell>Y</TableCell>
                    <TableCell>1</TableCell>
                    <TableCell>1</TableCell>
                    <TableCell>Y</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Tunnel</TableCell>
                    <TableCell>0</TableCell>
                    <TableCell>0</TableCell>
                    <TableCell>N/A</TableCell>
                    <TableCell>0</TableCell>
                    <TableCell>0</TableCell>
                    <TableCell>N/A</TableCell>
                    <TableCell>0</TableCell>
                    <TableCell>0</TableCell>
                    <TableCell>N/A</TableCell>
                    <TableCell>0</TableCell>
                    <TableCell>0</TableCell>
                    <TableCell>N/A</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>District</TableCell>
                    <TableCell>33</TableCell>
                    <TableCell>32</TableCell>
                    <TableCell>Y</TableCell>
                    <TableCell>23</TableCell>
                    <TableCell>23</TableCell>
                    <TableCell>Y</TableCell>
                    <TableCell>24</TableCell>
                    <TableCell>24</TableCell>
                    <TableCell>Y</TableCell>
                    <TableCell>26</TableCell>
                    <TableCell>17</TableCell>
                    <TableCell>Y</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Box>}
        </Paper>
      </Box> */}
    </>
  )
}
