import FormContainer from '@components/form/container'
import FormController from '@components/form/controller'
import FormField from '@components/form/field'
import { Control } from 'react-hook-form'
import uuid from 'react-uuid'
import { FormHWRModel, InitHWRForm } from '@services/model/form/form.HWR.model'
import FormFileUpload from '@components/form/fileUpload'
import FormBinaryRadio from '@components/form/binaryRadio'
import FormSignatureHistories from '@components/form/signatureHistories'
import { List, ListItemButton, ListItemIcon, Collapse, ListItemText, Typography } from '@mui/material'
import BookIcon from '@mui/icons-material/Book'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import FormSignaturePad from '@components/form/signaturePad'
import FormDate from '@components/form/date'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import {BaseFormPermission, FormStatusEnum} from '@services/model/form/form.model'
import { GlobalStateProps } from '@providers/globalStore'

export default ({
    control,
    userInfo,
    getValues,
    globalState,
    formPermission,
    handleOnComplete,
    formStatus
}:{
    control: Control<FormHWRModel>
    userInfo: any
    getValues: any
    globalState: GlobalStateProps
    formPermission: BaseFormPermission
    handleOnComplete: any
    formStatus: string
}) => {
    const UUID = uuid()
    const { t } = useTranslation()
    const [open, setOpen] = useState<boolean>(true)
    const handleCollapseOpen = () => {
      setOpen(!open)
    }
    console.log('baseForm.formStatusHistories', getValues('baseForm.formStatusHistories'))
    // if (formStatus === FormStatusEnum.FORM_HWR_APPROVED ){
    //     const iowChecked = getValues('iowChecked')
    //     if (!iowChecked){
    //         formPermission.canUpdate = false
    //     }
    // }

    return (
        // <FormContainer fkey={`${UUID}-hwr-e`}>
        //   <FormSignatureHistories histories={getValues('baseForm.formStatusHistories')} />
        //   {getValues('signatureRequired') && (
        //     <List>
        //       <ListItemButton onClick={handleCollapseOpen}>
        //         <ListItemIcon>
        //           <BookIcon />
        //         </ListItemIcon>
        //         <ListItemText primary={t('Current action')} />
        //         {open ? <ExpandLess /> : <ExpandMore />}
        //       </ListItemButton>
        //       <Collapse in={open}>
        //         <FormField fieldName="Submit by">
        //           <Typography>{userInfo.firstName}</Typography>
        //         </FormField>
        //         <FormField fieldName="Signature">
        //           <FormController
        //             controllerProps={{
        //               name: 'signatureBase64',
        //               control: control,
        //               rules: { required: true },
        //             }}>
        //             <FormSignaturePad error={errors.signatureBase64} />
        //           </FormController>
        //         </FormField>
        //         <FormField fieldName="Date Sign-off">
        //           <FormDate visable={!!getValues('signatureBase64')} />
        //         </FormField>
        //       </Collapse>
        //     </List>
        //   )}
        // </FormContainer>
        <FormContainer fkey={`${UUID}-sd-i`}>
            <FormSignatureHistories
                formStatusList={globalState.formStatusList}
                histories={getValues('baseForm.formStatusHistories')}
                control={control}
                handleOnComplete={() => handleOnComplete()}
                endOfFlow={formPermission?.endOfFlow}
                userMetaList={globalState.userMetaList}
            roleMetaList={globalState.roleMetaList}
                disabled={!formPermission?.canUpdate}
                />
        </FormContainer>
    )
  }