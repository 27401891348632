import apiGateway           from '@utils/axiosInterceptor'
import {
  DnDashboard,
  DnDashboardReq,
  DnFilterOptions,
  DNGeneralOptions,
  DnListResponse,
  FormDNModel
}                           from '../model/form/form.DN.model'
import { BaseFormResponse } from '../model/form/form.model'

const GetGeneralOptions = async () => {
  return await apiGateway.get<DNGeneralOptions>(`/api/v1/forms/DN/options`).then((rep) => {
    return rep.data
  })
}

const GetDNList = async (req: object, cancelToken: any, myRecordOnly: boolean) => {
  return await apiGateway
    .post<DnListResponse>(`api/v1/forms/DN/${myRecordOnly ? 'my-action' : 'list'}`, req, {
      cancelToken: cancelToken,
    })
    .then((rep) => {
      return rep.data
    })
}

const GetDnFilterOption = async (showMyRecord?:boolean) => {
  let api = `api/v1/forms/DN/filter-options`
  if (showMyRecord) {
    api += '?my_action=true'
  }
  return await apiGateway.get<DnFilterOptions>(api).then((rep) => {
    return rep.data
  })
}

const GetDNForm = async (formId: string) =>
    await apiGateway
        .get<FormDNModel>(`api/v1/forms/DN/${formId}`)
        .then(resp => resp.data)

const GetDnDashboard = async (req: DnDashboardReq, cancelToken: any): Promise<DnDashboard> =>
    await apiGateway
        .post<DnDashboard>('api/v1/forms/DN/dashboard', req, { cancelToken: cancelToken })
        .then(resp => resp.data)

const SaveDNForm = async (req: FormDNModel) => {
  if (req.baseForm.formId === '')
    return await apiGateway.post<BaseFormResponse>('api/v1/forms/DN', req).then((rep) => {
      return rep.data
    })
  else {
    return await apiGateway
      .put<BaseFormResponse>(`api/v1/forms/DN/${req.baseForm.formId}`, req)
      .then((rep) => {
        return rep.data
      })
  }
}

const ApproveDNForm = async (req: FormDNModel) => {
  return await apiGateway
    .put<BaseFormResponse>(`api/v1/forms/DN/${req.baseForm.formId}/approve`, req)
    .then((rep) => {
      return rep.data
    })
}

const RejectDNForm = async (req: FormDNModel) => {
  return await apiGateway
    .put<BaseFormResponse>(`api/v1/forms/DN/${req.baseForm.formId}/reject`, req)
    .then((rep) => {
      return rep.data
    })
}

const DeleteDNForm = async (req: FormDNModel) => {
  return await apiGateway.delete<BaseFormResponse>(
    `api/v1/forms/DN/${req.baseForm.formId}`,
  )
}

const UpdateMap = async (req: FormDNModel) => {
  return await apiGateway
    .put<BaseFormResponse>(
      `api/v1/forms/DN/${req.baseForm.formId ? req.baseForm.formId : '_'}/map-image`,
      req,
    )
    .then((rep) => {
      return rep.data
    })
}

const CancelDNForm = async (req: FormDNModel) => {
  return await apiGateway
    .put<BaseFormResponse>(`api/v1/forms/DN/${req.baseForm.formId}/cancel`, req)
    .then((rep) => {
      return rep.data
    })
}

const FormDNService = {
  GetDNForm,
  GetDNList,
  GetDnFilterOption,
  GetDnDashboard,
  SaveDNForm,
  ApproveDNForm,
  RejectDNForm,
  DeleteDNForm,
  GetGeneralOptions,
  UpdateMap,
  CancelDNForm
}

export default FormDNService