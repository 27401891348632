import apiGateway from '@utils/axiosInterceptor'

const ConvertFiles = async (formData: FormData) => {
  return await apiGateway
  .post(`api/v1/convertors/pdf`, formData, {  responseType: 'blob'})
  .then((rep) => {
    return rep.data
  })
}

const ConvertorsService = {
    ConvertFiles
}

export default ConvertorsService
