import { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Box, Drawer, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import { useKeycloak } from '@react-keycloak/web'
import { GlobalContext } from '@providers/globalStore'
import styles from '@styles/sidebar.module.scss'
import { InitUserInfo, UserInfo } from '@services/model/user.model'
import { RightsCategory } from '@services/model/form/form.model'
import useLocalStorage from '@hooks/useLocalStorage'
import MenuList from './menuList'
import UseLeftSideBar from '../LeftSideBar'
import SelectContract from './selectContract'
import logo from '../../assets/img/logo.png'
import home from '../../assets/img/home.svg'
import feedback from '../../assets/img/feedback.svg'
import noty from '../../assets/img/notification.svg'
import settings from '../../assets/img/setting.svg'
import presentations from '../../assets/img/presentation-fill.svg'
import usersIc from '../../assets/img/users.svg'
import highway from '../../assets/img/highways-department.png'
import highwayfull from '../../assets/img/HydLogo-ec.png'

export const DrawerWidth = 300

const Sidebar = function () {
  const { state, dispatch, hasRightByCatAndCode } = useContext(GlobalContext)
  const theme = useTheme()
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'))
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md')) // check if it is mobile
  const [LeftSideBar] = UseLeftSideBar()

  const { keycloak, initialized } = useKeycloak()
  const [loginUser, setLoginUser] = useLocalStorage<UserInfo | undefined>('usr', undefined)
  const navigate = useNavigate()

  const [width, setWidth] = useState<number>(window.innerWidth)

  const handleLeftDrawerToggle = () => {
    dispatch({ type: 'toggleLeftDrawer' })
  }

  const drawer = (
    <>
      {state.leftDrawerOpened && (matchUpMd || matchDownMd) ? (
        <>
          {matchDownMd ? (
            /* mobile view */
            <>
              <Box className={`${styles.sideBar} ${styles.mobile}`}>
                <Box className={styles.sideBarHeader}>
                  <Box
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box>
                      <Link to="/landing" onClick={handleLeftDrawerToggle}>
                        <Box component="img" alt="Varadise" src={home} sx={{ padding: '0 5px' }} />
                      </Link>
                      <Link to="/notifications" onClick={handleLeftDrawerToggle}>
                        <Box component="img" alt="Varadise" src={noty} sx={{ padding: '0 5px' }} />
                      </Link>
                      <Link to="/settings" onClick={handleLeftDrawerToggle}>
                        <Box
                          component="img"
                          alt="Varadise"
                          src={settings}
                          sx={{ padding: '0 5px' }}
                        />
                      </Link>
                      <Link to="/user-training">
                        <Box
                          component="img"
                          alt="Training"
                          src={presentations}
                          sx={{ padding: '0 5px' }}
                        />
                      </Link>
                      {hasRightByCatAndCode(RightsCategory.FORM_USER, ['C', 'R', 'U', 'D']) && (
                        <Link to="/admin">
                          <Box
                            component="img"
                            alt="Varadise"
                            src={usersIc}
                            sx={{ padding: '0 5px' }}
                          />
                        </Link>
                      )}
                    </Box>
                    <Box>
                      <IconButton
                        sx={{ p: '10px' }}
                        onClick={() => {
                          navigate('/setup')
                        }}>
                        <FormatListBulletedIcon />
                      </IconButton>
                      <IconButton
                        sx={{
                          p: 0,
                        }}
                        onClick={() => {
                          setLoginUser({ ...InitUserInfo })
                          localStorage.clear()
                          navigate('/')
                          keycloak.logout()
                        }}>
                        <img src="/img/logout.svg" alt="menu" />
                      </IconButton>
                    </Box>
                  </Box>
                  {/* <SearchBar /> */}
                </Box>
                <Box
                  className={styles.sideBarContent}
                  sx={{
                    marginTop: theme.spacing(0.5),
                    marginBottom: theme.spacing(0.5),
                    padding: theme.spacing(0.5),
                  }}>
                  <MenuList isMobile />
                </Box>
              </Box>
              <Box className={styles.sideBarFooter}>
                <Box
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Box className={styles.userInfo}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: '0.75rem',
                      }}>
                      <Box component="img" alt="Logo" src={highway} sx={{ width: '6rem' }} />
                    </Box>
                    <Box sx={{ padding: '0 8px' }}>
                      <Typography>{loginUser?.firstName}</Typography>
                      <Typography>{loginUser?.position}</Typography>
                      <Typography>{loginUser?.organizationName}</Typography>
                      <Typography>{loginUser?.defaultRoleName}</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </>
          ) : (
            /* desktop view */
            <>
              <Box
                sx={{
                  position: 'sticky',
                  top: 0,
                  backgroundColor: '#fff',
                  zIndex: 999,
                  textAlign: 'center',
                  margin: '0',
                }}
                className={styles.sideMenuHeader}
                onClick={handleLeftDrawerToggle}>
                <Box
                  component="img"
                  className={styles.logoImage}
                  alt="Highway"
                  src={highwayfull}
                  sx={{ marginTop: '24px' }}
                />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '8px 0px',
                    gap: '8px',
                  }}>
                  <img src={logo} width="20%" />
                  <Typography fontSize={12}>Powered by Varadise</Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  position: 'sticky',
                  top: 0,
                  backgroundColor: '#fff',
                  zIndex: 999,
                  textAlign: 'center',
                  margin: '0',
                }}>
                <SelectContract uneditable={true} />
              </Box>
              <Box
                component="div"
                sx={{
                  flex: 1,
                  paddingLeft: '16px',
                  borderTop: '1px solid #ccc',
                  marginRight: '1rem',
                  paddingTop: '1rem',
                  overflowY: 'scroll',
                  '::-webkit-scrollbar': {
                    display: 'none',
                  },
                }}>
                <MenuList />
              </Box>
              {/* </Box> */}
              <Box
                sx={{
                  position: 'sticky',
                  bottom: 0,
                  backgroundColor: '#fff',
                  zIndex: 99,
                  padding: '0 .5rem',
                }}>
                <Box sx={{ background: '#ebebeb', display: 'flex', padding: '0px 10px' }}>
                  <Box
                    sx={{
                      background: '#fff',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                    <Box component="img" alt="Logo" src={highway} sx={{ width: '6rem' }} />
                  </Box>
                  <Box sx={{ padding: '0 8px' }}>
                    <Typography sx={{ fontSize: '.7rem' }}>{loginUser?.firstName}</Typography>
                    <Typography sx={{ fontSize: '.7rem' }}>{loginUser?.position}</Typography>
                    <Typography sx={{ fontSize: '.7rem' }}>
                      {loginUser?.organizationName}
                    </Typography>
                    <Typography sx={{ fontSize: '.7rem' }}>{loginUser?.defaultRoleName}</Typography>
                  </Box>
                </Box>
                <Box
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Box>
                    <a href={process.env.REACT_APP_USERSNAP_BOARD_URL ?? ''} target="_blank">
                      <Box
                        component="img"
                        alt="Varadise"
                        src={feedback}
                        sx={{ padding: '0 5px' }}
                      />
                    </a>
                    <Link to="/landing">
                      <Box component="img" alt="Varadise" src={home} sx={{ padding: '0 5px' }} />
                    </Link>
                    <Link to="/notifications">
                      <Box component="img" alt="Varadise" src={noty} sx={{ padding: '0 5px' }} />
                    </Link>
                    <Link to="/settings">
                      <Box
                        component="img"
                        alt="Varadise"
                        src={settings}
                        sx={{ padding: '0 5px' }}
                      />
                    </Link>
                    <Link to="/user-training">
                      <Box
                        component="img"
                        alt="Training"
                        src={presentations}
                        sx={{ padding: '0 5px' }}
                      />
                    </Link>
                    {hasRightByCatAndCode(RightsCategory.FORM_USER, ['C', 'R', 'U', 'D']) && (
                        <Link to="/admin">
                          <Box
                            component="img"
                            alt="Varadise"
                            src={usersIc}
                            sx={{ padding: '0 5px' }}
                          />
                        </Link>
                      )}
                  </Box>
                  <Box>
                    <IconButton
                      sx={{ p: '10px' }}
                      onClick={() => {
                        navigate('/setup')
                      }}>
                      <FormatListBulletedIcon />
                    </IconButton>
                    <IconButton
                      sx={{ p: 0 }}
                      onClick={() => {
                        sessionStorage.clear()
                        setLoginUser({ ...InitUserInfo })
                        localStorage.clear()
                        navigate('/')
                        keycloak.logout()
                      }}>
                      <img src="/img/logout.svg" alt="menu" />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </>
      ) : (
        <LeftSideBar />
      )}
    </>
  )

  return (
    <Box component="nav" aria-label="mailbox folders">
      <Drawer
        variant={matchUpMd ? 'persistent' : 'temporary'}
        anchor="left"
        open={matchUpMd || state.leftDrawerOpened}
        onClose={() => {
          dispatch({ type: 'toggleLeftDrawer' })
        }}
        sx={{
          '& .MuiDrawer-paper': {
            width: state.leftDrawerOpened
              ? matchDownMd
                ? '85%'
                : matchUpMd
                ? DrawerWidth
                : '100px'
              : '100px',
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
            borderRight: 'none',
            boxShadow: '0 3px 6px rgb(0 0 0 / 16%)',
          },
        }}
        ModalProps={{ keepMounted: true }}>
        {drawer}
      </Drawer>
    </Box>
  )
}

export default Sidebar
