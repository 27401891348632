import { Fragment, useContext, useEffect, useRef, useState } from 'react'

import { useForm } from 'react-hook-form'
import { GlobalContext } from '@providers/globalStore'
import FormField from '@components/form/field'
import FormContainer from '@components/form/container'
import { useTranslation } from 'react-i18next'
import useQuery from '@hooks/useQuery'
import { KeyValPair } from '@models/common'
import FormMultipleSelect from '@components/form/multipleSelect'
import FormSelect from '@components/form/select'
import FormController from '@components/form/controller'
import { NavigateTo } from '@utils/navigate'
import uuid from 'react-uuid'
import {
  UserFormModel,
  InitUserForm,
  DutyFormModel,
  InitDutyForm,
} from '@services/model/admin.model'
import AdminService from '@services/admin.service'
import { useNavigate, useParams } from 'react-router-dom'
import FormFreeText from '@components/form/freeText'
import useAPIFetch from '@hooks/useAPIFetch'
import { useSnackbar } from 'notistack'
import { DevTool } from '@hookform/devtools'
import { styled, SxProps, Theme, useTheme } from '@mui/material/styles'
import FormBinarySelect from '@components/form/binarySelect'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Box, Typography, Divider, Button, Grid, Stack, useMediaQuery } from '@mui/material'
import { GetOrganizationOptions } from '@utils/organizationOptions'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import DialogButton from '@components/button/DialogButton'
import FormDialog from '@components/form/dialog_v2'
import ValidationToast from '@components/form/ValidationToast'
import { DatePicker } from '@mui/x-date-pickers'
import FormDateTimePicker from '@components/form/dateTimePicker'
import LoadingPopup from '@components/form/LoadingDialog'

const UUID = uuid()
let readonly = false

type DialogContextProps = {
  isOpen: boolean
  title?: JSX.Element
  toolbarStyle?: SxProps<Theme>
  children?: JSX.Element
  buttons?: JSX.Element
}

export default function Form() {
  const { state: globalState, userInfo, dispatch: globalAction } = useContext(GlobalContext)
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const theme = useTheme()
  let { id: formId } = useParams<string>()

  const validationSchema = yup
    .object()
    .shape({
      email: yup.string().required(t('Email is required')),
      position: yup.string().required(t('Position is required')),
      nameOfPredecessor: yup.string().required(t('Name of predecessor is required')),
      effectiveDateFrom: yup.string().required(t('Start date is required')),
      effectiveDateTo: yup.string().required(t('End date is required')),
    })
    .required()

  const { control, getValues, reset, trigger } = useForm<DutyFormModel>({
    defaultValues: { ...InitDutyForm },
    mode: 'all',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    resolver: yupResolver(validationSchema),
  })

  const isMounted = useRef(false)
  const { setRequest, isLoading } = useAPIFetch()

  const InitDialogContent: DialogContextProps = {
    isOpen: false,
    title: <></>,
  }
  const [dialogContext, setDialogContext] = useState<DialogContextProps>(InitDialogContent)

  const handleDialogClose = () => {
    setDialogContext(InitDialogContent)
  }

  const reload = async () => {
    setRequest({
      callback: async () => {
        if (formId) {
          await AdminService.GetDutyFormById(formId)
            .then(async (f) => {
              if (f) reset(f)
            })
            .catch((err) => {
              enqueueSnackbar(err.response.data.message, {
                variant: 'error',
                autoHideDuration: null,
              })
            })
        }
        isMounted.current = true
      },
    })
  }

  useEffect(() => {
    if (formId == null) {
      reset({ ...InitUserForm })
    }

    reload()
  }, [formId])

  const handleBack = async (event: any) => {
    NavigateTo(navigate, '/admin/duty/all-record')
  }

  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))

  const StyledBox = styled(Box)(({ theme }) => ({
    display: 'block',
    margin: theme.spacing(1),
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
  }))

  const StyledDivider = styled(Divider)(() => ({
    '&::before, &::after': {
      borderColor: 'black',
    },
  }))

  const ButtonGroupBox = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    pt: 2,
  }))

  const SwitcherButton = styled(Button)(() => ({
    color: '#000000',
    '&:hover': {
      backgroundColor: '#e6e6e6',
    },
  }))

  const handleSubmitDialog = async () => {
    if (!formId) {
      setDialogContext({
        isOpen: true,
        title: <Typography variant="body1">Create new Duty Effective Time Record</Typography>,
        children: (
          <Typography>You are going to create a new Duty Effective Time Record.</Typography>
        ),
        buttons: (
          <Stack {...(!matchDownMd && { direction: 'row' })} spacing={2}>
            <DialogButton
              disabled={isLoading}
              onClick={async (event) => {
                if (!(await trigger())) {
                  console.log('in trigger')
                  handleDialogClose()
                  return
                }
                let values = getValues()
                if (values.status === undefined || values.status === null) {
                  values.status = true
                }
                setRequest({
                  callback: async () => {
                    await AdminService.SaveCreateDuty(values, formId ?? '').then((resp) => {
                      enqueueSnackbar(t('Record Saved'), { variant: 'success' })
                      NavigateTo(navigate, '/admin/duty/all-record')
                    })
                  },
                })
                handleDialogClose()
              }}>
              {t('Confirm')}
            </DialogButton>
            <DialogButton onClick={handleDialogClose}>{t('Cancel')}</DialogButton>
          </Stack>
        ),
      })
    } else {
      if (!(await trigger())) {
        console.log('in trigger')
        return
      }
      let values = getValues()
      if (values.status === undefined || values.status === null) {
        values.status = true
      }
      setRequest({
        callback: async () => {
          await AdminService.SaveCreateDuty(values, formId ?? '').then((resp) => {
            enqueueSnackbar(t('Record Saved'), { variant: 'success' })
            NavigateTo(navigate, '/admin/duty/all-record')
          })
        },
      })
    }
  }

  return (
    <Fragment>
      <Box
        sx={{
          position: 'relative',
        }}>
        <LoadingPopup open={isLoading} />
        <StyledBox>
          <Box
            sx={{
              marginLeft: 'auto',
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            <Typography
              variant={matchDownMd ? 'h6' : 'h4'}
              sx={{ fontWeight: 'bold', margin: theme.spacing(6) }}>
              {!formId ? t('Add Duty Effective Time') : t('Edit Duty Effective Time')}
            </Typography>
            {/* {formId && (
              <Button
                variant="outlined"
                sx={{ fontWeight: 'bold', margin: theme.spacing(6) }}
                onClick={onClickChangePassword}>
                {t('Reset Password')}
              </Button>
            )} */}
          </Box>
          {/* <FormSwitcher /> */}
          <FormContainer fkey={`${UUID}-ei-a`}>
            <FormField fieldName="Email">
              <FormController
                controllerProps={{
                  name: 'email',
                  control,
                }}>
                <FormFreeText
                  textfieldProps={{ fullWidth: true }}
                  uneditable={readonly || !!formId}
                />
              </FormController>
            </FormField>

            {/* <FormField fieldName="Full Name">
              <FormController
                controllerProps={{
                  name: 'firstName',
                  control,
                }}>
                <FormFreeText textfieldProps={{ fullWidth: true }} uneditable={readonly} />
              </FormController>
            </FormField> */}

            <FormField fieldName="Position">
              <FormController
                controllerProps={{
                  name: 'position',
                  control,
                }}>
                <FormFreeText textfieldProps={{ fullWidth: true }} uneditable={readonly} />
              </FormController>
            </FormField>

            <FormField fieldName="Name of Predecessor">
              <FormController
                controllerProps={{
                  name: 'nameOfPredecessor',
                  control,
                }}>
                <FormFreeText textfieldProps={{ fullWidth: true }} uneditable={readonly} />
              </FormController>
            </FormField>

            <FormField fieldName="Effective Date From">
              <FormController
                controllerProps={{
                  name: 'effectiveDateFrom',
                  control,
                }}>
                <FormDateTimePicker type="date" uneditable={readonly} />
              </FormController>
            </FormField>

            <FormField fieldName="Effective Date To">
              <FormController
                controllerProps={{
                  name: 'effectiveDateTo',
                  control,
                }}>
                <FormDateTimePicker type="date" uneditable={readonly} />
              </FormController>
            </FormField>

            {/* <FormField fieldName="Organisation">
             <FormController
                controllerProps={{
                  name: 'organizationId',
                  control,
                }}>
                <FormSelect options={GetOrganizationOptions() || []} uneditable={readonly} />
              </FormController>
            </FormField>

            <FormField fieldName="Team">
              <FormController
                controllerProps={{
                  name: 'teams',
                  control,
                }}>
                <FormMultipleSelect
                  key={0}
                  options={globalState.teamList || []}
                  uneditable={readonly}
                />
              </FormController>
            </FormField>

            <FormField fieldName="District">
              <FormController
                controllerProps={{
                  name: 'districts',
                  control,
                }}>
                <FormMultipleSelect
                  key={1}
                  options={globalState?.districtList || []}
                  uneditable={readonly}
                />
              </FormController>
            </FormField>

            <FormField fieldName="Role">
              <FormController
                controllerProps={{
                  name: 'roles',
                  control,
                }}>
                <FormMultipleSelect
                  key={2}
                  options={globalState?.roleMetaList || []}
                  uneditable={readonly}
                />
              </FormController>
            </FormField> */}
            {/* 
            {formId ? (
              <FormField fieldName="Status">
                <FormController
                  controllerProps={{
                    name: 'status',
                    control,
                  }}>
                  <FormBinarySelect
                    binaryOptionNames={{ yes: t('Enabled'), no: t('Disabled') }}
                    uneditable={readonly}
                  />
                </FormController>
              </FormField>
            ) : (
              <></>
            )} */}
          </FormContainer>
          <Grid>
            <StyledDivider>{t('')}</StyledDivider>
            <ButtonGroupBox>
              <SwitcherButton
                disabled={false}
                onClick={handleBack}
                startIcon={<ArrowBackIosNewIcon />}>
                {!formId ? t('Back') : t('Back')}
              </SwitcherButton>
              <Box sx={{ flex: '1 1 auto' }} />
              <SwitcherButton
                endIcon={<ArrowForwardIosIcon />}
                onClick={handleSubmitDialog}
                disabled={false}>
                {!formId ? t('Submit') : t('Save')}
              </SwitcherButton>
            </ButtonGroupBox>
          </Grid>
          <DevTool control={control} />
        </StyledBox>
      </Box>
      <FormDialog
        key={'dialog'}
        open={dialogContext.isOpen}
        onClose={handleDialogClose}
        toolbarStyle={dialogContext.toolbarStyle}
        title={dialogContext.title}
        buttons={dialogContext.buttons}
        children={dialogContext.children}
      />
      <ValidationToast control={control} />
    </Fragment>
  )
}
