import FormContainer from '@components/form/container'
import FormController from '@components/form/controller'
import { FormSDModel } from '@services/model/form/form.SD.model'
import { Control } from 'react-hook-form'
import FormField from '@components/form/field'
import uuid from 'react-uuid'
import { useTranslation } from 'react-i18next'
import { FormStatusEnum } from '@services/model/form/form.model'
import FormFreeText from '@components/form/freeText'

export default ({
    control,
    formStatus,
    readOnly
}:{
    control: Control<FormSDModel>
    formStatus: string
    readOnly: boolean
}) => {
    const UUID = uuid()
    let index = 0
    const { t } = useTranslation()
    console.log('formStatus', formStatus)

    return (
        <FormContainer fkey={`${UUID}-sd-g`}>
        <FormField fieldName="Remarks" index={index++}>
          <FormController
            controllerProps={{
              name: 'remarks',
              control: control,
            }}>
             <FormFreeText
              textfieldProps={{
                fullWidth: true,
                multiline: true,
                rows: 7,
              }}
              sx={{
                width: '90%',
              }}
              uneditable = {readOnly}
            />
          </FormController>
        </FormField>
        <FormField fieldName="Instruction to Contractor" index={index++}>
          <FormController
            controllerProps={{
              name: 'instructionToContractor',
              control: control,
            }}>
            <FormFreeText
              textfieldProps={{
                fullWidth: true,
                multiline: true,
                rows: 7,
              }}
              sx={{
                width: '90%',
              }}
              uneditable = {readOnly}
            />
          </FormController>
        </FormField>
        <FormField fieldName="Comment by Contractor's Repesentative" index={index++}>
          <FormController
            controllerProps={{
              name: 'contactorRepesentativeComment',
              control: control,
            }}>
            <FormFreeText
              textfieldProps={{
                fullWidth: true,
                multiline: true,
                rows: 7,
              }}
              sx={{
                width: '90%',
              }}
              uneditable = {formStatus !== FormStatusEnum.FORM_SD_CONTRACTOR_REVIEW}
            />
          </FormController>
        </FormField>
      </FormContainer>
    )
  }