import FormContainer from '@components/form/container'
import FormController from '@components/form/controller'
import FormField from '@components/form/field'
import { Control } from 'react-hook-form'
import { FormWouModel, WouGeneralOptions } from '@services/model/form/form.WOU.model'
import { useTranslation } from 'react-i18next'
import FormBinarySelect from '@components/form/binarySelect'
import FormFileUpload from '@components/form/fileUpload'
import FormTable from '@components/form/table'
import FormTypography from '@components/form/typography'

export default ({
  control,
  options,
  uneditable
}: {
  control: Control<FormWouModel>
  options?: WouGeneralOptions
  uneditable: boolean
}) => {
  const { t } = useTranslation()

  return (
    <FormContainer fkey={`wou-d`}>
      <FormField fieldName="Delay of Works">
        <FormController
          controllerProps={{
            name: 'letterOfSlowProgressIssued', //'delayOfWorks',
            control,
          }}>
          <FormTypography type="binary" />
        </FormController>
      </FormField>
      <FormField
        fieldName="Letter of Confirmation"
        fieldTooltip="Letter of the Contractor's Confirmation of No EOT Claim">
        <FormController
          controllerProps={{
            name: 'letterOfConfirmation',
            control,
          }}>
          <FormBinarySelect uneditable={uneditable}/>
        </FormController>
      </FormField>
      <FormField fieldName="Attachment">
        <FormController
          controllerProps={{
            name: 'attachmentForLetterOfNoEot',
            control,
          }}>
          <FormFileUpload height="125" acceptFiles={['application/pdf']} uneditable={uneditable}/>
        </FormController>
      </FormField>
      <FormTable
        columns={[
          {
            field: 'dateOfContractorNotification',
            displayName: 'Date of the Contractor Notification',
            type: 'date',
          },
          {
            field: 'dateOfContractorParticularsSubmission',
            displayName: 'Date of the Contractor Particulars Submission',
            type: 'date',
          },
          {
            field: 'completionOrWithdrawal',
            displayName: 'Completion / Withdrawal',
            type: 'select',
            options:
              options?.completionOrWithdraw?.map((x) => ({
                key: x.id,
                value: x.name,
              })) ?? [],
            tooltip: t("Completion of the Engineer's Assessment/Contractor's Withdrawal of Claim"),
          },
          {
            field: 'dateOfCompletionOrWithdrawal',
            displayName: 'Date of Completion / Withdrawal',
            type: 'date',
            tooltip: t(
              "Date of Completion of the Engineer's Assessment/Contractor's Withdrawal of Claim",
            ),
          },
        ]}
        fieldArrayProps={{
          name: 'extensionOfTimes',
          control,
        }}
        height={100}
        uneditable={uneditable}
      />
    </FormContainer>
  )
}
