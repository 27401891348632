import { AppBar, Box, CssBaseline, styled, Toolbar, useMediaQuery, useTheme } from '@mui/material'
import Header from './header'
import Sidebar, { DrawerWidth } from './sidebar'
import { GlobalContext } from '@providers/globalStore'
import { useContext, useEffect, useRef } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

import { ErrorBoundary } from 'react-error-boundary'
import { GetMenuMetaData } from '@utils/getMenuMetaData'
import BottomNavBar from './BottomNavBar'
import useAPIFetch from '@hooks/useAPIFetch'
import LoadingPopup from './form/LoadingDialog'
import { initContractRelatedOptions, initOptions } from '@utils/reloadOptions'
import { get, isNil } from 'lodash'
import useLocalStorage from '@hooks/useLocalStorage'
import ContractService from '@services/contract.service'
import { UserInfo } from '@services/model/user.model'

type mainProps = {
  leftDrawerOpened?: boolean
  isInForm?: boolean
}

// styles
const Main = styled('main', {
  shouldForwardProp: (prop: string) => prop !== 'leftDrawerOpened',
})<mainProps>(({ theme, leftDrawerOpened: open, isInForm }) => ({
  width: 'inherit !important',
  flexGrow: 1,
  padding: '0px',
  margin: '65px 0px 0px 0px',
  paddingTop: '5px',
  backgroundRepeat: 'no-repeat',
  backgroundAttachment: 'fixed',
  overflow: 'auto',
  ...(!open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      marginLeft: '100px',
      height: 'calc(100vh - 75px);',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: isInForm ? 'calc(100vh - 75px);' : 'calc(100vh - 75px - 75px);',
      // height: 'calc(100vh - 75px - 75px);',
    },
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up('md')]: {
      marginLeft: DrawerWidth,
      height: 'calc(100vh - 75px);',
    },
    [theme.breakpoints.down('md')]: {
      height: isInForm ? 'calc(100vh - 75px);' : 'calc(100vh - 75px - 75px);',
      // height: 'calc(100vh - 75px - 75px);',
    },
  }),
}))

const WrappedHeader = styled('header', {
  shouldForwardProp: (prop: string) => prop !== 'leftDrawerOpened',
})<mainProps>(({ theme, leftDrawerOpened: open }) => ({
  ...{
    width: '100%',
    padding: '5px',
    margin: '0px 10px 0px 10px',
    zIndex: 999,
  },
  ...(!open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      marginLeft: '100px',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up('md')]: {
      marginLeft: DrawerWidth + 10,
    },
  }),
}))

const MainLayout = () => {
  const theme = useTheme()
  const { state, dispatch } = useContext(GlobalContext)
  const { contractNoId, workGroupId, isInitiated } = state

  const [loginUser, setLoginUser] = useLocalStorage<UserInfo | undefined>('usr', undefined)
  const [lsContractNoId, setContractNoId] = useLocalStorage<string | undefined>(
    'contract',
    undefined,
  )
  const [lsWorkGroupId, setWorkGroupId] = useLocalStorage<string | number | undefined>(
    'workgroup',
    undefined,
  )
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'))
  const { setRequest, isLoading } = useAPIFetch()
  const { setRequest: setContractRelatedRequest, isLoading: isContractRelatedLoading } =
    useAPIFetch()
  const { setRequest: setContractRequest, isLoading: isContractLoading } = useAPIFetch()

  const isMounted = useRef(false)
  useEffect(() => {
    if (!isInitiated && !isMounted.current) {
      setContractRequest({
        callback: async () => {
          const contract = await ContractService.GetContractList()
          dispatch({
            type: 'reloadContractList',
            list: contract,
          })

          if (!lsContractNoId) {
            setContractNoId(String(get(contract, `0.id`, '')))
            setWorkGroupId(get(contract, `0.workGroups.0.id`, ''))
          }

          console.debug('mainLayout current contract', contract)
        },
      })
      setRequest({
        callback: async (cancelToken: any) => {
          const res = await initOptions()
          console.info(`[InitGeneral  Options] - contractNoId: ${contractNoId} workGroupId: ${workGroupId}`)
          Object.entries(res).forEach(([type, list = []]: [any, any[]]) => {
            dispatch({ type, list })
          })
          dispatch({ type: 'setInitiazed' })
          console.info('[GlobalContext - state]', state)
        },
      })
    }

    isMounted.current = true
  }, [loginUser])

  useEffect(() => {
    if (lsContractNoId && lsWorkGroupId) {
      setContractRelatedRequest({
        callback: async (cancelToken: any) => {
          const res = await initContractRelatedOptions()
          console.info(
            `[Init Contract Related Options] - contractNoId: ${lsContractNoId} workGroupId: ${lsWorkGroupId}`,
          )
          Object.entries(res).forEach(([type, list = []]: [any, any[]]) => {
            dispatch({ type, list })
          })
        },
      })
    }

    console.info('[GlobalContext - state]', state)
  }, [lsContractNoId, lsWorkGroupId])

  useEffect(() => {
    const metaData = GetMenuMetaData(window.location.pathname)
    dispatch({ type: 'changeHeaderColor', color: metaData?.colorCode })
    dispatch({
      type: 'changeTitle',
      title: metaData?.title ?? 'Road Maintenance Monitoring System',
    })
    if (metaData) {
      dispatch({
        type: 'changeSubtitle',
        subtitle: metaData.subtitle,
      })
    } else {
      dispatch({
        type: 'changeSubtitle',
        subtitle: 'Highways Department',
      })
    }
    document.title = metaData?.title ?? 'Road Maintenance Monitoring System'
  }, [window.location.pathname])

  function ErrorFallback({ error, resetErrorBoundary }) {
    return (
      <div role="alert">
        <p>Something went wrong:</p>
        <pre>{error.message}</pre>
        <button onClick={resetErrorBoundary}>Try again</button>
      </div>
    )
  }

  const pathList = window.location.pathname.split('/')
  const pathListLast = pathList[pathList.length - 1]
  const isInForm = !(
    pathListLast === 'my-action' ||
    pathListLast === 'dashboard' ||
    pathListLast === 'overview' ||
    pathListLast === 'all-record'
  )

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <CssBaseline />
      <LoadingPopup open={isLoading || isContractLoading || isContractRelatedLoading} />

      <AppBar enableColorOnDark position="fixed" color="inherit" elevation={0} sx={{ zIndex: 999 }}>
        <Toolbar
          sx={{
            backgroundColor: state.headerColor,
            boxShadow: '0 3px 6px rgb(0 0 0 / 16%)',
            // padding: '14px 20px',
            padding: 'auto',
            maxHeight: '75px !important',
            height: '75px !important',
          }}>
          <WrappedHeader leftDrawerOpened={state.leftDrawerOpened} theme={theme}>
            <Header
              title={state.title}
              bgColor={state.headerColor}
              subtitle={state.subtitle}
              formStatus={state.formStatus}
              workOrder={state.workOrder}
            />
          </WrappedHeader>
        </Toolbar>
      </AppBar>

      <Sidebar />

      <Main leftDrawerOpened={state.leftDrawerOpened} isInForm={isInForm}>
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => {
            // reset the state of your app so the error doesn't happen again
          }}>
          <Outlet />
        </ErrorBoundary>
      </Main>

      {!matchUpMd && !isInForm && <BottomNavBar />}
    </Box>
  )
}

export default MainLayout
