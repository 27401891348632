import {
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { Box, useTheme } from '@mui/system'
import { useContext, useEffect, useState } from 'react'
import { GlobalContext } from '@providers/globalStore'
import moment from 'moment'
import { useForm } from 'react-hook-form'
import { DashboardChart } from '@services/model/base.model'
import FormController from '@components/form/controller'
import FormMultipleSelect from '@components/form/multipleSelect'
import FormDateTimePicker from '@components/form/dateTimePicker'
import useAPIFetch from '@hooks/useAPIFetch'
import { ScrDashboardReq } from '@services/model/form/form.SCR.model'
import FormScrService from '@services/formService/form.SCR.service'
import { useSnackbar } from 'notistack'

export default function SCRDashboard() {
  const { state: globalState, userInfo } = useContext(GlobalContext)
  const { setRequest, isLoading } = useAPIFetch()
  const [dashboardData, setDashboardData] = useState<DashboardChart>()
  const theme = useTheme()
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'))
  const { enqueueSnackbar } = useSnackbar()

  const { control, watch, handleSubmit, setValue, getValues } = useForm<ScrDashboardReq>({
    defaultValues: {
      workOrderId: { operator: '=', value: [] },
      districtId: { operator: '=', value: [] },
    },
  })

  useEffect(() => {
    if (userInfo) {
      setValue('districtId.value', [userInfo.districts?.[0] ?? 18])
      setValue('startDate', moment().weekday(-20).toDate())

      let req: ScrDashboardReq = {
        startDate: getValues('startDate'),
        districtId: getValues('districtId'),
      }

      setRequest({
        callback: async (cancelToken: any) => {
          await FormScrService.GetScrDashboardContent(req).then(async (rep) => {
            if (!rep.mmWorksInScr?.showChart) {
              enqueueSnackbar(rep.mmWorksInScr?.message, {
                variant: 'warning',
                autoHideDuration: null,
              })
            }
            setDashboardData(rep.mmWorksInScr)
          })
        },
      })
    }
  }, [])

  useEffect(() => {
    const subscription = watch((data) => {
      if (
        data.startDate !== undefined &&
        data.districtId?.value !== undefined &&
        data.districtId?.value?.length > 0
      ) {
        let req: ScrDashboardReq = {
          startDate: data.startDate,
          districtId: data.districtId,
        }

        setRequest({
          callback: async (cancelToken: any) => {
            await FormScrService.GetScrDashboardContent(req).then(async (rep) => {
              if (!rep.mmWorksInScr?.showChart) {
                enqueueSnackbar(rep.mmWorksInScr?.message, {
                  variant: 'warning',
                  autoHideDuration: null,
                })
              }
              setDashboardData(rep.mmWorksInScr)
            })
          },
        })
      }
    })

    return () => subscription.unsubscribe()
  }, [watch])

  return (
    <>
      <Box
        sx={{
          padding: matchUpMd ? '40px' : '10px',
          gap: '12px',
          display: 'flex',
          flexDirection: 'column',
        }}>
        <Box
          display="flex"
          justifyContent="flex-start"
          gap="20px"
          alignItems="center"
          sx={{
            width: '100%',
          }}>
          <img src="/img/dashboard.svg" alt="back" />
          <Typography sx={{ fontSize: '2.125rem', fontWeight: 'bold', color: '#707070' }}>
            Site Check Record - Dashboard
          </Typography>
        </Box>
        <Paper sx={{ padding: matchUpMd ? '24px' : '4px' }}>
          <Box
            display="flex"
            gap="20px"
            alignItems="center"
            sx={{
              width: '100%',
              marginBottom: '12px',
              flexDirection: matchUpMd ? 'row' : 'column',
            }}
            justifyContent="space-between">
            <Typography sx={{ fontSize: '1.5rem', fontWeight: 'bold', color: '#707070' }}>
              {dashboardData?.title}
            </Typography>
            <Box sx={{ gap: '20px', display: 'flex', flexDirection: 'row' }}>
              <FormController
                controllerProps={{
                  name: 'districtId.value',
                  control,
                }}>
                <FormMultipleSelect options={globalState.districtList || []} />
              </FormController>
              <FormController
                controllerProps={{
                  name: 'startDate',
                  control,
                }}>
                <FormDateTimePicker
                  type="date"
                  filterDate={(day) => {
                    return moment(day).day() === 1
                  }}
                />
              </FormController>
            </Box>
          </Box>
          {
            <Box sx={{ width: '100%', gap: '12px', display: 'flex', flexDirection: 'column' }}>
              <Box
                sx={{
                  width: matchUpMd ? '1482px' : '100%',
                  overflow: matchUpMd ? 'auto' : 'scroll',
                }}>
                {isLoading ? (
                  <TableBody>
                    {[...Array(5)].map((e, i) => {
                      return (
                        <TableRow key={`skeleton_r_${i}`}>
                          {[...Array(19)].map((r, index) => {
                            return (
                              <TableCell key={`skeleton_${index}`} sx={{ width: '62px' }}>
                                <Skeleton />
                              </TableCell>
                            )
                          })}
                        </TableRow>
                      )
                    })}
                  </TableBody>
                ) : (
                  <Table>
                    <TableHead sx={{ backgroundColor: '#CCCCCC' }}>
                      <TableRow>
                        <TableCell key={`form-table-header-team`}>Team</TableCell>
                        {dashboardData?.datasets ? (
                          dashboardData?.datasets?.[0]?.data?.map((x) => {
                            return (
                              <TableCell key={`form-table-header-${x.itemName}`} colSpan={3}>
                                {x.itemName}
                              </TableCell>
                            )
                          })
                        ) : (
                          <>
                            <TableCell key={`form-table-header-temp-1`} colSpan={3}></TableCell>
                            <TableCell key={`form-table-header-temp-2`} colSpan={3}></TableCell>
                            <TableCell key={`form-table-header-temp-3`} colSpan={3}></TableCell>
                            <TableCell key={`form-table-header-temp-4`} colSpan={3}></TableCell>
                          </>
                        )}
                      </TableRow>
                      <TableRow>
                        <>
                          <TableCell key={`form-table-header-team-1`}></TableCell>
                          {dashboardData?.datasets?.[0]?.data?.map((dateGroup) => {
                            if (dateGroup?.valueStr === '-') {
                              return (
                                <>
                                  <TableCell key={`form-table-body-${dateGroup.itemName}-dummy-0`}>
                                    -
                                  </TableCell>
                                  <TableCell key={`form-table-body-${dateGroup.itemName}-dummy-1`}>
                                    -
                                  </TableCell>
                                  <TableCell key={`form-table-body-${dateGroup.itemName}-dummy-2`}>
                                    -
                                  </TableCell>
                                </>
                              )
                            }
                            return dateGroup?.subDatasets?.[0]?.data?.map((x) => {
                              return (
                                <TableCell
                                  key={`form-table-subheader-${dateGroup.itemName}-${x.itemName}`}>
                                  {x.itemName}
                                </TableCell>
                              )
                            })
                          })}
                        </>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dashboardData?.datasets?.map((c, cIdx) => {
                        return (
                          <TableRow>
                            <TableCell key={`form-table-body-${cIdx}-groupName`}>
                              {c.groupName}
                            </TableCell>
                            {c.data &&
                              c.data?.map((item) => {
                                if (item?.valueStr === '-') {
                                  return (
                                    <>
                                      <TableCell
                                        key={`form-table-body-${cIdx}-${c.groupName}-dummy-0`}>
                                        -
                                      </TableCell>
                                      <TableCell
                                        key={`form-table-body-${cIdx}-${c.groupName}-dummy-1`}>
                                        -
                                      </TableCell>
                                      <TableCell
                                        key={`form-table-body-${cIdx}-${c.groupName}-dummy-2`}>
                                        -
                                      </TableCell>
                                    </>
                                  )
                                }
                                return item?.subDatasets?.[0]?.data?.map((x) => {
                                  return (
                                    <TableCell
                                      key={`form-table-body-${cIdx}-${c.groupName}-${x.itemName}`}>
                                      {x.itemNo ?? x.valueStr}
                                    </TableCell>
                                  )
                                })
                              })}
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                )}
              </Box>
            </Box>
          }
        </Paper>
      </Box>
    </>
  )
}
