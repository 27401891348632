import { ImageModel } from '../image.model'
import { LocationModel } from '../location.model'

export interface FormScrpModel {
  uuid: string
  status: boolean
  scrNo?: string
  scrPhotoNo?: string
  photoCategoryId?: number
  images?: ImageModel[]
  location?: LocationModel

  locationMapBase64?: string
}

export const InitScrpForm: FormScrpModel = {
  uuid: '',
  status: true,
}
