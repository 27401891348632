import { styled } from '@mui/material/styles'
import { Grid } from '@mui/material'

const OutterContainer = styled(Grid)(({ theme }) => ({
  whiteSpace: 'nowrap',
  overflow: 'auto',
  width: '100%',
  [theme.breakpoints.up('md')]: {
    paddingLeft: 60,
    paddingRight: 60,
    paddingBottom: 70
  },
  '.grid-item:not([style*="display:none"]):nth-of-type(2n + 1)': {
    backgroundColor: '#f2f2f2',
  },
  flex: 1,
}))

export default function FormContainer(props: { children: any; fkey?: string }) {
  return (
    <OutterContainer key={`form-contrainer-${props.fkey}`}>
      <Grid container sx={{ overflow: 'hidden', width: 'inherit' }}>
        {props.children}
      </Grid>
    </OutterContainer>
  )
}
