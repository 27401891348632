import FormContainer from '@components/form/container'
import FormController from '@components/form/controller'
import FormDateTimePicker from '@components/form/dateTimePicker'
import FormField from '@components/form/field'
import FormSelect from '@components/form/select'
import { Control, UseFormSetValue } from 'react-hook-form'
import uuid from 'react-uuid'
import { FormStatusEnum } from '@services/model/form/form.model'
import FormTypography from '@components/form/typography'
import FormMediumMap from '@components/form/mediumMap'
import FormFreeText from '@components/form/freeText'
import { FormEIModel, InitEIForm, EiGeneralOptions } from '@services/model/form/form.EI.model'
import Radio from '@components/form/radio'
import FormMultipleSelect from '@components/form/multipleSelect'
import { GetTeamOptions } from '@utils/teamOptions'
import { GetDistrictOptions } from '@utils/districtOptions'
import { GetWorksOrderOptions } from '@utils/worksOrderOptions'
import { canvasExport } from '@utils/canvasExport'
import { ForwardedRef, forwardRef, useRef } from 'react'
import { GlobalStateProps } from '@providers/globalStore'
import { Stack } from '@mui/system'

declare module 'react' {
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null,
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null
}

function PartAInner(
  {
    control,
    formStatus,
    userInfo,
    useWatch,
    options,
    globalState,
    setValue,
    isMounted,
    watch,
    getValues,
  }: {
    control: Control<FormEIModel>
    formStatus: string
    userInfo: any
    useWatch: any
    options: any
    globalState: GlobalStateProps
    setValue: UseFormSetValue<FormEIModel>
    isMounted: any
    watch: any
    getValues: any
  },
  ref: ForwardedRef<HTMLUListElement>,
) {
  const UUID = uuid()
  const [contractNoId] = watch(['baseForm.contractNoId'])
  const matchedContract = globalState.contractList?.find(
    (contractNo) => contractNo.id === contractNoId,
  )

  const uneditable = !(
    formStatus === FormStatusEnum.FORM_EI_DRAFT || formStatus === FormStatusEnum.FORM_EI_REJECTED
  )

  const contractNoList =
    globalState.contractList?.map((x) => {
      return {
        key: x.id,
        value: x.contractNo,
      }
    }) || []

  const PartAWorksOrderNo = () => {
    const [contractNo, teamId, districtId] = useWatch({
      name: ['baseForm.contractNoId', 'baseForm.teamId', 'baseForm.districtId'],
      control,
    })

    console.log('globalState?.worksOrderLis', globalState?.worksOrderList)
    return (
      <>
        <FormField fieldName="Works Order No">
          {uneditable ? (
            <FormTypography value={getValues('baseForm.workOrderNo')} />
          ) : (
            <FormController
              controllerProps={{
                name: 'baseForm.workOrderId',
                control: control,
              }}>
              <FormSelect
                fullWidth
                options={GetWorksOrderOptions(
                  globalState?.worksOrderList || [],
                  teamId,
                  districtId,
                )}
                uneditable={uneditable}
              />
            </FormController>
          )}
        </FormField>
      </>
    )
  }

  const CaseSourceDetails = () => {
    const caseSourceId = useWatch({ control: control, name: 'caseSourceId' })

    if (['Others'].includes(options?.caseSource?.find((o) => o.id === caseSourceId)?.name ?? ''))
      return (
        <FormController
          controllerProps={{
            name: 'caseSource',
            control,
          }}>
          <FormFreeText textfieldProps={{ fullWidth: true }} uneditable={uneditable} />
        </FormController>
      )

    return <></>
  }

  console.log("options", options)

  return (
    <FormContainer fkey={`${UUID}-ei-a`}>
      <FormField fieldName="Contract No.">
        <FormController
          controllerProps={{
            name: 'baseForm.contractNoId',
            control,
            rules: { required: true },
          }}>
          <FormSelect fullWidth options={contractNoList} uneditable={true} />
        </FormController>
      </FormField>

      <FormField fieldName="Team">
        <FormController
          controllerProps={{
            name: 'baseForm.teamId',
            control,
          }}>
          <FormMultipleSelect
            options={uneditable ? globalState.teamList || [] : GetTeamOptions(userInfo.teams)}
            uneditable={uneditable}
            additionalChangeAction={(event) => {
              setValue('baseForm.workOrderId', undefined)
            }}
          />
        </FormController>
      </FormField>

      <FormField fieldName="District">
        <FormController
          controllerProps={{
            name: 'baseForm.districtId',
            control,
          }}>
          <FormMultipleSelect
            options={
              uneditable ? globalState.districtList || [] : GetDistrictOptions(userInfo.districts)
            }
            uneditable={uneditable}
            additionalChangeAction={(event) => {
              setValue('baseForm.workOrderId', undefined)
            }}
          />
        </FormController>
      </FormField>

      <FormField fieldName="From">
        <FormTypography type="string" value={matchedContract?.client} />
      </FormField>

      <FormField fieldName="Contractor">
        <FormTypography type="string" value={matchedContract?.contractor} />
      </FormField>

      <FormField fieldName="EI No">
        <FormController
          controllerProps={{
            name: 'eiNo',
            control,
          }}>
          <FormTypography />
        </FormController>
      </FormField>

      <FormField fieldName="EIA No">
        <FormController
          controllerProps={{
            name: 'eiaNo',
            control,
          }}>
          <FormTypography />
        </FormController>
      </FormField>

      <FormField fieldName="Location">
        <FormController
          controllerProps={{
            name: 'location',
            control,
          }}>
          <FormMediumMap
            additionalChangeAction={async (address) => {
              setValue('roadName', address)
            }}
            uneditable={uneditable}
            isMounted={isMounted.current}
            ref={ref}
          />
        </FormController>
      </FormField>

      <FormField fieldName="Road">
        <FormController
          controllerProps={{
            name: 'roadName',
            control,
          }}>
          <FormTypography />
        </FormController>
      </FormField>
      <FormField fieldName="Location Remarks">
        <FormController
          controllerProps={{
            name: 'locationRemark',
            control,
          }}>
          <FormFreeText textfieldProps={{ fullWidth: true }} uneditable={uneditable} />
        </FormController>
      </FormField>

      <FormField fieldName="Ref. No.">
        <FormController
          controllerProps={{
            name: 'refNo',
            control,
          }}>
          <FormFreeText textfieldProps={{ fullWidth: true }} uneditable={uneditable} />
        </FormController>
      </FormField>

      <FormField fieldName="Date of Checking">
        <FormController
          controllerProps={{
            name: 'dateOfCheck',
            control,
            rules: { required: true },
          }}>
          <FormDateTimePicker type="datetime" uneditable={uneditable} currentTimeBtn={true} />
        </FormController>
      </FormField>

      <FormField fieldName="Case Source">
        <Stack width="100%" gap="8px">
          <FormController
            controllerProps={{
              name: 'caseSourceId',
              control: control,
            }}>
            <Radio
              labels={
                options?.caseSource
                  ?.filter((x) => x.name !== 'Engineer Audit')
                  ?.map((x) => {
                    return {
                      key: x.id,
                      value: x.name,
                    }
                  }) || []
              }
              uneditable={uneditable}
              additionalChangeAction={(id) => {
                if (
                  !['Others'].includes(
                    options?.caseSource?.find((o) => o.id === id)?.name ?? '',
                  )
                ) {
                  setValue('caseSource', undefined)
                }
              }}
            />
          </FormController>
          <CaseSourceDetails />
        </Stack>
      </FormField>

      <PartAWorksOrderNo />

      <FormField fieldName="ICC No.">
        <FormController
          controllerProps={{
            name: 'iccNo',
            control,
          }}>
          <FormFreeText textfieldProps={{ fullWidth: true }} uneditable={uneditable} />
        </FormController>
      </FormField>

      <FormField fieldName="Emgergency Serial/SSW/XP No.">
        <FormController
          controllerProps={{
            name: 'emergencySerialNo',
            control,
          }}>
          <FormFreeText textfieldProps={{ fullWidth: true }} uneditable={uneditable} />
        </FormController>
      </FormField>

      <FormField fieldName="Remarks">
        <FormController
          controllerProps={{
            name: 'remarks',
            control,
          }}>
          <FormTypography />
        </FormController>
      </FormField>
    </FormContainer>
  )
}

export const PartA = forwardRef(PartAInner)
