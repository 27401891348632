import { DashboardChart }        from '@services/model/base.model'
import {
  EIADashboardReq,
  EIAFilterOptions,
  EIAGeneralOptions,
  EIAListResponse,
  FormEIAModel
}                                from '../model/form/form.EIA.model'
import { BaseFormResponse }      from '../model/form/form.model'
import apiGateway, { APIConfig } from '@utils/axiosInterceptor'

const GetEIAOptions = async (teams?: Array<number>, districts?: Array<number>) => {
  if (teams === undefined && districts === undefined)
    return await apiGateway.get<EIAGeneralOptions>(`api/v1/forms/EIA/options`)
                           .then(rep => rep.data)
  else {
    const params = new URLSearchParams()
    teams?.forEach(team => params.append('team', team.toString()))
    districts?.forEach(district => params.append('district', district.toString()))

    return await apiGateway.get<EIAGeneralOptions>(`api/v1/forms/EIA/options?${params.toString()}`)
                           .then(rep => rep.data)
  }
}

const GetEIAList = async (req: object, cancelToken: any, myRecordOnly: boolean) => {
  return await apiGateway
    .post<EIAListResponse>(`api/v1/forms/EIA/${myRecordOnly ? 'my-action' : 'list'}`, req, {
      cancelToken: cancelToken,
    })
    .then((rep) => {
      return rep.data
    })
}

const GetEIAFilterOption = async (showMyRecord?: boolean) => {
  let api = `api/v1/forms/EIA/filter-options`
  if (showMyRecord) {
    api += '?my_action=true'
  }
  return await apiGateway.get<EIAFilterOptions>(api).then((rep) => {
    return rep.data
  })
}

const GetEIAForm = async (formId: string) => {
  return await apiGateway.get<FormEIAModel>(`api/v1/forms/EIA/${formId}`).then((rep) => {
    return rep.data
  })
}

const SaveEIAForm = async (req: FormEIAModel) => {
  const config: APIConfig = {
    compress: true,
  }
  if (req.baseForm.formId === '')
    return await apiGateway.post<BaseFormResponse>('api/v1/forms/EIA', req, config).then((rep) => {
      return rep.data
    })
  else {
    return await apiGateway
      .put<BaseFormResponse>(`api/v1/forms/EIA/${req.baseForm.formId}`, req)
      .then((rep) => {
        return rep.data
      })
  }
}

const ApproveEIAForm = async (req: FormEIAModel) => {
  const config: APIConfig = {
    compress: true,
  }
  return await apiGateway
    .put<BaseFormResponse>(
      `api/v1/forms/EIA/${req.baseForm.formId ? req.baseForm.formId : '_'}/approve`,
      req,
      config,
    )
    .then((rep) => {
      return rep.data
    })
}

const RejectEIAForm = async (req: FormEIAModel) => {
  return await apiGateway
    .put<BaseFormResponse>(
      `api/v1/forms/EIA/${req.baseForm.formId ? req.baseForm.formId : '_'}/reject`,
      req,
    )
    .then((rep) => {
      return rep.data
    })
}

const ArchiveEIAForm = async (req: FormEIAModel) => {
  return await apiGateway
    .put<BaseFormResponse>(
      `api/v1/forms/EIA/${req.baseForm.formId ? req.baseForm.formId : '_'}/archive`,
      req,
    )
    .then((rep) => {
      return rep.data
    })
}

const DeleteEIAForm = async (req: FormEIAModel) => {
  return await apiGateway.delete<BaseFormResponse>(`api/v1/forms/EIA/${req.baseForm.formId}`)
}

const UpdateMap = async (req: FormEIAModel) => {
  return await apiGateway
    .put<BaseFormResponse>(
      `api/v1/forms/EIA/${req.baseForm.formId ? req.baseForm.formId : '_'}/map-image`,
      req,
    )
    .then((rep) => {
      return rep.data
    })
}

const GetEIADashboardContent = async (req: EIADashboardReq) => {
  return await apiGateway
    .post<{ mmWoMeetMinEiRqmt: DashboardChart }>(`api/v1/forms/EIA/dashboards`, req)
    .then((rep) => {
      return rep.data
    })
}

const FormEIAService = {
  GetEIAForm,
  GetEIAList,
  GetEIAFilterOption,
  SaveEIAForm,
  ApproveEIAForm,
  RejectEIAForm,
  ArchiveEIAForm,
  DeleteEIAForm,
  GetEIAOptions,
  UpdateMap,
  GetEIADashboardContent,
}

export default FormEIAService