import {
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Box,
  Typography,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useMediaQuery } from '@mui/material'
import { useState } from 'react'
import { fontSize, useTheme } from '@mui/system'
import VideoFileIcon from '@mui/icons-material/VideoFile'
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const videoUrl = [
  {
    path: 'https://rmms.hk/api/v1/files/2beb8749-c2ad-4489-b5f0-3faa4fce36be',
    name: 'User_Interface.mp4',
  },
  {
    path: 'https://rmms.hk/api/v1/files/fceae45d-9cfd-4e10-8739-6075213ab449',
    name: 'Basic_Functions.mp4',
  },
  {
    path: 'https://rmms.hk/api/v1/files/ac2981be-5408-4feb-902a-0745c8791226',
    name: 'M1_EIA.mp4',
  },
  {
    path: 'https://rmms.hk/api/v1/files/997c0741-83eb-4335-bfc7-e88120749433',
    name: 'M1_EI.mp4',
  },
  {
    path: 'https://rmms.hk/api/v1/files/a5117eb0-7355-45db-a1c8-e161a764af15',
    name: 'M1_NF.mp4',
  },
  {
    path: 'https://rmms.hk/api/v1/files/fb850ec3-a025-43b3-bcb0-e07c356e5f4b',
    name: 'M1_DN.mp4',
  },
  {
    path: 'https://rmms.hk/api/v1/files/40c69691-1591-4e87-b8f8-99c7a1c74991',
    name: 'M1_DNA.mp4',
  },
  {
    path: 'https://rmms.hk/api/v1/files/b67ece8b-60f1-4f8d-94d7-08dbf5c162c3',
    name: 'M2_NEA.mp4',
  },
  {
    path: 'https://rmms.hk/api/v1/files/2e7595be-3561-4f68-a7b6-1d0df11453d3',
    name: 'M2_EA.mp4',
  },
  {
    path: 'https://rmms.hk/api/v1/files/293f2df7-405b-42c0-82a8-fd99d7dc15d6',
    name: 'M2_QS.mp4',
  },
  {
    path: 'https://rmms.hk/api/v1/files/f6192424-8867-42b8-8bf3-2bdb6036dd09',
    name: 'M3_CDR.mp4',
  },
  {
    path: 'https://rmms.hk/api/v1/files/fb91874a-15c4-44c7-980f-362c38a5c13c',
    name: 'M3_SD.mp4',
  },
  {
    path: 'https://rmms.hk/api/v1/files/657d93f7-4f06-4c5e-b7a9-27ba0ed6b43b',
    name: 'M3_SCR.mp4',
  },
  {
    path: 'https://rmms.hk/api/v1/files/1fdcb1f7-377f-4991-843c-c0d2605bbcc3',
    name: 'M4_HWR.mp4',
  },
  {
    path: 'https://rmms.hk/api/v1/files/48a9efc7-2dbe-4ea6-9560-863c9b16f533',
    name: 'M5_CR.mp4',
  },
]

const fileUrl = [
  {
    path: 'https://rmms.hk/api/v1/files/ff5b3231-abc7-476f-8611-be0aaceae492',
    name: 'RMMS_User_Manual_v3.1.pdf',
  },
]

export const UserTraining = () => {
  const theme = useTheme()
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'))

  const [open, setOpen] = useState(false)
  const [selectedVideo, setSelectedVideo] = useState<string | null>(null)

  const handleClickOpen = (videoPath: string) => {
    setSelectedVideo(videoPath)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setSelectedVideo(null)
  }

  return (
    <Box
      sx={{
        padding: matchUpMd ? '40px' : '10px',
        gap: '12px',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <Box display="flex" gap="20px" alignItems="center" sx={{ width: '100%' }}>
        <Typography sx={{ fontSize: '2.125rem', fontWeight: 'bold', color: '#707070' }}>
          User Training
        </Typography>
      </Box>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {videoUrl.map(({ path = '', name = '' }, index) => (
          <Grid item key={index} xs={6} sm={4} lg={2}>
            <Box
              onClick={() => handleClickOpen(path)}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
                padding: '10px',
                border: '1px solid #d9d9d9',
                borderRadius: '4px',
                cursor: 'pointer',
                borderColor: 'white',
                fontSize: 80,
                color: '#707070',
                flexDirection: 'column',
              }}>
              <VideoFileIcon fontSize="inherit" />
              <Typography>{name}</Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Box display="flex" gap="20px" alignItems="center" sx={{ width: '100%' }}>
        <Typography sx={{ fontSize: '2.125rem', fontWeight: 'bold', color: '#707070' }}>
          User Manual
        </Typography>
      </Box>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {fileUrl.map(({ path = '', name = '' }, index) => (
          <Grid item key={index} xs={6} sm={4} lg={2}>
            <a href={path} target="_blank" download>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px',
                  padding: '10px',
                  border: '1px solid #d9d9d9',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  borderColor: 'white',
                  fontSize: 80,
                  color: '#707070',
                  flexDirection: 'column',
                }}>
                <FileDownloadIcon fontSize="inherit" />
                <Typography>{name}</Typography>
              </Box>
            </a>
          </Grid>
        ))}
      </Grid>
      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogTitle>
          <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {selectedVideo && (
            <video width="100%" controls>
              <source src={selectedVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  )
}
