import { KeyValPair } from '@models/common'

export interface UserListModel {
  status?: boolean
  displayName?: string
  position?: string
  organizationName?: string
  userId?: string
  email?: string
}

export interface UserFilterOptions {
  organizationIdList: number[]
}

export interface UserMetaModel {
  userId?: string
  displayName?: string
  status?: boolean
  position?: string
  roles?: number[]
}

export interface DutyFormModel {
  id: number
  email: string
  position: string
  nameOfPredecessor: string
  effectiveDateFrom: Date
  effectiveDateTo: Date
  status: boolean
}

export interface DutyListModel {
  id: number
  email: string
  position: string
  predecessor: string
  effectiveStartTime: Date
  effectiveEndTime: Date
  status: boolean
}

export const InitDutyForm: DutyFormModel = {
  // baseForm: { ...InitFormModel, formStatus: FormStatusEnum.FORM_USER_CREATE },
  id: 0,
  email: '',
  position: '',
  nameOfPredecessor: '',
  effectiveDateFrom: new Date(),
  effectiveDateTo: new Date(),
  status: true,
}

export interface UserFormModel {
  // baseForm: FormModel.displayName
  id?: number
  uuid?: string
  userId?: string
  status?: boolean
  email: string
  displayName?: string
  firstName?: string
  lastName?: string
  position?: string
  teams?: number[] | KeyValPair[]
  organizationId?: number
  assignedGroup?: any
  roles?: number[] | KeyValPair[]
  districts?: number[] | KeyValPair[]

  contractRole?: {
    contractNoId?: number
    role?: number[]
    teams?: number[] 
    districts?: number[] 
  }[]
}

export const InitUserForm: UserFormModel = {
  // baseForm: { ...InitFormModel, formStatus: FormStatusEnum.FORM_USER_CREATE },
  email: '',
}
