import Keycloak from 'keycloak-js'

const keycloakURL = process.env.REACT_APP_KEYCLOAK_URL as string

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloak = new Keycloak({
    url: keycloakURL,
    realm: "Hyd-RMMS",
    clientId: "hyd-rmms",
})

export default keycloak