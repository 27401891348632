import SearchBar from '@components/SearchBar'
import type { useTableViewProps } from '@hooks/useTableView'
import { KeyValPair } from '@models/common'
import { ArrowDropDown, ArrowDropUp, RestartAlt, Close, Search } from '@mui/icons-material'
import ClearIcon from '@mui/icons-material/Clear'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import { styled, useTheme } from '@mui/material/styles'
import {
  Autocomplete,
  Box,
  BoxProps,
  Button,
  Collapse,
  Drawer,
  FormLabel,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { DesktopDatePicker, LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { GlobalContext } from '@providers/globalStore'
import { InitialSearchPanelState, SearchPanelContext } from '@providers/SearchPanelProvider'
import SearchPanelReducer, { SearchPanelState } from '@reducers/searchPanelReducer'
import { StatusEnum, StatusEnumFilterList } from '@services/model/base.model'
import moment from 'moment'
import {
  Fragment,
  MutableRefObject,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'

export type SearchPanelProps = {
  addUrl?: string
  criteria?: SearchCriteriaList[]
  onSearch?: (filterResult?: any, resetPage?: boolean) => void
  onInitReload?: () => void
  onToggleFilterDrawer?: () => Promise<void>
  dispatch?: any
  appendDefaultFilter?: boolean
  excludeLastModify?: boolean
  excludeStatus?: boolean
  mountedRef: MutableRefObject<boolean>
  extraButtons?: JSX.Element
}

type operator = {
  sign: string
  desc: string
}

const equal: operator = { sign: '=', desc: 'Include' }
const notEqual: operator = { sign: '!=', desc: 'Exclude' }
const contains: operator = { sign: '~', desc: 'Contains' }
const notContains: operator = { sign: '!~', desc: 'Not Contains' }
const between: operator = { sign: 'in', desc: 'Betweens' }

// const greaterThan: operator = { sign: '>', desc: 'Greater than' }
const greaterThanOrEqual: operator = { sign: '>=', desc: 'More than' }
const dateGreaterThanOrEqual: operator = { sign: '>=', desc: 'After' }

// const lessThan: operator = { sign: '<', desc: 'Less than' }
const lessThanOrEqual: operator = { sign: '<=', desc: 'Less than' }
const dateLessThanOrEqual: operator = { sign: '<=', desc: 'Before' }

const empty: operator = { sign: 'null', desc: 'Is Empty' }
const notEmpty: operator = { sign: '!null', desc: 'Is Not Empty' }

const stringFilterOperators: operator[] = [equal, notEqual, contains, notContains]

const stringSelectionFilterOperators: operator[] = [equal, notEqual]

const booleanSelectionFilterOperators: operator[] = [contains, notContains]

const minAndMaxOperatorWidth = '150px'

const numberFilterOperators: operator[] = [
  equal,
  notEqual,
  between,
  greaterThanOrEqual,
  lessThanOrEqual,
]

const dateFilterOperators: operator[] = [
  equal,
  notEqual,
  between,
  dateGreaterThanOrEqual,
  dateLessThanOrEqual,
]

const dateFilterWithNullOperators: operator[] = dateFilterOperators.concat([empty, notEmpty])

type SearchCriteriaList =
  | StringFilterProps
  | StringSelectionFilterProps
  | NumberSelectionFilterProps
  | NumberFilterProps
  | BooleanSelectionFilterProps
  | DateFilterProps

type StringFilterProps = {
  key: string
  desc: string
  type: 'StringFilter'
  defaultValue?: string
}

const StringFilter = (props: StringFilterProps, matchUpMd: boolean) => {
  return (
    <SearchPanelContext.Consumer>
      {({ state, dispatch }) => (
        <Box
          key={props.key}
          sx={{
            display: 'flex',
            bgcolor: 'background.paper',
            borderRadius: 1,
            alignItems: 'center',
            flexDirection: matchUpMd ? 'row' : 'column',
          }}>
          <Box
            sx={{
              minWidth: matchUpMd ? minAndMaxOperatorWidth : '100%',
              maxWidth: minAndMaxOperatorWidth,
              mr: '10px',
            }}>
            <TextField
              select
              fullWidth
              margin="normal"
              defaultValue="~"
              value={state[props.key]?.operator || '~'}
              onChange={(e) => {
                dispatch({
                  type: 'changeOperator',
                  key: props.key,
                  operator: e.target.value,
                })
              }}>
              {stringFilterOperators.map((o) => {
                return (
                  <MenuItem key={`${props.key}_${o.sign}`} value={o.sign} selected>
                    {o.desc}
                  </MenuItem>
                )
              })}
            </TextField>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              pt: '7.5px',
              minWidth: matchUpMd ? minAndMaxOperatorWidth : '100%',
              mr: matchUpMd ? '0px' : '10px',
            }}>
            <TextField
              hiddenLabel={true}
              variant="outlined"
              fullWidth
              value={(state[props.key]?.value as string) || ('' as string)}
              onChange={(e) => {
                dispatch({
                  type: 'changeStrVal',
                  key: props.key,
                  value: e.target.value,
                })
              }}
            />
          </Box>
        </Box>
      )}
    </SearchPanelContext.Consumer>
  )
}

type StringSelectionFilterProps = {
  key: string
  desc: string
  type: 'StringSelectionFilter'
  defaultValue?: string[] // Codes
  multiple?: boolean //Indicates single/multiple values
  valueList: KeyValPair[] | undefined //Indicates whe,
  hidden?: boolean
}

type NumberSelectionFilterProps = {
  key: string
  desc: string
  defaultValue?: Number[] // Ids
  type: 'NumberSelectionFilter'
  multiple?: boolean //Indicates single/multiple values
  valueList: KeyValPair[] | undefined //Indicates whe,
  hidden?: boolean
}

const StringSelectionFilter = function (props: StringSelectionFilterProps, matchUpMd: boolean) {
  if (props.hidden === true) return <></>

  return (
    <SearchPanelContext.Consumer>
      {({ state, dispatch }) => (
        <Box
          key={props.key}
          sx={{
            display: 'flex',
            bgcolor: 'background.paper',
            borderRadius: 1,
            alignItems: 'center',
            flexDirection: matchUpMd ? 'row' : 'column',
          }}>
          <Box
            key={`${props.key}_2`}
            sx={{
              minWidth: matchUpMd ? minAndMaxOperatorWidth : '100%',
              maxWidth: minAndMaxOperatorWidth,
              mr: '10px',
            }}>
            <TextField
              key={`${props.key}_in`}
              select
              fullWidth
              margin="normal"
              value={state[props.key]?.operator || '='}
              onChange={(e) => {
                dispatch({
                  type: 'changeOperator',
                  key: props.key,
                  operator: e.target.value,
                })
              }}>
              {stringSelectionFilterOperators.map((o, oIdx) => {
                return (
                  <MenuItem key={`${props.key}_1_${oIdx}`} value={o.sign}>
                    {o.desc}
                  </MenuItem>
                )
              })}
            </TextField>
          </Box>

          <Box
            key={`${props.key}_3`}
            sx={{
              flexGrow: 1,
              pt: '7.5px',
              minWidth: matchUpMd ? minAndMaxOperatorWidth : '100%',
              mr: matchUpMd ? '0px' : '10px',
            }}>
            <Autocomplete
              key={`${props.key}_ac`}
              multiple={props.multiple || false}
              options={props.valueList || []}
              getOptionLabel={(option) => option?.value || ''}
              value={(state[props.key]?.value || []) as any}
              onChange={(event, value: KeyValPair) => {
                console.log('value', value)
                dispatch({ type: 'changeVal', key: props.key, value: value })
              }}
              renderInput={(params) => (
                <TextField
                  key={`${props.key}_2_in`}
                  hiddenLabel={true}
                  variant="outlined"
                  sx={{
                    color: 'white',
                  }}
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    type: 'search',
                  }}
                />
              )}
              renderOption={(ps, option) => {
                return (
                  <li {...ps} key={`${props.key}_2`}>
                    {option.value}
                  </li>
                )
              }}
            />
          </Box>
        </Box>
      )}
    </SearchPanelContext.Consumer>
  )
}

const NumberSelectionFilter = function (props: NumberSelectionFilterProps, matchUpMd: boolean) {
  if (props.hidden === true) return <></>

  return (
    <SearchPanelContext.Consumer>
      {({ state, dispatch }) => (
        <Box
          key={props.key}
          sx={{
            display: 'flex',
            bgcolor: 'background.paper',
            borderRadius: 1,
            alignItems: 'center',
            flexDirection: matchUpMd ? 'row' : 'column',
          }}>
          <Box
            key={`${props.key}_2`}
            sx={{
              minWidth: matchUpMd ? minAndMaxOperatorWidth : '100%',
              maxWidth: minAndMaxOperatorWidth,
              mr: '10px',
            }}>
            <TextField
              key={`${props.key}_in`}
              select
              fullWidth
              margin="normal"
              value={state[props.key]?.operator || '='}
              onChange={(e) => {
                dispatch({
                  type: 'changeOperator',
                  key: props.key,
                  operator: e.target.value,
                })
              }}>
              {stringSelectionFilterOperators.map((o, oIdx) => {
                return (
                  <MenuItem key={`${props.key}_1_${oIdx}`} value={o.sign}>
                    {o.desc}
                  </MenuItem>
                )
              })}
            </TextField>
          </Box>

          <Box
            key={`${props.key}_3`}
            sx={{
              flexGrow: 1,
              pt: '7.5px',
              minWidth: matchUpMd ? minAndMaxOperatorWidth : '100%',
              mr: matchUpMd ? '0px' : '10px',
            }}>
            <Autocomplete
              key={`${props.key}_ac`}
              multiple={props.multiple || false}
              options={props.valueList || []}
              getOptionLabel={(option) => option?.value || ''}
              value={(state[props.key]?.value || []) as any}
              onChange={(event, value: KeyValPair) => {
                console.log('value', value)
                dispatch({ type: 'changeVal', key: props.key, value: value })
              }}
              isOptionEqualToValue={(option, value) => Number(option.key) === Number(value.key)}
              renderInput={(params) => (
                <TextField
                  key={`${props.key}_2_in`}
                  hiddenLabel={true}
                  variant="outlined"
                  sx={{
                    color: 'white',
                  }}
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    type: 'search',
                  }}
                />
              )}
              renderOption={(ps, option) => {
                return (
                  <li {...ps} key={`${props.key}_2`}>
                    {option.value}
                  </li>
                )
              }}
            />
          </Box>
        </Box>
      )}
    </SearchPanelContext.Consumer>
  )
}

type NumberFilterProps = {
  key: string
  desc: string
  type: 'NumberFilter'
  defaultMin?: number
  defaultMax?: number
  defaultOperator?: '=' | '!=' | 'in' | '>' | '>=' | '<' | '<='
}

const NumberFilter = (props: NumberFilterProps, matchUpMd: boolean) => {
  return (
    <SearchPanelContext.Consumer>
      {({ state, dispatch }) => (
        <Box
          key={props.key}
          sx={{
            display: 'flex',
            bgcolor: 'background.paper',
            borderRadius: 1,
            alignItems: 'center',
          }}>
          <Box
            sx={{
              minWidth: minAndMaxOperatorWidth,
              maxWidth: minAndMaxOperatorWidth,
              mr: '10px',
            }}>
            <TextField
              select
              fullWidth
              margin="normal"
              value={state[props.key]?.operator || '='}
              onChange={(e) => {
                dispatch({
                  type: 'changeOperator',
                  key: props.key,
                  operator: e.target.value,
                })
              }}>
              {numberFilterOperators.map((o, oIdx) => {
                return (
                  <MenuItem key={`${props.key}_${oIdx}`} value={o.sign} selected>
                    {o.desc}
                  </MenuItem>
                )
              })}
            </TextField>
          </Box>

          <Box sx={{ flexGrow: 1, pt: '7.5px' }}>
            {state[props.key]?.operator === 'in' ? (
              <Stack direction="row" spacing={2}>
                <TextField
                  type={'number'}
                  hiddenLabel={true}
                  variant="outlined"
                  fullWidth
                  value={state[props.key]?.value?.min || 0}
                  onChange={(e) => {
                    const re = /^[0-9\b]+$/
                    if (re.test(e.target.value)) {
                      dispatch({
                        type: 'changeVal',
                        key: props.key,
                        value: {
                          max: state[props.key]?.value?.max,
                          min: Number(e.target.value),
                        },
                      })
                    }
                  }}
                />
                <FormLabel sx={{ padding: '8px' }}>To</FormLabel>
                <TextField
                  type={'number'}
                  hiddenLabel={true}
                  variant="outlined"
                  fullWidth
                  value={state[props.key]?.value?.max || 0}
                  onChange={(e) => {
                    const re = /^[0-9\b]+$/
                    if (re.test(e.target.value)) {
                      dispatch({
                        type: 'changeVal',
                        key: props.key,
                        value: {
                          max: Number(e.target.value),
                          min: state[props.key]?.value?.min,
                        },
                      })
                    }
                  }}
                />
              </Stack>
            ) : (
              <TextField
                hiddenLabel={true}
                variant="outlined"
                fullWidth
                type={'number'}
                value={state[props.key]?.value?.min || 0}
                onChange={(e) => {
                  const re = /^[0-9\b]+$/
                  if (re.test(e.target.value)) {
                    dispatch({
                      type: 'changeVal',
                      key: props.key,
                      value: {
                        max: state[props.key]?.value?.max,
                        min: Number(e.target.value),
                      },
                    })
                  }
                }}
              />
            )}
          </Box>
        </Box>
      )}
    </SearchPanelContext.Consumer>
  )
}

type BooleanSelectionFilterProps = {
  key: string
  desc: string
  type: 'BooleanSelectionFilter'
  defaultValue?: boolean[] // Codes
  multiple?: boolean //Indicates single/multiple values
  valueList: KeyValPair[] | undefined //Indicates whe,
  hidden?: boolean
}

const BooleanSelectionFilter = function (props: BooleanSelectionFilterProps, matchUpMd: boolean) {
  if (props.hidden === true) return <></>

  return (
    <SearchPanelContext.Consumer>
      {({ state, dispatch }) => (
        <Box
          key={props.key}
          sx={{
            display: 'flex',
            bgcolor: 'background.paper',
            borderRadius: 1,
            alignItems: 'center',
            flexDirection: matchUpMd ? 'row' : 'column',
          }}>
          <Box
            key={`${props.key}_2`}
            sx={{
              minWidth: matchUpMd ? minAndMaxOperatorWidth : '100%',
              maxWidth: minAndMaxOperatorWidth,
              mr: '10px',
            }}>
            <TextField
              key={`${props.key}_in`}
              select
              fullWidth
              margin="normal"
              value={state[props.key]?.operator || '~'}
              onChange={(e) => {
                dispatch({
                  type: 'changeOperator',
                  key: props.key,
                  operator: e.target.value,
                })
              }}>
              {booleanSelectionFilterOperators.map((o, oIdx) => {
                return (
                  <MenuItem key={`${props.key}_1_${oIdx}`} value={o.sign}>
                    {o.desc}
                  </MenuItem>
                )
              })}
            </TextField>
          </Box>

          <Box
            key={`${props.key}_3`}
            sx={{
              flexGrow: 1,
              pt: '7.5px',
              minWidth: matchUpMd ? minAndMaxOperatorWidth : '100%',
              mr: matchUpMd ? '0px' : '10px',
            }}>
            <Autocomplete
              key={`${props.key}_ac`}
              multiple={props.multiple || false}
              options={props.valueList || []}
              getOptionLabel={(option) => option?.value || ''}
              value={(state[props.key]?.value || []) as any}
              onChange={(event, value: KeyValPair) => {
                console.log('value', value)
                dispatch({ type: 'changeVal', key: props.key, value: value })
              }}
              renderInput={(params) => (
                <TextField
                  key={`${props.key}_2_in`}
                  hiddenLabel={true}
                  variant="outlined"
                  sx={{
                    color: 'white',
                  }}
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    type: 'search',
                  }}
                />
              )}
              renderOption={(ps, option) => {
                return (
                  <li {...ps} key={`${props.key}_2`}>
                    {option.value}
                  </li>
                )
              }}
            />
          </Box>
        </Box>
      )}
    </SearchPanelContext.Consumer>
  )
}

type DateFilterProps = {
  key: string
  desc: string
  type: 'DateFilter'
  minDate?: string
  maxDate?: string
  isNullable?: boolean
  disableOperator?: boolean
  defaultFrom?: string
  defaultTo?: string
  defaultOperator?: '=' | '!=' | 'in' | '>' | '>=' | '<' | '<='
}

const DateFilter = (props: DateFilterProps, matchUpMd: boolean) => {
  return (
    <SearchPanelContext.Consumer>
      {({ state, dispatch }) => (
        <Box
          key={props.key}
          sx={{
            display: 'flex',
            bgcolor: 'background.paper',
            borderRadius: 1,
            alignItems: 'center',
            flexDirection: matchUpMd ? 'row' : 'column',
          }}>
          <Box
            key={`${props.key}_2`}
            sx={{
              minWidth: matchUpMd ? minAndMaxOperatorWidth : '100%',
              maxWidth: minAndMaxOperatorWidth,
              mr: '10px',
            }}>
            <TextField
              select
              fullWidth
              margin="normal"
              value={state[props.key]?.operator || '='}
              onChange={(e) => {
                dispatch({
                  type: 'changeOperator',
                  key: props.key,
                  operator: e.target.value,
                })
              }}
              disabled={props.disableOperator}>
              {props.isNullable === true
                ? dateFilterWithNullOperators.map((o, oIdx) => {
                    return (
                      <MenuItem key={`${props.key}_${oIdx}`} value={o.sign} selected>
                        {o.desc}
                      </MenuItem>
                    )
                  })
                : dateFilterOperators.map((o, oIdx) => {
                    return (
                      <MenuItem key={`${props.key}_${oIdx}`} value={o.sign} selected>
                        {o.desc}
                      </MenuItem>
                    )
                  })}
            </TextField>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              pt: '7.5px',
              minWidth: matchUpMd ? minAndMaxOperatorWidth : '100%',
              mr: matchUpMd ? '0px' : '10px',
            }}>
            {state[props.key]?.operator === 'null' || state[props.key]?.operator === '!null' ? (
              <></>
            ) : state[props.key]?.operator === 'in' ? (
              <Stack direction="row" spacing={2}>
                {matchUpMd ? (
                  <DesktopDatePicker
                    inputFormat="dd-MM-yyyy"
                    mask="__-__-____"
                    value={
                      state[props.key]?.value?.min !== undefined
                        ? moment(state[props.key]?.value?.min, 'yyyy-MM-DDTHH:mm:ss.000Z')
                        : null
                    }
                    onChange={(newVal) => {
                      dispatch({
                        type: 'changeVal',
                        key: props.key,
                        value: {
                          max: state[props.key]?.value?.max,
                          min: moment(newVal)?.format('yyyy-MM-DDTHH:mm:ss.000') + 'Z',
                        },
                      })
                    }}
                    renderInput={(params) => (
                      <TextField hiddenLabel={true} {...params} error={false} />
                    )}
                    InputProps={{
                      startAdornment: (
                        <div
                          onClick={() => {
                            dispatch({
                              type: 'changeVal',
                              key: props.key,
                              value: {
                                max: state[props.key]?.value?.max,
                                min: undefined,
                              },
                            })
                          }}
                          style={{
                            marginRight: 20,
                            cursor: 'pointer',
                            visibility:
                              state[props.key]?.value?.min === undefined ? 'hidden' : 'visible',
                          }}>
                          <ClearIcon />
                        </div>
                      ),
                    }}
                  />
                ) : (
                  <MobileDatePicker
                    inputFormat="dd-MM-yyyy"
                    mask="__-__-____"
                    value={
                      state[props.key]?.value?.min !== undefined
                        ? moment(state[props.key]?.value?.min, 'yyyy-MM-DDTHH:mm:ss.000Z')
                        : null
                    }
                    onChange={(newVal) => {
                      dispatch({
                        type: 'changeVal',
                        key: props.key,
                        value: {
                          max: state[props.key]?.value?.max,
                          min: moment(newVal)?.format('yyyy-MM-DDTHH:mm:ss.000') + 'Z',
                        },
                      })
                    }}
                    renderInput={(params) => (
                      <TextField hiddenLabel={true} {...params} error={false} />
                    )}
                  />
                )}
                <FormLabel sx={{ padding: '8px' }}>To</FormLabel>
                {matchUpMd ? (
                  <DesktopDatePicker
                    inputFormat="dd-MM-yyyy"
                    mask="__-__-____"
                    value={
                      state[props.key]?.value?.max !== undefined
                        ? moment(state[props.key]?.value?.max, 'yyyy-MM-DDTHH:mm:ss.000Z')
                        : null
                    }
                    onChange={(newVal) => {
                      dispatch({
                        type: 'changeVal',
                        key: props.key,
                        value: {
                          max: moment(newVal)?.format('yyyy-MM-DDTHH:mm:ss.000') + 'Z',
                          min: state[props.key]?.value?.min,
                        },
                      })
                    }}
                    renderInput={(params) => (
                      <TextField hiddenLabel={true} {...params} error={false} />
                    )}
                    InputProps={{
                      startAdornment: (
                        <div
                          onClick={() => {
                            dispatch({
                              type: 'changeVal',
                              key: props.key,
                              value: {
                                max: undefined,
                                min: state[props.key]?.value?.min,
                              },
                            })
                          }}
                          style={{
                            marginRight: 20,
                            cursor: 'pointer',
                            visibility:
                              state[props.key]?.value?.max === undefined ? 'hidden' : 'visible',
                          }}>
                          <ClearIcon />
                        </div>
                      ),
                    }}
                  />
                ) : (
                  <MobileDatePicker
                    inputFormat="dd-MM-yyyy"
                    mask="__-__-____"
                    value={
                      state[props.key]?.value?.max !== undefined
                        ? moment(state[props.key]?.value?.max, 'yyyy-MM-DDTHH:mm:ss.000Z')
                        : null
                    }
                    onChange={(newVal) => {
                      dispatch({
                        type: 'changeVal',
                        key: props.key,
                        value: {
                          max: moment(newVal)?.format('yyyy-MM-DDTHH:mm:ss.000') + 'Z',
                          min: state[props.key]?.value?.min,
                        },
                      })
                    }}
                    renderInput={(params) => (
                      <TextField hiddenLabel={true} {...params} error={false} />
                    )}
                  />
                )}
              </Stack>
            ) : matchUpMd ? (
              <DesktopDatePicker
                inputFormat="dd-MM-yyyy"
                mask="__-__-____"
                value={
                  state[props.key]?.value?.min !== undefined
                    ? moment(state[props.key]?.value?.min, 'yyyy-MM-DDTHH:mm:ss.000Z')
                    : null
                }
                onChange={(newVal) => {
                  dispatch({
                    type: 'changeVal',
                    key: props.key,
                    value: { min: moment(newVal)?.format('yyyy-MM-DDTHH:mm:ss.000') + 'Z' },
                  })
                }}
                renderInput={(params) => (
                  <TextField hiddenLabel={true} {...params} error={false} fullWidth />
                )}
                InputProps={{
                  startAdornment: (
                    <div
                      onClick={() => {
                        dispatch({
                          type: 'changeVal',
                          key: props.key,
                          value: undefined,
                        })
                      }}
                      style={{
                        marginRight: 20,
                        cursor: 'pointer',
                        visibility:
                          state[props.key]?.value?.min === undefined ? 'hidden' : 'visible',
                      }}>
                      <ClearIcon />
                    </div>
                  ),
                }}
              />
            ) : (
              <MobileDatePicker
                inputFormat="dd-MM-yyyy"
                mask="__-__-____"
                value={
                  state[props.key]?.value?.min !== undefined
                    ? moment(state[props.key]?.value?.min, 'yyyy-MM-DDTHH:mm:ss.000Z')
                    : null
                }
                onChange={(newVal) => {
                  dispatch({
                    type: 'changeVal',
                    key: props.key,
                    value: { min: moment(newVal)?.format('yyyy-MM-DDTHH:mm:ss.000') + 'Z' },
                  })
                }}
                renderInput={(params) => (
                  <TextField hiddenLabel={true} {...params} error={false} fullWidth />
                )}
              />
            )}
          </Box>
        </Box>
      )}
    </SearchPanelContext.Consumer>
  )
}

function IconItem(iconProps: BoxProps) {
  const { sx, ...other } = iconProps
  return (
    <Box
      sx={{
        m: 1,
        color: 'dark',
        border: '1px solid',
        borderColor: 'transparent',
        fontSize: '0.875rem',
        ...sx,
      }}
      {...other}
    />
  )
}

const SearchPanel = function (props: SearchPanelProps) {
  const theme = useTheme()
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'))
  const [isOpen, setIsOpen] = useState<boolean | undefined>()
  const { state } = useContext(GlobalContext)
  const searchContext = useContext(SearchPanelContext)
  const { t } = useTranslation()

  const toggleFilter = () => {
    if (props.onToggleFilterDrawer) props.onToggleFilterDrawer()
    setIsOpen(!isOpen)
  }

  var cri: SearchCriteriaList[] = []
  if (props.criteria) cri = cri.concat(props.criteria)

  useEffect(() => {
    if (props.onToggleFilterDrawer)
      props.onToggleFilterDrawer().then(() => {
        props.dispatch({ type: 'setIsInitialized' })
      })
  }, [])

  useEffect(() => {
    if (props.mountedRef.current) {
      cri?.forEach((c) => {
        if (c.type === 'StringSelectionFilter') {
          if (c.defaultValue) {
            var defaultValues: KeyValPair[] =
              c.valueList?.filter((x) => c.defaultValue?.includes(x.key)) || []
            props.dispatch({ type: 'changeVal', key: c.key, value: defaultValues })
          }
        } else if (c.type === 'NumberSelectionFilter') {
          if (c.defaultValue) {
            var dv: KeyValPair[] = c.valueList?.filter((x) => c.defaultValue?.includes(x.key)) || []
            props.dispatch({ type: 'changeVal', key: c.key, value: dv })
          }
        } else if (c.type === 'StringFilter') {
          if (c.defaultValue) {
            props.dispatch({ type: 'changeStrVal', key: c.key, value: c.defaultValue })
          }
        } else if (c.type === 'BooleanSelectionFilter') {
          if (c.defaultValue) {
            props.dispatch({ type: 'changeVal', key: c.key, value: c.defaultValue })
          }
        } else if (c.type === 'NumberFilter') {
          if (c.defaultOperator) {
            props.dispatch({ type: 'changeOperator', key: c.key, operator: c.defaultOperator })
          }
          if (c.defaultMin && c.defaultMax) {
            props.dispatch({
              type: 'changeVal',
              key: c.key,
              value: { max: c.defaultMax, min: c.defaultMin },
            })
          } else if (c.defaultMin) {
            props.dispatch({ type: 'changeVal', key: c.key, value: { min: c.defaultMin } })
          } else if (c.defaultMax) {
            props.dispatch({ type: 'changeVal', key: c.key, value: { max: c.defaultMax } })
          }
        } else if (c.type === 'DateFilter') {
          if (c.defaultOperator) {
            props.dispatch({ type: 'changeOperator', key: c.key, operator: c.defaultOperator })
          }
          if (c.defaultFrom && c.defaultTo) {
            props.dispatch({
              type: 'changeVal',
              key: c.key,
              value: { max: c.defaultTo, min: c.defaultFrom },
            })
          } else if (c.defaultFrom) {
            props.dispatch({ type: 'changeVal', key: c.key, value: { min: c.defaultFrom } })
          } else if (c.defaultTo) {
            props.dispatch({ type: 'changeVal', key: c.key, value: { max: c.defaultTo } })
          }
        }
      })
      props.dispatch({ type: 'setCanReload' })
    }
  }, [searchContext.state.isInitialized])

  useEffect(() => {
    if (searchContext.state.canReload && props.onInitReload && props.mountedRef.current)
      props.onInitReload()
  }, [searchContext.state.canReload])

  const [open, setOpen] = useState<boolean[]>(cri.map((x) => (matchUpMd ? true : false)))

  const handleOpen = (index: number) => {
    const _open = [...open]
    _open[index] = !_open[index]
    setOpen(_open)
  }

  const filterRow = (c: SearchCriteriaList) => {
    if (c.type === 'StringSelectionFilter') {
      return StringSelectionFilter(c as StringSelectionFilterProps, matchUpMd)
    } else if (c.type === 'NumberSelectionFilter') {
      return NumberSelectionFilter(c as NumberSelectionFilterProps, matchUpMd)
    } else if (c.type === 'BooleanSelectionFilter') {
      return BooleanSelectionFilter(c as BooleanSelectionFilterProps, matchUpMd)
    } else if (c.type === 'StringFilter') {
      return StringFilter(c as StringFilterProps, matchUpMd)
    } else if (c.type === 'NumberFilter') {
      return NumberFilter(c as NumberFilterProps, matchUpMd)
    } else if (c.type === 'DateFilter') {
      return DateFilter(c as DateFilterProps, matchUpMd)
    }
  }

  const SearchDrawerHeaderBox = styled(Box)(({ theme }) => ({
    fontWeight: 'bold',
    paddingTop: '10px',
    paddingBottom: '10px',
    fontSize: '36px',
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '40px',
    paddingRight: '20px',
    position: 'fixed',
    width: matchUpMd ? '800px' : '100%',
    backgroundColor: 'white',
    zIndex: 100,
  }))

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} key={'RootSearchPanel'}>
      <Box
        key={'searchPanel'}
        sx={{
          width: '100%',
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row-reverse',
            padding: '5px 10px 5px 10px',
            // backgroundColor: theme.palette.primary.dark,
            // color: theme.palette.primary.contrastText,
            borderTopLeftRadius: 'inherit',
            borderTopRightRadius: 'inherit',
          }}
          key={'mainSearchPanel'}>
          <Tooltip title={t('Filter')}>
            <IconButton
              sx={{
                backgroundColor: state.headerColor,
                color: 'white',
                borderRadius: '10%',
                marginLeft: '10px',
                '&:hover': {
                  backgroundColor: state.headerColor,
                },
              }}
              onClick={toggleFilter}>
              <FilterAltIcon />
            </IconButton>
          </Tooltip>
          {props.extraButtons && props.extraButtons}
          {/* <SearchBar /> */}
        </Box>

        <Drawer anchor={'right'} open={isOpen} onClose={() => toggleFilter()}>
          <SearchPanelContext.Consumer>
            {({ state, dispatch }) => (
              <SearchDrawerHeaderBox boxShadow="2">
                {t('Filter')}
                <Box
                  sx={{
                    display: 'flex',
                  }}>
                  <IconItem>
                    <IconButton
                      onClick={() => {
                        dispatch({ type: 'removeAll' })
                      }}>
                      <RestartAlt />
                    </IconButton>
                  </IconItem>
                  <IconItem>
                    <IconButton
                      onClick={() => {
                        if (props.onSearch) {
                          props.onSearch(state, true)
                          setIsOpen(false)
                        }
                      }}>
                      <Close />
                    </IconButton>
                  </IconItem>
                </Box>
              </SearchDrawerHeaderBox>
            )}
          </SearchPanelContext.Consumer>
          <Grid
            sx={{
              width: matchUpMd ? '800px' : '100%',
              minWidth: matchUpMd ? '800px' : '100%',
              paddingX: 5,
              position: 'relative',
              height: '100%',
              paddingTop: '85px',
              marginBottom: '85px',
              overflowY: 'auto',
            }}
            key={'searchPanelGrid'}>
            {cri &&
              cri.map((c, cIdx) => {
                return (
                  <Box key={`spg_${cIdx}`}>
                    <List>
                      <List sx={{ padding: '0px' }}>
                        <Fragment>
                          <ListItemButton
                            onClick={(_: any) => {
                              handleOpen(cIdx)
                            }}
                            sx={{
                              padding: '4px',
                              border: '0px solid',
                            }}>
                            <ListItemIcon>
                              {open[cIdx] ? <ArrowDropDown /> : <ArrowDropUp />}
                            </ListItemIcon>
                            <ListItemText primary={c.desc} />
                          </ListItemButton>
                          <Collapse in={open[cIdx]}>
                            <Fragment>{filterRow(c)}</Fragment>
                          </Collapse>
                        </Fragment>
                      </List>
                    </List>
                  </Box>
                )
              })}
          </Grid>
          <SearchPanelContext.Consumer>
            {({ state, dispatch }) => (
              <Box
                sx={{
                  flexDirection: 'row-reverse',
                  zIndex: 100,
                  backgroundColor: 'white',
                  padding: '16px',
                  position: 'fixed',
                  bottom: 0,
                  width: matchUpMd ? '800px' : '100%',
                  display: 'flex',
                  height: '75px',
                }}>
                <Button
                  sx={{
                    backgroundColor: 'dodgerblue',
                    color: 'white',
                    width: matchUpMd ? '800px' : '100%',
                    ':hover': {
                      backgroundColor: 'dodgerblue',
                    },
                  }}
                  onClick={() => {
                    if (props.onSearch) {
                      props.onSearch(state, true)
                      setIsOpen(false)
                    }
                  }}>
                  <Search sx={{ margin: '4px' }} />
                  Search
                </Button>
              </Box>
            )}
          </SearchPanelContext.Consumer>
        </Drawer>
      </Box>
    </LocalizationProvider>
  )
}

export default SearchPanel
