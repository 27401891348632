import React from 'react'
import { List, ListItem, ListItemText, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export interface ListFormIntroductionFieldProps {
    fields: formIntroductionFieldProps[]
    sx?: object | Array<object>
}

export interface formIntroductionFieldProps {
    title: string
    titleTextDeco?: string
    content?: string
    sx?: object | Array<object>
}

function generateFields(fields: formIntroductionFieldProps[], listSx?: object | Array<object>) {
    return fields.map((field, id) => {
        return (
            <ListItem sx={listSx} key={id}>
                <ListItemText
                    disableTypography
                    primary={
                        <Typography sx={field.sx} style={{ textDecoration: field.titleTextDeco }}>
                            {field.title}
                        </Typography>
                    }
                    secondary={
                        field.content ? (
                            <Typography sx={field.sx}>{field.content}</Typography>
                        ) : null
                    }
                />
            </ListItem>
        )
    })
}

const FormIntroductionFields = (props: ListFormIntroductionFieldProps) => {
    return <List dense={true}>{generateFields(props.fields, props.sx)}</List>
}
export default FormIntroductionFields
