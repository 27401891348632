import { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { useForm, useWatch }                                 from 'react-hook-form'
import { useNavigate, useParams }                            from 'react-router-dom'
import { useTranslation }                                    from 'react-i18next'
import * as yup                                              from 'yup'
import { find, get }                                         from 'lodash'
import { useSnackbar }                                       from 'notistack'
import { GlobalContext }                                     from '@providers/globalStore'
import { FormContext }                                       from '@providers/formStateProvider'
import { FormPartComponents }                                from '@models/common'
import FormService                                           from '@services/form.service'
import { FormEIAModel, InitEIAForm, EIAGeneralOptions }      from '@services/model/form/form.EIA.model'
import FormEIAService                                        from '@services/formService/form.EIA.service'
import { FormStatusEnum }                                    from '@services/model/form/form.model'
import AdminService                                          from '@services/admin.service'
import { DevTool }                                           from '@hookform/devtools'
import { yupResolver }                                       from '@hookform/resolvers/yup'
import useQuery                                              from '@hooks/useQuery'
import useFormSwitcher                                       from '@hooks/useFormSwitcher'
import useAPIFetch                                           from '@hooks/useAPIFetch'
import useLocalStorage                                       from '@hooks/useLocalStorage'
import ValidationToast                                       from '@components/form/ValidationToast'
import { PartA }                                             from './part-a'
import PartB                                                 from './part-b'
import { QueryStepParser }                                   from '@utils/queryStepParser'
import { NavigateTo }                                        from '@utils/navigate'
import getFormValidationSchema                               from '@utils/formValidationSchema'

export default function EIAForm() {
  const { state: globalState, userInfo, dispatch: globalAction } = useContext(GlobalContext)
  const { state: formState, dispatch: formStateAction } = useContext(FormContext)
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const [storageContractId, setStorageContractId] = useLocalStorage<string | undefined>(
    'contract',
    undefined,
  )

  // extracting id from params
  let { id: formId } = useParams<string>()

  // extracting step from query
  const query = useQuery()
  const step = QueryStepParser(query.get('step'))

  const validationSchemaConditions = [
    {
      formStatusList: [FormStatusEnum.FORM_EIA_DRAFT],
      validationSchema: yup.object().shape({
        baseForm: yup.object().shape({
          contractNoId: yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .moreThan(0, t('Contract No. is required'))
            .required(t('Contract No. is required')),
          teamId: yup.array().required().min(1),
          districtId: yup.array().required().min(1),
        }),
      }),
    },
    {
      formStatusList: [FormStatusEnum.FORM_EIA_ASSIGNED],
      validationSchema: yup.object(),
    },
  ]

  const { control, watch, setValue, getValues, reset, trigger } = useForm<FormEIAModel>({
    defaultValues: { ...InitEIAForm },
    resolver: (data, context, options) => {
      const validatorSchema = getFormValidationSchema(
        data.baseForm.formStatus,
        validationSchemaConditions,
      )
      return yupResolver(validatorSchema)(data, context, options)
    },
    mode: 'all',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
  })

  const [contractNoId, formStatus] = watch(['baseForm.contractNoId', 'baseForm.formStatus'])

  const formPermission = getValues('baseForm.formPermission')
  const [eiaGeneralOptions, setEiaGeneralOptions] = useState<EIAGeneralOptions>(
    globalState.formOptionsList?.find(({ key }) => key === 'EIA')?.value ?? {},
  )

  const isMounted = useRef(false)

  const { setRequest, isLoading } = useAPIFetch()
  const { setRequest: setOptionRequest, isLoading: isLoadingOption } = useAPIFetch()

  useEffect(() => {
    if (!isMounted.current) {
      if (formState.form && formState.formType === 'EIA') {
        formStateAction({ type: 'clear' })
        reset({ ...formState.form })
        console.debug('case 1')
      } else if (formId) {
        reload()
        console.debug('case 2')
      } else {
        setValue('baseForm.contractNoId', storageContractId ? parseInt(storageContractId) : 0)
        setValue('baseForm.teamId', userInfo.teams)
        setValue('baseForm.districtId', userInfo.districts)
        console.debug('case 3')
      }

      globalAction({
        type: 'changeFormStatus',
        formStatus: getValues('baseForm.formStatusName'),
      })
      isMounted.current = true
    }

    return () => {
      globalAction({
        type: 'changeFormStatus',
        formStatus: undefined,
      })
    }
  }, [formId, formStateAction, globalAction])

  const reloadEIAGeneralOptions = async (teams: Array<number>, districts: Array<number>) => {
    if (teams !== undefined && districts !== undefined) {
      setOptionRequest({
                         callback: async () => {
                           await FormEIAService.GetEIAOptions(teams, districts)
                                               .then(async list => {
                                                 if (list)
                                                   setEiaGeneralOptions(list)
                                               })
                         }
                       })
    }
  }

  const [teamId, districtId] = watch(['baseForm.teamId', 'baseForm.districtId'])

  useEffect(() => {
    if (teamId !== null && districtId !== null)
      reloadEIAGeneralOptions(teamId, districtId)
  }, [teamId, districtId])

  const reload = async () => {
    setRequest({
      callback: async () => {
        if (formId) {
          await FormEIAService.GetEIAForm(formId)
            .then(async (f) => {
              if (f) {
                if (f.assignedTo) {
                  const assignedToWithEffectiveDuty = await AdminService.GetDutyFormByUUId(
                    f.assignedTo,
                  )
                  if (assignedToWithEffectiveDuty.id !== 0) {
                    f.assignedToWithEffectiveDuty = assignedToWithEffectiveDuty.nameOfPredecessor
                  }
                }

                if (
                  f.baseForm.contractNoId &&
                  f.baseForm.contractNoId !== globalState.contractNoId
                ) {
                  globalAction({
                    type: 'selectContract',
                    contractNoId: f.baseForm.contractNoId,
                  })

                  globalAction({
                    type: 'selectWorkGroup',
                    workGroupId: get(
                      find(globalState.contractList, { id: f.baseForm.contractNoId }),
                      'workGroups.0.id',
                      0,
                    ),
                  })
                }

                reset(f)
              }
              globalAction({
                type: 'changeFormStatus',
                formStatus: f.baseForm.formStatusName,
              })
            })
            .catch((err) => {
              enqueueSnackbar(err.response.data.message, {
                variant: 'error',
                autoHideDuration: null,
              })
              NavigateTo(navigate, '/engineer-inspection-assignment/all-record')
            })
        }
      },
    })
  }

  const handleOnSubmit = async (
    event: any,
    isReject: boolean,
    notifyList?: string[],
    signatureBase64?: string,
    submissionComment?: string,
  ) => {
    if (!(await trigger())) return
    setValue('signatureBase64', signatureBase64)
    setValue('notifyUserList', notifyList)
    setValue('submissionComment', submissionComment)
    const ff = getValues()

    if (isReject) {
      setRequest({
        callback: async () => {
          await FormEIAService.RejectEIAForm(ff).then(() => {
            enqueueSnackbar(t('Record Submitted'), { variant: 'success' })
            NavigateTo(navigate, '/engineer-inspection-assignment/all-record')
          })
        },
      })
    } else {
      setRequest({
        callback: async () => {
          await FormEIAService.ApproveEIAForm(ff).then(() => {
            enqueueSnackbar(t('Record Submitted'), { variant: 'success' })
            NavigateTo(navigate, '/engineer-inspection-assignment/all-record')
          })
        },
      })
    }
  }

  const handleOnSave = async () => {
    // TODO: on save
    setRequest({
      callback: async () => {
        await FormEIAService.SaveEIAForm(getValues()).then((resp) => {
          setValue('baseForm.formId', resp.formId)
          setValue('baseForm.formStatus', resp.formStatus)
          NavigateTo(navigate, '/engineer-inspection-assignment/:id', {
            id: resp.formId,
          })
          formId = resp.formId
          reload()
          enqueueSnackbar(t('Record Saved'), { variant: 'success' })
        })
      },
    })
  }

  const handleOnDelete = async (
    event: any,
    notifyUserList?: string[] | undefined,
    signatureBase64?: string | undefined,
    submissionComment?: string | undefined,
  ) => {
    setValue('signatureBase64', signatureBase64)
    setValue('notifyUserList', notifyUserList)
    setValue('submissionComment', submissionComment)
    const ff = getValues()

    setRequest({
      callback: async () => {
        await FormEIAService.ArchiveEIAForm(ff).then(() => {
          enqueueSnackbar(t('Record Archived'), { variant: 'success' })
          NavigateTo(navigate, '/engineer-inspection-assignment/all-record')
        })
      },
    })
  }

  const EIPartA = (): FormPartComponents => {
    return {
      partTitle: t('General information'),
      component: (
        <PartA
          control={control}
          formStatus={formStatus}
          userInfo={userInfo}
          useWatch={useWatch}
          options={eiaGeneralOptions}
          globalState={globalState}
          setValue={setValue}
          isMounted={isMounted}
          watch={watch}
          getValues={getValues}
        />
      ),
    }
  }

  const EIPartB = (): FormPartComponents => {
    return {
      partTitle: 'Submission Confirmation',
      component: (
        <PartB
          control={control}
          getValues={getValues}
          globalState={globalState}
          formPermission={formPermission}
          handleOnComplete={() => handleOnComplete()}
        />
      ), //TODO: Endofflow, userMetaList
    }
  }

  const onLoadNotifyList = async (isReject: boolean) => {
    const defaultNotifyList = await FormService.GetNotifyList(
      'EIA',
      getValues('baseForm.formId'),
      isReject,
      getValues('baseForm.districtId'),
      getValues('baseForm.teamId'),
      [
        {
          key: 'at',
          value: getValues('assignedTo') || '',
        },
      ],
    )
    return defaultNotifyList
  }

  const onLoadHistoryList = async () => {
    var defaultNotifyList = await FormService.GetHistoryList(getValues('baseForm.formId'))
    return defaultNotifyList
  }

  const [FormSwitcher, handleOnComplete] = useFormSwitcher({
    title: t('ENGINEER INSPECTION ASSIGNMENT'),
    components: [EIPartA(), EIPartB()],
    formOnLoadNotifyList: onLoadNotifyList,
    formOnLoadCommentList: async () => {
      return await FormService.GetCommentList(getValues('baseForm.formId'))
    },
    formOnSubmitComment: async (comment) => {
      await FormService.SubmitComment(getValues('baseForm.formId'), comment)
    },
    formOnLoadFormHistoryList: onLoadHistoryList,
    formOnSubmit: handleOnSubmit,
    formOnDelete: handleOnDelete,
    formOnSave: handleOnSave,
    startStep: step,
    isLoading: isLoading || isLoadingOption,
    disableSave: !formPermission?.canUpdate, // can edit this form
    disableDelete: !formPermission?.canDelete, // can delete this form
    disableComment: getValues('baseForm.formId') ? false : true,
    approveRequired: formPermission?.workflowRequired, // can go to next stage
    endOfFlow: formPermission?.endOfFlow,
    isMounted: isMounted.current,
    deleteRequireWorkflow: true,
  })

  return (
    <Fragment>
      <FormSwitcher />
      <DevTool control={control} />
      <ValidationToast control={control} />
    </Fragment>
  )
}
