import { useEffect, useState }                                          from 'react'
import { Control, UseFormGetValues, UseFormSetValue, useWatch }         from 'react-hook-form'
import { useTranslation }                                               from 'react-i18next'
import uuid                                                             from 'react-uuid'
import { Box, Grid, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material'
import { SelectChangeEvent }                                            from '@mui/material/Select'
import { styled }                                                       from '@mui/material/styles'
import { FormStatusEnum }                                               from '@services/model/form/form.model'
import { EaCategory, FormEaModel }                                      from '@services/model/form/form.EA.model'
import { ContractNo }                                                   from '@services/model/contract.model'
import FormContainer                                                    from '@components/form/container'
import FormTable                                                        from '@components/form/table'
import FormField                                                        from '@components/form/field'
import FormController                                                   from '@components/form/controller'
import FormTypography                                                   from '@components/form/typography'
import FormBinaryRadio                                                  from '@components/form/binaryRadio'
import useGeneralOptions                                                from '@hooks/useGeneralOptions_to_be_used'

const UUID = uuid()

interface EaDefectiveLaneSummary {
  totalEffectiveLaneSection: number
  totalDefectsIdentified: number
  totalDefectiveLaneSection: number
}

const FormTableBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    margin: '16px 30px',
  },
  [theme.breakpoints.down('md')]: {
    margin: '16px 0px',
  },
}))

const BodyTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: 12,
  padding: theme.spacing(1),
}))

export default ({
  control,
  formStatus,
  getValues,
  setValue,
  eaCategoryList,
  contractInfo,
}: {
  control: Control<FormEaModel>
  formStatus: string
  getValues: UseFormGetValues<FormEaModel>
  setValue: UseFormSetValue<FormEaModel>
  eaCategoryList?: EaCategory[]
  contractInfo?: ContractNo
}) => {
  const { t } = useTranslation()
  const { getOptionsByKey } = useGeneralOptions()

  const mmWorksValue = getOptionsByKey('Form EA - Category')?.find(x => x.key === getValues('formNea.typeOfMmWorks'))?.value ?? ''

  const mmWorksIsVetgetation = mmWorksValue === 'Maintain Vegetation on Vegetated Highway Registered Slopes and Designated Planted Areas'

  const [defectiveLaneSummary, setDefectiveLaneSummary] = useState<EaDefectiveLaneSummary>()

  const [viableToConductInspection, defectiveList] = useWatch({ control, name: ['viableToConductInspection', 'defectiveList'] })

  let showInspectionSection = false
  if (contractInfo?.highSpeedRoad &&
      (getValues('formNea.typeOfMmWorksValue') === 'Maintenance of Road Marking, Road Drainage System and Detailed Inspections' ||
       getValues('formNea.typeOfMmWorksValue') === 'Maintenance of Road Marking, Road Drainage System, Detailed Inspections in Hygienic Conditions'))
    showInspectionSection = true

  const _calculateDefectiveLaneSummary = () => {
    if (defectiveList) {
      const defectiveLaneSummary = {
        totalEffectiveLaneSection: defectiveList.reduce((result, defect) => (defect.available === 'Y' ? ++result : result), 0),
        totalDefectsIdentified: defectiveList.reduce((result, defect) => defect.noOfDefectIdentified > 0 ? result + defect.noOfDefectIdentified : result, 0),
        totalDefectiveLaneSection: defectiveList.reduce((result, defect) => (defect.defective === 'Y' ? ++result : result), 0)
      }

      setDefectiveLaneSummary(defectiveLaneSummary)
    }
  }

  const _handleOnChangeAvailable = (event: SelectChangeEvent) => {
    const [root, index, field] = event.target.name.split('.') // e.g. defectiveList.0.available

    if (defectiveList) {
      const availability = event.target.value // Y or N
      const defect       = defectiveList[index]

      if (availability === 'N')
        defect.defective = 'N/A'
      else // available === 'Y'
        defect.defective = defect.noOfDefectIdentified > 0 ? 'Y' : 'N'

      defect.available     = availability
      defectiveList[index] = defect
      setValue('defectiveList', defectiveList)
      _calculateDefectiveLaneSummary()
    }
  }

  useEffect(() => {
    _calculateDefectiveLaneSummary()
  }, [defectiveList])

  const DEFECTIVE_LANE_SUMMARY_KEY_MAPPING = {
    totalEffectiveLaneSection: 'Total Effective Lane Section',
    totalDefectsIdentified   : 'Total Defects Identified',
    totalDefectiveLaneSection: 'Total Defective Lane Section',
  }

  return (
    <>
      <FormContainer fkey={`${UUID}-ea-d`}>
        <FormField fieldName="Type of M&M Works">
          <FormController
            controllerProps={{
              name: 'formNea.typeOfMmWorks',
              control,
            }}>
            <FormTypography type="select" options={getOptionsByKey('Form EA - Category')} />
          </FormController>
        </FormField>
        <FormField fieldName="Definition of Non-compliance" hidden={mmWorksIsVetgetation}>
          <FormTypography value={ eaCategoryList?.find(x => x.typeOfMmWorks === getValues('formNea.typeOfMmWorks'))?.defectDefinition } />
        </FormField>

        <FormField fieldName='Total No. of Defect(s) Identified'>
          <FormTypography value={
              getValues('formDeaList')?.filter(
                  formDea =>
                      formDea.baseForm.formStatus !== FormStatusEnum.FORM_DEA_APPEAL_SUCCESS &&
                      formDea.markApproval !== true
              )?.length || 0
          } />
        </FormField>

        {
            viableToConductInspection === true &&
            !mmWorksIsVetgetation &&
            !showInspectionSection &&
            <FormField fieldName='Defective'>
              <FormController controllerProps={ { control, name: 'defective' } }>
                <FormBinaryRadio disabled={
                    formStatus === FormStatusEnum.FORM_EA_AGREE_PRELIM_RESULT ||
                    formStatus === FormStatusEnum.FORM_EA_REVIEW_PRELIM_RESULT ||
                    formStatus === FormStatusEnum.FORM_EA_ENDORSE_PRELIM_RESULT ||
                    formStatus === FormStatusEnum.FORM_EA_AUDIT_RESULT_ISSUED ||
                    formStatus === FormStatusEnum.FORM_EA_ENDORSE_AUDIT_APPEAL ||
                    formStatus === FormStatusEnum.FORM_EA_AUDIT_RESULT_UPDATED ||
                    formStatus === FormStatusEnum.FORM_EA_AUDIT_UNAVAILABLE
                }
                                 binaryOptionNames={ {
                                   yes: 'Yes',
                                   no : 'No',
                                   na : getOptionsByKey('Form EA - Category')?.find(kvp =>
                                                                                        kvp.key === getValues('formNea.typeOfMmWorks') &&
                                                                                        kvp.value === 'Maintain Vegetation on Vegetated Highway Registered Slopes and Designated Planted Areas')
                                        ? 'N/A'
                                        : undefined
                                 } } />
              </FormController>
            </FormField>
        }

        {
            showInspectionSection &&
            <>
              <FormTable hiddenItemNo
                         header={ t('Performance Standard of Engineer Audit') }
                         removeAddButton
                         height={ 50 }
                         fieldArrayProps={ { control: control, name: 'defectiveList' } }
                         columns={ [
                           {
                             field      : 'sectionNo',
                             displayName: 'Section No.',
                             type       : 'string',
                             uneditable : true
                           },
                           {
                             field      : 'available',
                             displayName: 'Available',
                             type       : 'select',
                             onChange   : _handleOnChangeAvailable,
                             options    : ['Y', 'N'].map(k => ({ key: k, value: k })),
                             uneditable : (row, index) => {
                               if (formStatus === FormStatusEnum.FORM_EA_DRAFT || formStatus === FormStatusEnum.FORM_EA_PRELIM_RESULT_REJECTED)
                                 return row['noOfDefectIdentified'] > 0
                               else
                                 return true
                             }
                           },
                           {
                             field      : 'noOfDefectIdentified',
                             displayName: 'No. of Defect Identified',
                             type       : 'string',
                             uneditable : true
                           },
                           {
                             field      : 'defective',
                             displayName: 'Defective',
                             type       : 'select',
                             options    : ['Y', 'N'].map(k => ({ key: k, value: k })),
                             uneditable : formStatus !== FormStatusEnum.FORM_EA_DRAFT && formStatus !== FormStatusEnum.FORM_EA_PRELIM_RESULT_REJECTED
                           }
                         ] } />

              {
                  defectiveLaneSummary &&
                  <Grid xs={ 12 }>
                    <FormTableBox>
                      <Typography>{ t('Summary') }</Typography>
                      <Table size='small'>
                        <TableBody>
                          {
                            Object.keys(defectiveLaneSummary).map(key =>
                                                                      <TableRow key={ key } sx={ { backgroundColor: '#edeef0' } }>
                                                                        <BodyTableCell
                                                                            style={ {
                                                                              width   : '50%',
                                                                              position: 'relative',
                                                                              display : 'table-cell'
                                                                            } }>
                                                                          <Box textAlign='center'>{ DEFECTIVE_LANE_SUMMARY_KEY_MAPPING[key] }</Box>
                                                                        </BodyTableCell>
                                                                        <BodyTableCell
                                                                            style={ {
                                                                              width   : '50%',
                                                                              position: 'relative',
                                                                              display : 'table-cell'
                                                                            } }>
                                                                          <Box textAlign='center'>{ defectiveLaneSummary[key] }</Box>
                                                                        </BodyTableCell>
                                                                      </TableRow>)
                          }
                        </TableBody>
                      </Table>
                    </FormTableBox>
                  </Grid>
              }
            </>
        }
      </FormContainer>
    </>
  )
}
