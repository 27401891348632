import FormContainer from '@components/form/container'
import FormSignatureHistories from '@components/form/signatureHistories'
import { GlobalStateProps } from '@providers/globalStore'
import { FormDNAModel } from '@services/model/form/form.DNA.model'
import { Control, useWatch } from 'react-hook-form'

export default ({
  globalState,
  control,
  handleOnComplete,
}: {
  globalState: GlobalStateProps
  control: Control<FormDNAModel>
  handleOnComplete: () => void
}) => {
  const [histories, formPermission] = useWatch({
    name: ['baseForm.formStatusHistories', 'baseForm.formPermission'],
    control,
  })

  return (
    <FormContainer fkey="dna-b">
      <FormSignatureHistories
        formStatusList={globalState.formStatusList}
        histories={histories}
        control={control}
        handleOnComplete={handleOnComplete}
        endOfFlow={formPermission?.endOfFlow}
        userMetaList={globalState.userMetaList}
        disabled={!formPermission?.canUpdate}
      />
    </FormContainer>
  )
}
