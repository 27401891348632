import { useContext }    from 'react'
import { GlobalContext } from '@providers/globalStore'
import { KeyValPair }    from '@models/common'

function useGeneralOptions(): { getOptionsByKey: (key: string, activeOnly?: boolean) => KeyValPair[] } {
	const { state } = useContext(GlobalContext)

	const getOptionsByKey = (key: string, activeOnly: boolean = true) => {
		if (state.generalOptionGroupList) {
			let generalOptions = state.generalOptionGroupList
			                          .find(option => option.name.toLowerCase() === key.toLowerCase())?.generalOptions

			if (activeOnly)
				generalOptions = generalOptions?.filter(option => option.status)

			return generalOptions?.map(option => ({
				key     : option.id,
				value   : option.name,
				status  : option.status,
				parentId: option.parentId
			})) || []
		}
		return []
	}

	return { getOptionsByKey }
}

export default useGeneralOptions