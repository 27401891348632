import FormContainer from '@components/form/container'
import { FormStatusEnum } from '@services/model/form/form.model'
import { FormEaModel } from '@services/model/form/form.EA.model'
import { t } from 'i18next'
import { Control, Path } from 'react-hook-form'
import uuid from 'react-uuid'
import FormLinkTable from '@components/form/linkTable'
import FormField from '@components/form/field'
import { Typography } from '@mui/material'
import FormImageUpload from '@components/form/imageUpload'
import FormController from '@components/form/controller'
import FormFreeText from '@components/form/freeText'
import { useTranslation } from 'react-i18next'

const UUID = uuid()

export default ({
  control,
  formStatus,
}: {
  control: Control<FormEaModel>
  formStatus: string
}) => {
  const { t } = useTranslation()
  return (
    <>
      <FormContainer fkey={`${UUID}-ea-c`}>
          <FormField fieldName="Reminder">
            <Typography>
              {t(
                'Please upload photo of area without non-conformity or fill in other information if audit is unavailable',
              )}
            </Typography>
          </FormField>
          <FormField fieldName="Record Photo">
            <FormController
              controllerProps={{
                name: 'eaImages',
                control,
              }}>
              <FormImageUpload multiple disabled={formStatus !== FormStatusEnum.FORM_EA_DRAFT && formStatus !== FormStatusEnum.FORM_EA_PRELIM_RESULT_REJECTED}/>
            </FormController>
          </FormField>
          <FormField fieldName="Other Information">
            <FormController
              controllerProps={{
                name: 'otherInfo',
                control,
              }}>
              <FormFreeText
                textfieldProps={{
                  fullWidth: false,
                  multiline: true,
                  rows: 10,
                }}
                sx={{
                  width: '70%',
                }}
                disabled={formStatus !== FormStatusEnum.FORM_EA_DRAFT && formStatus !== FormStatusEnum.FORM_EA_PRELIM_RESULT_REJECTED}
              />
            </FormController>
          </FormField>
        </FormContainer>
    </>
  )
}
