import FormContainer from '@components/form/container'
import FormController from '@components/form/controller'
import { useNavigate, useParams } from 'react-router-dom'
import { FormSDModel, InitSDForm } from '@services/model/form/form.SD.model'
import { ArrayPath, Control, FieldArray, useFieldArray, UseFieldArrayReturn } from 'react-hook-form'
import { NavigateTo } from '@utils/navigate'
import FormLargeMap from '@components/form/largeMap'
import FormLinkTable from '@components/form/linkTable'
import uuid from 'react-uuid'
import useGeneralOptions from '@hooks/useGeneralOptions_to_be_used'

export default ({
    control,
    dispatch,
    getValues,
    id,
    readOnly,
    options,
    LargeMap
}:{
    control: Control<FormSDModel>
    dispatch: any,
    getValues: any
    id?: string
    readOnly: boolean,
    options: any
    LargeMap: () => JSX.Element
}) => {
  const UUID = uuid()
  let index = 0

  const navigate = useNavigate()
  const { getOptionsByKey } = useGeneralOptions()

  const handleLinkClick = (index: number) => {
    dispatch({ type: 'push', form: getValues(), formType: 'SD' })
    NavigateTo(navigate, `/site-diary/:id/photo`, { id }, { num: index })
  }

  const handleAddPhotoClick = (_: any) => {
    dispatch({ type: 'push', form: getValues(), formType: 'SD' })
    NavigateTo(navigate, `/site-diary/:id/photo`, { id })
  }

    return (
        <FormContainer fkey={`${UUID}-sd-e`}>
          <LargeMap />
          <FormLinkTable
            columns={[
              { field: 'images', name: 'Images', type: 'images' },
              {
                field: 'photoCategoryId',
                name: 'Category',
                type: 'selected',
                options: getOptionsByKey('Form SD - Site Photo Category')
              },
              { field: 'location.locationAddress', name: 'Address', type: 'string' },
            ]}
            fieldArrayProps={{
              control: control,
              name: 'sdPhotos',
            }}
            title={" "}
            onLinkClick={handleLinkClick}
            appendField={handleAddPhotoClick}
            uneditable = {readOnly}
            addButtonText={"Add Site Photo"}
          />
        </FormContainer>
    )
  }
