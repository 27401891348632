import FormContainer from '@components/form/container'
import FormController from '@components/form/controller'
import { Control } from 'react-hook-form'
import uuid from 'react-uuid'
import { FormStatusEnum, RightsCategory } from '@services/model/form/form.model'
import FormTypography from '@components/form/typography'
import { FormEIModel } from '@services/model/form/form.EI.model'
import { NavigateTo } from '@utils/navigate'
import { useNavigate } from 'react-router-dom'
import FormLargeMap from '@components/form/largeMap'
import { Fragment, useContext } from 'react'
import { GlobalContext } from '@providers/globalStore'
import FormLinkTable from '@components/form/linkTable'
import useGeneralOptions from '@hooks/useGeneralOptions_to_be_used'
import { FormNFModel } from '@services/model/form/form.NF.model'
import { t } from 'i18next'
import { useSnackbar } from 'notistack'

export default ({
  control,
  formStatus,
  formStateAction,
  getValues,
  formId,
  options,
}: {
  control: Control<FormEIModel>
  formStatus: string
  formStateAction: any
  getValues: any
  formId: any
  options: any
}) => {
  const UUID = uuid()
  const navigate = useNavigate()
  const { hasRightByCatAndCode } = useContext(GlobalContext)
  const { getOptionsByKey } = useGeneralOptions()
  const { enqueueSnackbar } = useSnackbar()

  const handleLinkClick = (index: number) => {
    formStateAction({ type: 'push', form: getValues(), formType: 'EI' })
    NavigateTo(navigate, '/notification-form-of-defect/:formId', { formId }, { num: index })
  }

  const handleAddPhotoClick = () => {
    if (getValues('baseForm.teamId')?.length > 0 && getValues('baseForm.districtId')?.length > 0) {
      formStateAction({ type: 'push', form: getValues(), formType: 'EI' })
      NavigateTo(navigate, '/notification-form-of-defect/:formId', { formId })
    } else {
      enqueueSnackbar('Team and district are required to create defect identified. ', { variant: 'error',autoHideDuration: null,})
    }
  }

  console.log('options', options)

  const uneditable =
    !(
      formStatus === FormStatusEnum.FORM_EI_DRAFT || formStatus === FormStatusEnum.FORM_EI_REJECTED
    ) || !hasRightByCatAndCode(RightsCategory.FORM_NF, ['C'], true)
  return (
    <FormContainer fkey={`${UUID}-ei-b`}>
      {/* map */}
      <FormController
        controllerProps={{
          name: 'formNf',
          control: control,
        }}>
        <FormLargeMap
          onMarkerClick={(_, markerIndex: number) => {
            handleLinkClick(markerIndex)
          }}
        />
      </FormController>
      {/* NF table */}
      <FormLinkTable
        addButtonText="Create NF"
        columns={[
          { field: 'images', name: 'Images', type: 'images' },
          { field: 'nfNo', name: 'NF No.', type: 'string', mobileHidden: true },
          {
            field: 'scopeOfWorksId',
            name: 'Scope of Works',
            type: 'selected',
            options: getOptionsByKey('Form NF - Scope of Works'),
            mobileHidden: true,
          },
          {
            field: 'defectCategoryId',
            name: 'Category',
            type: 'selected',
            options:
              options?.defectCategory?.map((defect) => ({
                key: defect.id,
                value: defect.name,
              })) ?? [],
          },
          {
            field: '',
            name: 'Defects Identified by the Engineer',
            type: 'custom',
            render: (_row) => {
              const row = _row as FormNFModel
              return (
                <FormTypography
                  value={row.defectOptionId}
                  type="select"
                  options={
                    options?.defectCategory?.find((category) => row.defectCategoryId === category.id)
                      ?.defectOptions?.map((option) => ({
                        key: option.id,
                        value: option.defectCode + '. ' + option.description,
                      })) ?? []
                  }
                  sx={{
                    fontSize: 12,
                  }}
                />
              )
            },
          },
          {
            field: '',
            name: 'No. of DN',
            type: 'custom',
            render: (_row) => {
              const row = _row as FormNFModel
              return <Fragment>{row?.formDn?.length || 0}</Fragment>
            },
            mobileHidden: true,
          },
        ]}
        fieldArrayProps={{
          control: control,
          name: 'formNf',
        }}
        title={t('Defects Identified')}
        onLinkClick={handleLinkClick}
        appendField={handleAddPhotoClick}
        uneditable={uneditable}
      />
    </FormContainer>
  )
}
