import { Box, Grid } from '@mui/material'
import { Fragment, useContext, useReducer, useRef } from 'react'

import useTableView from '@hooks/useTableView'
import TableBox from '@components/table/HoverTableBox'
import SearchPanel from '@components/searchPanel'
import { IowWorkOrder } from '@services/model/contract.model'
import SearchPanelReducer, { SearchPanelState } from '@reducers/searchPanelReducer'
import ContractService from '@services/contract.service'
import { GlobalContext } from '@providers/globalStore'
import { InitialSearchPanelState, SearchPanelContext } from '@providers/SearchPanelProvider'

const ContractList = (props: { showMyRecord?: boolean }) => {
  const refMounted = useRef(true)
  const [state, dispatch] = useReducer<React.Reducer<SearchPanelState, any>>(
    SearchPanelReducer,
    InitialSearchPanelState,
  )

  const { state: globalState } = useContext(GlobalContext)

  const [TableView, reload] = useTableView<IowWorkOrder>({
    headers: [
      {
        key: 'iowEmail',
        desc: 'IOW Email',
        renderCell: (record: IowWorkOrder) => <Fragment>{record.iowEmail}</Fragment>,
        width: 100,
        disableSort: true,
      },
      {
        key: 'workOrderNo',
        desc: 'Work Order',
        renderCell: (record: IowWorkOrder) => <Fragment>{record.workOrderNo}</Fragment>,
        width: 100,
        disableSort: true,
      },
      {
        key: 'districtId',
        desc: 'District',
        renderCell: (record: IowWorkOrder) => (
          <Fragment>
            <TableBox columnValue={record.districtId} list={globalState.districtList} />
          </Fragment>
        ),
        width: 250,
        disableSort: true,
      },
      {
        key: 'teamId',
        desc: 'Team',
        renderCell: (record: IowWorkOrder) => (
          <Fragment>
            <TableBox columnValue={record.teamId} list={globalState.teamList} />
          </Fragment>
        ),
        width: 250,
        disableSort: true,
      },
    ],
    mountedRef: refMounted,
    reloadCallback: async (pagination: object, sorting: object | undefined, cancelToken: any) => {
      let resp = await ContractService.GetIowWorkOrderList({
        ...state.filterResult,
        sorting: sorting,
        pagination: pagination,
      })
      return resp
    },
  })

  return (
    <Grid component="main" container padding={1}>
      <SearchPanelContext.Provider value={{ state, dispatch }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            p: 1,
            m: 1,
            bgcolor: 'background.paper',
            borderRadius: 1,
            width: '100%',
          }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box>
              <SearchPanel
                dispatch={dispatch}
                addUrl="/booking/new"
                onSearch={reload}
                onInitReload={reload}
                mountedRef={refMounted}
                excludeStatus={true}
                criteria={[
                  {
                    key: 'iowEmail',
                    desc: 'IOW Email',
                    type: 'StringFilter',
                    defaultValue:
                      sessionStorage?.IowWO !== 'undefined' && sessionStorage?.IowWO !== undefined
                        ? JSON.parse(sessionStorage?.IowWO)?.iowEmail?.value
                        : '',
                  },
                  {
                    key: 'teamId',
                    desc: 'Team',
                    type: 'NumberSelectionFilter',
                    multiple: true,
                    valueList: globalState.teamList,
                    defaultValue:
                      sessionStorage?.IowWO !== 'undefined' && sessionStorage?.IowWO !== undefined
                        ? JSON.parse(sessionStorage?.IowWO)?.teamId?.value
                        : [],
                  },
                  {
                    key: 'districtId',
                    desc: 'District',
                    type: 'NumberSelectionFilter',
                    multiple: true,
                    valueList: globalState.districtList,
                    defaultValue:
                      sessionStorage?.IowWO !== 'undefined' && sessionStorage?.IowWO !== undefined
                        ? JSON.parse(sessionStorage?.IowWO)?.districtId?.value
                        : [],
                  },
                  {
                    key: 'workOrderId',
                    desc: 'Works Order No',
                    type: 'StringSelectionFilter',
                    multiple: true,
                    valueList: globalState.worksOrderList?.map(({ id, workOrderNo }) => ({
                      key: id,
                      value: workOrderNo,
                    })),
                    defaultValue:
                      sessionStorage?.IowWO !== 'undefined' && sessionStorage?.IowWO !== undefined
                        ? JSON.parse(sessionStorage?.IowWO)?.workOrderId?.value
                        : [],
                  },
                ]}
              />
            </Box>
          </Box>
        </Box>
        <TableView />
      </SearchPanelContext.Provider>
    </Grid>
  )
}

export default ContractList
