import { basicChartConfig, basicChartOption } from '../BasicChart'
import DoughnutChart from './DoughnutChart'
import PieChart from './PieChart'

export interface basicPieChartConfig extends basicChartConfig {
    xAxisID?: string
    yAxisID?: string
    spanGaps?: boolean | number //default false
    showLine?: boolean //default true
    fill?: boolean | boolean[]
}

interface basicPieChartDataset {
    name?: string
    data: (number | null)[]
    dataConfig?: basicChartConfig
}

export interface basicPieChartProps extends pieChartProps {
    type: string
}

export interface pieChartProps {
    options?: any //basicChartOption
    labels: string[]
    datasets: basicPieChartDataset[]
}

const BasicPieChart = (props: basicPieChartProps) => {
    switch (props.type) {
        default:
            return (
                <PieChart
                    labels={props.labels}
                    datasets={props.datasets}
                    options={props.options}
                ></PieChart>
            )
        case 'doughnut':
            return (
                <DoughnutChart
                    labels={props.labels}
                    datasets={props.datasets}
                    options={props.options}
                ></DoughnutChart>
            )
    }
}
export default BasicPieChart
