import { Typography } from '@mui/material'
import moment from 'moment'
import { CSSProperties, Fragment } from 'react'

interface formDateProps {
  value?: Date
  onChange?: (...event: any[]) => void
  visable?: boolean
  sx?: CSSProperties
  format?: string
}

const FormDate = (props: formDateProps) => {
  return (
    <Fragment>
      {props.visable === undefined || props.visable ? (
        <Typography>
          {props.value
            ? moment(new Date(props.value)).format(props.format ?? moment.defaultFormat)
            : ''}
        </Typography>
      ) : (
        <></>
      )}
    </Fragment>
  )
}

export default FormDate
