import FormContainer from '@components/form/container'
import FormController from '@components/form/controller'
import FormField from '@components/form/field'
import { Control } from 'react-hook-form'
import { FormWoiModel, WoiGeneralOptions } from '@services/model/form/form.WOI.model'
import FormTypography from '@components/form/typography'
import { useTranslation } from 'react-i18next'
import FormFreeText from '@components/form/freeText'
import FormMultipleSelect from '@components/form/multipleSelect'
import FormSelect from '@components/form/select'
import { GetDistrictOptions } from '@utils/districtOptions'
import { GetTeamOptions } from '@utils/teamOptions'
import { Fragment } from 'react'

export default ({
  control,
  getValues,
  options,
  globalState,
  userInfo,
}: {
  control: Control<FormWoiModel>
  getValues: any
  options?: WoiGeneralOptions
  globalState?: any
  userInfo
}) => {
  const { t } = useTranslation()
  const typeOfWorks = getValues('typeOfWorks')

  return (
    <FormContainer fkey={`woi-b`}>
      <FormField fieldName="Contract No.">
        <FormController
          controllerProps={{
            name: 'baseForm.contractNoId',
            control,
          }}>
          <FormTypography
            type="select"
            options={
              globalState.contractList?.map((contract) => ({
                key: contract.id,
                value: contract.contractNo,
              })) ?? []
            }
          />
        </FormController>
      </FormField>
      <FormField fieldName="Temporary Works Order No.">
        <FormController
          controllerProps={{
            name: 'linkedTempWorkOrder.tempWorkOrder.tempWorkOrderNo',
            control,
          }}>
          <FormTypography />
        </FormController>
      </FormField>
      {getValues('linkedTempWorkOrder.tempWorkOrder.tempWorkOrderNo') && (
        <Fragment>
          <FormField fieldName="Justification on Covering Works Order">
            <FormController
              controllerProps={{
                name: 'linkedTempWorkOrder.tempWorkOrder.justificationOnCoveringWorkOrder',
                control,
              }}>
              <FormTypography />
            </FormController>
          </FormField>
          <FormField fieldName="Date & Time of Verbal Agreement from E/CTO">
            <FormController
              controllerProps={{
                name: 'linkedTempWorkOrder.tempWorkOrder.verbalAgreementFromEctoDate',
                control,
              }}>
              <FormTypography type="date" />
            </FormController>
          </FormField>
          <FormField fieldName="Date & Time of Verbal Instruction to Contractor">
            <FormController
              controllerProps={{
                name: 'linkedTempWorkOrder.tempWorkOrder.verbalAgreementToContractorDate',
                control,
              }}>
              <FormTypography type="date" />
            </FormController>
          </FormField>
        </Fragment>
      )}
      {typeOfWorks && (
        <FormField fieldName="Type of M&M Works">
          <FormController
            controllerProps={{
              name: 'typeOfMmWorks',
              control,
            }}>
            <FormSelect
              options={options?.typeOfMmWorks?.map((x) => ({ key: x.id, value: x.name })) ?? []}
            />
          </FormController>
        </FormField>
      )}
      {/* <FormField fieldName="District">
        <FormController
          controllerProps={{
            name: 'baseForm.districtId',
            control,
          }}>
          <FormSelect options={globalState.districtList ?? []} />
        </FormController>
      </FormField>
      <FormField fieldName="Site Supervision Team">
        <FormController
          controllerProps={{
            name: 'baseForm.teamId',
            control,
          }}>
          <FormSelect options={globalState.teamList ?? []} />
        </FormController>
      </FormField> */}
      <FormField fieldName="District">
        <FormController
          controllerProps={{
            name: 'baseForm.districtId',
            control: control,
          }}>
          <FormMultipleSelect options={GetDistrictOptions(userInfo.districts)} limit={1} uneditable={true} />
        </FormController>
      </FormField>
      <FormField fieldName="Site Supervision Team">
        <FormController
          controllerProps={{
            name: 'baseForm.teamId',
            control: control,
          }}>
          <FormMultipleSelect options={GetTeamOptions(userInfo.teams)} limit={1} uneditable={true}/>
        </FormController>
      </FormField>
      {typeOfWorks && (
        <FormField fieldName="Schedule of Rate">
          <FormController
            controllerProps={{
              name: 'sorItemNo',
              control,
            }}>
            <FormFreeText textfieldProps={{ type: 'number' }} />
          </FormController>
        </FormField>
      )}
    </FormContainer>
  )
}
