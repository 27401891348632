export interface FileModel {
	fileId: string
	status: boolean
	fileUrl: string
	fileName: string
	ext: string
	size: number
	bytesBase64?: string // client upload
	bytes?: ArrayBuffer // db returns / client upload
	description?: string
	pdfPageNum?: number
}

export const InitFileModel: FileModel = {
	fileId  : '00000000-0000-0000-0000-000000000000', // need be overwritten in backend when used caused the db requires each record to be unique
	status  : true,
	fileUrl : 'N/A',
	fileName: 'N/A',
	ext     : 'N/A',
	size    : 0
}