import FormContainer from '@components/form/container'
import { Control } from 'react-hook-form'
import { FormWoiModel, WoiGeneralOptions } from '@services/model/form/form.WOI.model'
import { useTranslation } from 'react-i18next'
import FormTable from '@components/form/table'

export default ({ control }: { control: Control<FormWoiModel> }) => {
  const { t } = useTranslation()

  return (
    <FormContainer fkey={`woi-h`}>
      <FormTable
        columns={[
          {
            field: 'starRateNo',
            displayName: 'Star Rate No.',
            type: 'string',
            uneditable: true,
          },
          {
            field: 'dateOfContractorParticularsSubmission',
            displayName: 'Date of Contractor Particulars Submission',
            type: 'date',
          },
          {
            field: 'dateOfCompletionOfEngineerValuation',
            displayName: 'Date of Completion of Engineer Valuation',
            type: 'date',
          },
        ]}
        fieldArrayProps={{
          name: 'starRates',
          control,
        }}
        rowFilter={() => true}
        removeAddButton
        height={100}
      />
    </FormContainer>
  )
}
