import svgUrl from '@images/map_recap.svg'
import { Box } from '@mui/material'
import { CSSProperties } from 'react'

interface iconProps {
  id?: string
  sx?: CSSProperties
}

const RefreshIcon = (props: iconProps) => {
  return (
    <Box style={{ height: 20, width: 20, ...props.sx }}>
      <img
        src={svgUrl}
        alt="refresh-icon"
        style={{ height: 'inherit', width: 'inherit' }}
        id={props.id}
      />
    </Box>
  )
}

export default RefreshIcon
