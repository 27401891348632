import { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Control, useForm, useWatch } from 'react-hook-form'
import { GlobalContext } from '@providers/globalStore'
import FormField from '@components/form/field'
import FormContainer from '@components/form/container'
import { FormPartComponents, KeyValPair } from '@models/common'
import { useTranslation } from 'react-i18next'
import FormDateTimePicker from '@components/form/dateTimePicker'
import useQuery from '@hooks/useQuery'
import { QueryStepParser } from '@utils/queryStepParser'
import FormBinaryRadio from '@components/form/binaryRadio'
import FormController from '@components/form/controller'
import { FormContext } from '@providers/formStateProvider'
import FormSignatureHistories from '@components/form/signatureHistories'
import useGeneralOptions from '@hooks/useGeneralOptions'
import uuid from 'react-uuid'
import FormTypography from '@components/form/typography'
import FormFreeText from '@components/form/freeText'
import useFormSwitcher from '@hooks/useFormSwitcher'
import FormSelect from '@components/form/select'
import { FormTWOModel, InitTWOForm, TwoGeneralOptions } from '@services/model/form/form.TWO.model'
import moment from 'moment'
import useAPIFetch from '@hooks/useAPIFetch'
import { useSnackbar } from 'notistack'
import FormService from '@services/form.service'
import FormTWOService from '@services/formService/form.TWO.service'
import { NavigateTo } from '@utils/navigate'
import { FormStatusEnum } from '@services/model/form/form.model'
import { GetDistrictOptions } from '@utils/districtOptions'
import { GetTeamOptions } from '@utils/teamOptions'
import FormMultipleSelect from '@components/form/multipleSelect'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import ValidationToast from '@components/form/ValidationToast'
import getFormValidationSchema from '@utils/formValidationSchema'
import { find, get } from 'lodash'
import useLocalStorage from '@hooks/useLocalStorage'

const UUID = uuid()

const PartAWorkOrderNo = ({
  control,
  options,
  formStatus,
}: {
  control: Control<FormTWOModel>
  options: KeyValPair[]
  formStatus: string
}) => {
  const coveringWorkOrder = useWatch({
    name: 'coveringWorkOrder',
    control,
  })

  if (coveringWorkOrder) {
    return (
      <FormField fieldName="Works Order No.">
        <FormController controllerProps={{ name: 'linkedWoId', control }}>
          <FormSelect
            options={options}
            disabled={formStatus !== FormStatusEnum.FORM_TWO_ISSUE_CONFIRMED}
            uneditable={
              formStatus === FormStatusEnum.FORM_TWO_COMPLETED ||
              formStatus === FormStatusEnum.FORM_TWO_REJECTED
            }
          />
        </FormController>
      </FormField>
    )
  }
  return <></>
}

const PartAReasonNotCoveringWorks = ({
  control,
  formStatus,
}: {
  control: Control<FormTWOModel>
  formStatus: string
}) => {
  const coveringWorkOrder = useWatch({
    name: 'coveringWorkOrder',
    control,
  })

  if (coveringWorkOrder === false) {
    return (
      <FormField fieldName="Reason for Not Issuing Covering Works Order">
        <FormController
          controllerProps={{
            name: 'notCoveringReason',
            control,
          }}>
          <FormFreeText
            disabled={formStatus !== FormStatusEnum.FORM_TWO_REVIEW}
            uneditable={
              formStatus === FormStatusEnum.FORM_TWO_COMPLETED ||
              formStatus === FormStatusEnum.FORM_TWO_REJECTED
            }
          />
        </FormController>
      </FormField>
    )
  }
  return <></>
}

export default () => {
  const { state: globalState, userInfo, dispatch: globalAction } = useContext(GlobalContext)
  const { state: formState, dispatch: formStateAction } = useContext(FormContext)

  const navigate = useNavigate()
  const { t } = useTranslation()
  const [storageContractId, setStorageContractId] = useLocalStorage<string | undefined>(
    'contract',
    undefined,
  )
  // extracting id from params
  let { id: formId } = useParams<string>()

  // extracting step from query
  const query = useQuery()
  const step = QueryStepParser(query.get('step'))

  const { setRequest, isLoading } = useAPIFetch()
  const { setRequest: setOptionRequest, isLoading: isLoadingOption } = useAPIFetch()
  const { enqueueSnackbar } = useSnackbar()

  const [twoGeneralOptions, setTwoGeneralOptions] = useState<TwoGeneralOptions>(
    globalState.formOptionsList?.find(({ key }) => key === 'TWO')?.value ?? {},
  )
  const isMounted = useRef<boolean>(false)

  const validationSchemaConditions = [
    {
      formStatusList: [FormStatusEnum.FORM_TWO_DRAFT],
      validationSchema: yup.object().shape({
        baseForm: yup.object().shape({
          contractNoId: yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .moreThan(0, t('Contract No. is required'))
            .required(t('Contract No. is required')),
          districtId: yup.array().nullable().min(1).required(t('District is required')),
          teamId: yup.array().nullable().min(1).required(t('Site Supervision Team is required')),
        }),
        descriptionOfWorks: yup.string().required(t('Description of Works is required')),
        verbalAgreementToContractorDate: yup
          .date()
          .required(t('Date & Time of Verbal Instruction to Contractor is required')),
      }),
    },
    {
      formStatusList: [FormStatusEnum.FORM_TWO_REVIEW],
      validationSchema: yup.object().shape({
        coveringWorkOrder: yup
          .boolean()
          .nullable()
          .required(t('Issue Covering Works Order is required')),
      }),
    },
    {
      formStatusList: [FormStatusEnum.FORM_TWO_ISSUE_CONFIRMED],
      validationSchema: yup.object().shape({
        linkedWoId: yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .moreThan(0)
          .required(t('Works Order No. is required')),
      }),
    },
    {
      formStatusList: [FormStatusEnum.FORM_TWO_REJECTED, FormStatusEnum.FORM_TWO_COMPLETED],
      validationSchema: yup.object(),
    },
  ]

  const { control, watch, setValue, getValues, reset, trigger } = useForm<FormTWOModel>({
    defaultValues: { ...InitTWOForm },
    resolver: (data, context, options) => {
      const validatorSchema = getFormValidationSchema(
        data.baseForm.formStatus,
        validationSchemaConditions,
      )
      return yupResolver(validatorSchema)(data, context, options)
    },
    mode: 'all',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
  })

  const [formStatus, formPermission] = getValues(['baseForm.formStatus', 'baseForm.formPermission'])

  const reload = async () => {
    setRequest({
      callback: async () => {
        if (formId) {
          await FormTWOService.GetTWOForm(formId)
            .then(async (f) => {
              if (f) {
                reset(f)

                if (
                  f.baseForm.contractNoId &&
                  f.baseForm.contractNoId !== globalState.contractNoId
                ) {
                  globalAction({
                    type: 'selectContract',
                    contractNoId: f.baseForm.contractNoId,
                  })

                  globalAction({
                    type: 'selectWorkGroup',
                    workGroupId: get(
                      find(globalState.contractList, { id: f.baseForm.contractNoId }),
                      'workGroups.0.id',
                      0,
                    ),
                  })
                }

                globalAction({
                  type: 'changeFormStatus',
                  formStatus: f.baseForm.formStatusName,
                })
              }
            })
            .catch((err) => {
              enqueueSnackbar(err.response.data.message, {
                variant: 'error',
                autoHideDuration: null,
              })
              NavigateTo(navigate, '/temporary-works-order/all-record')
            })
        }

        isMounted.current = true
      },
    })
  }

  useEffect(() => {
    if (formId) {
      // existed form
      reload()
    } else {
      setValue('baseForm.contractNoId', storageContractId ? parseInt(storageContractId) : 0)
      // setValue("baseForm.teamId",userInfo.teams.slice(0, 1))
      // setValue("baseForm.districtId",userInfo.districts.slice(0, 1))
    }

    // clear form stack just in case it is not empty
    return () => {
      formStateAction({ type: 'clear' })
      globalAction({
        type: 'changeFormStatus',
        formStatus: undefined,
      }) // nav bar remove status
    }
  }, [])

  //#region form parts

  const TWOPartA = (): FormPartComponents => {
    return {
      partTitle: t('General Information'),
      component: (
        <FormContainer fkey={`${UUID}-two-a`}>
          <FormField fieldName="Contract No.">
            <FormController
              controllerProps={{
                name: 'baseForm.contractNoId',
                control,
              }}>
              <FormSelect
                options={
                  twoGeneralOptions?.contractNo?.map((x) => ({
                    key: x.id,
                    value: x.contractNo,
                  })) ?? []
                }
                disabled={formStatus !== FormStatusEnum.FORM_TWO_DRAFT}
                uneditable={true}
              />
            </FormController>
          </FormField>
          <FormField fieldName="Temporary Works Order No.">
            <FormController
              controllerProps={{
                name: 'tempWorkOrderNo',
                control,
              }}>
              <FormTypography />
            </FormController>
          </FormField>
          <FormField fieldName="District">
            <FormController
              controllerProps={{
                name: 'baseForm.districtId',
                control: control,
              }}>
              <FormMultipleSelect
                options={
                  formStatus !== FormStatusEnum.FORM_TWO_DRAFT
                    ? globalState.districtList || []
                    : GetDistrictOptions(userInfo.districts)
                }
                disabled={formStatus !== FormStatusEnum.FORM_TWO_DRAFT}
                uneditable={formStatus !== FormStatusEnum.FORM_TWO_DRAFT}
                limit={1}
              />
            </FormController>
          </FormField>
          <FormField fieldName="Site Supervision Team">
            <FormController
              controllerProps={{
                name: 'baseForm.teamId',
                control: control,
              }}>
              <FormMultipleSelect
                options={
                  formStatus !== FormStatusEnum.FORM_TWO_DRAFT
                    ? globalState.teamList || []
                    : GetTeamOptions(userInfo.teams)
                }
                disabled={formStatus !== FormStatusEnum.FORM_TWO_DRAFT}
                uneditable={formStatus !== FormStatusEnum.FORM_TWO_DRAFT}
                limit={1}
              />
            </FormController>
          </FormField>
          <FormField fieldName="Description of Works">
            <FormController
              controllerProps={{
                name: 'descriptionOfWorks',
                control,
              }}>
              <FormFreeText
                textfieldProps={{
                  multiline: true,
                  rows: 4,
                }}
                disabled={formStatus !== FormStatusEnum.FORM_TWO_DRAFT}
                uneditable={
                  formStatus === FormStatusEnum.FORM_TWO_COMPLETED ||
                  formStatus === FormStatusEnum.FORM_TWO_REJECTED
                }
              />
            </FormController>
          </FormField>
          <FormField fieldName="Justification on Covering Works Order">
            <FormController
              controllerProps={{
                name: 'justificationOnCoveringWorkOrder',
                control,
              }}>
              <FormFreeText
                textfieldProps={{
                  multiline: true,
                  rows: 4,
                }}
                disabled={formStatus !== FormStatusEnum.FORM_TWO_DRAFT}
                uneditable={
                  formStatus === FormStatusEnum.FORM_TWO_COMPLETED ||
                  formStatus === FormStatusEnum.FORM_TWO_REJECTED
                }
              />
            </FormController>
          </FormField>
          <FormField fieldName="Date & Time of Verbal Agreement from E/CTO">
            <FormController
              controllerProps={{
                name: 'verbalAgreementFromEctoDate',
                control,
              }}>
              <FormDateTimePicker
                disabled={formStatus !== FormStatusEnum.FORM_TWO_DRAFT}
                uneditable={
                  formStatus === FormStatusEnum.FORM_TWO_COMPLETED ||
                  formStatus === FormStatusEnum.FORM_TWO_REJECTED
                }
              />
            </FormController>
          </FormField>
          <FormField fieldName="Date & Time of Verbal Instruction to Contractor">
            <FormController
              controllerProps={{
                name: 'verbalAgreementToContractorDate',
                control,
              }}>
              <FormDateTimePicker
                additionalChangeAction={(date) => {
                  if (date) {
                    setValue('issuingCoveringWorkOrderDueDate', moment(date).add(7, 'day').toDate())
                  }
                }}
                disabled={formStatus !== FormStatusEnum.FORM_TWO_DRAFT}
                uneditable={
                  formStatus === FormStatusEnum.FORM_TWO_COMPLETED ||
                  formStatus === FormStatusEnum.FORM_TWO_REJECTED
                }
              />
            </FormController>
          </FormField>
          <FormField fieldName="Due Date for Issuing Covering Works Order">
            <FormController
              controllerProps={{
                name: 'issuingCoveringWorkOrderDueDate',
                control,
              }}>
              <FormTypography type="date" />
            </FormController>
          </FormField>
          <FormField fieldName="Issue Covering Works Order">
            <FormController
              controllerProps={{
                name: 'coveringWorkOrder',
                control,
              }}>
              <FormBinaryRadio
                disabled={formStatus !== FormStatusEnum.FORM_TWO_REVIEW}
                uneditable={
                  formStatus === FormStatusEnum.FORM_TWO_COMPLETED ||
                  formStatus === FormStatusEnum.FORM_TWO_REJECTED
                }
              />
            </FormController>
          </FormField>
          <PartAReasonNotCoveringWorks control={control} formStatus={formStatus} />
          <FormField fieldName="E/CTO Remarks">
            <FormController
              controllerProps={{
                name: 'ectoRemarks',
                control,
              }}>
              <FormFreeText
                disabled={formStatus !== FormStatusEnum.FORM_TWO_REVIEW}
                uneditable={
                  formStatus === FormStatusEnum.FORM_TWO_COMPLETED ||
                  formStatus === FormStatusEnum.FORM_TWO_REJECTED
                }
              />
            </FormController>
          </FormField>
          {formStatus === FormStatusEnum.FORM_TWO_COMPLETED ? (
            <FormField fieldName="Work Order No">
              <FormController
                controllerProps={{
                  name: 'workOrderNo',
                  control,
                }}>
                <FormFreeText uneditable={formStatus === FormStatusEnum.FORM_TWO_COMPLETED} />
              </FormController>
            </FormField>
          ) : (
            <PartAWorkOrderNo
              control={control}
              options={
                globalState.worksOrderList
                  ?.filter((x) => !x.isLinkWithTempWorkOrder)
                  ?.map((wo) => ({
                    key: wo.id,
                    value: wo.workOrderNo,
                  })) ?? []
              }
              formStatus={formStatus}
            />
          )}
        </FormContainer>
      ),
    }
  }

  const TWOPartB = (): FormPartComponents => {
    return {
      partTitle: t('Submission Confirmation'),
      component: (
        <FormContainer fkey={`${UUID}-two-b`}>
          <FormSignatureHistories
            formStatusList={globalState.formStatusList}
            histories={getValues('baseForm.formStatusHistories')}
            control={control}
            handleOnComplete={() => handleOnComplete()}
            endOfFlow={formPermission?.endOfFlow}
            userMetaList={globalState.userMetaList}
            roleMetaList={globalState.roleMetaList}
            disabled={!formPermission?.canUpdate}
          />
        </FormContainer>
      ),
    }
  }

  //#endregion

  const handleOnSubmit = async (
    event: any,
    isReject: boolean,
    notifyList?: string[],
    signatureBase64?: string,
    submissionComment?: string,
  ) => {
    // TODO: submit
    if (!(await trigger())) return
    setValue('signatureBase64', signatureBase64)
    var ff = getValues()
    ff.signatureBase64 = signatureBase64
    ff.notifyUserList = notifyList
    ff.submissionComment = submissionComment
    if (isReject) {
      setRequest({
        callback: async () => {
          await FormTWOService.RejectTWOForm(ff).then((resp) => {
            enqueueSnackbar(t('Record Submitted'), { variant: 'success' })
            NavigateTo(navigate, '/temporary-works-order/all-record')
          })
        },
      })
    } else {
      setRequest({
        callback: async () => {
          console.log('form', ff)
          await FormTWOService.ApproveTWOForm(ff).then((resp) => {
            enqueueSnackbar(t('Record Submitted'), { variant: 'success' })
            NavigateTo(navigate, '/temporary-works-order/all-record')
          })
        },
      })
    }
  }

  const handleOnSave = async () => {
    setRequest({
      callback: async () => {
        await FormTWOService.SaveTWOForm(getValues()).then((resp) => {
          setValue('baseForm.formId', resp.formId)
          setValue('baseForm.formStatus', resp.formStatus)
          NavigateTo(navigate, '/temporary-works-order/:id', {
            id: resp.formId,
          })

          formId = resp.formId
          reload()

          enqueueSnackbar(t('Record Saved'), { variant: 'success' })
        })
      },
    })
  }

  const handleOnDelete = async () => {
    setRequest({
      callback: async () => {
        await FormTWOService.DeleteTWOForm(getValues())
          .then(() => {
            enqueueSnackbar(t('Record Archived'), { variant: 'success' })
            NavigateTo(navigate, '/temporary-works-order/all-record')
          })
          .catch((err) => {
            enqueueSnackbar(err.response.data.message, { variant: 'error', autoHideDuration: null })
          })
      },
    })
  }

  const onLoadNotifyList = async (isReject: boolean) => {
    var defaultNotifyList = await FormService.GetNotifyList(
      'TWO',
      getValues('baseForm.formId'),
      isReject,
      getValues('baseForm.districtId'),
      getValues('baseForm.teamId'),
    )
    return defaultNotifyList
  }

  const onLoadHistoryList = async () => {
    var defaultNotifyList = await FormService.GetHistoryList(getValues('baseForm.formId'))
    return defaultNotifyList
  }

  const [FormSwitcher, handleOnComplete] = useFormSwitcher({
    title: t('TEMPORARY WORKS ORDER'),
    components: [TWOPartA(), TWOPartB()],
    formOnLoadNotifyList: onLoadNotifyList,
    formOnLoadCommentList: async () => {
      return await FormService.GetCommentList(getValues('baseForm.formId'))
    },
    formOnSubmitComment: async (comment) => {
      await FormService.SubmitComment(getValues('baseForm.formId'), comment)
    },
    formOnLoadFormHistoryList: onLoadHistoryList,
    formOnSubmit: handleOnSubmit,
    formOnDelete: handleOnDelete,
    formOnSave: handleOnSave,
    startStep: step,
    isLoading: isLoading || isLoadingOption,
    disableSave: !formPermission?.canUpdate,
    disableDelete: !formPermission?.canDelete,
    disableComment: getValues('baseForm.formId') ? false : true,
    approveRequired: formPermission?.workflowRequired,
    endOfFlow: formPermission?.endOfFlow,
  })

  return (
    <Fragment>
      <FormSwitcher />
      <ValidationToast control={control} />
    </Fragment>
  )
}
