import svgUrl from '@images/information.svg'
import { Box } from '@mui/material'
import { CSSProperties } from 'react'

interface iconProps {
  sx?: CSSProperties
}

const InfoIcon = (props: iconProps) => {
  return (
    <Box style={{ height: 20, width: 20, ...props.sx }}>
      <img src={svgUrl} alt="info-icon" style={{ height: 'inherit', width: 'inherit' }} />
    </Box>
  )
}

export default InfoIcon
