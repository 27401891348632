import FormContainer from '@components/form/container'
import FormController from '@components/form/controller'
import FormField from '@components/form/field'
import { Control } from 'react-hook-form'
import { FormWoiModel, WoiGeneralOptions } from '@services/model/form/form.WOI.model'
import FormTypography from '@components/form/typography'
import { useTranslation } from 'react-i18next'

export default ({ control }: { control: Control<FormWoiModel> }) => {
  const { t } = useTranslation()

  return (
    <FormContainer fkey={`woi-c`}>
      <FormField fieldName="Aggregated Interim Payments">
        <FormController
          controllerProps={{
            name: 'aggregatedInterimPayments',
            control,
          }}>
          <FormTypography />
        </FormController>
      </FormField>
      <FormField fieldName="Date of Most Previous IP">
        <FormController
          controllerProps={{
            name: 'dateOfSecondLastIp',
            control,
          }}>
          <FormTypography type="date" />
        </FormController>
      </FormField>
      <FormField fieldName="Ceiling of Aggregated Interim Payment">
        <FormController
          controllerProps={{
            name: 'ceilingOfAggregatedInterimPayment',
            control,
          }}>
          <FormTypography />
        </FormController>
      </FormField>
      <FormField fieldName="Application for SBV by the Contractor">
        <FormController
          controllerProps={{
            name: 'applicationOfSbv',
            control,
          }}>
          <FormTypography type="binary" />
        </FormController>
      </FormField>
      <FormField fieldName="Date of Application for SBV by the Contractor">
        <FormController
          controllerProps={{
            name: 'dateOfApplicationOfSbv',
            control,
          }}>
          <FormTypography type="date" />
        </FormController>
      </FormField>
    </FormContainer>
  )
}
