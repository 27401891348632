import { Dispatch, SetStateAction, useEffect } from 'react'
import { useWatch, Control, UseFormSetValue }  from 'react-hook-form'
import FormContainer                           from '@components/form/container'
import FormController                          from '@components/form/controller'
import FormField                               from '@components/form/field'
import FormRadio                               from '@components/form/radio'
import FormSelect                              from '@components/form/select'
import FormTypography                          from '@components/form/typography'
import useGeneralOptions                       from '@hooks/useGeneralOptions_to_be_used'
import { TextField }                           from '@mui/material'
import { FormStatusEnum }                      from '@services/model/form/form.model'
import { FormScrModel }                        from '@services/model/form/form.SCR.model'
import { t }                                   from 'i18next'

export default ({
                    control,
                    formStatus,
                    setValue,
                    setDisableNav
                }: {
    control: Control<FormScrModel>
    formStatus: string
    setValue: UseFormSetValue<FormScrModel>
    setDisableNav: Dispatch<SetStateAction<boolean>>
}) => {
    const { getOptionsByKey } = useGeneralOptions()
    const editable            = formStatus === FormStatusEnum.FORM_SCR_DRAFT || formStatus === FormStatusEnum.FORM_SCR_REJECTED

    const workStatus = useWatch({
                                    name: 'worksStatusId',
                                    control
                                })

    const WorkInProgressFields = () => {
        if (workStatus)
            setDisableNav(false)

        const isInProgress =
                  getOptionsByKey('Form SD - Works Status')?.find(o => o.value === 'Works in progress')?.key === workStatus

        if (isInProgress) {
            return (
                <FormField fieldName='Category'>
                    <FormController
                        controllerProps={ {
                            name   : 'workInProgressTypeId',
                            control: control
                        } }>
                        <FormSelect
                            fullWidth
                            options={ getOptionsByKey('Form SD/SCR - Works In Progress Type') }
                            uneditable={ !editable }
                            additionalChangeAction={ (event, newValue) => {
                                setValue('wipTypeSubOptionId', undefined)
                                setValue('wipTypeSubOptionOthers', '')
                            } } />
                    </FormController>
                </FormField>
            )
        } else {
            return <></>
        }
    }

  const WorkInProgressSuboptionsField = () => {
    const [workStatus, progressTypeId] = useWatch({
      name: ['worksStatusId', 'workInProgressTypeId'],
      control,
    })

    const isInProgress = getOptionsByKey('Form SD - Works Status')?.find((o) => o.value === 'Works in progress')?.key === workStatus

    if (isInProgress) {
      return (
        <>
          <FormField fieldName="Actual Activities">
            <FormController
              controllerProps={{
                name: 'wipTypeSubOptionId',
                control: control,
              }}>
              <FormSelect
                fullWidth
                options={getOptionsByKey('Form SD/SCR - WIP Type Sub Option').filter(
                  (x) => x.parentId === progressTypeId,
                )}
                uneditable={!editable}
                additionalChangeAction={(event, newValue) => {
                  console.log('additional change action')
                  setValue('wipTypeSubOptionOthers', '')
                }}
              />
            </FormController>
          </FormField>
        </>
      )
    }else{
      return <></>
    }
  }

  const OthersFields = () => {
    const [workStatus, wipTypeSubOptionId] = useWatch({
      name: ['worksStatusId', 'wipTypeSubOptionId'],
      control,
    })

    const isInProgress = getOptionsByKey('Form SD - Works Status')?.find((o) => o.value === 'Works in progress')?.key === workStatus
    const isOthers = getOptionsByKey('Form SD/SCR - WIP Type Sub Option')?.find((o) => o.value === 'Others')?.key === wipTypeSubOptionId

    if (isInProgress && isOthers) {
      return (
        <>
          <FormField fieldName="Others">
            <FormController
              controllerProps={{
                name: 'wipTypeSubOptionOthers',
                control: control,
              }}>
              {editable ? (
                <TextField
                  fullWidth
                  multiline
                  rows={7}
                  placeholder={t('Please fill in information')}
                />
              ) : (
                <FormTypography />
              )}
            </FormController>
          </FormField>
        </>
      )
    } else {
      return <></>
    }
  }

  const OldActualActivitiesFormFields = () => {
    const actualActivities = useWatch({
      name: 'actualActivities',
      control,
    })

    if(actualActivities){
      return (
      <FormField fieldName="Actual Activities(Old)">
        <FormController
          controllerProps={{
            name: 'actualActivities',
            control: control,
          }}>
          {editable ? (
            <TextField
              fullWidth
              multiline
              rows={7}
              placeholder={t('Please fill in information')}
            />
          ) : (
            <FormTypography />
          )}
        </FormController>
      </FormField>
      )
    }else{
      return <></>
    }
  }

    useEffect(() => {
        if (workStatus === undefined)
            setDisableNav(true)
    }, [workStatus])

  return (
    <FormContainer fkey={`$scr-c`}>
      <FormField fieldName="Works Status">
        <FormController
          controllerProps={{
            name: 'worksStatusId',
            control: control,
          }}>
          <FormRadio
            labels={getOptionsByKey('Form SD - Works Status')}
            uneditable={!editable}
            additionalChangeAction={(value: number) => {
              if (
                value !== getOptionsByKey('Form SD - Works Status')?.find( (o) => o.value === 'Works in progress' )?.key
              ) {
                setValue('workInProgressTypeId', undefined)
                setValue('wipTypeSubOptionId', undefined)
                setValue('wipTypeSubOptionOthers', '')
              }
            }}
          />
        </FormController>
      </FormField>
      <WorkInProgressFields />
      <WorkInProgressSuboptionsField />
      <OthersFields />
      <OldActualActivitiesFormFields />
    </FormContainer>
  )
}
