import { Fragment, useContext, useEffect, useRef, useState }    from 'react'
import { useNavigate, useParams }                               from 'react-router-dom'
import { useForm }                                              from 'react-hook-form'
import { useTranslation }                                       from 'react-i18next'
import { useSnackbar }                                          from 'notistack'
import { GlobalContext }                                        from '@providers/globalStore'
import { FormContext }                                          from '@providers/formStateProvider'
import { FormPartComponents }                                   from '@models/common'
import { FormDNAModel, InitDNAForm, DNAGeneralOptions }         from '@services/model/form/form.DNA.model'
import { FormDNModel }                                          from '@services/model/form/form.DN.model'
import FormService                                              from '@services/form.service'
import FormDNAService                                           from '@services/formService/form.DNA.service'
import { FormStatusEnum, InitFormModel, InitFormStatusHistory } from '@services/model/form/form.model'
import useQuery                                                 from '@hooks/useQuery'
import useFormSwitcher                                          from '@hooks/useFormSwitcher'
import useAPIFetch                                              from '@hooks/useAPIFetch'
import { NavigateTo }                                           from '@utils/navigate'
import { QueryStepParser }                                      from '@utils/queryStepParser'

// parts
import { PartA }               from './part-a'
import PartB                   from './part-b'
import PartC                   from './part-c'
import * as yup                from 'yup'
import { yupResolver }         from '@hookform/resolvers/yup'
import ValidationToast         from '@components/form/ValidationToast'
import { find, get, isString } from 'lodash'
import useLocalStorage         from '@hooks/useLocalStorage'

export default () => {
  const { state: globalState, userInfo, dispatch: globalAction } = useContext(GlobalContext)
  const { state: formState, dispatch: formStateAction } = useContext(FormContext)

  const navigate = useNavigate()
  const { t } = useTranslation()
  const [storageContractId, setStorageContractId] = useLocalStorage<string | undefined>(
    'contract',
    undefined,
  )
  // extracting id from params
  let { id: formId } = useParams<string>()

  // extracting step from query
  const query = useQuery()
  const step = QueryStepParser(query.get('step'))

  const { setRequest, isLoading } = useAPIFetch()
  const { setRequest: setOptionRequest, isLoading: isLoadingOption } = useAPIFetch()
  const { enqueueSnackbar } = useSnackbar()

  const isMounted = useRef<boolean>(false)

  const validationSchemaConditions = [
    {
      formStatusList: [FormStatusEnum.FORM_DNA_DRAFT],
      validationSchema: yup.object().shape({
        baseForm: yup.object().shape({
          contractNoId: yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .moreThan(0, t('Contract No. is required'))
            .required(t('Contract No. is required')),
          teamId: yup.array().nullable().min(1).required(t('Team is required')),
          districtId: yup.array().nullable().min(1).required(t('District is required')),
          workOrderId: yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .moreThan(0)
            .required(t('Works Order No. is required')),
        }),
      }),
    },
  ]

  const { control, watch, setValue, getValues, reset, trigger } = useForm<FormDNAModel>({
    defaultValues: { ...InitDNAForm },
    resolver: (data, context, options) => {
      // const validatorSchema = getFormValidationSchema(data.baseForm.formStatus, validationSchemaConditions)
      const validatorSchema = validationSchemaConditions?.[0]?.validationSchema
      return yupResolver(validatorSchema)(data, context, options)
    },
    mode: 'all',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
  })

  const formPermission = getValues('baseForm.formPermission')
  const formStatus = getValues('baseForm.formStatus')
  const [dnaGeneralOptions, setDnaGeneralOptions] = useState<DNAGeneralOptions>(
    globalState.formOptionsList?.find(({ key }) => key === 'DNA')?.value ?? [],
  )

  const calculateExpectedGeneratedDn = (startDate: Date, endDate: Date, reoccurrenceInHour: number, nonComplianceId: number) => {
    let expectedGeneratedDnNo = Math.floor((endDate.getTime() - startDate.getTime()) /
                                           (reoccurrenceInHour * 3600 * 1000))

    expectedGeneratedDnNo = expectedGeneratedDnNo > 0 ? expectedGeneratedDnNo : 0

    let expectedGeneratedDn = [] as Array<FormDNModel>
    for (let i = 0; i < expectedGeneratedDnNo; i++)
      expectedGeneratedDn.push({
                                 baseForm    : {
                                   ...InitFormModel,
                                   formStatus         : FormStatusEnum.FORM_DN_ISSUED,
                                   formStatusName     : 'Issued',
                                   formStatusHistories: [{ ...InitFormStatusHistory, actionName: 'Issued' }]
                                 },
                                 appealImages: [],
                                 startAt     : new Date(startDate.getTime() + i * reoccurrenceInHour * 3600 * 1000),
                                 endAt       : new Date(startDate.getTime() + (i + 1) * reoccurrenceInHour * 3600 * 1000),
                                 nonComplianceId
                               })

    return expectedGeneratedDn
  }

  const reload = async () => {
    setRequest({
      callback: async () => {
        if (formId) {
          await FormDNAService.GetDNAForm(formId)
            .then(async (f) => {
              if (f) {
                if (
                  f.baseForm.contractNoId &&
                  f.baseForm.contractNoId !== globalState.contractNoId
                ) {
                  globalAction({
                    type: 'selectContract',
                    contractNoId: f.baseForm.contractNoId,
                  })

                  globalAction({
                    type: 'selectWorkGroup',
                    workGroupId: get(
                      find(globalState.contractList, { id: f.baseForm.contractNoId }),
                      'workGroups.0.id',
                      0,
                    ),
                  })
                }

                const selectedDnType = dnaGeneralOptions?.typeOfNonCompliance?.find(x => x.id === f.nonComplianceId)
                if (selectedDnType?.reoccurenceInHour && f.ncStartDate && f.ncEndDate)
                  f.formDnView = calculateExpectedGeneratedDn(isString(f.ncStartDate) ? new Date(f.ncStartDate) : f.ncStartDate,
                                                              isString(f.ncEndDate) ? new Date(f.ncEndDate) : f.ncEndDate,
                                                              selectedDnType.reoccurenceInHour,
                                                              selectedDnType?.id)

                reset(f)

                globalAction({
                  type: 'changeFormStatus',
                  formStatus: f.baseForm.formStatusName,
                })
              }
            })
            .catch((err) => {
              enqueueSnackbar(err.response.data.message, { variant: 'error' })
              NavigateTo(navigate, '/application-of-default-notice-for-non-compliance/all-record')
            })
        }
      },
    })
  }

  useEffect(() => {
    if (!isMounted.current) {
      if (formState.form && formState.formType === 'DNA') {
        formStateAction({ type: 'pop', targetFormType: 'DNA' })

        // previous editing form (new / existed form)
        reset({ ...formState.form })
      } else if (formId) {
        reload()
      } else {
        setValue('baseForm.contractNoId', storageContractId ? parseInt(storageContractId) : 0)
        setValue('baseForm.teamId', userInfo.teams)
        setValue('baseForm.districtId', userInfo.districts)
      }

      globalAction({
        type: 'changeFormStatus',
        formStatus: getValues('baseForm.formStatusName'),
      })
    }

    return () => {
      // formStateAction({ type: 'clear' })
      globalAction({
        type: 'changeFormStatus',
        formStatus: undefined,
      })
    }
  }, [formId, formStateAction, globalAction])

  const handleOnSubmit = async (
    event: any,
    isReject: boolean,
    notifyList?: string[],
    signatureBase64?: string,
    submissionComment?: string,
  ) => {
    if (!(await trigger())) return
    setValue('signatureBase64', signatureBase64)
    var ff = getValues()
    ff.signatureBase64 = signatureBase64
    ff.notifyUserList = notifyList
    ff.submissionComment = submissionComment
    if (isReject) {
      setRequest({
        callback: async () => {
          await FormDNAService.RejectDNAForm(ff).then((resp) => {
            enqueueSnackbar(t('Record Submitted'), { variant: 'success' })
            NavigateTo(navigate, '/application-of-default-notice-for-non-compliance/all-record')
          })
        },
      })
    } else {
      setRequest({
        callback: async () => {
          console.log('form', ff)
          await FormDNAService.ApproveDNAForm(ff).then((resp) => {
            enqueueSnackbar(t('Record Submitted'), { variant: 'success' })
            NavigateTo(navigate, '/application-of-default-notice-for-non-compliance/all-record')
          })
        },
      })
    }
  }

  const handleOnSave = async () => {
    setRequest({
      callback: async () => {
        await FormDNAService.SaveDNAForm(getValues()).then((resp) => {
          setValue('baseForm.formId', resp.formId)
          setValue('baseForm.formStatus', resp.formStatus)
          NavigateTo(navigate, '/application-of-default-notice-for-non-compliance/:id', {
            id: resp.formId,
          })
          formId = resp.formId
          reload()
          enqueueSnackbar(t('Record Saved'), { variant: 'success' })
        })
      },
    })
  }

  const handleOnDelete = async () => {
    setRequest({
      callback: async () => {
        await FormDNAService.DeleteDNAForm(getValues())
          .then(() => {
            enqueueSnackbar(t('Record Archived'), { variant: 'success' })
            NavigateTo(navigate, '/application-of-default-notice-for-non-compliance/all-record')
          })
          .catch((err) => {
            enqueueSnackbar(err.response.data.message, { variant: 'error' })
          })
      },
    })
  }

  const partA: FormPartComponents = {
    partTitle: t('General Information'),
    component: (
        <PartA globalState={ globalState }
               control={ control }
               watch={ watch }
               userInfo={ userInfo }
               getValues={ getValues }
               options={ dnaGeneralOptions }
               setValue={ setValue }
               calculateExpectedGeneratedDn={ calculateExpectedGeneratedDn }
               isMounted={ isMounted } />
    )
  }

  const partB: FormPartComponents = {
    partTitle: t('Re-submission'),
    component: (
      <PartB
        control={control}
        options={dnaGeneralOptions}
        formStatus={formStatus}
        getValues={getValues}
      />
    ),
    disabled:
      formStatus === FormStatusEnum.FORM_DNA_DRAFT ||
      formStatus === FormStatusEnum.FORM_DNA_PENDING ||
      formStatus === FormStatusEnum.FORM_DNA_IOW_REVIEW ||
      formStatus === FormStatusEnum.FORM_DNA_ECTO_REVIEW ||
      formStatus === FormStatusEnum.FORM_DNA_REVIEW_REJECTED ||
      formStatus === FormStatusEnum.FORM_DNA_ISSUED,
  }

  const partC: FormPartComponents = {
    partTitle: t('Submission Confirmation'),
    component: (
      <PartC
        control={control}
        globalState={globalState}
        handleOnComplete={() => handleOnComplete()}
      />
    ),
  }

  const onLoadNotifyList = async (isReject: boolean) => {
    var defaultNotifyList = await FormService.GetNotifyList(
      'DNA',
      getValues('baseForm.formId'),
      isReject,
      getValues('baseForm.districtId'),
      getValues('baseForm.teamId'),
    )
    return defaultNotifyList
  }

  const [FormSwitcher, handleOnComplete] = useFormSwitcher({
    title: t('DEFAULT NOTICE FOR NON-COMPLIANCE APPLICATION'),
    components: [partA, partB, partC],
    formOnLoadNotifyList: onLoadNotifyList,
    formOnLoadCommentList: async () => {
      return await FormService.GetCommentList(getValues('baseForm.formId'))
    },
    formOnSubmitComment: async (comment) => {
      await FormService.SubmitComment(getValues('baseForm.formId'), comment)
    },
    formOnSubmit: handleOnSubmit,
    formOnDelete: handleOnDelete,
    formOnSave: handleOnSave,
    startStep: step,
    isLoading: isLoading || isLoadingOption,
    disableSave: !formPermission?.canUpdate,
    disableDelete: !formPermission?.canDelete,
    disableComment: getValues('baseForm.formId') ? false : true,
    approveRequired: formPermission?.workflowRequired,
    endOfFlow: formPermission?.endOfFlow,
  })

  return (
    <Fragment>
      <FormSwitcher />
      <ValidationToast control={control} />
    </Fragment>
  )
}
