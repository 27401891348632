import FormContainer from '@components/form/container'
import { Control } from 'react-hook-form'
import uuid from 'react-uuid'
import { FormWouModel } from '@services/model/form/form.WOU.model'
import FormSignatureHistories from '@components/form/signatureHistories'
import { BaseFormPermission } from '@services/model/form/form.model'

export default ({
    control,
    getValues,
    globalState,
    formPermission,
    handleOnComplete
}:{
    control: Control<FormWouModel>
    getValues: any
    globalState: any
    formPermission: BaseFormPermission
    handleOnComplete:any
}) => {
    const UUID = uuid()

    return (
    <FormContainer fkey={`${UUID}-wou-g`}>
        <FormSignatureHistories
          formStatusList={globalState.formStatusList}
          histories={getValues('baseForm.formStatusHistories')}
          control={control}
          handleOnComplete={() => handleOnComplete()}
          endOfFlow={formPermission?.endOfFlow}
          userMetaList={globalState.userMetaList}
            roleMetaList={globalState.roleMetaList}
          disabled={!formPermission?.canUpdate}
        />
    </FormContainer>
    )
  }