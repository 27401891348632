import FormDialog from '@components/form/dialog_v2'
import FormSignaturePad from '@components/form/signaturePad'
import { styled, SxProps, Theme, useTheme } from '@mui/material/styles'
import { FormPartComponents, KeyValPair } from '@models/common'
import {
  Box,
  Typography,
  Divider,
  Button,
  BoxProps,
  Grid,
  Stack,
  Autocomplete,
  TextField,
  useMediaQuery,
} from '@mui/material'
import { useState, useContext, useRef, Fragment, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Comment, FormHistory, NotifyList } from '@services/model/form/form.model'
import { GlobalContext, GlobalStateProps } from '@providers/globalStore'
import DialogButton from '@components/button/DialogButton'
import { useSnackbar } from 'notistack'

function RoleItem(props: BoxProps) {
  const { sx, ...other } = props
  return (
    <Box
      sx={{
        mr: 1,
        p: 1,
        border: '1px solid',
        borderRadius: 2,
        fontSize: '0.875rem',
        fontWeight: '700',
        ...sx,
      }}
      {...other}
    />
  )
}

type DialogContextProps = {
  isOpen: boolean
  title?: JSX.Element
  toolbarStyle?: SxProps<Theme>
  children?: JSX.Element
  buttons?: JSX.Element
}

type useFormApproveProps = {
  formOnSubmit?: (
    event: any,
    isReject: boolean,
    notifyUserList?: string[],
    signatureBase64?: string,
  ) => Promise<void>

  formOnLoadNotifyList?: (isRejcet: boolean) => Promise<NotifyList>

  isLoading?: boolean
  disableSave?: boolean
  disableDelete?: boolean
  disableComment?: boolean
  disableComplete?: boolean

  onSubFormComplete?: () => void
  approveRequired: () => boolean | undefined
  endOfFlow?: boolean

  isMounted?: boolean
}

const useFormApprove = function (
  props: useFormApproveProps,
): [FormApprove: () => JSX.Element, handleOnComplete: () => void] {
  const { state: globalState } = useContext(GlobalContext)
  const theme = useTheme()
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))

  // startStep checking
  const InitDialogContent: DialogContextProps = {
    isOpen: false,
    title: <></>,
  }

  const [dialogContext, setDialogContext] = useState<DialogContextProps>(InitDialogContent)

  const { t } = useTranslation()


  const handleDialogClose = () => {
    setDialogContext(InitDialogContent)
  }
  
  const userListRef = useRef<KeyValPair[]>([])
  const signatureBase64Ref = useRef<string | undefined>()

  const handleOnApprove = (event: any) => {
    changeContextForConfirmation(t('Approve'), 'green', false, props.formOnSubmit)
  }
  const handleOnReject = (event: any) => {
    changeContextForConfirmation(t('Reject'), 'red', true, props.formOnSubmit)
  }

  const { enqueueSnackbar } = useSnackbar()

  const changeContextForConfirmation = async (
    headerTitle: string,
    headerColor: string,
    isReject: boolean,
    onConfirmCallback?: (
      event: any,
      isReject: boolean,
      notifyList?: string[],
      signatureBase64?: string,
    ) => Promise<void>,
  ) => {
    var defaultNotifyList: NotifyList = { roles: [], users: [] }
    let hasError = false
    if (props.formOnLoadNotifyList) {
      await props
        .formOnLoadNotifyList(isReject)
        .then((x) => {
          defaultNotifyList = x
        })
        .catch((err) => {
          enqueueSnackbar(err.response.data.message, { variant: 'error', autoHideDuration: null })
          hasError = true
        })
    }

    if (hasError) return

    var defaultNotifyUserList =
      globalState.userMetaList?.filter((x) => {
        return defaultNotifyList?.users?.includes(x.key)
      }) || []

    userListRef.current = defaultNotifyUserList

    setDialogContext({
      isOpen: true,
      toolbarStyle: headerColor ? { backgroundColor: headerColor, color: 'white' } : undefined,
      title: headerTitle ? <Typography variant="body1">{headerTitle}</Typography> : undefined,
      children: (
        <Grid container sx={{ alignItems: 'center' }}>
          <Grid item xs={matchDownMd ? 12 : 3} sx={{ p: 1 }}>
            {t('Submit to')}
          </Grid>

          <Grid item xs={matchDownMd ? 12 : 9} sx={{ p: 1 }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                bgcolor: 'background.paper',
                borderRadius: 1,
              }}>
              {(
                globalState.roleMetaList?.filter((x) => {
                  return defaultNotifyList?.roles?.includes(x.key)
                }) || []
              )?.map((x, idx) => {
                return <RoleItem key={`ri_${idx}`}>{x.value}</RoleItem>
              })}
            </Box>
          </Grid>

          <Grid item xs={matchDownMd ? 12 : 3} sx={{ p: 1 }}>
            {t('Notify')}
          </Grid>
          <Grid item xs={matchDownMd ? 12 : 9} sx={{ p: 1 }}>
            <Autocomplete
              fullWidth
              disablePortal
              multiple
              defaultValue={defaultNotifyUserList}
              getOptionLabel={(option: KeyValPair) => {
                return option.value
              }}
              options={globalState.userMetaList || []}
              onChange={(event: any, newValue: KeyValPair[]) => {
                userListRef.current = newValue
              }}
              // sx={{
              //   height: '100px',
              //   overflowY: 'auto',
              //   '&.MuiFormControl-root': {
              //     height: 'inherit',
              //   },
              //   '::-webkit-scrollbar': {
              //     width: '20px',
              //   },
              // }}
              {...(matchDownMd && {
                sx: {
                  height: '100px',
                  overflowY: 'auto',
                  '&.MuiFormControl-root': {
                    height: 'inherit',
                  },
                  '::-webkit-scrollbar': {
                    width: '20px',
                  },
                },
              })}
              renderInput={ params => {
                const { InputProps, ...restParams } = params;
                const { startAdornment, ...restInputProps } = InputProps;
                return (
                  <TextField
                    { ...restParams }
                    InputProps={ {
                      ...restInputProps,
                      startAdornment: (
                        <div style={ {
                          maxHeight: '250px',
                          overflowY: 'auto',
                        } }
                        >
                          {startAdornment}
                        </div>
                      ),
                    } }
                  />
                );
              } }
            />
          </Grid>

          <Grid item xs={matchDownMd ? 12 : 3} sx={{ p: 1 }}>
            {t('Signature')}
          </Grid>
          <Grid
            item
            xs={matchDownMd ? 12 : 9}
            sx={{ p: 1, border: '1px solid', overflow: 'hidden' }}>
            <FormSignaturePad
              onChange={(newValue: string) => {
                signatureBase64Ref.current = newValue
              }}
            />
          </Grid>
        </Grid>
      ),
      buttons: (
        <Stack {...(!matchDownMd && { direction: 'row' })} spacing={2}>
          <DialogButton
            disabled={props.disableSave}
            onClick={async (event) => {
              if (onConfirmCallback) {
                await onConfirmCallback(
                  event,
                  isReject,
                  userListRef.current.map((x) => x.key),
                  signatureBase64Ref.current,
                )

                handleDialogClose()
              }
            }}>
            {t('Confirm')}
          </DialogButton>
          <DialogButton onClick={handleDialogClose}>{t('Cancel')}</DialogButton>
        </Stack>
      ),
    })
  }

  const handleOnComplete = () => {
    if (props.onSubFormComplete) {
      props.onSubFormComplete()
      return
    }

    signatureBase64Ref.current = undefined

    if (props.approveRequired() ) {
      setDialogContext({
        isOpen: true,
        title: <Typography variant="body1">{t('Would you like to proceed?')}</Typography>,
        children: <Grid></Grid>,
        buttons: (
          <Stack {...(!matchDownMd && { direction: 'row' })} spacing={2}>
            <DialogButton onClick={handleOnApprove} disabled={props.disableSave}>
              {t('Approve')}
            </DialogButton>
            <DialogButton onClick={handleOnReject} disabled={props.disableSave}>
              {t('Reject')}
            </DialogButton>
            <DialogButton onClick={handleDialogClose} disabled={props.disableSave}>
              {t('Cancel')}
            </DialogButton>
          </Stack>
        ),
      })
    } else {
      changeContextForConfirmation('', '', false, props.formOnSubmit)
    }
  }

  const FormApprove = () => (
      <FormDialog
        key={'dialog'}
        open={dialogContext.isOpen}
        onClose={handleDialogClose}
        toolbarStyle={dialogContext.toolbarStyle}
        title={dialogContext.title}
        buttons={dialogContext.buttons}
        children={dialogContext.children}
      />
  )

  return [FormApprove, handleOnComplete]
}

export default useFormApprove