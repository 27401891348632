import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { useFormState } from 'react-hook-form'

const ValidationToast = (props: { control: any }) => {
  const { enqueueSnackbar } = useSnackbar()

  const { errors } = useFormState({
    control: props.control,
  })

  const getFirstErrorMessage = (errors) => {
    for(let err in errors){
      if(errors[err]?.message?.toString() !== undefined){
        return errors[err]?.message?.toString()
      }else{
        return getFirstErrorMessage(errors[err])
      }
    }
  }


  useEffect(() => {
    if (errors) {
      let keys: string[] = []
      Object.keys(errors).forEach(function (key) {
        keys.push(key)
      })

      if (keys.length > 0) {
        let msg = getFirstErrorMessage(errors)
        if (msg) {
          enqueueSnackbar(msg, {
            variant: 'error',
            autoHideDuration: null,
          })
        }
      }
    }
  }, [errors])

  return <></>
}

export default ValidationToast
