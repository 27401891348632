import MainLayout from '@components/MainLayout'
import { useRoutes } from 'react-router-dom'
import { ProtectedRoute } from './protectedRoute'
import Login from '@pages/login'

import UnauthorizedPage from '@pages/401'
//admin
import AdminUserList from '@pages/admin/user-list'
import UserForm from '@pages/admin/user-form'
import DutyForm from '@pages/admin/duty/form'
import DutyList from '@pages/admin/duty/all-record'

//#region m1
//EIA
import EIADashboard from '@pages/module_1/EIA-Form/dashboard'
import EIAMyRecord from '@pages/module_1/EIA-Form/my-action'
import EIAAllRecord from '@pages/module_1/EIA-Form/all-record'
import EIAForm from '@pages/module_1/EIA-Form/form'
import EIAOverview from '@pages/module_1/EIA-Form/overview'
// EI
import EIDashboard from '@pages/module_1/EI-Form/dashboard'
import EIMyRecord from '@pages/module_1/EI-Form/my-action'
import EIAllRecord from '@pages/module_1/EI-Form/all-record'
import EIForm from '@pages/module_1/EI-Form/form'
import EIOverview from '@pages/module_1/EI-Form/overview'
//#endregion

// NF
import NFDashboard from '@pages/module_1/NF-Form/dashboard'
import NFMyRecord from '@pages/module_1/NF-Form/my-action'
import NFAllRecord from '@pages/module_1/NF-Form/all-record'
import NFForm from '@pages/module_1/NF-Form/form'
import NFOverview from '@pages/module_1/NF-Form/overview'

// DN
import DNDashboard from '@pages/module_1/DN-Form/dashboard'
import DNMyRecord from '@pages/module_1/DN-Form/my-action'
import DNAllRecord from '@pages/module_1/DN-Form/all-record'
import DNForm from '@pages/module_1/DN-Form/form'
import DNOverview from '@pages/module_1/DN-Form/overview'

//#region m2
// NEA
import NEAForm from '@pages/module_2/NEA-Form/form'
import NEAOverview from '@pages/module_2/NEA-Form/overview'
import NEAAllRecord from '@pages/module_2/NEA-Form/all-record'
import NEAMyRecord from '@pages/module_2/NEA-Form/my-action'
import NEADashboard from '@pages/module_2/NEA-Form/dashboard'

// EA
import EAForm from '@pages/module_2/EA-Form/form'
import EAOverview from '@pages/module_2/EA-Form/overview'
import EAAllRecord from '@pages/module_2/EA-Form/all-record'
import EAMyRecord from '@pages/module_2/EA-Form/my-action'
import EADashboard from '@pages/module_2/EA-Form/dashboard'

// DEA
import DEAForm from '@pages/module_2/DEA-Form/form'
import DEAOverview from '@pages/module_2/DEA-Form/overview'
import DEAAllRecord from '@pages/module_2/DEA-Form/all-record'
import DEAMyRecord from '@pages/module_2/DEA-Form/my-action'
import DEADashboard from '@pages/module_2/DEA-Form/dashboard'

// QS
import QSForm from '@pages/module_2/QS-Form/form'
import QSOverview from '@pages/module_2/QS-Form/overview'
import QSAllRecord from '@pages/module_2/QS-Form/all-record'
import QSMyRecord from '@pages/module_2/QS-Form/my-action'
import QSDashboard from '@pages/module_2/QS-Form/dashboard'
//#endregion

//#region m3
// CDR
import CDRDashboard from '@pages/module_3/CDR-Form/dashboard'
import CDRMyRecord from '@pages/module_3/CDR-Form/my-action'
import CDRAllRecord from '@pages/module_3/CDR-Form/all-record'
import CDRForm from '@pages/module_3/CDR-Form/form'
import CDROverview from '@pages/module_3/CDR-Form/overview'

// SD
import SDDashboard from '@pages/module_3/SD-Form/dashboard'
import SDMyRecord from '@pages/module_3/SD-Form/my-action'
import SDAllRecord from '@pages/module_3/SD-Form/all-record'
import SDForm from '@pages/module_3/SD-Form/form'
import SDPForm from '@pages/module_3/SD-Form/SDP-Form/form'
import SDOverview from '@pages/module_3/SD-Form/overview'

// SCR
import SCRDashboard from '@pages/module_3/SCR-Form/dashboard'
import SCRMyRecord from '@pages/module_3/SCR-Form/my-action'
import SCRAllRecord from '@pages/module_3/SCR-Form/all-record'
import SCRForm from '@pages/module_3/SCR-Form/form'
import SCRPForm from '@pages/module_3/SCR-Form/SCRP-Form/form'
import SCROverview from '@pages/module_3/SCR-Form/overview'

//#endregion

//#region m4
// HWR
import HWRForm from '@pages/module_4/HWR-Form/form'
import HWRDashboard from '@pages/module_4/HWR-Form/dashboard'
import HWRMyRecord from '@pages/module_4/HWR-Form/my-action'
import HWRAllRecord from '@pages/module_4/HWR-Form/all-record'
import HWROverview from '@pages/module_4/HWR-Form/overview'
import Notification from '@pages/notifications'

//#endregion

//#region m5
// TWO
import TWODashboard from '@pages/module_5/TWO-Form/dashboard'
import TWOMyRecord from '@pages/module_5/TWO-Form/my-action'
import TWOAllRecord from '@pages/module_5/TWO-Form/all-record'
import TWOForm from '@pages/module_5/TWO-Form/form'
import TWOOverview from '@pages/module_5/TWO-Form/overview'

// WOI
import WOIDashboard from '@pages/module_5/WOI-Form/dashboard'
import WOIMyRecord from '@pages/module_5/WOI-Form/my-action'
import WOIAllRecord from '@pages/module_5/WOI-Form/all-record'
import WOIForm from '@pages/module_5/WOI-Form/form'
import WOIOverview from '@pages/module_5/WOI-Form/overview'

// WOU
import WOUDashboard from '@pages/module_5/WOU-Form/dashboard'
import WOUMyRecord from '@pages/module_5/WOU-Form/my-action'
import WOUAllRecord from '@pages/module_5/WOU-Form/all-record'
import WOUForm from '@pages/module_5/WOU-Form/form'
import WOUOverview from '@pages/module_5/WOU-Form/overview'

//#endregion

//#region m6
// CR
import CRDashboard from '@pages/module_6/CR-Form/dashboard'
import CRMyRecord from '@pages/module_6/CR-Form/my-action'
import CRAllRecord from '@pages/module_6/CR-Form/all-record'
import CRForm from '@pages/module_6/CR-Form/form'
import CROverview from '@pages/module_6/CR-Form/overview'

//#region m7
// DNA
import DNADashboard from '@pages/module_7/DNA-Form/dashboard'
import DNAMyRecord from '@pages/module_7/DNA-Form/my-action'
import DNAAllRecord from '@pages/module_7/DNA-Form/all-record'
import DNAForm from '@pages/module_7/DNA-Form/form'
import DNAOverview from '@pages/module_7/DNA-Form/overview'

import Landing from '@pages/landing'
// settings
import Settings from '@pages/settings'
import ContractList from '@pages/settings/contracts/contracts-list'
import ContractForm from '@pages/settings/contracts/contracts-form'
import TeamList from '@pages/settings/teams/teams-list'
import TeamForm from '@pages/settings/teams/teams-form'
import DistrictList from '@pages/settings/districts/districts-list'
import DistrictForm from '@pages/settings/districts/districts-form'
import { UserTraining } from '@pages/usertraining'

//#endregion
import { useContext } from 'react'
import { GlobalContext } from '@providers/globalStore'
import { RightsCategory } from '@services/model/form/form.model'
import WorkGroupsList from '@pages/settings/workgroups/workgroups-list'
import WorkGroupsForm from '@pages/settings/workgroups/workgroups-form'
import IowWorkOrderList from '@pages/settings/worksOrder/worksOrder-list'
import SetupPage from '@pages/setup'

function MainRoutes() {
  const { hasRightByCatAndCode } = useContext(GlobalContext)
  const routeResult = useRoutes([
    {
      path: '/',
      element: <ProtectedRoute children={<SetupPage />} />,
    },
    {
      path: '/setup',
      element: <ProtectedRoute children={<SetupPage />} />,
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        {
          path: '/landing',
          element: <ProtectedRoute children={<Landing />} />,
        },
        {
          path: '/admin',
          children: [
            {
              path: '/admin',
              element: (
                <ProtectedRoute
                  children={<AdminUserList />}
                  permision={hasRightByCatAndCode(RightsCategory.FORM_USER, ['C', 'R', 'U', 'D'])}
                />
              ),
            },
            {
              path: '/admin/user',
              element: (
                <ProtectedRoute
                  children={<UserForm />}
                  permision={hasRightByCatAndCode(RightsCategory.FORM_USER, ['C', 'R', 'U', 'D'])}
                />
              ),
            },
            {
              path: '/admin/user/:id',
              element: (
                <ProtectedRoute
                  children={<UserForm />}
                  permision={hasRightByCatAndCode(RightsCategory.FORM_USER, ['C', 'R', 'U', 'D'])}
                />
              ),
            },
            {
              path: '/admin/duty/all-record',
              element: (
                <ProtectedRoute
                  children={<DutyList />}
                  permision={hasRightByCatAndCode(RightsCategory.FORM_DUTY, ['C', 'R', 'U', 'D'])}
                />
              ),
            },
            {
              path: '/admin/duty',
              element: (
                <ProtectedRoute
                  children={<DutyForm />}
                  permision={hasRightByCatAndCode(RightsCategory.FORM_DUTY, ['C', 'R', 'U', 'D'])}
                />
              ),
            },
            {
              path: '/admin/duty/:id',
              element: (
                <ProtectedRoute
                  children={<DutyForm />}
                  permision={hasRightByCatAndCode(RightsCategory.FORM_DUTY, ['C', 'R', 'U', 'D'])}
                />
              ),
            },
          ],
        },
        {
          path: '/settings',
          children: [
            {
              path: '/settings',
              element: <ProtectedRoute children={<Settings />} />,
            },
            {
              path: '/settings/contracts/list',
              element: <ProtectedRoute children={<ContractList />} />,
            },
            {
              path: '/settings/contracts',
              element: <ProtectedRoute children={<ContractForm />} />,
            },
            {
              path: '/settings/contracts/:id',
              element: <ProtectedRoute children={<ContractForm />} />,
            },
            {
              path: '/settings/teams/list',
              element: <ProtectedRoute children={<TeamList />} />,
            },
            {
              path: '/settings/teams/',
              element: <ProtectedRoute children={<TeamForm />} />,
            },
            {
              path: '/settings/teams/:id',
              element: <ProtectedRoute children={<TeamForm />} />,
            },
            {
              path: '/settings/districts/list',
              element: <ProtectedRoute children={<DistrictList />} />,
            },
            {
              path: '/settings/districts/',
              element: <ProtectedRoute children={<DistrictForm />} />,
            },
            {
              path: '/settings/districts/:id',
              element: <ProtectedRoute children={<DistrictForm />} />,
            },
            {
              path: '/settings/workgroups/list',
              element: <ProtectedRoute children={<WorkGroupsList />} />,
            },
            {
              path: '/settings/workgroups',
              element: <ProtectedRoute children={<WorkGroupsForm />} />,
            },
            {
              path: '/settings/workgroups/:id',
              element: <ProtectedRoute children={<WorkGroupsForm />} />,
            },
            {
              path: '/settings/iow/workorders',
              element: <ProtectedRoute children={<IowWorkOrderList />} />,
            },
          ],
        },
        {
          path: '/user-training',
          element: <ProtectedRoute children={<UserTraining />} />,
        },
        {
          path: '/engineer-inspection-assignment',
          children: [
            {
              path: '/engineer-inspection-assignment',
              element: <ProtectedRoute children={<EIAForm />} />,
            },
            {
              path: '/engineer-inspection-assignment/:id',
              element: <ProtectedRoute children={<EIAForm />} />,
            },
            {
              path: '/engineer-inspection-assignment/overview',
              element: <ProtectedRoute children={<EIAOverview />} />,
            },
            {
              path: '/engineer-inspection-assignment/dashboard',
              element: <ProtectedRoute children={<EIADashboard />} />,
            },
            {
              path: '/engineer-inspection-assignment/my-action',
              element: <ProtectedRoute children={<EIAMyRecord />} />,
            },
            {
              path: '/engineer-inspection-assignment/all-record',
              element: <ProtectedRoute children={<EIAAllRecord />} />,
            },
          ],
        },
        {
          path: '/engineer-inspection',
          children: [
            {
              path: '/engineer-inspection',
              element: <ProtectedRoute children={<EIForm />} />,
            },
            {
              path: '/engineer-inspection/:id',
              element: <ProtectedRoute children={<EIForm />} />,
            },
            {
              path: '/engineer-inspection/overview',
              element: <ProtectedRoute children={<EIOverview />} />,
            },
            {
              path: '/engineer-inspection/dashboard',
              element: <ProtectedRoute children={<EIDashboard />} />,
            },
            {
              path: '/engineer-inspection/my-action',
              element: <ProtectedRoute children={<EIMyRecord />} />,
            },
            {
              path: '/engineer-inspection/all-record',
              element: <ProtectedRoute children={<EIAllRecord />} />,
            },
          ],
        },

        {
          path: '/notification-form-of-defect',
          children: [
            {
              path: '/notification-form-of-defect',
              element: <ProtectedRoute children={<NFForm />} />,
            },
            {
              path: '/notification-form-of-defect/:id',
              element: <ProtectedRoute children={<NFForm />} />,
            },
            {
              path: '/notification-form-of-defect/overview',
              element: <ProtectedRoute children={<NFOverview />} />,
            },
            {
              path: '/notification-form-of-defect/dashboard',
              element: <ProtectedRoute children={<NFDashboard />} />,
            },
            {
              path: '/notification-form-of-defect/my-action',
              element: <ProtectedRoute children={<NFMyRecord />} />,
            },
            {
              path: '/notification-form-of-defect/all-record',
              element: <ProtectedRoute children={<NFAllRecord />} />,
            },
          ],
          // notification-form-of-defect NFForm
        },
        {
          path: '/default-notice-for-non-compliance',
          children: [
            {
              path: '/default-notice-for-non-compliance',
              element: <ProtectedRoute children={<DNForm />} />,
            },
            {
              path: '/default-notice-for-non-compliance/:id',
              element: <ProtectedRoute children={<DNForm />} />,
            },
            {
              path: '/default-notice-for-non-compliance/overview',
              element: <ProtectedRoute children={<DNOverview />} />,
            },
            {
              path: '/default-notice-for-non-compliance/dashboard',
              element: <ProtectedRoute children={<DNDashboard />} />,
            },
            {
              path: '/default-notice-for-non-compliance/my-action',
              element: <ProtectedRoute children={<DNMyRecord />} />,
            },
            {
              path: '/default-notice-for-non-compliance/all-record',
              element: <ProtectedRoute children={<DNAllRecord />} />,
            },
          ],
          // default-notice-for-non-compliance
        },
        {
          path: '/notification-of-engineer-audit',
          children: [
            {
              path: '/notification-of-engineer-audit',
              element: <ProtectedRoute children={<NEAForm />} />,
            },
            {
              path: '/notification-of-engineer-audit/:id',
              element: <ProtectedRoute children={<NEAForm />} />,
            },
            {
              path: '/notification-of-engineer-audit/overview',
              element: <ProtectedRoute children={<NEAOverview />} />,
            },
            {
              path: '/notification-of-engineer-audit/dashboard',
              element: <ProtectedRoute children={<NEADashboard />} />,
            },
            {
              path: '/notification-of-engineer-audit/my-action',
              element: <ProtectedRoute children={<NEAMyRecord />} />,
            },
            {
              path: '/notification-of-engineer-audit/all-record',
              element: <ProtectedRoute children={<NEAAllRecord />} />,
            },
          ],
        },
        {
          path: '/engineer-audit',
          children: [
            {
              path: '/engineer-audit',
              element: <ProtectedRoute children={<EAForm />} />,
            },
            {
              path: '/engineer-audit/:id',
              element: <ProtectedRoute children={<EAForm />} />,
            },
            {
              path: '/engineer-audit/overview',
              element: <ProtectedRoute children={<EAOverview />} />,
            },
            {
              path: '/engineer-audit/dashboard',
              element: <ProtectedRoute children={<EADashboard />} />,
            },
            {
              path: '/engineer-audit/my-action',
              element: <ProtectedRoute children={<EAMyRecord />} />,
            },
            {
              path: '/engineer-audit/all-record',
              element: <ProtectedRoute children={<EAAllRecord />} />,
            },
          ],
        },
        {
          path: '/defect-identified-in-engineer-audit',
          children: [
            {
              path: '/defect-identified-in-engineer-audit',
              element: <ProtectedRoute children={<DEAForm />} />,
            },
            {
              path: '/defect-identified-in-engineer-audit/:id',
              element: <ProtectedRoute children={<DEAForm />} />,
            },
            {
              path: '/defect-identified-in-engineer-audit/overview',
              element: <ProtectedRoute children={<DEAOverview />} />,
            },
            {
              path: '/defect-identified-in-engineer-audit/dashboard',
              element: <ProtectedRoute children={<DEADashboard />} />,
            },
            {
              path: '/defect-identified-in-engineer-audit/my-action',
              element: <ProtectedRoute children={<DEAMyRecord />} />,
            },
            {
              path: '/defect-identified-in-engineer-audit/all-record',
              element: <ProtectedRoute children={<DEAAllRecord />} />,
            },
          ],
        },
        {
          path: '/quarterly-summary',
          children: [
            {
              path: '/quarterly-summary',
              element: <ProtectedRoute children={<QSForm />} />,
            },
            {
              path: '/quarterly-summary/:id',
              element: <ProtectedRoute children={<QSForm />} />,
            },
            {
              path: '/quarterly-summary/overview',
              element: <ProtectedRoute children={<QSOverview />} />,
            },
            {
              path: '/quarterly-summary/dashboard',
              element: <ProtectedRoute children={<QSDashboard />} />,
            },
            {
              path: '/quarterly-summary/my-action',
              element: <ProtectedRoute children={<QSMyRecord />} />,
            },
            {
              path: '/quarterly-summary/all-record',
              element: <ProtectedRoute children={<QSAllRecord />} />,
            },
          ],
        },
        {
          path: '/contractor-daily-report',
          children: [
            {
              path: '/contractor-daily-report',
              element: <ProtectedRoute children={<CDRForm />} />,
            },
            {
              path: '/contractor-daily-report/:id',
              element: <ProtectedRoute children={<CDRForm />} />,
            },
            {
              path: '/contractor-daily-report/overview',
              element: <ProtectedRoute children={<CDROverview />} />,
            },
            {
              path: '/contractor-daily-report/dashboard',
              element: <ProtectedRoute children={<CDRDashboard />} />,
            },
            {
              path: '/contractor-daily-report/my-action',
              element: <ProtectedRoute children={<CDRMyRecord />} />,
            },
            {
              path: '/contractor-daily-report/all-record',
              element: <ProtectedRoute children={<CDRAllRecord />} />,
            },
          ],
        },
        {
          path: '/site-diary',
          children: [
            {
              path: '/site-diary',
              element: <ProtectedRoute children={<SDForm />} />,
            },
            {
              path: '/site-diary/photo',
              element: <ProtectedRoute children={<SDPForm />} />,
            },
            {
              path: '/site-diary/:id',
              element: <ProtectedRoute children={<SDForm />} />,
            },
            {
              path: '/site-diary/:id/photo',
              element: <ProtectedRoute children={<SDPForm />} />,
            },
            {
              path: '/site-diary/overview',
              element: <ProtectedRoute children={<SDOverview />} />,
            },
            {
              path: '/site-diary/dashboard',
              element: <ProtectedRoute children={<SDDashboard />} />,
            },
            {
              path: '/site-diary/my-action',
              element: <ProtectedRoute children={<SDMyRecord />} />,
            },
            {
              path: '/site-diary/all-record',
              element: <ProtectedRoute children={<SDAllRecord />} />,
            },
          ],
        },
        {
          path: '/site-check-record',
          children: [
            {
              path: '/site-check-record',
              element: <ProtectedRoute children={<SCRForm />} />,
            },
            {
              path: '/site-check-record/:id',
              element: <ProtectedRoute children={<SCRForm />} />,
            },
            {
              path: '/site-check-record/:id/photo',
              element: <ProtectedRoute children={<SCRPForm />} />,
            },
            {
              path: '/site-check-record/overview',
              element: <ProtectedRoute children={<SCROverview />} />,
            },
            {
              path: '/site-check-record/dashboard',
              element: <ProtectedRoute children={<SCRDashboard />} />,
            },
            {
              path: '/site-check-record/my-action',
              element: <ProtectedRoute children={<SCRMyRecord />} />,
            },
            {
              path: '/site-check-record/all-record',
              element: <ProtectedRoute children={<SCRAllRecord />} />,
            },
          ],
        },
        {
          path: '/hidden-works-record',
          children: [
            {
              path: '/hidden-works-record',
              element: <ProtectedRoute children={<HWRForm />} />,
            },
            {
              path: '/hidden-works-record/:id',
              element: <ProtectedRoute children={<HWRForm />} />,
            },
            {
              path: '/hidden-works-record/overview',
              element: <ProtectedRoute children={<HWROverview />} />,
            },
            {
              path: '/hidden-works-record/dashboard',
              element: <ProtectedRoute children={<HWRDashboard />} />,
            },
            {
              path: '/hidden-works-record/my-action',
              element: <ProtectedRoute children={<HWRMyRecord />} />,
            },
            {
              path: '/hidden-works-record/all-record',
              element: <ProtectedRoute children={<HWRAllRecord />} />,
            },
          ],
        },
        {
          path: '/temporary-works-order',
          children: [
            {
              path: '/temporary-works-order',
              element: <ProtectedRoute children={<TWOForm />} />,
            },
            {
              path: '/temporary-works-order/:id',
              element: <ProtectedRoute children={<TWOForm />} />,
            },
            {
              path: '/temporary-works-order/overview',
              element: <ProtectedRoute children={<TWOOverview />} />,
            },
            {
              path: '/temporary-works-order/dashboard',
              element: <ProtectedRoute children={<TWODashboard />} />,
            },
            {
              path: '/temporary-works-order/my-action',
              element: <ProtectedRoute children={<TWOMyRecord />} />,
            },
            {
              path: '/temporary-works-order/all-record',
              element: <ProtectedRoute children={<TWOAllRecord />} />,
            },
          ],
        },
        {
          path: '/works-order-information',
          children: [
            {
              path: '/works-order-information',
              element: <ProtectedRoute children={<WOIForm />} />,
            },
            {
              path: '/works-order-information/:id',
              element: <ProtectedRoute children={<WOIForm />} />,
            },
            {
              path: '/works-order-information/overview',
              element: <ProtectedRoute children={<WOIOverview />} />,
            },
            {
              path: '/works-order-information/dashboard',
              element: <ProtectedRoute children={<WOIDashboard />} />,
            },
            {
              path: '/works-order-information/my-action',
              element: <ProtectedRoute children={<WOIMyRecord />} />,
            },
            {
              path: '/works-order-information/all-record',
              element: <ProtectedRoute children={<WOIAllRecord />} />,
            },
          ],
        },
        {
          path: '/works-order-update',
          children: [
            {
              path: '/works-order-update',
              element: <ProtectedRoute children={<WOUForm />} />,
            },
            {
              path: '/works-order-update/:id',
              element: <ProtectedRoute children={<WOUForm />} />,
            },
            {
              path: '/works-order-update/overview',
              element: <ProtectedRoute children={<WOUOverview />} />,
            },
            {
              path: '/works-order-update/dashboard',
              element: <ProtectedRoute children={<WOUDashboard />} />,
            },
            {
              path: '/works-order-update/my-action',
              element: <ProtectedRoute children={<WOUMyRecord />} />,
            },
            {
              path: '/works-order-update/all-record',
              element: <ProtectedRoute children={<WOUAllRecord />} />,
            },
          ],
        },
        {
          path: '/completion-report',
          children: [
            {
              path: '/completion-report',
              element: <ProtectedRoute children={<CRForm />} />,
            },
            {
              path: '/completion-report/:id',
              element: <ProtectedRoute children={<CRForm />} />,
            },
            {
              path: '/completion-report/overview',
              element: <ProtectedRoute children={<CROverview />} />,
            },
            {
              path: '/completion-report/dashboard',
              element: <ProtectedRoute children={<CRDashboard />} />,
            },
            {
              path: '/completion-report/my-action',
              element: <ProtectedRoute children={<CRMyRecord />} />,
            },
            {
              path: '/completion-report/all-record',
              element: <ProtectedRoute children={<CRAllRecord />} />,
            },
          ],
        },
        {
          path: '/application-of-default-notice-for-non-compliance',
          children: [
            {
              path: '/application-of-default-notice-for-non-compliance',
              element: <ProtectedRoute children={<DNAForm />} />,
            },
            {
              path: '/application-of-default-notice-for-non-compliance/:id',
              element: <ProtectedRoute children={<DNAForm />} />,
            },
            {
              path: '/application-of-default-notice-for-non-compliance/overview',
              element: <ProtectedRoute children={<DNAOverview />} />,
            },
            {
              path: '/application-of-default-notice-for-non-compliance/dashboard',
              element: <ProtectedRoute children={<DNADashboard />} />,
            },
            {
              path: '/application-of-default-notice-for-non-compliance/my-action',
              element: <ProtectedRoute children={<DNAMyRecord />} />,
            },
            {
              path: '/application-of-default-notice-for-non-compliance/all-record',
              element: <ProtectedRoute children={<DNAAllRecord />} />,
            },
          ],
        },
        {
          path: '/notifications',
          element: <ProtectedRoute children={<Notification />} />,
        },
      ],
    },
    {
      path: '/401',
      element: <UnauthorizedPage />,
    },
  ])

  return routeResult
}

export default MainRoutes
