import { BaseModel } from './base.model'
import { FormModel, InitFormModel } from './form/form.model'
import { WorkGroup } from './workGroup.model'

export interface ContractNo {
  id: number
  contractNoUUID: string
  contractNo: string
  client: string
  contractor: string
  clientOrganizationId: number
  contractorOrganizationId: number
  contractTitle: string
  clientOrganization: string
  contractorOrganization: string
  workOrderList?: WorkOrder[]
  workGroups?: WorkGroup[]
  highSpeedRoad: boolean
}

export const InitContractNo: ContractNo = {
  id: 0,
  contractNoUUID: '',
  contractNo: '',
  client: '',
  contractor: '',
  clientOrganizationId:  0,
  contractorOrganizationId:  0,
  contractTitle: '',
  clientOrganization: '',
  contractorOrganization: '',
  highSpeedRoad: false
}

export interface WorkOrder extends BaseModel {
  BaseForm: FormModel

  id: number
  formId: number
  workOrderNo: number
  mainsWorkOrderId?: number
  location?: string
  teamId?: number[]
  typeOfWorks: boolean
  activeStatus: boolean
  commencedDate?: Date
  completedDate?: Date
  crLock?: boolean

  workActivities: WorkActivity[]
  workActivityDetails: WorkActivityDetail[]

  coveringWorkOrder?: boolean
  districtId?: number[]
  isLinkWithTempWorkOrder?: boolean
}

export const InitWorkOrder: WorkOrder = {
  BaseForm: { ...InitFormModel },
  id: 0,
  formId: 0,
  workOrderNo: 0,
  typeOfWorks: false,
  activeStatus: false,
  workActivities: [],
  workActivityDetails: [],
  status: false,
  createdBy: '',
  createdAt: new Date(),
}

export interface WorkActivity extends BaseModel {
  id: number
  formCdrId: number
  workOrderId: number
  siteActive: boolean
  idlingReason?: string
}

export const InitWorkActivity: WorkActivity = {
  id: 0,
  formCdrId: 0,
  workOrderId: 0,
  siteActive: false,
  status: false,
  createdBy: '',
  createdAt: new Date(),
}

export interface WorkActivityDetail extends BaseModel {
  id: number
  status: boolean

  roadNamenLocDetails: string
  proposedActivity: string
  noOfWorker?: number
  lsgOnSite?: boolean
  essentialOperation?: boolean
  hiddenWorkOtherThanLsg?: boolean
  inSituTest?: boolean
  dayNightWorks?: number // not in non m&m works
  remarks?: string
}

export const InitWorkActivityDetail: WorkActivityDetail = {
  id: 0,
  status: true,
  roadNamenLocDetails: '',
  proposedActivity: '',
  // noOfWorkers: undefined,
  // lsgOnSite: false,
  // dayNightWorks: false,
  createdBy: '',
  createdAt: new Date(),
}

export interface WorkOrderList{
    id: number
    workOrderNo: string
    contractNoId: number
    districtId: number[]
    teamId: number[]
    typeOfWorks?: boolean

    // TWO specific
    coveringWorkOrder?: boolean
    isLinkWithTempWorkOrder?: boolean

    // CR specific
    crLock?: boolean
}

export interface IowWorkOrder {
  workOrderNo: string
  contractNoId: number
  districtId: number[]
  teamId: number[]
  iowEmail: string
}