import SingleDatasetChart from '@components/chart/SingleDatasetChart'
import FormActivityLogBox from '@components/form/overview/activityLog/activityLogBox'
import { formIconProps } from '@components/form/overview/icon'
import FormIntroductionBox, { formBriefInfo } from '@components/form/overview/introductionBox'
import { Card, Grid } from '@mui/material'
import { getFormColor } from '@utils/getFormColor'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import { NavigateTo } from '@utils/navigate'
import { useNavigate } from 'react-router-dom'
//TODO: remove later
import SDInfo from '../../../mockData/fetchData/formInfo/form.SD.json'
import EILogs from '../../../mockData/fetchData/activityLog/form.EI.json'
import EIStatus from '../../../mockData/fetchData/chart/status.json'
import EIByApproval1 from '../../../mockData/fetchData/chart/byApproval1.json'
import EIByApproval2 from '../../../mockData/fetchData/chart/byApproval2.json'
import EIByDate from '../../../mockData/fetchData/chart/byDate.json'
import MultiTypeDatasetChart, {
  generalChartDatasetProps,
} from '@components/chart/MultiTypeDatasetChart'
import { GlobalContext } from '@providers/globalStore'
import { RightsCategory } from '@services/model/form/form.model'

const StyledCard = styled(Card)(({ theme }) => ({
  margin: theme.spacing(2),
  padding: theme.spacing(2),
}))

export default function Overview({ isDashboard }: { isDashboard?: boolean }) {
  const { t } = useTranslation('dashboard')
  const navigate = useNavigate()
  const { hasRightByCatAndCode } = useContext(GlobalContext)
  //TODO: get form info
  const [formInfo, setFormInfo] = useState(SDInfo)

  //form introduction
  const fIconProps: formIconProps = {
    shortName: formInfo.shortName,
    shortNameBgColor: getFormColor(formInfo.shortName, formInfo.module),
    SvgSx: {
      fontSize: '70px',
    },
  }

  const fInfo: formBriefInfo = {
    id: formInfo.id,
    workflowId: formInfo.workflowId,
    name: formInfo.name,
    description: formInfo.description,
    workflowInvolvement: formInfo.workflowInvolvement,
    linkedLists: formInfo.linkedLists,
  }

  //TODO: implement create form function
  const fOnClickSideBtnHandler: Function = () => {
    NavigateTo(navigate, '/site-diary')
  }

  //form activity log
  //TODO: get log from api
  const [logs, setLog] = useState(EILogs)

  //TODO: fake data
  let mutliTypeDatasetChartData: generalChartDatasetProps[] = []
  for (const key in EIByDate) {
    let obj: generalChartDatasetProps = {
      type: EIByDate[key].setting?.type ? EIByDate[key].setting?.type : 'bar',
      label: key,
      data: EIByDate[key].data,
      yAxisID: EIByDate[key].setting?.type === 'line' ? 'y1' : 'y',
      borderColor: EIByDate[key].setting?.borderColor,
      backgroundColor: EIByDate[key].setting?.backgroundColor,
    }
    mutliTypeDatasetChartData.push(obj)
  }

  let multiTypeDatasetChartOptions = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        type: 'linear' as const,
        display: true,
        position: 'left' as const,
      },
      y1: {
        type: 'linear' as const,
        display: true,
        position: 'right' as const,
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        {!isDashboard && (
          <StyledCard variant="outlined">
            <FormIntroductionBox
              formIconProps={fIconProps}
              formBriefInfo={fInfo}
              hasSideBtn={true}
              disableSideBtn={!hasRightByCatAndCode(RightsCategory.FORM_SD, ['C'], true)}
              onClickSideBtnHandler={fOnClickSideBtnHandler}
            />
          </StyledCard>
        )}
        <StyledCard variant="outlined">
          <MultiTypeDatasetChart
            eleBefore={
              <>
                <h4>{t('by-date')}</h4>
                <span>{t('count/running-total')}</span>
              </>
            }
            chartWrapperSX={{
              width: '75%',
              margin: 'auto',
            }}
            datasets={mutliTypeDatasetChartData}
            options={multiTypeDatasetChartOptions}></MultiTypeDatasetChart>
          <SingleDatasetChart
            eleBefore={
              <>
                <h4>{t('by-approval')}</h4>
              </>
            }
            chartWrapperSX={{
              width: '75%',
              margin: 'auto',
            }}
            type={'line'}
            data={EIByApproval1}
            dataConfig={{
              borderColor: 'rgb(50, 50, 150)',
              borderWidth: 2,
              backgroundColor: 'rgba(50, 50, 150, .2)',
            }}
            options={{
              plugins: {
                legend: {
                  display: false,
                },
              },
              scales: {
                y: {
                  title: {
                    display: true,
                    text: 'Percentage',
                  },
                },
              },
            }}></SingleDatasetChart>
          <SingleDatasetChart
            chartWrapperSX={{
              width: '75%',
              margin: 'auto',
            }}
            type={'line'}
            data={EIByApproval2}
            dataConfig={{
              borderColor: 'rgb(50, 150, 50)',
              borderWidth: 2,
              backgroundColor: 'rgba(50, 150, 50, .2)',
            }}
            options={{
              plugins: {
                legend: {
                  display: false,
                },
              },
            }}></SingleDatasetChart>
        </StyledCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <StyledCard variant="outlined">
          <SingleDatasetChart
            chartWrapperSX={{
              width: '75%',
              margin: 'auto',
            }}
            type={'doughnut'}
            hasSummaryTable={true}
            data={EIStatus}
            dataConfig={{
              borderColor: [
                'rgb(11, 100, 214)',
                'rgb(245, 115, 113)',
                'rgb(122, 156, 214)',
                'rgb(150, 214, 186)',
              ],
              borderWidth: 2,
              backgroundColor: [
                'rgb(11, 100, 214)',
                'rgb(245, 115, 113)',
                'rgb(122, 156, 214)',
                'rgb(150, 214, 186)',
              ],
            }}
            options={{
              plugins: {
                legend: {
                  display: true,
                  position: 'bottom',
                },
              },
            }}></SingleDatasetChart>
        </StyledCard>
        <StyledCard variant="outlined">
          <FormActivityLogBox logItems={logs}></FormActivityLogBox>
        </StyledCard>
      </Grid>
    </Grid>
  )
}
