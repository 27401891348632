import { Card } from '@mui/material'
import { Box, styled } from '@mui/system'

export const StyledCard = styled(Card)(({ theme }) => ({
    transition: "transform 0.15s ease-in-out",
    "&:hover": { transform: "scale3d(1.02, 1.02, 1)", cursor: "pointer"},
    border: "1px solid white",
    height: '180px',
    fontSize: '24px',
    fontWeight: "bold",
    display: 'flex',
    justifyContent : 'center',
    alignItems: 'center',   
    color: '#707070' 
}))