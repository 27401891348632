import FormContainer                 from '@components/form/container';
import { FormStatusEnum }            from '@services/model/form/form.model';
import { FormEaModel }               from '@services/model/form/form.EA.model';
import { t }                         from 'i18next';
import { Control, UseFormGetValues } from 'react-hook-form';
import uuid                          from 'react-uuid';
import FormLinkTable                 from '@components/form/linkTable';

const UUID = uuid();

export default ({
	                control,
	                formStatus,
	                getValues,
	                globalState,
	                handleLinkClick,
	                handleAddPhotoClick,
	                LargeMap
                }: {
	control: Control<FormEaModel>
	formStatus: string
	getValues: UseFormGetValues<FormEaModel>
	globalState: any
	handleLinkClick: (index: number) => void
	handleAddPhotoClick: () => void
	LargeMap: () => JSX.Element
}) => {

	const defectOptions =
			      globalState.generalOptionGroupList
			                 ?.find((x) => x.name === 'Form EA - Category')
			                 ?.generalOptions?.find((x) => x.id === getValues('formNea.typeOfMmWorks'))
			                 ?.defectOptions?.map((x) => {
				      return {
					      key  : x.id,
					      value: `${x.defectCode} - ${x.description}`
				      };
			      }) || [];

	return (
			<>
				<FormContainer fkey={ `${UUID}-ea-b` }>
					<LargeMap />
					<FormLinkTable
							columns={ [
								{
									field: 'reportNo',
									name : 'EA Defect No.',
									type : 'string'
								},
								{
									field  : 'defectsIdentified',
									name   : 'Defects Identified',
									type   : 'selected',
									options: defectOptions
								},
								{ field: 'images', name: 'Images', type: 'images' },
								{
									field       : 'baseForm.formStatusName',
									name        : 'Status',
									type        : 'string',
									mobileHidden: true
								}
							] }
							fieldArrayProps={ {
								control: control,
								name   : 'formDeaList'
							} }
							title='DEA Forms'
							onLinkClick={ handleLinkClick }
							appendField={ handleAddPhotoClick }
							uneditable={
									formStatus !== FormStatusEnum.FORM_EA_DRAFT &&
									formStatus !== FormStatusEnum.FORM_EA_PRELIM_RESULT_REJECTED
							} />
				</FormContainer>
			</>
	);
}
