import svgUrl            from '@images/export.svg'
import { Box }           from '@mui/material'
import { CSSProperties } from 'react'

interface iconProps {
	sx?: CSSProperties
}

const ExportIcon = (props: iconProps) => {
	return (
		<Box style={ { height: 20, width: 20, ...props.sx } }>
			<img src={ svgUrl } alt='road-icon' style={ { height: 'inherit', width: 'inherit' } } />
		</Box>
	)
}

export default ExportIcon
