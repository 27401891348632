import { useEffect } from 'react'
import 'leaflet/dist/leaflet.css'
import 'esri-leaflet-geocoder/dist/esri-leaflet-geocoder.css'
import { MapContainer, TileLayer, useMap } from 'react-leaflet'
import { LatLngLiteral } from 'leaflet'
import * as L from "leaflet";
import EsriLeafletGeoSearch from 'react-esri-leaflet/plugins/EsriLeafletGeoSearch'
import { styled, useTheme } from '@mui/material/styles'
import { FullscreenControl, Zoom } from './mapCoreControl'
import { GestureHandling } from 'leaflet-gesture-handling'
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css'
import { useMediaQuery } from '@mui/material'
import { FeatureLayer } from 'react-esri-leaflet'
import * as EL from "esri-leaflet";
import * as ELG from 'esri-leaflet-geocoder';

const StyledMapContainer = styled(MapContainer)<{
  state: {
    sx?: Record<string, any>
    disabled?: boolean
  }
}>(({ state }) => ({
  height: '100%',
  width: '100%',
  '.leaflet-control-attribution': {
    display: 'none',
  },
  '.leaflet-bottom': {
    display: 'flex',
    flexDirection: 'column-reverse',
    alignItems: 'flex-end',
  },
  '.fullscreen-icon': {
    backgroundImage: 'url(https://leaflet.github.io/Leaflet.fullscreen/dist/fullscreen.png)',
    backgroundPosition: '0px 0px',
    backgroundSize: 'cover',
  },
  '.fullscreen-icon.leaflet-fullscreen-on': {
    backgroundImage: 'url(https://leaflet.github.io/Leaflet.fullscreen/dist/fullscreen.png)',
    backgroundPosition: '0px -40px',
    backgroundSize: 'cover',
  },
  '.leaflet-control': {
    border: 'none',
  },
  '.leaflet-control-zoom': {
    opacity: 0.8,
  },
  '.leaflet-control-zoom-in': {
    borderTopLeftRadius: '12px !important',
    borderTopRightRadius: '12px !important',
  },
  '.leaflet-control-zoom-out': {
    borderBottomLeftRadius: '12px !important',
    borderBottomRightRadius: '12px !important',
  },
  '.leaflet-control-zoom-fullscreen': {
    width: '40px !important',
    height: '40px !important',
    borderRadius: '12px !important',
    boxShadow: '0 3px 3px rgb(0 0 0 / 16%)',
  },
  //TODO: remove this - do not render when move center
  '& .geocoder-control:not(:first-child)': {
    display: 'none',
  },
  ...(state.disabled && { pointerEvents: 'none' }),
  ...state.sx,
}))

//#region constants
const MAP_URL = `https://api.hkmapservice.gov.hk/osm/xyz/basemap/WGS84/tile/{z}/{x}/{y}.png?key=${process.env.REACT_APP_LAND_KEY}`
const MAP_LABEL_URL = `https://api.hkmapservice.gov.hk/osm/xyz/label-tc/WGS84/tile/{z}/{x}/{y}.png?key=${process.env.REACT_APP_LAND_KEY}`
const ARCGIS_PROVIDER = {
  token:
    'AAPKb51e793081f546ce93af3798dcdef61dmPEGmWV-YK4mq7A25UlWQQYI6HzYrQft4aDdGEWhlJx1p6GNRWHgb_iHWd6Bkj-8',
  label: 'ArcGIS Online Results',
  maxResults: 10,
}

//#endregion

interface mapCoreProps {
  children: any
  esriEventHandlers?: 
    | {
        requeststart?: Function | undefined
        requestend?: Function | undefined
        requestsuccess?: Function | undefined
        requesterror?: Function | undefined
        authenticationrequired?: Function | undefined
        results?: Function | undefined
      }
    | undefined
  zoom?: number
  minZoom?: number
  maxZoom?: number
  center?: LatLngLiteral
  sx?: Record<string, any>
  disabled?: boolean

  enableSearch?: boolean
}

const FormMapCore = (props: mapCoreProps) => {
  const theme = useTheme()
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))

  const GestureHandlingSetter = () => {
    const map = useMap() as any

    if (matchDownMd) {
      map.gestureHandling.enable()
      map.addHandler('gestureHandling', GestureHandling)
    }

    return null
  }

  const SearchMap = ( props: {esriEventHandlers?: 
    | {
        requeststart?: Function | undefined
        requestend?: Function | undefined
        requestsuccess?: Function | undefined
        requesterror?: Function | undefined
        authenticationrequired?: Function | undefined
        results?: Function | undefined
      }
    | undefined
  }) => {
    ELG.Suggest.prototype.params.key = process.env.REACT_APP_LAND_KEY;
    ELG.Geocode.prototype.params.key = process.env.REACT_APP_LAND_KEY;
    EL.Service.prototype.metadata = function(callback, context) {
      return this._request('get', '', {
        key: process.env.REACT_APP_LAND_KEY
      }, callback, context);
    }
    const map = useMap();
    const GEOCODE_SERVICE_PROVIDERS = [
      ELG.geocodeServiceProvider({
        url: `https://api.hkmapservice.gov.hk/ags/gc/ib1000/transportation/streetcentrelines`,
        label: 'Streets',
        maxResults: 15,
      }),
      ELG.geocodeServiceProvider({
        url: `https://api.hkmapservice.gov.hk/ags/gc/loc/address`,
        label: 'Address Point',
        maxResults: 15
      }),
      ELG.geocodeServiceProvider({
        url: `https://api.hkmapservice.gov.hk/ags/gc/ib1000/buildings/building`,
        label: 'Building',
        maxResults: 15,
      }),
      ELG.geocodeServiceProvider({
        url: `https://api.hkmapservice.gov.hk/ags/gc/ic1000/buildinglicence`,
        label: 'Building Licence',
        maxResults: 15,
      }),
      ELG.geocodeServiceProvider({
        url: `https://api.hkmapservice.gov.hk/ags/gc/loc/geocomm`,
        label: 'Geo Community',
        maxResults: 15,
      }),
      ELG.geocodeServiceProvider({
        url: `https://api.hkmapservice.gov.hk/ags/gc/ib5000/poi/placepoint`,
        label: 'Place',
        maxResults: 15,
      }),
      ELG.geocodeServiceProvider({
        url: `https://api.hkmapservice.gov.hk/ags/gc/ib5000/poi/poipoint`,
        label: 'POI',
        maxResults: 15,
      }),
      ELG.geocodeServiceProvider({
        url: `https://api.hkmapservice.gov.hk/ags/gc/ib1000/buildings/site`,
        label: 'Site',
        maxResults: 15,
      }),
      ELG.geocodeServiceProvider({
        url: `https://api.hkmapservice.gov.hk/ags/gc/ib1000/buildings/subsite`,
        label: 'SubSite',
        maxResults: 15,
      }),
      ELG.geocodeServiceProvider({
        url: `https://api.hkmapservice.gov.hk/ags/gc/ic1000/lot`,
        label: 'LOT',
        maxResults: 15,
      }),
      ELG.geocodeServiceProvider({
        url: `https://api.hkmapservice.gov.hk/ags/gc//ic1000/tenancypoly`,
        label: 'LOT',
        maxResults: 15,
      }),
      ELG.geocodeServiceProvider({
        url: `https://api.hkmapservice.gov.hk/ags/gc/ic1000/gla`,
        label: 'GLA',
        maxResults: 15,
      }),
      ELG.geocodeServiceProvider({
        url: `https://api.hkmapservice.gov.hk/ags/gc/ls/vacantgovsite`,
        label: 'VGS',
        maxResults: 15,
      }),
      ELG.geocodeServiceProvider({
        url: `https://api.hkmapservice.gov.hk/ags/gc/loc/streetintersection`,
        label: 'Streets Intersection',
        maxResults: 15,
      }),
      ELG.geocodeServiceProvider({
        url: `https://api.hkmapservice.gov.hk/ags/gc/sc/localcontrol`,
        label: 'Local Control',
        maxResults: 15,
      }),
      ELG.geocodeServiceProvider({
        url: `https://api.hkmapservice.gov.hk/ags/gc/sc/GeodeticHControl`,
        label: 'GeodeticHControl',
        maxResults: 15,
      }),
      ELG.geocodeServiceProvider({
        url: `https://api.hkmapservice.gov.hk/ags/gc/sc/GeodeticVControl`,
        label: 'GeodeticVControl',
        maxResults: 15,
      }),
      ELG.geocodeServiceProvider({
        url: `https://api.hkmapservice.gov.hk/ags/gc/ib1000/utilities/utilitypoint`,
        searchFields: [],
        label: 'Utility Point',
        maxResults: 50,
      }),
    ]

    const searchControl = new ELG.geosearch({
      collapseAfterResult: true,
      allowMultipleResults: true,
      providers: GEOCODE_SERVICE_PROVIDERS,
      useMapBounds: false,
    })
    map.addControl(searchControl)
    if(props.esriEventHandlers){
      const events = Object.keys(props.esriEventHandlers);
      console.log('results',props.esriEventHandlers["results"])
      events.forEach((event) => {
        searchControl.on(event, props.esriEventHandlers?.[event]);
      });
    }

    return null;
  }

  return (
    <StyledMapContainer
      state={{
        sx: props.sx,
        disabled: props.disabled,
      }}
      center={
        // TODO: auto re-focus without rerendering
        props.center ?? {
          lat: 22.3814682,
          lng: 114.187941,
        }
      }
      zoom={props.zoom ?? 18}
      minZoom={props.minZoom ?? 10}
      maxZoom={props.maxZoom ?? 18}
      zoomControl={false}
      scrollWheelZoom={false}
      // 👇️ ts-ignore ignores any ts errors on the next line
      // eslint-disable-next-line
      // @ts-ignore
      gestureHandling={true}
      gestureHandlingOptions={{
        text: {
          touch: 'Navigate with two fingers',
          scroll: 'Navigate with two fingers',
          scrollMac: 'Navigate with two fingers',
        },
      }}>

      <GestureHandlingSetter />
      {/* <TileLayer url={GEODATA_QUERY_URL} key="geodata-query" /> */}
      <TileLayer url={MAP_URL} key="basemap" />
      <TileLayer url={MAP_LABEL_URL} key="map-label" />
      <Zoom position="bottomright" />
      <FullscreenControl position="bottomright" />
      {props.enableSearch && (
        <SearchMap esriEventHandlers={props.esriEventHandlers}/>
      )}
      {props.children}
    </StyledMapContainer>
  )
}

export default FormMapCore
