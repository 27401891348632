import { useContext }                                                      from 'react'
import { useTranslation }                                                  from 'react-i18next'
import { Box, Divider, Grid, styled, Typography, useMediaQuery, useTheme } from '@mui/material'
import { GlobalContext }                                                   from '@providers/globalStore'
import LandingFormItem                                                     from '@components/landing/LandingFormItem'
import { menuItems }                                                       from '@components/sidebar/menuList'
import SelectContract                                                      from '@components/sidebar/selectContract'
import useLocalStorage                                                     from '@hooks/useLocalStorage'
import { UserInfo }                                                        from '@services/model/user.model'

const HeaderTitle = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    fontSize: 45,
  },
  [theme.breakpoints.down('md')]: {
    fontSize: 24,
  },
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontWeight: 'bold',
  marginTop: '0px',
}))

const HeaderSubtitle = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    fontSize: 27,
  },
  [theme.breakpoints.down('md')]: {
    fontSize: 24,
    margin: 8,
  },
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  marginBottom: '0px',
  fontWeight: 'bold',
}))

export default function LandingPage() {
  const { t } = useTranslation()
  const theme = useTheme()
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))
  const { state, dispatch } = useContext(GlobalContext)
  const { totalFormCountList = [] } = state

  const [loginUser, setLoginUser] = useLocalStorage<UserInfo | undefined>('usr', undefined)

  const menu = [...menuItems]

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'center',
        padding: matchDownMd ? '10px 10px' : '57px 80px',
      }}>
      <Box>
        <HeaderSubtitle hidden={matchDownMd}>{t('Welcome Back, ')}</HeaderSubtitle>
        <HeaderTitle hidden={matchDownMd}>{loginUser?.firstName}</HeaderTitle>
      </Box>
      <Box>
        <SelectContract uneditable={true}/>
      </Box>
      {menu.map((module) => {
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', paddingTop: '16px' }}>
            <HeaderSubtitle>{module.title}</HeaderSubtitle>
            <Typography>{module.caption}</Typography>
            <Divider sx={{ mb: '10px', mt: '10px' }} />
            <Grid container spacing={2}>
              {module.children
                .filter((x) => !x.hidden)
                .map((item) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={4}
                      key={`landing-form-item-${item.acronym}`}>
                      <LandingFormItem
                        formCode={item.acronym ?? ''}
                        totalCount={
                          totalFormCountList?.find((x) => x.formCode === item.acronym)
                            ?.totalCount ?? 0
                        }
                      />
                    </Grid>
                  )
                })}
            </Grid>
          </Box>
        )
      })}
    </Box>
  )
}
