import FormContainer from '@components/form/container'
import FormController from '@components/form/controller'
import FormField from '@components/form/field'
import { Control } from 'react-hook-form'
import { FormWoiModel, WoiGeneralOptions } from '@services/model/form/form.WOI.model'
import FormTypography from '@components/form/typography'
import { useTranslation } from 'react-i18next'

export default ({ control }: { control: Control<FormWoiModel> }) => {
  const { t } = useTranslation()

  return (
    <FormContainer fkey={`woi-k`}>
      <FormField fieldName="Date of 1st Contractor's Submission">
        <FormController
          controllerProps={{
            name: 'dateOfFirstContractorSubmission',
            control,
          }}>
          <FormTypography type="date" />
        </FormController>
      </FormField>
      <FormField fieldName="Number of Revisions Received">
        <FormController
          controllerProps={{
            name: 'numberOfRevisionsReceived',
            control,
          }}>
          <FormTypography />
        </FormController>
      </FormField>
      <FormField fieldName="Date of Dimension Book Acceptance">
        <FormController
          controllerProps={{
            name: 'dateOfDimensionBookAcceptance',
            control,
          }}>
          <FormTypography type="date" />
        </FormController>
      </FormField>
      {/* <FormField fieldName="Payment Recovery">
            <FormController
              controllerProps={{
                name: 'paymentRecovery',
                control,
              }}>
              <FormTypography type="binary" />
            </FormController>
          </FormField>
          <FormTable
            columns={[
              {
                field: 'dateOfFirstContractorApplicationForExemption',
                displayName: 'Date of 1st Contractor Application for Exemption',
                type: 'date',
              },
              {
                field: 'ectoApproval',
                displayName: 'E/CTO Approval',
                type: 'binarySelect',
              },
              {
                field: 'dateOfEctoApproval',
                displayName: 'Date of E/CTO Approval',
                type: 'date',
              },
            ]}
            fieldArrayProps={{
              name: 'applicationForExemptions',
              control,
            }}
            rowFilter={() => true}
            uneditable
            height={50}
          /> */}
    </FormContainer>
  )
}
