import { Autocomplete, TextField, Typography } from '@mui/material'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

interface BinaryOption {
  name: string
  value?: boolean
}

export interface BinaryOptionNames {
  yes: string
  no: string
  na?: string
}

interface formBinarySelectProps {
  value?: boolean
  error?: any
  onBlur?: () => void
  onChange?: (...event: any[]) => void
  fullWidth?: boolean
  variant?: 'standard' | 'filled' | 'outlined'
  binaryOptionNames?: BinaryOptionNames

  disabled?: boolean
  uneditable?: boolean
}

/**
 * form binary select wrapped with autocomplete
 * @param props formBinarySelectProps
 * @example
 * ```
 * <FormField fieldName="Contract No." index={0}>
 *      <FormController
 *          controllerProps={{
 *              name: 'contractNo',
 *              control: control,
 *          }}>
 *          <FormBinarySelect fullWidth />
 *      </FormController>
 *  </FormField>
 * ```
 */
function FormBinarySelect(props: formBinarySelectProps) {
  const { t } = useTranslation()

  if (!props.onChange) {
    return <>missing onChange from props</>
  }

  const handleChange = (_: any, newValue: BinaryOption | null) => {
    props.onChange!(newValue ? newValue.value : undefined)
  }

  const binaryOptions: BinaryOption[] = [
    {
      name: props.binaryOptionNames ? t(props.binaryOptionNames.yes) : t('Yes'),
      value: true,
    },
    {
      name: props.binaryOptionNames ? t(props.binaryOptionNames.no) : t('No'),
      value: false,
    },
    {
      name:
        (props.binaryOptionNames && props.binaryOptionNames.na && t(props.binaryOptionNames.na)) ??
        '',
      value: undefined,
    },
  ]

  const getTypographyDisplay = (): string => {
    switch (props.value) {
      case true: {
        if (props.binaryOptionNames) {
          return t(props.binaryOptionNames.yes)
        }
        return t('Yes')
      }
      case false: {
        if (props.binaryOptionNames) {
          return t(props.binaryOptionNames.no)
        }
        return t('No')
      }
      default:
        if (props.binaryOptionNames && props.binaryOptionNames.na) {
          return t(props.binaryOptionNames.na)
        }
        return t('N/A')
    }
  }

  const parseValue = (value?: boolean) => {
    switch (value) {
      case true: {
        return true
      }
      case false: {
        return false
      }
      default: {
        return undefined
      }
    }
  }

  return (
    <Fragment>
      {props.uneditable ? (
        <Typography>{getTypographyDisplay()}</Typography>
      ) : (
        <Autocomplete
          disablePortal
          value={{
            name: binaryOptions.find((option) => option.value === props.value)?.name || '',
            value: parseValue(props.value),
          }}
          getOptionLabel={(option: BinaryOption) => {
            return option.name
          }}
          isOptionEqualToValue={(option: BinaryOption, value: BinaryOption) =>
            option.value === value.value
          }
          options={binaryOptions}
          onChange={handleChange}
          onBlur={props.onBlur}
          disabled={props.disabled ?? false}
          fullWidth={props.fullWidth ?? true}
          renderInput={(params) => <TextField variant={props.variant ?? 'standard'} {...params} error={!!props.error}/>}
          filterOptions={(options) =>
            options.filter(
              (opt: BinaryOption) =>
                (props.binaryOptionNames && props.binaryOptionNames.na && opt.name) ??
                (opt.name !== '' && opt.name),
            )
          }
        />
      )}
    </Fragment>
  )
}

export default FormBinarySelect
