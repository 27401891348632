import { Box, IconButton, Typography } from '@mui/material'
import highway from '../assets/img/highways-department.png'
import { InitUserInfo, UserInfo } from '@services/model/user.model'
import useLocalStorage from '@hooks/useLocalStorage'
import { Link, useNavigate } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web'
import home from '../assets/img/home.svg'

const UserBox = () => {
  const { keycloak, initialized } = useKeycloak()
  const [loginUser, setLoginUser] = useLocalStorage<UserInfo | undefined>('usr', undefined)
  const navigate = useNavigate()

  const UserIcon = () => {
    return (
      <Box sx={{ padding: '0 8px' }}>
        <Typography sx={{ fontSize: '.7rem' }}>{loginUser?.firstName}</Typography>
        <Typography sx={{ fontSize: '.7rem' }}>{loginUser?.position}</Typography>
        <Typography sx={{ fontSize: '.7rem' }}>{loginUser?.organizationName}</Typography>
        <Typography sx={{ fontSize: '.7rem' }}>{loginUser?.defaultRoleName}</Typography>
      </Box>
    )
  }
  const UserLogo = () => {
    return (
      <Box sx={{ width: '60%', padding: '10px', background: '#fff', marginBottom: '15px' }}>
        <Box component="img" alt="UserLogo" src={highway} sx={{ width: '100%' }} />
      </Box>
    )
  }
  const BtnBar = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          width: '100%',
          height: '32px',
          gap: '8px',
          alignItems: 'center',
        }}>
        <Link to="/landing" >
          <Box component="img" alt="Varadise" src={home}  />
          {/* sx={{ padding: '0 5px' }} */}
        </Link>

        <Link to="/notifications">
          <Box
            component="img"
            alt="Varadise"
            src={'/img/notification.svg'}
            // sx={{ padding: '0 5px' }}
          />
        </Link>

        <Box>
          <IconButton
            sx={{ p: 0 }}
            onClick={() => {
              setLoginUser({ ...InitUserInfo })
              localStorage.clear()
              navigate('/')
              keycloak.logout()
            }}>
            <img src="/img/logout.svg" alt="menu" />
          </IconButton>
        </Box>
      </Box>
    )
  }
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          background: '#f2f2f2',
          boxShadow: '0 3px 6px rgb(0 0 0 / 16%)',
        }}>
        <UserIcon></UserIcon>
        <UserLogo></UserLogo>
      </Box>
      <BtnBar></BtnBar>
    </>
  )
}
export default UserBox
