import { Box, List } from '@mui/material'
import { formIconProps } from '../icon'
import { useTranslation } from 'react-i18next'
import FormActivityLogRecordRow from './activityLogRecordRow'
import { getFormColor } from '@utils/getFormColor'
import Styles from '@styles/activityLog.module.scss'
import { styled } from '@mui/material/styles'

const StyledBox = styled(Box)(({ theme }) => ({
  margin: theme.spacing(1),
  padding: theme.spacing(1),
}))

//list -> activityLog
export interface formActivityLogBoxProps {
  logItems: formActivityLogItems[]
}

export interface formActivityLogItems {
  id: number
  type: string
  fromUser: number
  formShortName?: string
  module?: string
  title: string
  msgContent?: string | null
  logDatetime: string
}

const FormActivityLogBox = (props: formActivityLogBoxProps) => {
  let iconSx = {
    fontSize: '10px',
  }

  let iconSvgSx = {
    fontSize: '30px',
  }

  let logs = props.logItems.map((item, index) => {
    let fIconProps: formIconProps = {
      shortName: '',
      shortNameBgColor: '',
      IconSx: iconSx,
      SvgSx: iconSvgSx,
    }
    if (item.formShortName !== undefined) {
      fIconProps.shortName = item.formShortName
      fIconProps.shortNameBgColor = getFormColor(item.formShortName, item.module)
    }
    return (
      <FormActivityLogRecordRow
        id={item.id}
        key={`form-activity-log-record-row-${index}`}
        type={item.type}
        fromUser={item.fromUser}
        formIconProps={fIconProps}
        title={item.title}
        msgContent={item.msgContent}
        logDatetime={new Date(item.logDatetime)}
      />
    )
  })

  return (
    <StyledBox>
      <Box component={'div'} className={Styles.title}>
      </Box>
      <List dense={true}>{logs}</List>
    </StyledBox>
  )
}
export default FormActivityLogBox
