import AddIcon from '@mui/icons-material/Add'

import Select, { SelectChangeEvent } from '@mui/material/Select'

import { Box, Typography, Button, Grid, TableCell } from '@mui/material'
import SearchPanel from '@components/searchPanel'
import * as common from '@common/common'
import { GlobalContext } from '@providers/globalStore'
import { InitialSearchPanelState, SearchPanelContext } from '@providers/SearchPanelProvider'
import SearchPanelReducer, { SearchPanelState } from '@reducers/searchPanelReducer'
import { Fragment, useContext, useEffect, useReducer, useRef, useState } from 'react'
import { NavigateFunction } from 'react-router-dom'
import useTableView from '@hooks/useTableView'
import { useTranslation } from 'react-i18next'
import { UserFilterOptions, UserListModel } from '@services/model/admin.model'
import AdminService from '@services/admin.service'
import { NavigateTo } from '@utils/navigate'
import { useNavigate } from 'react-router-dom'
import { styled, SxProps, Theme } from '@mui/material/styles'

const AdminUserList = (props: { showMyRecord?: boolean }) => {
  const refMounted = useRef(true)
  const { state: globalState, userInfo } = useContext(GlobalContext)
  const [state, dispatch] = useReducer<React.Reducer<SearchPanelState, any>>(
    SearchPanelReducer,
    InitialSearchPanelState,
  )
  const [status, setStatus] = useState('')

  const handleChangeStatus = (event: SelectChangeEvent) => {
    // setStatus(event.target.value)
  }
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [extraListInfo, setExtraListInfo] = useState<UserFilterOptions>({
    organizationIdList: [],
  })
  const getExtraListInfo = async () => {
    let extraInfo = await AdminService.GetUserFilterOption()
    setExtraListInfo(extraInfo)
    refMounted.current = true
  }

  const [TableView, reload] = useTableView<UserListModel>({
    headers: [
      {
        key: 'status',
        desc: t('Status', { ns: common.i18nFormNS }),
        renderCell: (record: UserListModel) => (
          <Fragment>{record.status === true ? 'Enabled' : 'Disabled'}</Fragment>
        ),
      },
      {
        key: 'displayName',
        desc: t('Display Name', { ns: common.i18nFormNS }),
        renderCell: (record: UserListModel) => <Fragment>{record.displayName}</Fragment>,
      },
      {
        key: 'email',
        desc: t('Email', { ns: common.i18nFormNS }),
        renderCell: (record: UserListModel) => <Fragment>{record.email}</Fragment>,
      },
      {
        key: 'position',
        desc: t('Position', { ns: common.i18nFormNS }),
        renderCell: (record: UserListModel) => <Fragment>{record.position}</Fragment>,
      },
      {
        key: 'organizationName',
        desc: t('Organisation', { ns: common.i18nFormNS }),
        renderCell: (record: UserListModel) => <Fragment>{record.organizationName}</Fragment>,
      },
      // {
      //   key: 'action',
      //   desc: t('Action', { ns: common.i18nFormNS }),
      //   renderCell: (record: UserListModel) => (
      //     <Fragment>
      //       <EditIcon />
      //       <EditIcon />
      //     </Fragment>
      //   ),
      // },
    ],
    customRow: false, // if true, use onRowGen to generate row
    onRowGen: function (navigate: NavigateFunction, record: UserListModel): JSX.Element {
      return (
        <Fragment>
          <TableCell>{record.status}</TableCell>
          <TableCell>{record.displayName}</TableCell>
          <TableCell>{record.email}</TableCell>
          <TableCell>{record.position}</TableCell>
          <TableCell>{record.organizationName}</TableCell>
          {/* <TableCell>
            <EditIcon /> <EditIcon />
          </TableCell> */}
        </Fragment>
      )
    },
    onRowClick: function (navigate: NavigateFunction, record: UserListModel) {
      NavigateTo(navigate, '/admin/user/:id', { id: record.userId })
    },
    mountedRef: refMounted,
    reloadCallback: async (pagination: object, sorting: object | undefined, cancelToken: any) => {
      let payload = {
        ...state.filterResult,
        sorting: sorting,
        pagination: pagination,
      } 
      if (!userInfo.allRights && globalState.contractNoId) {
        payload = {
          ...payload,
          contractNoId: [globalState.contractNoId],
        }
      }
      const list = await AdminService.GetUserList(
        payload,
        cancelToken,
        props.showMyRecord || false,
      )

      return list
    },
  })

  useEffect(() => {
    document.title = t('Admin', { ns: common.i18nFormNS })

    reload()
  }, [])

  const handleClickDuty = () => {
    NavigateTo(navigate, '/admin/duty/all-record')
  }

  const LinkTableAddButton = styled(Box)<{
    state: {
      disableAddButton?: boolean
      uneditable?: boolean
      color?: string
      hoverBackgroundColor?: string
      backgroundColor?: string
    }
  }>(({ state }) => ({
    width: 40,
    height: 40,
    display: state.uneditable ? 'none' : 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    position: 'relative',
    zIndex: 1000,
    backgroundColor: state.disableAddButton ? '#eeeeee' : state.color ?? '#23e9c0',
    ...(!state.disableAddButton && {
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: state.hoverBackgroundColor ? state.hoverBackgroundColor : 'grey',
        '#add-button-hover-text': {
          opacity: '100% !important',
          width: '200px',
          transition: 'opacity 500ms !important',
        },
      },
    }),
  }))
  const handleAddUser = (event) => {
    console.log('event', event.target.value)
    NavigateTo(navigate, '/admin/user')
  }
  return (
    <Grid component="main" container padding={1}>
      <SearchPanelContext.Provider value={{ state, dispatch }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: 1,
            m: 1,
            bgcolor: 'background.paper',
            borderRadius: 1,
            width: '100%',
          }}>
          <Box>
            <Button
              variant="outlined"
              sx={{
                fontWeight: 'bold',
                // margin: theme.spacing(6)
              }}
              onClick={handleClickDuty}>
              {t('Duty Effective Time')}
            </Button>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ padding: '5px 10px ' }}>
              <LinkTableAddButton
                state={{
                  disableAddButton: false, // add logic
                  uneditable: false, // add logic
                  color: '#cccccc',
                  hoverBackgroundColor: '#000000',
                }}
                onClick={handleAddUser}>
                <AddIcon
                  id="link-table-add-icon"
                  sx={{
                    height: '100%',
                    width: '100%',
                    color: '#ffffff',
                  }}
                />
                <Box
                  id="add-button-hover-text"
                  style={{
                    position: 'absolute',
                    right: 0,
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    boxShadow: '0 3px 6px rgb(0 0 0 / 16%)',
                    borderTopRightRadius: 10,
                    borderBottomRightRadius: 10,
                    padding: 10,
                    paddingRight: 70,
                    opacity: 0,
                  }}>
                  <Typography>{t('Add a User')}</Typography>
                </Box>
              </LinkTableAddButton>
            </Box>
            <Box>
              <SearchPanel
                dispatch={dispatch}
                addUrl="/booking/new"
                onSearch={reload}
                onInitReload={reload}
                onToggleFilterDrawer={getExtraListInfo}
                mountedRef={refMounted}
                excludeStatus={true}
                criteria={[
                  {
                    key: 'status',
                    desc: 'Status',
                    type: 'BooleanSelectionFilter',
                    multiple: true,
                    valueList: [
                      {
                        key: true,
                        value: 'Enabled',
                      },
                      {
                        key: false,
                        value: 'Disabled',
                      },
                    ],
                    defaultValue:
                      sessionStorage?.User !== 'undefined' && sessionStorage?.User !== undefined
                        ? JSON.parse(sessionStorage?.User)?.status?.value
                        : [],
                  },
                  {
                    key: 'displayName',
                    desc: 'Display Name',
                    type: 'StringFilter',
                    defaultValue:
                      sessionStorage?.User !== 'undefined' && sessionStorage?.User !== undefined
                        ? JSON.parse(sessionStorage?.User)?.displayName?.value
                        : '',
                  },
                  {
                    key: 'position',
                    desc: 'Position',
                    type: 'StringFilter',
                    defaultValue:
                      sessionStorage?.User !== 'undefined' && sessionStorage?.User !== undefined
                        ? JSON.parse(sessionStorage?.User)?.position?.value
                        : '',
                  },
                  {
                    key: 'organizationName',
                    desc: 'Organization',
                    type: 'NumberSelectionFilter',
                    multiple: true,
                    valueList: globalState.organizationMetaList?.filter((x) =>
                      extraListInfo.organizationIdList?.includes(x.key),
                    ),
                    defaultValue:
                      sessionStorage?.User !== 'undefined' && sessionStorage?.User !== undefined
                        ? JSON.parse(sessionStorage?.User)?.organizationId?.value
                        : [],
                  },
                  {
                    key: 'updatedAt',
                    desc: 'Last Update',
                    type: 'DateFilter',
                    defaultOperator:
                      sessionStorage?.User !== 'undefined' && sessionStorage?.User !== undefined
                        ? JSON.parse(sessionStorage?.User)?.updatedAt?.operator
                        : 'in',
                    defaultFrom:
                      sessionStorage?.User !== 'undefined' && sessionStorage?.User !== undefined
                        ? JSON.parse(sessionStorage?.User)?.updatedAt?.value?.min
                        : '',
                    defaultTo:
                      sessionStorage?.User !== 'undefined' && sessionStorage?.User !== undefined
                        ? JSON.parse(sessionStorage?.User)?.updatedAt?.value?.max
                        : '',
                  },
                ]}
              />
            </Box>
          </Box>
        </Box>
        <TableView />
      </SearchPanelContext.Provider>
    </Grid>
  )
}

export default AdminUserList
