import { useTranslation } from 'react-i18next'
import { Control, Path, UseFormGetValues } from 'react-hook-form'
import uuid from 'react-uuid'
import { GlobalStateProps } from '@providers/globalStore'
import { FormStatusEnum } from '@services/model/form/form.model'
import { ContractNo } from '@services/model/contract.model'
import { FormEaModel } from '@services/model/form/form.EA.model'
import FormBinarySelect from '@components/form/binarySelect'
import FormContainer from '@components/form/container'
import FormController from '@components/form/controller'
import FormDateTimePicker from '@components/form/dateTimePicker'
import FormField from '@components/form/field'
import FormFreeText from '@components/form/freeText'
import FormImageUpload from '@components/form/imageUpload'
import FormMultipleSelect from '@components/form/multipleSelect'
import FormTypography from '@components/form/typography'
import FormSelect from '@components/form/select'
import useGeneralOptions from '@hooks/useGeneralOptions_to_be_used'
import { EAPartATemplates } from './formPartATemplate'

const UUID = uuid()

export default ({
  control,
  formStatus,
  getValues,
  globalState,
  contractInfo,
}: {
  control: Control<FormEaModel>
  formStatus: string
  getValues: UseFormGetValues<FormEaModel>
  globalState: GlobalStateProps
  contractInfo?: ContractNo
}) => {
  const { t } = useTranslation()
  const { getOptionsByKey } = useGeneralOptions()
  const mmWorksValue =
    getOptionsByKey('Form EA - Category', false)?.find((x) => x.key === getValues('typeOfMmWorks'))
      ?.value ?? ''
  const neaParts = EAPartATemplates(
    mmWorksValue,
    getOptionsByKey,
    contractInfo?.highSpeedRoad === true,
    formStatus,
  )

  return (
    <FormContainer fkey={`${UUID}-ea-a`}>
      <FormField fieldName="Type of M&M Works">
        <FormController controllerProps={{ control, name: 'typeOfMmWorks' }}>
          <FormTypography type="select" options={getOptionsByKey('Form EA - Category', false)} />
        </FormController>
      </FormField>

      <FormField fieldName="Team">
        <FormController controllerProps={{ control, name: 'baseForm.teamId' }}>
          <FormMultipleSelect options={globalState.teamList || []} uneditable={true} />
        </FormController>
      </FormField>

      <FormField fieldName="District">
        <FormController controllerProps={{ control, name: 'baseForm.districtId' }}>
          <FormMultipleSelect options={globalState.districtList || []} uneditable={true} />
        </FormController>
      </FormField>

      <FormField fieldName="Works Order No">
        <FormTypography value={getValues('baseForm.workOrderNo')} />
      </FormField>

      <FormField fieldName="Month/Year of Performance Standard">
        <FormController
          controllerProps={{ control, name: 'formNea.monthYearOfPerformanceStandard' }}>
          <FormTypography type="date" dateFormat="MMMM YYYY" />
        </FormController>
      </FormField>

      <FormField fieldName="EA Notification No.">
        <FormController controllerProps={{ control, name: 'formNea.neaNotiticationNo' }}>
          <FormTypography />
        </FormController>
      </FormField>

      <FormField fieldName="EA No.">
        <FormController controllerProps={{ control, name: 'reportNo' }}>
          <FormTypography />
        </FormController>
      </FormField>

      {neaParts?.map((part, partIndex) => {
        switch (part.fieldType) {
          case 'number': {
            return (
              <FormField fieldName={part.displayName} key={`${UUID}-ea-a-${partIndex}`}>
                <FormController
                  controllerProps={{ control, name: part.field as Path<FormEaModel> }}>
                  <FormFreeText textfieldProps={{ type: 'number' }} />
                </FormController>
              </FormField>
            )
          }
          case 'string': {
            return (
              <FormField fieldName={part.displayName} key={`${UUID}-ea-a-${partIndex}`}>
                <FormController
                  controllerProps={{ control, name: part.field as Path<FormEaModel> }}>
                  <FormFreeText />
                </FormController>
              </FormField>
            )
          }
          case 'select': {
            return (
              <FormField fieldName={part.displayName} key={`${UUID}-ea-a-${partIndex}`}>
                <FormController
                  controllerProps={{ control, name: part.field as Path<FormEaModel> }}>
                  <FormSelect options={part.options ?? []} />
                </FormController>
              </FormField>
            )
          }
          case 'displayNumber': {
            return (
              <FormField fieldName={part.displayName} key={`${UUID}-ea-a-${partIndex}`}>
                <FormController
                  controllerProps={{ control, name: part.field as Path<FormEaModel> }}>
                  <FormTypography type="number" numberDemical={2} />
                </FormController>
              </FormField>
            )
          }
          default: {
            return (
              <FormField fieldName={part.displayName} key={`${UUID}-ea-a-${partIndex}`}>
                <FormController
                  controllerProps={{ control, name: part.field as Path<FormEaModel> }}>
                  <FormTypography />
                </FormController>
              </FormField>
            )
          }
        }
      })}

      <FormField fieldName="Auditing Officer">
        <FormController controllerProps={{ control, name: 'formNea.auditingOfficer' }}>
          <FormTypography type="select" options={globalState.userMetaList} />
        </FormController>
      </FormField>

      <FormField fieldName="Checking Officer">
        <FormController controllerProps={{ control, name: 'formNea.checkingOfficer' }}>
          <FormTypography type="select" options={globalState.userMetaList} />
        </FormController>
      </FormField>

      <FormField fieldName="Contractor's Witness">
        <FormController controllerProps={{ control, name: 'contractorWitness' }}>
          <FormFreeText
            textfieldProps={{ fullWidth: true }}
            uneditable={
              formStatus !== FormStatusEnum.FORM_EA_DRAFT &&
              formStatus !== FormStatusEnum.FORM_EA_PRELIM_RESULT_REJECTED
            }
          />
        </FormController>
      </FormField>

      <FormField fieldName="Actual Date & Time of Checking">
        <FormController controllerProps={{ control, name: 'actualCheckingDate' }}>
          <FormDateTimePicker
            uneditable={
              formStatus !== FormStatusEnum.FORM_EA_DRAFT &&
              formStatus !== FormStatusEnum.FORM_EA_PRELIM_RESULT_REJECTED
            }
          />
        </FormController>
      </FormField>

      {contractInfo?.highSpeedRoad &&
        (getValues('formNea.typeOfMmWorksValue') ===
          'Maintenance of Road Marking, Road Drainage System and Detailed Inspections' ||
          getValues('formNea.typeOfMmWorksValue') ===
            'Maintenance of Road Marking, Road Drainage System, Detailed Inspections in Hygienic Conditions') && (
          <FormField fieldName="Total Inspection Section">
            <FormController controllerProps={{ control, name: 'totalInspectionSection' }}>
              <FormTypography />
            </FormController>
          </FormField>
        )}

      <FormField fieldName="Viable to Conduct Inspection">
        <FormController controllerProps={{ control, name: 'viableToConductInspection' }}>
          <FormBinarySelect
            binaryOptionNames={{ yes: t('Available'), no: t('Unavailable') }}
            uneditable={
              formStatus !== FormStatusEnum.FORM_EA_DRAFT &&
              formStatus !== FormStatusEnum.FORM_EA_PRELIM_RESULT_REJECTED
            }
          />
        </FormController>
      </FormField>

      <FormField fieldName="Audit Location Plan">
        <FormController controllerProps={{ control, name: 'auditLocationPlanImages' }}>
          <FormImageUpload
            multiple
            disabled={
              formStatus !== FormStatusEnum.FORM_EA_DRAFT &&
              formStatus !== FormStatusEnum.FORM_EA_PRELIM_RESULT_REJECTED
            }
          />
        </FormController>
      </FormField>
    </FormContainer>
  )
}
