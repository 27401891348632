import { KeyValPair } from '@models/common'
import { GlobalStateProps } from '@providers/globalStore'
import { UserMetaModel } from '@services/model/admin.model'
import { ContractNo, WorkOrder } from '@services/model/contract.model'
import { FormCountByUser, FormStatus } from '@services/model/form/form.model'
import {
  GeneralConfig,
  GeneralOptionDNGroup,
  GeneralOptionGroup,
} from '@services/model/general.model'
import { GroupType } from '@services/model/group.model'
import { map } from 'lodash'

export type ActionType =
  | {
      type: 'login' | 'refreshLogin'
    }
  | {
      type: 'logout'
    }
  | {
      type: 'setInitiazed'
    }
  | {
      type: 'toggleLeftDrawer'
      opened?: boolean
    }
  | {
      type: 'changeHeaderColor'
      color?: string
    }
  | {
      type: 'changeTitle'
      title?: string
    }
  | {
      type: 'changeSubtitle'
      subtitle?: string
    }
  | {
      type: 'changeWorkOrder'
      workOrder?: string
    }
  | {
      type: 'changeFormStatus'
      formStatus?: string
    }
  | {
      type: 'openMenu'
      menuId: string | undefined
    }
  | {
      type: 'selectContract'
      contractNoId: number
    }
  | {
      type: 'selectWorkGroup'
      workGroupId: number
    }
  | {
      type: 'reloadUserMetaList'
      list: KeyValPair[]
    }
  | {
      type: 'reloadOrganizationMetaList'
      list: KeyValPair[]
    }
  | {
      type: 'reloadRoleMetaList'
      list: KeyValPair[]
    }
  | {
      type: 'reloadUserMetaFullList'
      list: UserMetaModel[]
    }
  | {
      type: 'reloadOptionGroupList'
      list: GeneralOptionGroup[]
    }
  | {
      type: 'reloadDNOptionGroupList'
      list: GeneralOptionDNGroup[]
    }
  | {
      type: 'reloadContractList'
      list: ContractNo[]
    }
  | {
      type: 'reloadGeneralConfigList'
      list: GeneralConfig[]
    }
  | {
      type: 'reloadGroupTypeList'
      list: GroupType[]
    }
  | {
      type: 'reloadFullGroupTypeList'
      list: GroupType[]
    }
  | {
      type: 'reloadFormActionList'
      list: FormStatus[]
    }
  | {
      type: 'reloadUserMetaEmailList'
      list: KeyValPair[]
    }
  | {
      type: 'reloadWorksOrderList'
      list: WorkOrder[]
    }
  | {
      type: 'reloadContractAndWorksOrderList' //deprecatd - not using
      list: ContractNo[]
    }
  | {
      type: 'reloadFormOptionsList'
      list: KeyValPair[]
    }
  | {
      type: 'reloadTotalFromCount'
      list: FormCountByUser[]
    }

function GlobalReducer(state: GlobalStateProps, action: ActionType): GlobalStateProps {
  switch (action.type) {
    case 'login':
    case 'refreshLogin':
      return {
        ...state,
      }

    case 'logout':
      return {
        ...state,
      }

    case 'setInitiazed':
      return {
        ...state,
        isInitiated: true,
      }
    case 'toggleLeftDrawer':
      if (action.opened)
        return {
          ...state,
          leftDrawerOpened: action.opened,
        }
      else
        return {
          ...state,
          leftDrawerOpened: !state.leftDrawerOpened,
        }

    case 'changeHeaderColor':
      return {
        ...state,
        headerColor: action.color ?? 'rgb(255, 202, 97)',
      }
    case 'changeTitle':
      return {
        ...state,
        title: action.title ?? 'HyD RMMS',
      }
    case 'changeSubtitle':
      return {
        ...state,
        subtitle: action.subtitle,
      }
    case 'changeWorkOrder':
      return {
        ...state,
        workOrder: action.workOrder,
      }
    case 'changeFormStatus':
      return {
        ...state,
        formStatus: action.formStatus,
      }
    case 'openMenu':
      return {
        ...state,
        openedMenuId: action.menuId,
      }
    case 'selectContract':
      return {
        ...state,
        contractNoId: action.contractNoId,
      }
    case 'selectWorkGroup':
      return {
        ...state,
        workGroupId: action.workGroupId,
      }
    case 'reloadUserMetaList':
      return {
        ...state,
        userMetaList: action.list,
      }

    case 'reloadOrganizationMetaList':
      return {
        ...state,
        organizationMetaList: action.list,
      }

    case 'reloadRoleMetaList':
      return {
        ...state,
        roleMetaList: action.list,
      }

    case 'reloadUserMetaFullList':
      return {
        ...state,
        userMetaFullList: action.list,
      }

    case 'reloadOptionGroupList':
      return {
        ...state,
        generalOptionGroupList: action.list,
        proposedActivityList:
          action.list
            ?.find((x) => x.name.toLowerCase() === 'proposed activities')
            ?.generalOptions.map((x) => {
              return { key: x.id, value: x.name, status: x.status }
            }) || [],
        weatherList:
          action.list
            ?.find((x) => x.name.toLowerCase() === 'weather')
            ?.generalOptions.map((x) => {
              return { key: x.id, value: x.name, status: x.status }
            }) || [],
        essentialWorkList:
          action.list
            ?.find((x) => x.name.toLowerCase() === 'essential work')
            ?.generalOptions.map((x) => {
              return { key: x.id, value: x.name, status: x.status }
            }) || [],
      }

    case 'reloadDNOptionGroupList':
      return {
        ...state,
        generalOptionDNGroupList: action.list,
      }
    case 'reloadContractList':
      return {
        ...state,
        contractList: action.list,
      }
    case 'reloadGeneralConfigList':
      return {
        ...state,
        generalConfigList: action.list,
      }
    case 'reloadGroupTypeList':
      return {
        ...state,
        teamList:
          action.list
            ?.find((x) => x.name?.toLowerCase() === 'team')
            ?.groups.map((x) => {
              return { key: x.id, value: x.name, status: x.status }
            }) || [],
        districtList:
          action.list
            ?.find((x) => x.name?.toLowerCase() === 'district')
            ?.groups.map((x) => {
              return { key: x.id, value: x.name, status: x.status }
            }) || [],
      }
    case 'reloadFullGroupTypeList':
      return {
        ...state,
        fullTeamList:
          map(
            action.list?.filter((x) => x.name?.toLowerCase() === 'team'),
            'groups',
          )
            ?.flat()
            ?.map((x) => {
              return { key: x.id, value: x.name, status: x.status }
            }) || [],
        fullDistrictList:
          map(
            action.list?.filter((x) => x.name?.toLowerCase() === 'district'),
            'groups',
          )
            ?.flat()
            ?.map((x) => {
              return { key: x.id, value: x.name, status: x.status }
            }) || [],
      }
    case 'reloadFormActionList':
      return {
        ...state,
        formStatusList: action.list,
      }
    case 'reloadUserMetaEmailList':
      return {
        ...state,
        userMetaEmailList: action.list,
      }
    case 'reloadWorksOrderList':
      return {
        ...state,
        worksOrderList: action.list,
      }
    case 'reloadContractAndWorksOrderList': //deprecatd - not using
      return {
        ...state,
        contractAndWorksOrderList: action.list,
      }
    case 'reloadFormOptionsList':
      return {
        ...state,
        formOptionsList: action.list,
      }
    case 'reloadTotalFromCount':
      return {
        ...state,
        totalFormCountList: action.list,
      }
    default:
      return state
  }
}

export default GlobalReducer
