import SearchPanel from '@components/searchPanel'
import { Grid } from '@mui/material'
import * as common from '@common/common'
import { GlobalContext } from '@providers/globalStore'
import { InitialSearchPanelState, SearchPanelContext } from '@providers/SearchPanelProvider'
import SearchPanelReducer, { SearchPanelState } from '@reducers/searchPanelReducer'
import FormCDRService from '@services/formService/form.CDR.service'
import moment from 'moment'
import { Fragment, useContext, useEffect, useReducer, useRef, useState } from 'react'
import { NavigateFunction } from 'react-router-dom'
import useTableView from '@hooks/useTableView'
import { useTranslation } from 'react-i18next'
import { FormCdrListModel } from '@services/model/form/form.CDR.model'
import FormStatusLabel from '@components/FormStatusLabel'
import GetValueWithKey from '@utils/getValueWithKey'
import useGeneralOptions from '@hooks/useGeneralOptions_to_be_used'
import { KeyValPair } from '@models/common'
import { RightsCategory } from '@services/model/form/form.model'
import { GetTeamOptions } from '@utils/teamOptions'
import { GetDistrictOptions } from '@utils/districtOptions'
import TableBox from '@components/table/HoverTableBox'
import { BaseFilterOptions } from '@services/model/base.model'

const AllRecord = (props: { showMyRecord?: boolean }) => {
  const refMounted = useRef(false)
  const { state: globalState, userInfo } = useContext(GlobalContext)
  const [state, dispatch] = useReducer<React.Reducer<SearchPanelState, any>>(
    SearchPanelReducer,
    InitialSearchPanelState,
  )

  const { getOptionsByKey } = useGeneralOptions()
  const { t } = useTranslation()
  const [extraListInfo, setExtraListInfo] = useState<BaseFilterOptions>({
    submittedByList: [],
    approvedByList: [],
    formIdList: [],
  })

  const getExtraListInfo = async () => {
    let extraInfo = await FormCDRService.GetCdrFilterOption(props.showMyRecord)
    setExtraListInfo(extraInfo)
    refMounted.current = true
  }

  const [TableView, reload] = useTableView<FormCdrListModel>({
    headers: [
      {
        key: 'formStatusShortName',
        desc: t('General Status', { ns: common.i18nFormNS }),
        renderCell: (record: FormCdrListModel) => (
          <FormStatusLabel
            label={record.formStatusShortName}
            color={record.formStatusColor || ''}
          />
        ),
      },
      {
        key: 'formStatus',
        desc: t('Submission Status', { ns: common.i18nFormNS }),
        renderCell: (record: FormCdrListModel) => <Fragment>{record.formStatus}</Fragment>,
      },
      {
        key: 'formId',
        desc: t('Form ID', { ns: common.i18nFormNS }),
        renderCell: (record: FormCdrListModel) => <Fragment>{record.reportNo}</Fragment>,
      },
      {
        key: 'formDate',
        desc: t('Report Date', { ns: common.i18nFormNS }),
        renderCell: (record: FormCdrListModel) => (
          <Fragment>
            {record.formDate ? moment(record.formDate).format('D MMMM YYYY') : ''}
          </Fragment>
        ),
      },
      {
        key: 'contractNo',
        desc: t('Contract No', { ns: common.i18nFormNS }),
        renderCell: (record: FormCdrListModel) => (
          <Fragment>
            {GetValueWithKey(
              record.contractNoId,
              globalState.contractList?.map((x) => ({ key: x.id, value: x.contractNo })) ?? [],
            )}
          </Fragment>
        ),
      },
      {
        key: 'teamId',
        desc: t('Team', { ns: common.i18nFormNS }),
        renderCell: (record: FormCdrListModel) => (
          <Fragment>
            <TableBox columnValue={record.teamId} list={globalState.teamList} />
          </Fragment>
        ),
      },
      {
        key: 'districtId',
        desc: t('District', { ns: common.i18nFormNS }),
        renderCell: (record: FormCdrListModel) => (
          <Fragment>
            <TableBox columnValue={record.districtId} list={globalState.districtList} />
          </Fragment>
        ),
      },
      {
        key: 'typeOfWorks',
        desc: t('Type Of Work', { ns: common.i18nFormNS }),
        renderCell: (record: FormCdrListModel) => (
          <Fragment>{record.typeOfWorks ? 'M&M Works' : 'Non-M&M Works'}</Fragment>
        ),
      },
      {
        key: 'updatedAt',
        desc: t('Last Update', { ns: common.i18nFormNS }),
        renderCell: (record: FormCdrListModel) => (
          <Fragment>{record.updatedAt ? moment(record.updatedAt).format() : ''}</Fragment>
        ),
      },
    ],
    onRowClick: function (navigate: NavigateFunction, record: FormCdrListModel) {
      navigate(`/contractor-daily-report/${record.formId}`)
    },
    mountedRef: refMounted,
    reloadCallback: async (pagination: object, sorting: object | undefined, cancelToken: any) => {
      if (refMounted.current) {
        sessionStorage.setItem('CDR', JSON.stringify(state.filterResult))
        let resp = await FormCDRService.GetCdrList(
          {
            ...state.filterResult,
            sorting: sorting,
            pagination: pagination,
          },
          cancelToken,
          props.showMyRecord || false,
        )
        return resp
      }
    },
  })

  return (
    <Grid component="main" container padding={1}>
      <SearchPanelContext.Provider value={{ state, dispatch }}>
        <SearchPanel
          dispatch={dispatch}
          addUrl="/booking/new"
          onSearch={reload}
          onInitReload={reload}
          onToggleFilterDrawer={getExtraListInfo}
          mountedRef={refMounted}
          excludeStatus={true}
          criteria={[
            {
              key: 'formStatus',
              desc: 'Submission Status',
              type: 'NumberSelectionFilter',
              multiple: true,
              valueList:
                globalState.formStatusList
                  ?.filter((x) => x.actionCode.includes(RightsCategory.FORM_CDR))
                  .map((x) => {
                    return {
                      key: x.id,
                      value: x.actionName,
                    }
                  }) || [],
              defaultValue:
                sessionStorage?.CDR !== 'undefined' && sessionStorage?.CDR !== undefined
                  ? JSON.parse(sessionStorage?.CDR)?.formStatus?.value
                  : [],
            },
            {
              key: 'formId',
              desc: 'Form ID',
              type: 'StringSelectionFilter',
              multiple: true,
              valueList: extraListInfo.formIdList,
              defaultValue:
                sessionStorage?.CDR !== 'undefined' && sessionStorage?.CDR !== undefined
                  ? JSON.parse(sessionStorage?.CDR)?.formId?.value
                  : [],
            },
            {
              key: 'formDate',
              desc: 'Report Date',
              type: 'DateFilter',
              defaultOperator:
                sessionStorage?.CDR !== 'undefined' && sessionStorage?.CDR !== undefined
                  ? JSON.parse(sessionStorage?.CDR)?.formDate?.operator
                  : 'in',
              defaultFrom:
                sessionStorage?.CDR !== 'undefined' && sessionStorage?.CDR !== undefined
                  ? JSON.parse(sessionStorage?.CDR)?.formDate?.value?.min
                  : '',
              defaultTo:
                sessionStorage?.CDR !== 'undefined' && sessionStorage?.CDR !== undefined
                  ? JSON.parse(sessionStorage?.CDR)?.formDate?.value?.max
                  : '',
            },
            {
              key: 'contractNo',
              desc: 'Contract No.',
              type: 'NumberSelectionFilter',
              multiple: true,
              valueList: globalState.contractList?.map((x) => {
                return {
                  key: x.id,
                  value: x.contractNo,
                }
              }),
              defaultValue:
                sessionStorage?.CDR !== 'undefined' && sessionStorage?.CDR !== undefined
                  ? JSON.parse(sessionStorage?.CDR)?.contractNo?.value
                  : [],
            },
            {
              key: 'teamId',
              desc: 'Team',
              type: 'NumberSelectionFilter',
              multiple: true,
              valueList: globalState.teamList,
              defaultValue:
                sessionStorage?.CDR !== 'undefined' && sessionStorage?.CDR !== undefined
                  ? JSON.parse(sessionStorage?.CDR)?.teamId?.value
                  : [],
            },
            {
              key: 'districtId',
              desc: 'District',
              type: 'NumberSelectionFilter',
              multiple: true,
              valueList: globalState.districtList,
              defaultValue:
                sessionStorage?.CDR !== 'undefined' && sessionStorage?.CDR !== undefined
                  ? JSON.parse(sessionStorage?.CDR)?.districtId?.value
                  : [],
            },
            {
              key: 'typeOfWorks',
              desc: 'Type of Works',
              type: 'NumberSelectionFilter',
              multiple: true,
              valueList: [
                {
                  key: 'true',
                  value: 'M&M Works',
                },
                {
                  key: 'false',
                  value: 'Non-M&M Works',
                },
              ],
              defaultValue:
                sessionStorage?.CDR !== 'undefined' && sessionStorage?.CDR !== undefined
                  ? JSON.parse(sessionStorage?.CDR)?.typeOfWorks?.value
                  : [],
            },
            // {
            //   key: 'submittedBy',
            //   desc: 'Submitted By',
            //   type: 'StringSelectionFilter',
            //   multiple: true,
            //   valueList: globalState.userMetaList?.filter((x) =>
            //     extraListInfo.SubmittedByList?.includes(x.key),
            //   ),
            // },
            // {
            //   key: 'approvedBy',
            //   desc: 'Approved By',
            //   type: 'StringSelectionFilter',
            //   multiple: true,
            //   valueList: globalState.userMetaList?.filter((x) =>
            //     extraListInfo.ApprovedList?.includes(x.key),
            //   ),
            // },
            {
              key: 'updatedAt',
              desc: 'Last Update',
              type: 'DateFilter',
              defaultOperator:
                sessionStorage?.CDR !== 'undefined' && sessionStorage?.CDR !== undefined
                  ? JSON.parse(sessionStorage?.CDR)?.updatedAt?.operator
                  : 'in',
              defaultFrom:
                sessionStorage?.CDR !== 'undefined' && sessionStorage?.CDR !== undefined
                  ? JSON.parse(sessionStorage?.CDR)?.updatedAt?.value?.min
                  : '',
              defaultTo:
                sessionStorage?.CDR !== 'undefined' && sessionStorage?.CDR !== undefined
                  ? JSON.parse(sessionStorage?.CDR)?.updatedAt?.value?.max
                  : '',
            },
          ]}
        />
        <TableView />
      </SearchPanelContext.Provider>
    </Grid>
  )
}

export default AllRecord
