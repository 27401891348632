import { Control, UseFormGetValues, UseFormSetValue, useWatch } from 'react-hook-form'
import moment from 'moment'
import { GlobalStateProps } from '@providers/globalStore'
import { KeyValPair } from '@models/common'
import { UserInfo } from '@services/model/user.model'
import { WorkOrderList } from '@services/model/contract.model'
import { FormCRModel } from '@services/model/form/form.CR.model'
import { FormStatusEnum } from '@services/model/form/form.model'
import FormBinaryRadio from '@components/form/binaryRadio'
import FormContainer from '@components/form/container'
import FormController from '@components/form/controller'
import FormDateTimePicker from '@components/form/dateTimePicker'
import FormField from '@components/form/field'
import FormFreeText from '@components/form/freeText'
import FormImageUpload from '@components/form/imageUpload'
import FormSelect from '@components/form/select'
import FormTypography from '@components/form/typography'
import FormMultipleSelect from '@components/form/multipleSelect'
import { GetWorksOrderListOptions } from '@utils/worksOrderOptions'
import { GetTeamOptions } from '@utils/teamOptions'
import { GetDistrictOptions } from '@utils/districtOptions'

export default ({
  globalState,
  control,
  userInfo,
  workOrderList,
  getValues,
  setValue,
  formStatus,
}: {
  globalState: GlobalStateProps
  control: Control<FormCRModel>
  userInfo: UserInfo
  workOrderList?: any[]
  getValues: UseFormGetValues<FormCRModel>
  setValue: UseFormSetValue<FormCRModel>
  formStatus: string
}) => {
  const uneditable = !(
    formStatus === FormStatusEnum.FORM_CR_DRAFT ||
    formStatus === FormStatusEnum.FORM_CR_WORKS_INCOMPLETE
  )

  const [workOrderId] = getValues(['baseForm.workOrderId'])

  const contractNoList =
    globalState.contractList?.map((x) => ({
      key: x.id,
      value: x.contractNo,
    })) ?? []

  const WorkOrderNo = () => {
    const [teamId, districtId] = useWatch({
      control,
      name: ['baseForm.teamId', 'baseForm.districtId'],
    })

    const _handleAdditionalChange = (event: any, newValue: KeyValPair | null) =>
      setValue('baseForm.workOrderNo', newValue?.value)

    return (
      <FormController
        controllerProps={{
          control,
          name: 'baseForm.workOrderId',
          rules: { required: true },
        }}>
        <FormSelect
          uneditable={false}
          fullWidth
          additionalChangeAction={_handleAdditionalChange}
          options={GetWorksOrderListOptions(
            workOrderList?.filter((x) => !x.crLock || x.id == workOrderId) || [],
            teamId,
            districtId,
          )}
        />
      </FormController>
    )
  }

  return (
    <FormContainer fkey="cr-a">
      <FormField fieldName="Contract No.">
        <FormController
          controllerProps={{
            control,
            name: 'baseForm.contractNoId',
            rules: { required: true },
          }}>
          <FormSelect options={contractNoList} uneditable={true} />
        </FormController>
      </FormField>

      <FormField fieldName="Team">
        <FormController controllerProps={{ control, name: 'baseForm.teamId' }}>
          <FormMultipleSelect uneditable={uneditable} options={GetTeamOptions(userInfo.teams)} />
        </FormController>
      </FormField>

      <FormField fieldName="District">
        <FormController controllerProps={{ control, name: 'baseForm.districtId' }}>
          <FormMultipleSelect
            uneditable={uneditable}
            options={GetDistrictOptions(userInfo.districts)}
          />
        </FormController>
      </FormField>

      <FormField fieldName="Works Order No.">
        {uneditable ? (
          <FormTypography value={getValues('baseForm.workOrderNo')} />
        ) : (
          <WorkOrderNo />
        )}
      </FormField>

      <FormField fieldName="CR No.">
        <FormController controllerProps={{ control, name: 'crNo' }}>
          <FormTypography />
        </FormController>
      </FormField>

      <FormField fieldName="Date of Completion of Works">
        <FormController
          controllerProps={{
            control,
            name: 'dateOfCompleteion',
            rules: { required: true },
          }}>
          <FormDateTimePicker
            type="date"
            uneditable={uneditable}
            minDate={
              getValues('firstSubmittedCompletionDate') === undefined
                ? moment(new Date()).subtract(2, 'days').toDate()
                : moment(getValues('firstSubmittedCompletionDate')).toDate()
            }
            maxDate={moment(new Date()).toDate()}
          />
        </FormController>
      </FormField>

      <FormField fieldName="Apply SBV">
        <FormController
          controllerProps={{
            control,
            name: 'applySbv',
            rules: { required: true },
          }}>
          <FormBinaryRadio uneditable={uneditable} />
        </FormController>
      </FormField>

      <FormField fieldName="Image">
        <FormController controllerProps={{ control, name: 'crImages' }}>
          <FormImageUpload uneditable={uneditable} multiple />
        </FormController>
      </FormField>

      <FormField fieldName="Contractor Remarks">
        <FormController controllerProps={{ control, name: 'contractorRemarks' }}>
          <FormFreeText
            uneditable={uneditable}
            textfieldProps={{
              multiline: true,
              rows: 4,
            }}
          />
        </FormController>
      </FormField>
    </FormContainer>
  )
}
