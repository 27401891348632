import { Box } from '@mui/material'
import { GlobalContext } from '@providers/globalStore'
import { useContext } from 'react'
import styles from '@styles/sidebar.module.scss'
import image from '../../assets/img/logo.png'
import highway from '../../assets/img/highways-department.png'

const SideMenu = () => {
    const { state, dispatch } = useContext(GlobalContext)
    const handleLeftDrawerToggle = () => {
        dispatch({ type: 'toggleLeftDrawer' })
    }
    return (
        <Box className={styles.headerLogoImage} onClick={handleLeftDrawerToggle}>
            <Box
                component="img"
                className={styles.logoImage}
                alt="Highway"
                src={highway}
                sx={{width:'60px', mb: '8px'}}
            />
            <Box
                component="img"
                className={styles.logoImage}
                alt="Varadise"
                src={image}
                sx={{width:'60px'}}
            />
        </Box>
    )
}
export default SideMenu
