import apiGateway           from '@utils/axiosInterceptor'
import {
  NFGeneralOptions,
  FormNFModel,
  NfListResponse,
  NfFilterOptions,
  NfDashboard,
  NfDashboardReq
}                           from '../model/form/form.NF.model' // TODO: EIContractOptions?
import { FormDNModel }      from '../model/form/form.DN.model'
import { BaseFormResponse } from '../model/form/form.model'

const GetGeneralOptions = async () => {
  return await apiGateway.get<NFGeneralOptions>(`/api/v1/forms/NF/options`).then((rep) => {
    return rep.data
  })
}

const GetNFList = async (req: object, cancelToken: any, myRecordOnly: boolean) => {
  return await apiGateway
    .post<NfListResponse>(`api/v1/forms/NF/${myRecordOnly ? 'my-action' : 'list'}`, req, {
      cancelToken: cancelToken,
    })
    .then((rep) => {
      return rep.data
    })
}

const GetNfDashboard = async (req: NfDashboardReq, cancelToken: any): Promise<NfDashboard> =>
    await apiGateway
        .post<NfDashboard>('api/v1/forms/NF/dashboard', req, { cancelToken: cancelToken })
        .then(resp => resp.data)

const GetNFFilterOption = async (showMyRecord?:boolean) => {
  let api = `api/v1/forms/NF/filter-options`
  if (showMyRecord) {
    api += '?my_action=true'
  }
  return await apiGateway.get<NfFilterOptions>(api).then((rep) => {
    return rep.data
  })
}

const GetNFForm = async (formId: string) => {
  return await apiGateway
    .get<FormNFModel>(`api/v1/forms/NF/${formId}`)
    .then((rep) => {
      return rep.data
    })
}

const SaveNFForm = async (req: FormNFModel) => {
  if (req.baseForm.formId === '')
    return await apiGateway.post<BaseFormResponse>('api/v1/forms/NF', req).then((rep) => {
      return rep.data
    })
  else {
    return await apiGateway
      .put<BaseFormResponse>(`api/v1/forms/NF/${req.baseForm.formId}`, req)
      .then((rep) => {
        return rep.data
      })
  }
}

const ApproveNFForm = async (req: FormNFModel) => {
  return await apiGateway
    .put<BaseFormResponse>(
      `api/v1/forms/NF/${req.baseForm.formId ? req.baseForm.formId : '_'}/approve`,
      req,
    )
    .then((rep) => {
      return rep.data
    })
}

const RejectNFForm = async (req: FormNFModel) => {
  return await apiGateway
    .put<BaseFormResponse>(`api/v1/forms/NF/${req.baseForm.formId}/reject`, req)
    .then((rep) => {
      return rep.data
    })
}

const DeleteNFForm = async (req: FormNFModel) => {
  return await apiGateway.delete<BaseFormResponse>(
    `api/v1/forms/NF/${req.baseForm.formId}`,
  )
}

const ApproveBatchDN = async (req: { signatureBase64: string; formDn: FormDNModel[] }) => {
  return await apiGateway
    .put<BaseFormResponse>(`api/v1/forms/DN/batch/approve`, req)
    .then((rep) => {
      return rep.data
    })
}

const RejectBatchDN = async (req: { signatureBase64: string; formDn: FormDNModel[] }) => {
  return await apiGateway.put<BaseFormResponse>(`api/v1/forms/DN/batch/reject`, req).then((rep) => {
    return rep.data
  })
}

const UpdateMap = async (req: FormNFModel) => {
  return await apiGateway
    .put<BaseFormResponse>(
      `api/v1/forms/NF/${req.baseForm.formId ? req.baseForm.formId : '_'}/map-image`,
      req,
    )
    .then((rep) => {
      return rep.data
    })
}

const FormNFService = {
  GetNFForm,
  GetNFList,
  GetNfDashboard,
  GetNFFilterOption,
  SaveNFForm,
  ApproveNFForm,
  RejectNFForm,
  DeleteNFForm,
  GetGeneralOptions,
  ApproveBatchDN,
  RejectBatchDN,
  UpdateMap
}

export default FormNFService