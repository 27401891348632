import ConvertorsService from '@services/convertors.service'
import createReport from 'docx-templates'
import { get } from 'lodash'
import { calculateImageWidthHeight, getCompressedImageBase64, GetBase64FromUrl, GetImageDimensions } from './image'
import { getFormData } from './export/data'
import { getEaFormData, getEaMatchedTemplates } from './export/data/ea'
import { GetFullUrl } from './file'

const additionalJsContext = {
  urlToImage: async (url: string, w?: number, h?: number) => {
    try {
      const svg_data = await GetBase64FromUrl(GetFullUrl(url))
      const widthHeight = await GetImageDimensions(GetFullUrl(url))
      const result = calculateImageWidthHeight(widthHeight, w, h)
      const compressedBase64 = await getCompressedImageBase64(GetFullUrl(url))
      let resultBase64
      if (compressedBase64 !== null || compressedBase64 !== undefined) {
        resultBase64 = typeof compressedBase64 === 'string' ? compressedBase64?.split(',')[1] : compressedBase64
      }else if (svg_data !== null || svg_data !== undefined) {
        resultBase64 = typeof svg_data === 'string' ? svg_data?.split(',')[1] : svg_data
      }

      return {
        width: get(result, 'width', 6) ,
        height: get(result, 'height', 6),
        data: resultBase64,
        extension: '.jpeg',
      }
    } catch (err) {
      console.error('Error in exporting image in pdf.')
    }
  },
  base64ToImage: async (base64: string, w?: number, h?: number) => {
    try {
      const widthHeight = await GetImageDimensions(base64)
      const result = calculateImageWidthHeight(widthHeight, w, h)

      return {
        width: get(result, 'width', 6) ,
        height: get(result, 'height', 6),
        data: base64.replace(/^data:image\/(png|jpg|jpeg);base64,/, ''),
        extension: '.jpeg',
      }
    } catch (err) {
      console.error('Error in exporting image in pdf.', err)
    }
  },
  signature: async (signatureBase64: string) => {
    try {
      return {
        width: 3,
        height: 0.7,
        data: signatureBase64,
        extension: '.jpeg',
      }
    } catch (e: any) {
      console.error('[SignatureBase64 Error]', signatureBase64, e)
    }
  },
}

const exportEa = async (data: any, optionList?: any) => {
  try {
    const template = await getTemplate('/templates/EA_template.docx')
    const contractNoId = get(data, 'baseForm.contractNoId')
    const mmValue = get(data, 'typeOfMmWorksValue', '')
    const templateType = getEaMatchedTemplates(contractNoId, mmValue)

    for (let i = 0; i < templateType.length; i++) {
      const mmType = templateType[i]

      const res = getEaFormData(data, mmType, optionList)

      const report = await createReport({
        // @ts-ignore
        template,
        data: res,
        additionalJsContext: additionalJsContext,
      })
      const reportBlob = new Blob([report], { type: 'application/vnd.openxmlformats-' })

      await saveDataToFile(reportBlob, `EA_${mmType}_${get(data, 'reportNo', 'EA')}`)
    }
  } catch (err) {
    console.error(err)
    return err
  }
}

const exportDocument = async (
  templatePath: string,
  reportName: String,
  data: any,
  data2: any,
  optionList?: any,
) => {
  const template = await getTemplate(templatePath)
  try {
    const report = await createReport({
      // @ts-ignore
      template,
      data: getFormData(templatePath, data, data2, optionList),
      additionalJsContext: additionalJsContext,
    })

    const reportBlob = new Blob([report], { type: 'application/vnd.openxmlformats-' })
    await saveDataToFile(reportBlob, reportName)
    
  } catch (err) {
    console.error(err)
    return err
  }
}

async function getTemplate(dataPath): Promise<Blob> {
  const request = await fetch(dataPath)
  const defaultTemplate = await request.blob()
  return defaultTemplate
}

// Load the user-provided file into an ArrayBuffer
const readFileIntoArrayBuffer = (fd): Promise<any> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onerror = reject
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.readAsArrayBuffer(fd)
  })

const saveDataToFile = async (blob, fileName) => {
  try {
    const _fileName = `${fileName}.pdf`

    const formData = new FormData()
    formData.append('file', blob)
    const pdfBlob = await ConvertorsService.ConvertFiles(formData)

    const url = window.URL.createObjectURL(pdfBlob)
    downloadURL(url, _fileName)
    setTimeout(() => {
      window.URL.revokeObjectURL(url)
    }, 1000)
  } catch (error) {
    console.error(error)
    return error
  }
}

const downloadURL = (data, fileName) => {
  const a = document.createElement('a')
  a.href = data
  a.download = fileName
  document.body.appendChild(a)
  a.click()
  a.remove()
}

export { exportDocument, exportEa }
