import { ListItemIcon, SvgIcon, Box } from '@mui/material'
import { ReactComponent as Car } from '@images/presentation-alt.svg'
import styles from '@styles/formicon.module.scss'

export interface formIconProps {
    shortName: string
    shortNameBgColor: string
    IconSx?: object | Array<object>
    SvgSx: object | Array<object>
}

const FormIcon = (props: formIconProps) => {
    return (
        <Box>
            <ListItemIcon
                style={
                    { '--data-code-bg-color-var': props.shortNameBgColor } as React.CSSProperties
                }
                sx={props.IconSx}
                className={styles.afa}
                data-code={props.shortName}
            >
                <SvgIcon sx={props.SvgSx}>
                    <Car />
                </SvgIcon>
            </ListItemIcon>
        </Box>
    )
}
export default FormIcon
