import { Button } from '@mui/material'
import { GlobalContext } from '@providers/globalStore'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

export type BaseButtonProps = {
  className?: string
  isShown: boolean
  isDisabled: boolean
  displayText: string
  onClickHanlder?: Function
}

const BaseButton = (props: BaseButtonProps) => {
  const { t } = useTranslation()
  const { state } = useContext(GlobalContext)

  const handleOnClick = (onClickHanlder) => {
    // console.log(
    //   'BaseButton - handleOnClick > props.onClickHanlder: ',
    //   onClickHanlder,
    //   '( type: ',
    //   typeof onClickHanlder,
    //   ')',
    // )
    if (onClickHanlder === undefined || typeof onClickHanlder !== 'function') {
      // console.log('no btn click event')
      return
    } else {
      // console.log('has btn click event')
      return onClickHanlder
    }
  }

  return (
    <Button
      className={props.className}
      disabled={props.isDisabled}
      onClick={handleOnClick(props.onClickHanlder)}
      sx={{
        color: props.isDisabled ? 'rgba(0, 0, 0, 0.26) !important' : 'inherit',
      }}
      {...(!props.isShown && {
        sx: {
          display: 'none',
        },
      })}>
      {props.displayText}
    </Button>
  )
}

export default BaseButton
