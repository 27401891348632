export const structuralMaintainence = [
  'Conduct Inspections of Highway Structures and Undertake Structural Maintenance',
  'Conducting Inspections of Highway Structures and Undertaking Structural Maintenance',
  'Conducting Inspections of Highway Structures and Undertake Structural Maintenance',
  'Conduct Inspections on Highway Structures and Undertake Structural Maintenance',
]
export const tunnel = [
  'Conducting Inspections of Tunnel Segments and Undertaking Structural Maintenance',
  'Conducting Inspection of Tunnel Segements and Undertaking Structural Maintenance',
  'Conduct Inspections of Tunnel Segments and Undertake Structural Maintenance',
]
export const detailedInspection = [
  'Conduct Detailed Inspections of Roads and Undertake Associated General Road Maintenance Works',
  'Detailed Inspections and Associated General Road Maintenance Works',
  'Detailed Inspections on Roads and Undertake Associated General Road Maintenance Works',
  'Conduct Detailed Inspections of Roads and Undertake Associated General Road Maintenance Works',
  'Maintenance of Road Marking, Road Drainage System, Detailed Inspections in Hygienic Conditions',
  'Maintenance of Road Marking, Road Drainage System and Detailed Inspections',
]
export const slope = [
  'Conducting Routine Maintenance Inspection for and Maintenance of Roadside Slopes',
  'Conduct Routine Maintenance Inspection For and Maintain Roadside Slopes',
]
export const stepping = [
  'Conduct Inspection of Stepping ??mm and ??0mm on Paver Footways',
  'Stepping Inspection on Paver Footways',
  'Conduct Inspection of Stepping ≧5mm and ≦10mm on Paver Footways'
]
export const landscape = [
  'Maintain Vegetation on Vegetated Highway Registered Slopes and Designated Planted Areas',
  'Vegetation Maintenance on Roadsides Slopes and Designated Planted Areas',
  'Maintain Vegetation on Vegetated Highway Registered Slopes and Designated Planted Areas',
]
export const roadMarking = [
  'Maintain Road Markings and Road Studs',
  'Maintenance of Road Marking and Road Studs',
  'Maintenance of Road Marking',
  'Conduct Inspections for and Maintenance of Road Marking',
  'Maintenance of Road Marking, Road Drainage System, Detailed Inspections in Hygienic Conditions',
  'Maintenance of Road Marking, Road Drainage System and Detailed Inspections',
]
export const roadDrainage = [
  'Maintain Road Drainage System',
  'Maintenance of Road Drainage System',
  'Maintenance of Road Marking, Road Drainage System, Detailed Inspections in Hygienic Conditions',
  'Maintenance of Road Marking, Road Drainage System and Detailed Inspections',
]

/* Do not have template */
export const routineInspection = [
  'Routine Inspections and Associated General Road Maintenance Works',
  'Conducting Routine Inspections and Undertake Associated General Road Maintenance Works',
  'Conduct Routine Inspections and Undertake Associated General Road Maintenance Works',
]

export const templateMapping = {
  structuralMaintainence : structuralMaintainence,
  tunnel : tunnel,
  detailedInspection : detailedInspection,
  slope : slope,
  stepping : stepping,
  landscape : landscape,
  roadMarking : roadMarking,
  roadDrainage : roadDrainage,
  routineInspection : routineInspection,  /** no custom */
}

// For contract 05/HY/2018 and 08/HT/2021, use below mapping
export const roadMarkingV2 = [
  'Maintain Road Markings and Road Studs',
  'Maintenance of Road Marking and Road Studs',
  'Maintenance of Road Marking',
  'Conduct Inspections for and Maintenance of Road Marking',
  'Maintaining the Road Network in Hygienic Conditions'
]
export const roadDrainageV2 = [
  'Maintain Road Drainage System',
  'Maintenance of Road Drainage System',
]

export const templateMappingV2 = {
  structuralMaintainence : structuralMaintainence,
  tunnel : tunnel,
  detailedInspection : detailedInspection,
  slope : slope,
  stepping : stepping,
  landscape : landscape,
  roadMarking : roadMarkingV2,
  roadDrainage : roadDrainageV2,
  routineInspection : routineInspection,  /** no custom */
}