import { Typography } from '@mui/material'

type FormStatusLabelProps = {
  label: string
  color: string
}

const FormStatusLabel = (props: FormStatusLabelProps) => {
  return (
    <Typography
      variant="body2"
      component="div"
      color={props.color}
      sx={{
        width: 'min-content',
        minWidth: '60px',
        border: `${props.color} 2px solid`,
        borderRadius: '10%',
        p: 1,
        textAlignLast: 'center',
      }}>
      {props.label}
    </Typography>
  )
}
export default FormStatusLabel
