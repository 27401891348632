import { Box, Grid, Tooltip, Typography, useMediaQuery } from '@mui/material'
import { SxProps, styled, useTheme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import commonStyles from '@styles/common.module.scss'

const StyledTypography = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
}))

const FormFieldContainer = styled(Grid)<{
  state: { mobileLayout?: boolean }
}>(({ state, theme }) => ({
  display: 'flex',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.up('md')]: {
    flexDirection: state.mobileLayout ? 'column' : 'row',
    padding: state.mobileLayout ? '0px' : '14px 0px',
  },
  minHeight: 67,
  whiteSpace: 'initial',
  wordBreak: 'break-word',
}))

const FormFieldNameBox = styled(Box)<{
  state: { mobileLayout?: boolean }
}>(({ state, theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '100%',
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  [theme.breakpoints.up('md')]: {
    ...(!state.mobileLayout && {
      paddingLeft: 60,
      minWidth: 200,
      width: '18%',
      paddingRight: 'max(2%, 20px);',
    }),
    ...(state.mobileLayout && {
      width: '100%',
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    }),
  },
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'flex-start',
}))

const FormFieldContentBox = styled(Box)<{
  state: { mobileLayout?: boolean }
}>(({ state, theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '100%',
    padding: theme.spacing(2),
  },
  [theme.breakpoints.up('md')]: {
    ...(!state.mobileLayout && { paddingRight: 140 }),
    ...(state.mobileLayout && {
      width: '100%',
      padding: theme.spacing(2),
    }),
  },
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'flex-start',
}))

interface formFieldProps {
  children: any
  additionalChildren?: any
  fieldName: string
  subfieldName?: string
  index?: number
  fieldTooltip?: string
  fieldTooltipPlacement?: 'top' | 'right' | 'bottom' | 'left'
  removeColon?: boolean
  hidden?: boolean
  mobileLayout?: boolean
  hiddenFieldName?: boolean
  fixedWidth?: SxProps
}

/**
 * form field wrapping a child jsx element
 * @param props formFieldProps
 * @example
 * ```
 * <FormField fieldName="Weather" index={8}>
 *      <FormController
 *          controllerProps={{
 *              name: 'weatherId',
 *              control: control,
 *          }}>
 *          <FormSelect fullWidth options={masterData.weather} />
 *      </FormController>
 *  </FormField>
 * ```
 */
const FormField = (props: formFieldProps) => {
  const { t } = useTranslation()

  if (props.hidden) {
    return <></>
  } else if (props.fixedWidth) {
    return <Grid sx={props.fixedWidth}>{props.children}</Grid>
  } else {
    return (
      <FormFieldContainer
        item
        xs={12}
        className="grid-item"
        state={{ mobileLayout: props.mobileLayout }}>
        <FormFieldNameBox state={{ mobileLayout: props.mobileLayout }}>
          <Tooltip
            title={props.fieldTooltip ? t(props.fieldTooltip) : ''}
            placement={props.fieldTooltipPlacement ?? 'right'}>
            <Box>
              {!props.hiddenFieldName && (
                <StyledTypography>
                  {t(props.fieldName) + (!props.removeColon && !props.subfieldName ? ':' : '')}
                </StyledTypography>
              )}
              {props.subfieldName && (
                <StyledTypography>{t(props.subfieldName) + ':'}</StyledTypography>
              )}
            </Box>
          </Tooltip>
        </FormFieldNameBox>
        <FormFieldContentBox
          className={commonStyles.fillBox}
          state={{ mobileLayout: props.mobileLayout }}>
          {props.children}
          {props.additionalChildren}
        </FormFieldContentBox>
      </FormFieldContainer>
    )
  }
}
export default FormField
