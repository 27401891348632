import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Grid } from '@mui/material'
import { FormStatus } from '@services/model/form/form.model'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import BookIcon from '@mui/icons-material/Book'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import FormField from '@components/form/field'
import FormController from '@components/form/controller'
import FormDateTimePicker from '@components/form/dateTimePicker'
import FormImageUpload from '@components/form/imageUpload'
import FormFreeText from '@components/form/freeText'

import { KeyValPair } from '@models/common'
import { NfRectificationModel } from '@services/model/form/form.NF.model'

type formRectificationsProps = {
  rectifications?: NfRectificationModel[]
  handleOnComplete?: () => void
  control?: any
  errors?: any
  endOfFlow?: boolean
  uneditable: boolean
}

/**
 * form rectifications showcasing history of rectifications and related fields from action histories
 * @param props formRectificationsProps
 * @example
 * ```
 * <formRectifications rectifications={form.rectifications} />
 * ```
 */
const FormRectifications = (props: formRectificationsProps) => {
  const [open, setOpen] = useState<boolean[]>(
    props.rectifications?.map((_: NfRectificationModel, index: number) =>
      index === (props.rectifications?.length || 1) - 1 ? true : false,
    ) || [],
  )
  const { t } = useTranslation()
  const handleOpen = (index: number) => {
    const _open = [...open]
    _open[index] = !_open[index]
    setOpen(_open)
  }

  const zeroPad = (num, places) => String(num).padStart(places, '0')

  return (
    <Grid xs={12}>
      <List>
        {props.rectifications?.map((rectification: NfRectificationModel, index: number) => (
          <List key={`form-rectification-${index}-${rectification.rectificationVersion}`}>
            <ListItemButton
              onClick={(_: any) => {
                handleOpen(index)
              }}>
              <ListItemIcon>
                <BookIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  'Submission: ' +
                  zeroPad(
                    rectification.rectificationVersion
                      ? rectification.rectificationVersion
                      : props.rectifications?.length,
                    3,
                  ).toString()
                  // make util for 1 => 001 format
                }
              />
              {open[index] ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>

            <Collapse in={open[index]}>
              {props.endOfFlow ? (
                <></>
              ) : (
                <Fragment>
                  {rectification.rectificationDate === undefined ? (
                    <></>
                  ) : (
                    <FormField fieldName="Date & Time">
                      <FormController
                        controllerProps={{
                          name: `rectifications.${index}.rectificationDate`,
                          control: props.control,
                          rules: { required: true },
                        }}>
                        <FormDateTimePicker type="datetime" uneditable={props.uneditable} />
                      </FormController>
                    </FormField>
                  )}

                  <FormField fieldName="Images">
                    <FormController
                      controllerProps={{
                        name: `rectifications.${index}.rectificationImages`,
                        control: props.control,
                      }}>
                      <FormImageUpload uneditable={props.uneditable} multiple />
                    </FormController>
                  </FormField>
                  <FormField fieldName="Other Informaton">
                    <FormController
                      controllerProps={{
                        name: `rectifications.${index}.otherInfo`,
                        control: props.control,
                      }}>
                      <FormFreeText
                        textfieldProps={{
                          fullWidth: true,
                          multiline: true,
                          rows: 10,
                        }}
                        sx={{
                          width: '90%',
                        }}
                        uneditable={props.uneditable}
                      />
                    </FormController>
                  </FormField>
                </Fragment>
              )}
            </Collapse>
          </List>
        ))}
      </List>
    </Grid>
  )
}

export default FormRectifications
