import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { Control, UseFormGetValues, UseFormSetValue, UseFormWatch, useWatch } from 'react-hook-form'
import uuid from 'react-uuid'
import moment from 'moment'
import { IconButton, Typography, Tooltip } from '@mui/material'
import { GlobalStateProps } from '@providers/globalStore'
import FormContainer from '@components/form/container'
import FormController from '@components/form/controller'
import FormDateTimePicker from '@components/form/dateTimePicker'
import FormField from '@components/form/field'
import FormSelect from '@components/form/select'
import FormBinaryRadio from '@components/form/binaryRadio'
import FormMultipleSelect from '@components/form/multipleSelect'
import FormFileUpload from '@components/form/fileUpload'
import FormDrawer from '@components/form/drawer'
import ExportIcon from '@components/icon/export'
import { ContractNo } from '@services/model/contract.model'
import { UserInfo } from '@services/model/user.model'
import { CDRGeneralOptions, FormCdrModel } from '@services/model/form/form.CDR.model'
import { FormStatusEnum } from '@services/model/form/form.model'
import { GetTeamOptions } from '@utils/teamOptions'
import { GetDistrictOptions } from '@utils/districtOptions'
import { exportToCSV } from '@utils/exportExcel'
import { FileModel } from '@services/model/file.model'
import { size } from 'lodash'

export default ({
  control,
  getValues,
  globalState,
  watch,
  userInfo,
  setDisableNav,
  setValue,
  formStatus,
  options,
  matchUpMd,
  contractInfo,
  handleGetWorksOrderReference,
  drawerCanOpen,
  setDrawerCanOpen,
}: {
  control: Control<FormCdrModel>
  getValues: UseFormGetValues<FormCdrModel>
  globalState: GlobalStateProps
  watch: UseFormWatch<FormCdrModel>
  userInfo: UserInfo
  setDisableNav: Dispatch<SetStateAction<boolean>>
  setValue: UseFormSetValue<FormCdrModel>
  formStatus: string
  options: CDRGeneralOptions
  matchUpMd: boolean
  contractInfo?: ContractNo
  handleGetWorksOrderReference: () => Promise<any>
  drawerCanOpen?: boolean
  setDrawerCanOpen: Dispatch<SetStateAction<boolean>>
}) => {
  const UUID = uuid()
  const uneditable = formStatus !== FormStatusEnum.FORM_CDR_DRAFT && formStatus !== 'Draft'

  const contractNoList =
    globalState.contractList?.map((x) => {
      return {
        key: x.id,
        value: x.contractNo,
      }
    }) || []

  useEffect(() => {
    const subscription = watch((data) => {
      if (
        data.baseForm?.contractNoId &&
        data.baseForm?.teamId &&
        data.baseForm?.districtId &&
        data.typeOfWorks !== undefined
      ) {
        if (contractInfo?.highSpeedRoad) {
          if (data.shiftType !== undefined) setDrawerCanOpen(true)
        } else setDrawerCanOpen(true)
      }

      if (formStatus !== FormStatusEnum.FORM_CDR_DRAFT) setDisableNav(false)
      else if (
        (data.baseForm?.contractNoId &&
          data.formDate &&
          moment(data.formDate).isValid() &&
          data.baseForm?.teamId &&
          data.baseForm?.districtId &&
          data.typeOfWorks !== undefined &&
          data?.weatherId &&
          data?.excelRef === false) ||
        (data?.excelRef === true &&
          data?.cdrExcelFile !== undefined &&
          size(data?.cdrExcelFile) !== 0)
      ) {
        if (contractInfo?.highSpeedRoad) {
          if (data?.shiftType !== undefined) setDisableNav(false)
        } else setDisableNav(false)
      }
    })

    return () => subscription.unsubscribe()
  }, [watch])

  const CdrExcelFileField = () => {
    const excelRef = useWatch({ control, name: 'excelRef' })

    if (excelRef) {
      return (
        <FormField fieldName="Upload Excel">
          <FormController controllerProps={{ control, name: 'cdrExcelFile' }}>
            <FormFileUpload
              height="125"
              disabled={uneditable}
              acceptFiles={[
                'application/vnd.ms-excel',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              ]}
              additionalChangeAction={(file: FileModel[]) => {
                if (file.length === 0 && excelRef) {
                  setDisableNav(true)
                  setValue('workActivities', [])
                } else {
                  setValue('firstPageData', true)
                }
              }}
            />
          </FormController>
        </FormField>
      )
    } else return <></>
  }

  const TypesOfWorksDrawer = () => {
    const [worksOrderReference, typeOfWorks] = useWatch({
      control,
      name: ['worksOrderReference', 'typeOfWorks'],
    })

    const _downloadWorksOrderReference = async () => {
      if (worksOrderReference) {
        if (typeOfWorks) {
          const excel = {
            'Site Activity': '',
            'Item No': '',
            'Road Name & Location Details': '',
            'Proposed activity': '',
            'Day/Night Works': '',
            'No. of Worker': '',
            'LSG On Site': '',
            'Reason for No Activity': '',
            'Site Idle Reason': '',
            'Site Idle Reason Others': '',
          }

          const data = worksOrderReference.map((wo) => ({
            'Date (Ref. Only)': moment(getValues('formDate')).format('YYYY-MM-DD'),
            'Works Order No.': wo,
            ...excel,
          }))

          // for empty line
          data.push({
            'Date (Ref. Only)': '',
            'Works Order No.': '',
            ...excel,
          })

          // for legend (explanation)
          data.push({
            'Date (Ref. Only)': 'Option to be chosen',
            'Works Order No.': '',
            ...excel,
            'Site Activity': 'Active\nInactive',
            'Day/Night Works': 'Night\nDay',
            'LSG On Site': 'Y\nN',
            'Reason for No Activity':
              'Completed in early morning on {today}\nCommenced but site idle\nCompleted on {yesterday}\nNot yet commenced\n',
            'Site Idle Reason': 'Inclement weather\nPending UU Diversion\nOthers\n',
          })

          await exportToCSV(data, 'Works Order Reference – M&M Works')
        } else {
          const excel = {
            'Site Activity': '',
            'Item No': '',
            'Road Name & Location Details': '',
            'Proposed activity': '',
            'Day/Night Works': '',
            'No. of Worker': '',
            'LSG On Site': '',
            'Essential Operation': '',
            'Hidden Works Other Than LSG': '',
            'In-situ Tests/In-situ Sampling': '',
            'Reason for No Activity': '',
            'Site Idle Reason': '',
            'Site Idle Reason Others': '',
          }

          const data = worksOrderReference.map((wo) => ({
            'Date (Ref. Only)': moment(getValues('formDate')).format('YYYY-MM-DD'),
            'Works Order No.': wo,
            ...excel,
          }))

          // for empty line
          data.push({
            'Date (Ref. Only)': '',
            'Works Order No.': '',
            ...excel,
          })

          // for legend (explanation)
          data.push({
            'Date (Ref. Only)': 'Option to be chosen',
            'Works Order No.': '',
            ...excel,
            'Site Activity': 'Active\nInactive',
            'Day/Night Works': 'Night\nDay',
            'LSG On Site': 'Y\nN',
            'Essential Operation': 'Y\nN',
            'Hidden Works Other Than LSG': 'Y\nN',
            'In-situ Tests/In-situ Sampling': 'Y\nN',
            'Reason for No Activity':
              'Completed in early morning on {today}\nCommenced but site idle\nCompleted on {yesterday}\nNot yet commenced\n',
            'Site Idle Reason': 'Inclement weather\nPending UU Diversion\nOthers\n',
          })

          await exportToCSV(data, 'Works Order Reference – Non-M&M Works')
        }
      }
    }

    return (
      <FormDrawer
        buttonText="Works Order Reference"
        title={typeOfWorks ? 'M&M Works Order' : 'Non M&M Works Order'}
        hidden={uneditable}
        matchUpMd={matchUpMd}
        disabled={!drawerCanOpen}
        onClickButton={async () => await handleGetWorksOrderReference()}
        additionalIcons={
          (worksOrderReference?.length ?? 0) > 0 && (
            <Tooltip title="Export">
              <IconButton
                title="Export to Excel"
                onClick={_downloadWorksOrderReference}
                sx={{
                  backgroundColor: 'currentcolor',
                  borderRadius: '10%',
                  marginLeft: 'auto',
                  width: 35,
                  height: 35,
                  '&:hover': { background: 'currentcolor' },
                }}>
                <ExportIcon />
              </IconButton>
            </Tooltip>
          )
        }>
        <FormContainer>
          {worksOrderReference?.length === 0 ? (
            <Typography sx={{ mt: '20px' }}>
              No works order under this district and team.
            </Typography>
          ) : (
            worksOrderReference?.map((wo) => (
              <FormField fieldName="" mobileLayout={true} hiddenFieldName={true}>
                <Typography>{wo}</Typography>
              </FormField>
            ))
          )}
        </FormContainer>
      </FormDrawer>
    )
  }

  return (
    <FormContainer fkey={`${UUID}-cdr-a`}>
      <FormField fieldName="Contract No.">
        <FormController
          controllerProps={{
            name: 'baseForm.contractNoId',
            control: control,
          }}>
          <FormSelect
            fullWidth
            options={contractNoList}
            uneditable={true}
            additionalChangeAction={(event) => {
              setValue('firstPageData', true)
            }}
          />
        </FormController>
      </FormField>
      {getValues('baseForm.contractNoId') > 0 ? (
        <>
          <FormField fieldName="Contractor's Daily Report No.">
            <Typography>{getValues('cdrNo')}</Typography>
          </FormField>
          <FormField fieldName="Date">
            <FormController
              controllerProps={{
                name: 'formDate',
                control: control,
              }}>
              <FormDateTimePicker
                type="date"
                minDate={moment(new Date()).toDate()}
                maxDate={moment(new Date()).add(6, 'days').toDate()}
                uneditable={uneditable}
                additionalChangeAction={(value) => {
                  setValue('firstPageData', true)
                }}
              />
            </FormController>
          </FormField>
          <FormField fieldName="District">
            <FormController
              controllerProps={{
                name: 'baseForm.districtId',
                control: control,
              }}>
              <FormMultipleSelect
                options={
                  uneditable
                    ? globalState.districtList || []
                    : GetDistrictOptions(userInfo.districts)
                }
                uneditable={uneditable}
                additionalChangeAction={(event) => {
                  setValue('firstPageData', true)
                }}
                limit={1}
              />
            </FormController>
          </FormField>
          <FormField fieldName="Site Supervision Team">
            <FormController
              controllerProps={{
                name: 'baseForm.teamId',
                control: control,
              }}>
              <FormMultipleSelect
                options={uneditable ? globalState.teamList || [] : GetTeamOptions(userInfo.teams)}
                uneditable={uneditable}
                additionalChangeAction={(event) => {
                  setValue('firstPageData', true)
                }}
                limit={1}
              />
            </FormController>
          </FormField>
          {contractInfo?.highSpeedRoad && (
            <FormField fieldName="Shift Type">
              <FormController
                controllerProps={{
                  name: 'shiftType',
                  control: control,
                }}>
                <FormSelect
                  fullWidth
                  uneditable={uneditable}
                  options={
                    options?.shiftType?.map((x) => {
                      return {
                        key: x.id,
                        value: x.name,
                      }
                    }) || []
                  }
                />
              </FormController>
            </FormField>
          )}
          <FormField fieldName="Type of Works">
            <FormController
              controllerProps={{
                name: 'typeOfWorks',
                control: control,
              }}>
              <FormBinaryRadio
                binaryOptionNames={{ yes: 'M&M Works', no: 'Non-M&M Works' }}
                uneditable={uneditable}
                additionalChangeAction={(event) => {
                  setValue('firstPageData', true)
                }}
              />
            </FormController>
            <TypesOfWorksDrawer />
          </FormField>
          <FormField fieldName="Weather">
            <FormController
              controllerProps={{
                name: 'weatherId',
                control: control,
              }}>
              <FormSelect
                fullWidth
                options={
                  options?.weatherList?.map((x) => {
                    return {
                      key: x.id,
                      value: x.name,
                    }
                  }) || []
                }
                uneditable={uneditable}
              />
            </FormController>
          </FormField>

          {formStatus === FormStatusEnum.FORM_CDR_DRAFT && (
            <FormField fieldName="Upload">
              <FormController
                controllerProps={{
                  name: 'excelRef',
                  control: control,
                }}>
                <FormBinaryRadio
                  additionalChangeAction={(event: any) => {
                    setValue('firstPageData', true)
                    if (event.target.value === 'false') {
                      setValue('cdrExcelFile', [])
                      setValue('firstPageData', true)
                    } else {
                      setDisableNav(true)
                      setValue('firstPageData', true)
                    }
                  }}
                  uneditable={uneditable}
                />
              </FormController>
            </FormField>
          )}

          <CdrExcelFileField />
        </>
      ) : (
        <></>
      )}
    </FormContainer>
  )
}
