import apiGateway from '@utils/axiosInterceptor'
import { FormStatus, FormCountByUser } from './model/form/form.model'
import { GeneralConfig, GeneralOptionDNGroup, GeneralOptionGroup } from './model/general.model'
import { EaListResponse } from './model/form/form.EA.model'

const GetFormActionList = async () => {
    return await apiGateway.get<FormStatus[]>('/api/v1/forms/form-status-list').then((rep) => {
        return rep.data
    })
}

const GetGeneralOptionGroupList = async () => {
    return await apiGateway.get<GeneralOptionGroup[]>('/api/v1/general-options').then((rep) => {
        return rep.data
    })
}

const GetGeneralOptionDNGroupList = async () => {
    return await apiGateway.get<GeneralOptionDNGroup[]>('/api/v1/general-options/non-compliance').then((rep) => {
        return rep.data
    })
}

const GetGeneralConfigList = async () => {
    return await apiGateway.get<GeneralConfig[]>('/api/v1/general-config').then((rep) => {
        return rep.data
    })
}

const GetUserFormCount = async () => {
    const all = await apiGateway.get<FormCountByUser[]>('/api/v1/forms/count').then((rep) => {
        return rep.data.filter((x) => x.formCode !== 'EA')
    })

    const ea =  await apiGateway.post<EaListResponse>(`api/v1/forms/EA/my-action`, {"pagination":{"page":1,"pageSize":1}})
    .then((rep) => {
      return rep.data.totalCount
    })

    all.push({formCode: 'EA', totalCount: ea})
    return all
}

const GeneralOptionsService = {
    GetGeneralOptionGroupList,
    GetGeneralOptionDNGroupList,
    GetFormActionList,
    GetGeneralConfigList,
    GetUserFormCount
}

export default GeneralOptionsService
