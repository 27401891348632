import FormContainer from '@components/form/container'
import FormController from '@components/form/controller'
import FormField from '@components/form/field'
import FormFileUpload from '@components/form/fileUpload'
import {FormPartComponents, KeyValPair} from '@models/common'
import { FormStatusEnum } from '@services/model/form/form.model'
import { FormScrModel } from '@services/model/form/form.SCR.model'
import { t } from 'i18next'
import { Control } from 'react-hook-form'
import FormBinaryRadio from "@components/form/binaryRadio";
import FormSelect from "@components/form/select";
import FormHWRService from "@services/formService/form.HWR.service";

export default ({
  control,
  getValues,
  useWatch,
  formStatus
}: {
  control: Control<FormScrModel>
  getValues: any
  useWatch: any
  formStatus: string
}) => {
  const editable =
    formStatus === FormStatusEnum.FORM_SCR_DRAFT || formStatus === FormStatusEnum.FORM_SCR_REJECTED

    const TempHiddenWorkRef = () => {
        const [hiddenWorkCheck] = useWatch({
            name: ['hiddenWorkCheck'],
            control: control,
        })

        return (
            <FormField fieldName="Photo / Attachment Upload">
                <FormController
                    controllerProps={{
                        name: 'hiddenWorkRefs',
                        control: control,
                    }}>
                    <FormFileUpload disabled={!editable||!hiddenWorkCheck} multipleUpload />
                </FormController>
            </FormField>
        )
    }

  return (
      <FormContainer fkey={`scr-h`}>
          <FormField fieldName="Document Availability">
              <FormController
                  controllerProps={{
                      name: 'hiddenWorkCheck',
                      control: control,
                  }}>
                  <FormBinaryRadio
                      binaryOptionNames={{
                          yes: 'Yes',
                          no: 'No',
                      }}
                      uneditable={!editable}
                  />
              </FormController>
          </FormField>
        <TempHiddenWorkRef />
      </FormContainer>
    )
  
}
