import FormContainer from '@components/form/container'
import FormController from '@components/form/controller'
import FormField from '@components/form/field'
import FormSelect from '@components/form/select'
import { Control, UseFormSetValue } from 'react-hook-form'
import uuid from 'react-uuid'
import { FormStatusEnum } from '@services/model/form/form.model'
import FormTypography from '@components/form/typography'
import { EIAGeneralOptions, FormEIAModel } from '@services/model/form/form.EIA.model'
import FormMultipleSelect from '@components/form/multipleSelect'
import { GetTeamOptions } from '@utils/teamOptions'
import { GetDistrictOptions } from '@utils/districtOptions'
import { GetWorksOrderOptions } from '@utils/worksOrderOptions'
import { ForwardedRef, forwardRef, useEffect, useMemo, useRef, useState } from 'react'
import { GlobalStateProps } from '@providers/globalStore'
import FormTable from '@components/form/table'
import { IconButton } from '@mui/material'
import { Box } from '@mui/system'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { useNavigate } from 'react-router-dom'
import { NavigateTo } from '@utils/navigate'

declare module 'react' {
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null,
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null
}

function PartAInner(
  {
    control,
    formStatus,
    userInfo,
    useWatch,
    options,
    globalState,
    setValue,
    isMounted,
    watch,
    getValues,
  }: {
    control: Control<FormEIAModel>
    formStatus: string
    userInfo: any
    useWatch: any
    options: EIAGeneralOptions
    globalState: GlobalStateProps
    setValue: UseFormSetValue<FormEIAModel>
    isMounted: any
    watch: any
    getValues: any
  },
  ref: ForwardedRef<HTMLUListElement>,
) {
  const UUID = uuid()
  // const [contractNoId] = watch(['baseForm.contractNoId'])
  const navigate = useNavigate()

  const uneditable =
    formStatus === FormStatusEnum.FORM_EIA_ASSIGNED ||
    formStatus === FormStatusEnum.FORM_EIA_COMPLETED

  const hidePen = formStatus === FormStatusEnum.FORM_EIA_DRAFT
  const hideDelete = formStatus !== FormStatusEnum.FORM_EIA_DRAFT

  const contractNoList =
    globalState.contractList?.map((x) => {
      return {
        key: x.id,
        value: x.contractNo,
      }
    }) || []

  const [contractOptions, setContractOptions] = useState(
    globalState.contractList?.map((x) => {
      return {
        key: x.id,
        value: x.contractNo,
      }
    }) || [],
  )

  useEffect(() => {
    setContractOptions(
      globalState.contractList?.map((x) => {
        return {
          key: x.id,
          value: x.contractNo,
        }
      }) || [],
    )
  }, [globalState])

  const assignedToList =
    options?.assignedTo?.map(({ id, name }) => {
      return {
        key: id,
        value: name,
      }
    }) || []

  const [contractNoId, teamId, districtId] = useWatch({
    name: ['baseForm.contractNoId', 'baseForm.teamId', 'baseForm.districtId'],
    control,
  })

  const matchedContract = globalState.contractList?.find(
    (contractNo) => contractNo.id === contractNoId,
  )
  return (
    <FormContainer fkey={`${UUID}-eia-a`}>
      <FormField fieldName="Contract No.">
        <FormController
          controllerProps={{
            name: 'baseForm.contractNoId',
            control,
            rules: { required: true },
          }}>
          <FormSelect fullWidth options={contractOptions} uneditable={true} />
        </FormController>
      </FormField>

      <FormField fieldName="Team">
        <FormController
          controllerProps={{
            name: 'baseForm.teamId',
            control,
          }}>
          <FormMultipleSelect
            options={uneditable ? globalState.teamList || [] : GetTeamOptions(userInfo.teams)}
            uneditable={uneditable}
            additionalChangeAction={(event) => {
              setValue('baseForm.workOrderId', undefined)
            }}
          />
        </FormController>
      </FormField>

      <FormField fieldName="District">
        <FormController
          controllerProps={{
            name: 'baseForm.districtId',
            control,
          }}>
          <FormMultipleSelect
            options={
              uneditable ? globalState.districtList || [] : GetDistrictOptions(userInfo.districts)
            }
            uneditable={uneditable}
            additionalChangeAction={(event) => {
              setValue('baseForm.workOrderId', undefined)
            }}
          />
        </FormController>
      </FormField>

      <FormField fieldName="From">
        <FormTypography type="string" value={matchedContract?.client} />
      </FormField>
      <FormField fieldName="Contractor">
        <FormTypography type="string" value={matchedContract?.contractor} />
      </FormField>
      <FormField fieldName="EIA No">
        <FormController
          controllerProps={{
            name: 'eiaNo',
            control,
          }}>
          <FormTypography />
        </FormController>
      </FormField>
      <FormField fieldName="Assigned To">
        {getValues('assignedToWithEffectiveDuty') && uneditable ? (
          <FormController
            controllerProps={{
              name: 'assignedToWithEffectiveDuty',
              control,
              rules: { required: true },
            }}>
            <FormTypography />
          </FormController>
        ) : (
          <FormController
            controllerProps={{
              name: 'assignedTo',
              control,
              rules: { required: true },
            }}>
            <FormSelect fullWidth options={assignedToList} uneditable={uneditable} />
          </FormController>
        )}
      </FormField>
      <FormTable
        fieldArrayProps={{
          name: 'eiAssignments',
          control: control,
        }}
        columns={[
          {
            field: 'caseSourceId',
            displayName: 'Case Source',
            type: 'select',
            options:
              options?.caseSource?.map((x) => {
                return {
                  key: x.id,
                  value: x.name,
                }
              }) || [],
            width: '100px',
            uneditable: uneditable,
          },
          {
            field: 'eiNo',
            displayName: 'EI No.',
            type: 'string',
            uneditable: true,
          },
          {
            field: 'iccNo',
            displayName: 'ICC No.',
            type: 'string',
            uneditable: uneditable,
          },
          {
            field: 'workOrderId',
            displayName: 'Works Order No.',
            type: 'select',
            uneditable: uneditable,
            options: GetWorksOrderOptions(globalState.worksOrderList || [], teamId, districtId),
          },
          {
            field: 'locationRemark',
            displayName: 'Location Remarks',
            type: 'string',
            uneditable: uneditable,
          },
          {
            field: 'remarks',
            displayName: 'Remarks',
            type: 'string',
            uneditable: uneditable,
          },
          {
            field: 'eiStatus',
            displayName: 'Status',
            type: 'displaySelected',
            options: [
              { key: true, value: 'Active' },
              { key: false, value: 'Cancelled' },
            ],
            uneditable: true,
          },
        ]}
        rowFilter={() => true}
        // hiddenItemNo
        removeAddButton={uneditable}
        removeDeleteButton={uneditable}
        extraButtons={(datumIndex) => {
          return hidePen ? (
            <></>
          ) : (
            <Box
              id="table-row-actions"
              sx={{
                position: 'absolute',
                transition: 'transform',
                transform: 'translateX(-100%)',
                display: 'none',
                ':hover': {
                  display: 'inherit',
                },
              }}>
              <IconButton
                disabled={getValues(`eiAssignments.${datumIndex}.eiStatus`) === false}
                sx={{
                  ':hover': {
                    backgroundColor: 'transparent',
                    transition: 'transform 1000ms ease-in-out',
                    transform: 'rotate(360deg)',
                  },
                }}
                onClick={(event: any) => {
                  const formId = getValues(`eiAssignments.${datumIndex}.eiId`)
                  const eiStatus = getValues(`eiAssignments.${datumIndex}.eiStatus`)
                  console.log('formId', formId)
                  if (eiStatus === false) return
                  NavigateTo(navigate, '/engineer-inspection/:id', { id: formId })
                }}>
                <VisibilityIcon />
              </IconButton>
            </Box>
          )
        }}
        height={50}
      />
    </FormContainer>
  )
}

export const PartA = forwardRef(PartAInner)
