import FormBinaryRadio from '@components/form/binaryRadio'
import FormContainer from '@components/form/container'
import FormController from '@components/form/controller'
import FormDateTimePicker from '@components/form/dateTimePicker'
import FormField from '@components/form/field'
import FormSelect from '@components/form/select'
import { CdrOptions, FormScrModel, ScrGeneralOptions } from '@services/model/form/form.SCR.model'
import { Control } from 'react-hook-form'
import { Fragment, useEffect } from 'react'
import FormTypography from '@components/form/typography'
import { FormStatusEnum } from '@services/model/form/form.model'
import { WorkOrderList } from '@services/model/contract.model'
import FormMultipleSelect from '@components/form/multipleSelect'
import { GetTeamOptions } from '@utils/teamOptions'
import { GetDistrictOptions } from '@utils/districtOptions'
import { GetWorksOrderListOptions } from '@utils/worksOrderOptions'

export default ({
  control,
  useWatch,
  setValue,
  getValues,
  userInfo,
  scrGeneralOptions,
  handleGetWorksOrderNo,
  cdrOptions,
  watch,
  formStatus,
  setDisableNav,
  globalState,
  workOrderList
}: {
  control: Control<FormScrModel>
  useWatch: any
  setValue: any
  getValues: any
  userInfo: any
  scrGeneralOptions?: ScrGeneralOptions
  handleGetWorksOrderNo: any
  cdrOptions: CdrOptions | undefined
  watch: any
  formStatus: string
  setDisableNav: any
  globalState: any
  workOrderList?: WorkOrderList[]
}) => {
  const editable =
    formStatus === FormStatusEnum.FORM_SCR_DRAFT || formStatus === FormStatusEnum.FORM_SCR_REJECTED

  useEffect(() => {
    const subscription = watch((data) => {
      if (
        data.baseForm?.contractNoId &&
        data.formDate &&
        data.cdrRef === true &&
        data.baseForm?.workOrderId
      ) {
        handleGetWorksOrderNo()
      }
      if(
        data.baseForm?.contractNoId &&
        data.formDate &&
        data.cdrRef === false &&
        data.baseForm?.workOrderId !== null && data.baseForm?.workOrderId !== undefined
      ){
        setDisableNav(false)
      }else if(
        data.baseForm?.contractNoId &&
        data.formDate &&
        data.cdrRef &&
        data.baseForm?.workOrderId !== null && data.baseForm?.workOrderId !== undefined && 
        data.cdrId !== 0 &&
        data.itemNo !== undefined && data.itemNo !== null
      ){
        setDisableNav(false)
      }else{
        setDisableNav(true)
      }
    })

    return () => subscription.unsubscribe()
  }, [watch])

  const CdrFields = () => {
    const [cdrRef] = useWatch({
      name: ['cdrRef'],
      control: control,
    })

    if (cdrRef) {
      return (
        <Fragment>
          <FormField fieldName="Contractor's Daily Report No.">
            <FormController
              controllerProps={{
                name: 'cdrId',
                control: control,
              }}>
              <FormSelect
                fullWidth
                options={
                  cdrOptions?.cdrList?.map((x) => {
                    return {
                      key: x.id,
                      value: x.cdrNo,
                    }
                  }) || []
                }
                uneditable={!editable}
                additionalChangeAction={() => {
                  setValue('itemNo', null)
                }}
              />
            </FormController>
          </FormField>
          <FormField fieldName="Item No.">
            <FormController
              controllerProps={{
                name: 'itemNo',
                control: control,
              }}>
              <FormSelect
                options={
                  cdrOptions?.cdrList
                    ?.find((cdr) => cdr.id === getValues('cdrId'))
                    ?.relatedWorkOrder[0]?.items?.map((x) => {
                      return {
                        key: x.id,
                        value: x.name,
                      }
                    }) || []
                }
                uneditable={!editable}
                additionalChangeAction={(_, selected) => {
                  let itemDetails = cdrOptions?.cdrList
                    ?.find((cdr) => cdr.id === getValues('cdrId'))
                    ?.relatedWorkOrder[0]?.items?.find((x) => x.id === selected?.key)
                  setValue('fromScrCrw.locationDetails', itemDetails?.locationDetails)
                  setValue('fromScrCrw.proposedActivities', itemDetails?.proposedActivities)
                  setValue('fromScrCrw.noOfWorker', itemDetails?.noOfWorker)
                  setValue('fromScrCrw.lsgOnSite', itemDetails?.lsgOnSite)
                  setValue('fromScrCrw.essentialOperation', itemDetails?.essentialOperation)
                  setValue('fromScrCrw.hiddenWorkOtherThanLsg', itemDetails?.hiddenWorkOtherThanLsg)
                  setValue('fromScrCrw.inSituTest', itemDetails?.inSituTest)
                  setValue('fromScrCrw.dayNightWorks', itemDetails?.dayNightWorks)
                  setValue('fromScrCrw.iosWsRemarks', itemDetails?.iosWsRemarks)
                }}
              />
            </FormController>
          </FormField>
        </Fragment>
      )
    } else {
      return <></>
    }
  }

  const WorkOrderField = () => {
    const [ teamId, districtId] = useWatch({
      name: [ 'baseForm.teamId', 'baseForm.districtId'],
      control: control,
    })

    return (
      <>
        <FormField fieldName="Works Order No.">
          {editable ? (
            <FormController
              controllerProps={{
                name: 'baseForm.workOrderId',
                control: control,
              }}>
              <FormSelect
                fullWidth
                options={GetWorksOrderListOptions(
                  workOrderList || [],
                  teamId,
                  districtId,
                )}
                uneditable={!editable}
              />
            </FormController>
          ) : (
            <FormTypography value={getValues('baseForm.workOrderNo')} />
          )}
        </FormField>
      </>
    )
  }

  return (
    <FormContainer fkey={`scr-a`}>
      <FormField fieldName="Contract No.">
        <FormController
          controllerProps={{
            name: 'baseForm.contractNoId',
            control: control,
          }}>
          <FormSelect
            fullWidth
            options={
              globalState.contractList?.map((x) => {
                return {
                  key: x.id,
                  value: x.contractNo,
                }
              }) || []
            }
            uneditable={true}
          />
        </FormController>
      </FormField>

      <FormField fieldName="Team">
        <FormController
          controllerProps={{
            name: 'baseForm.teamId',
            control,
          }}>
          <FormMultipleSelect
            options={GetTeamOptions(userInfo.teams)}
            uneditable={!editable}
            additionalChangeAction={(event) => {
              setValue('baseForm.workOrderId', undefined)
            }}
          />
        </FormController>
      </FormField>

      <FormField fieldName="District">
        <FormController
          controllerProps={{
            name: 'baseForm.districtId',
            control,
          }}>
          <FormMultipleSelect
            options={GetDistrictOptions(userInfo.districts)}
            uneditable={!editable}
            additionalChangeAction={(event) => {
              setValue('baseForm.workOrderId', undefined)
            }}
          />
        </FormController>
      </FormField>

      <FormField fieldName="Site Check Record No.">
        <FormController
          controllerProps={{
            name: 'scrNo',
            control: control,
          }}>
          <FormTypography />
        </FormController>
      </FormField>

      <FormField fieldName="Date">
        <FormController
          controllerProps={{
            name: 'formDate',
            control: control,
          }}>
          <FormDateTimePicker type="datetime" uneditable={!editable} />
        </FormController>
      </FormField>

      <FormField fieldName="Contractor's Daily Report Ref.">
        <FormController
          controllerProps={{
            name: 'cdrRef',
            control: control,
          }}>
          <FormBinaryRadio
            additionalChangeAction={(event: any) => {
              if (event.target.value === 'false') {
                setValue('formSdCrw', {})
                setValue('cdrId', 0)
              }
            }}
            uneditable={!editable}
          />
        </FormController>
      </FormField>

      <WorkOrderField />
      <CdrFields />
    </FormContainer>
  )
}
