import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

export interface BinaryOptionNames {
  yes: string
  no: string
  na?: string
}

interface formBinaryRadioProps {
  onChange?: (...event: any[]) => void
  onBlur?: () => void
  value?: boolean
  error?: any
  additionalChangeAction?: (event: any) => void
  binaryOptionNames?: BinaryOptionNames

  disabled?: boolean
  uneditable?: boolean
}

/**
 * form binary radio with `yes` and `no` yields boolean output
 * @param props formBinaryRadioProps
 * @example
 * ```
 * <FormController
 *     controllerProps={{
 *         name: 'siteIdling',
 *         control: control,
 *     }}>
 *     <FormBinaryRadio />
 * </FormController>
 * ```
 */
const FormBinaryRadio = (props: formBinaryRadioProps) => {
  const { t } = useTranslation()

  if (!props.onChange) {
    return <>missing onChange from props</>
  }

  const handleChange = (event: any) => {
    switch (event.target.value) {
      case 'true': {
        props.onChange!(true)
        break
      }
      case 'false': {
        props.onChange!(false)
        break
      }
      case '': {
        props.onChange!(undefined)
        break
      }
    }
    if (props.additionalChangeAction) {
      props.additionalChangeAction(event)
    }
  }

  const getTypographyDisplay = (): string => {
    switch (props.value) {
      case true: {
        if (props.binaryOptionNames) {
          return t(props.binaryOptionNames.yes)
        }
        return t('Yes')
      }
      case false: {
        if (props.binaryOptionNames) {
          return t(props.binaryOptionNames.no)
        }
        return t('No')
      }
      default:
        if (props.binaryOptionNames && props.binaryOptionNames.na) {
          return t(props.binaryOptionNames.na)
        }
        return t('N/A')
    }
  }

  const parseValue = (value?: boolean) => {
    switch (value) {
      case true: {
        return true
      }
      case false: {
        return false
      }
      default: {
        return ''
      }
    }
  }

  return (
    <Fragment>
      {props.uneditable ? (
        <Typography>{getTypographyDisplay()}</Typography>
      ) : (
        <RadioGroup
          onChange={handleChange}
          onBlur={props.onBlur}
          value={parseValue(props.value)}
          style={{ width: '100%' }}>
          <FormControlLabel
            disabled={props.disabled}
            label={props.binaryOptionNames ? t(props.binaryOptionNames.yes) : t('Yes')}
            value={true}
            control={<Radio sx={{color: props.error !== undefined ? "red" : "black"}} onBlur={props.onBlur}/>}
          />
          <FormControlLabel
            disabled={props.disabled}
            label={props.binaryOptionNames ? t(props.binaryOptionNames.no) : t('No')}
            value={false}
            control={<Radio sx={{color: props.error !== undefined ? "red" : "black"}} onBlur={props.onBlur}/>}
          />
          <FormControlLabel
            disabled={props.disabled}
            label={
              props.binaryOptionNames && props.binaryOptionNames.na
                ? t(props.binaryOptionNames.na)
                : t('N/A')
            }
            value={''}
            control={<Radio sx={{color: props.error !== undefined ? "red" : "black"}} onBlur={props.onBlur}/>}
            sx={{
              display: props.binaryOptionNames && props.binaryOptionNames.na ? 'inherit' : 'none',
            }}
          />
        </RadioGroup>
      )}
    </Fragment>
  )
}

export default FormBinaryRadio
