import FormContainer from '@components/form/container'
import FormController from '@components/form/controller'
import FormField from '@components/form/field'
import { Control, UseFormSetValue, useWatch } from 'react-hook-form'
import { FormWoiModel, WoiGeneralOptions } from '@services/model/form/form.WOI.model'
import { useTranslation } from 'react-i18next'
import FormFreeText from '@components/form/freeText'
import FormSelect from '@components/form/select'
import FormFileUpload from '@components/form/fileUpload'
import FormBinarySelect from '@components/form/binarySelect'
import { KeyValPair } from '@models/common'

export default ({
  control,
  options,
  setValue,
}: {
  control: Control<FormWoiModel>
  options?: WoiGeneralOptions
  setValue: any
}) => {
  const { t } = useTranslation()

  const PartJActualUrgencyLevel = ({
    control,
    options,
    setValue,
    sbvOptions,
  }: {
    control: Control<FormWoiModel>
    options: KeyValPair[]
    setValue: UseFormSetValue<FormWoiModel>
    sbvOptions: KeyValPair[]
  }) => {
    const urgencyLevelRequirementComplied = useWatch({
      name: 'urgencyLevelRequirementComplied',
      control,
    })

    if (urgencyLevelRequirementComplied === false) {
      return (
        <FormField fieldName="Actual Urgency Level">
          <FormController
            controllerProps={{
              name: 'actualUrgencyLevel',
              control,
            }}>
            <FormSelect
              options={options}
              additionalChangeAction={(_, selected) => {
                if (selected && (selected.value === 'Normal' || selected.value === 'Urgency')) {
                  setValue('sbv', sbvOptions?.find((o) => o.value === 'Nil')?.key)
                } else {
                  setValue('sbv', undefined)
                }
              }}
              uneditable={true}
            />
          </FormController>
        </FormField>
      )
    }

    return <></>
  }

  const PartJSbv = ({
    control,
    options,
    actualUrgencyLevelOptions,
  }: {
    control: Control<FormWoiModel>
    options: KeyValPair[]
    actualUrgencyLevelOptions: KeyValPair[]
  }) => {
    const actualUrgencyLevel = useWatch({
      name: 'actualUrgencyLevel',
      control,
    })

    const actualUrgencyLevelNormalKey =
      actualUrgencyLevelOptions?.find((o) => o.value === 'Normal')?.key ?? undefined
    const actualUrgencyLevelUrgencyKey =
      actualUrgencyLevelOptions?.find((o) => o.value === 'Urgency')?.key ?? undefined

    return (
      <FormField fieldName="SBV">
        <FormController
          controllerProps={{
            name: 'sbv',
            control,
          }}>
          <FormSelect
            options={options}
            uneditable={true}
          />
        </FormController>
      </FormField>
    )
  }

  return (
    <FormContainer fkey={`woi-j`}>
      <FormField
        fieldName="Urgency Level Requirement Complied"
        fieldTooltip="Works of Normal/Urgency/High Urgency/Extreme Urgency Requirment Complied">
        <FormController
          controllerProps={{
            name: 'urgencyLevelRequirementComplied',
            control,
          }}>
          <FormBinarySelect  uneditable={true}/>
        </FormController>
      </FormField>
      <PartJActualUrgencyLevel
        control={control}
        options={
          options?.urgencyLevel?.map((x) => ({
            key: x.id,
            value: x.name,
          })) ?? []
        }
        setValue={setValue}
        sbvOptions={
          options?.sbv?.map((x) => ({
            key: x.id,
            value: x.name,
          })) ?? []
        }
      />
      <PartJSbv
        control={control}
        options={
          options?.sbv?.map((x) => ({
            key: x.id,
            value: x.name,
          })) ?? []
        }
        actualUrgencyLevelOptions={
          options?.urgencyLevel?.map((x) => ({
            key: x.id,
            value: x.name,
          })) ?? []
        }
      />
      <FormField fieldName="Last Interim Payment">
        <FormController
          controllerProps={{
            name: 'lastInterimPayment',
            control,
          }}>
          <FormBinarySelect  uneditable={true}/>
        </FormController>
      </FormField>
      <FormField
        fieldName="Other Relevant Information"
        fieldTooltip="Information Relevant to Checking of Contractor's Dimension Book other than Above">
        <FormController
          controllerProps={{
            name: 'relevantInformation',
            control,
          }}>
          <FormFreeText
            textfieldProps={{
              multiline: true,
              rows: 4,
            }}
            uneditable={true}
          />
        </FormController>
      </FormField>
      <FormField fieldName="Relevant Attachment">
        <FormController
          controllerProps={{
            name: 'relevantAttachments',
            control,
          }}>
          <FormFileUpload height="125" uneditable={true} />
        </FormController>
      </FormField>
    </FormContainer>
  )
}
