import FormContainer from '@components/form/container'
import FormField from '@components/form/field'
import { FormSDModel, InitSDForm } from '@services/model/form/form.SD.model'
import { ArrayPath, Control, FieldArray, useFieldArray, UseFieldArrayReturn, useWatch } from 'react-hook-form'
import FormTable from '@components/form/table'
import FormSelect from '@components/form/select'
import { useTranslation } from 'react-i18next'
import uuid from 'react-uuid'
import { Typography, Autocomplete, TextField } from '@mui/material'
import { KeyValPair } from '@models/common'
import useGeneralOptions from '@hooks/useGeneralOptions_to_be_used'

export default ({
  control,
  options,
  readOnly,
  getValues
}: {
  control: Control<FormSDModel>
  options: any
  readOnly: boolean
  getValues: any
}) => {
  const UUID = uuid()
  const { t } = useTranslation()
  const { getOptionsByKey } = useGeneralOptions()

  const tradeList = options?.tradeList?.map((x) => {
    return {
      key: x.id,
      value: x.code + "-" + x.trade + "-" + x.tradeDivision ,
    }
  }) || []

  return (
    <FormContainer fkey={`${UUID}-sd-d`}>
      <FormField fieldName="Type" index={0}>
        <Typography>{t('Labour')}</Typography>
      </FormField>
      <FormTable
        columns={[
          {
            field: 'tradeId',
            displayName: 'Trade',
            type: 'selectWithSearch',
            options: tradeList,
            width: '450px',
          },
          {
            field: 'workingHourFrom',
            subfield: 'workingHourTo',
            displayName: 'Working Hours',
            type: 'times',
          },
          {
            field: 'company',
            displayName: 'Company',
            type: 'string',
          },
          {
            field: 'noOfWorker',
            displayName: 'No. of Worker',
            type: 'number',
            minHeight: '100px',
            minNumber: 0
          },
          {
            field: 'resourceTypeId',
            displayName: 'Resource Type',
            type: 'select',
            options: getOptionsByKey('Form SD/SCR - Labour'),
          },
          {
            field: 'remarks',
            displayName: 'Remark',
            type: 'string',
          },
        ]}
        fieldArrayProps={{
          control: control,
          name: 'siteProgressForLabour',
        }}
        uneditable={readOnly}
      />
      <FormField fieldName="Type" index={2}>
        <Typography>{t('Plant')}</Typography>
      </FormField>
      <FormTable
        columns={[
          {
            field: 'plantType',
            displayName: 'Type',
            type: 'string',
          },
          {
            field: 'plantId',
            displayName: 'Plant ID',
            type: 'string',
            // minHeight: '100px',
          },
          {
            field: 'company',
            displayName: 'Company',
            type: 'string',
          },
          {
            field: 'workingHourFrom',
            subfield: 'workingHourTo',
            displayName: 'Working Hours',
            type: 'times',
          },
          {
            field: 'noOfPlant',
            displayName: 'No. of Plant',
            type: 'number',
            minHeight: '100px', 
          },
          {
            field: 'noOfIdlePlant',
            displayName: 'No. of Idle Plant',
            type: 'number',
            minHeight: '100px',
          },
          {
            field: 'idleCodeId',
            displayName: 'Idle Code',
            type: 'select',
            options:
              options?.idleCodeList?.map((x) => {
                return {
                  key: x.id,
                  value: x.name,
                }
              }) || [],
            width: '200px',
            minHeight: '100px',
          },
          {
            field: 'resourceTypeId',
            displayName: 'Resource Type',
            type: 'select',
            options: getOptionsByKey('Form SD/SCR - Construction Plant'),
          },
          {
            field: 'resourceTypeOthers',
            displayName: 'Other',
            type: 'string',
            disableFieldCallback: (name: string, datumIndex: number) => {
              return getOptionsByKey('Form SD/SCR - Construction Plant')?.find((x) => x.value === "Others")?.key !== getValues(`${name}.${datumIndex}.resourceTypeId`)
            },
          },
          {
            field: 'remarks',
            displayName: 'Remark',
            type: 'string',
          },
        ]}
        fieldArrayProps={{
          control: control,
          name: 'siteProgressForPlant',
        }}
        uneditable={readOnly}
        useWatch={useWatch}
      />
    </FormContainer>
  )
}
