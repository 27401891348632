import { KeyValPair } from '@models/common'
import { BaseFilterOptions, BaseListResponse } from '../base.model'
import { ContractNo, WorkOrder, WorkOrderList } from '../contract.model'
import { FileModel } from '../file.model'
import { FormModel, InitFormModel, InitFormStatusHistory } from './form.model'
import { FormWoiModel, WOIExtensionOfTimeAssessment, WOISiteInstructionValuation } from './form.WOI.model'

export interface FormWouModel {
  baseForm: FormModel
  formWoi?: FormWoiModel

  //a
  wouNo: string
  typeOfWorks?: boolean
  provisional?: boolean
  workDescRemark?: string

  //b
  typeOfMmWorks?: number
  sorItemNo?: number

  //d
  delayOfWorks?: boolean
  letterOfSlowProgressIssued?: boolean
  slowProgressNumber?: string
  letterOfOverdueProgressIssued?: boolean
  attachmentForLetterOfSlowProgress?: FileModel[]
  attachmentForLetterOfOverdueProgress?: FileModel[]

  //e
  letterOfConfirmation?: boolean
  attachmentForLetterOfNoEot?: FileModel[]
  extensionOfTimes?: WOIExtensionOfTimeAssessment[]

  //i
  siteInstructionValuations?: WOISiteInstructionValuation[]

  //j
  urgencyLevelRequirementComplied?: boolean
  actualUrgencyLevel?: number
  sbv?: number
  lastInterimPayment?: boolean
  relevantInformation?: string
  relevantAttachments?: FileModel[]

  signatureRequired: boolean
  signatureBase64?: string
  submissionComment?: string
  notifyUserList?: string[]

  //for frontend 
  firstPageData: boolean
}

export interface WouListResponse extends BaseListResponse {
  list: FormWouListModel[]
}

export const InitWOUForm: FormWouModel = {
  signatureRequired: true,
  baseForm: {
    ...InitFormModel,
    formStatus: 'FORM_WOU_DRAFT',
    formStatusHistories: [{ ...InitFormStatusHistory, actionName: 'Draft' }],
  },
  wouNo: '',
  firstPageData: false
}

export interface WouGeneralOptions {
  typeOfMmWorks: {
    id: number,
    name: string
  }[]
  urgencyLevel: {
    id: number,
    name: string
  }[]
  workOrderList: WorkOrderList[]
  sbv: {
    id: number,
    name: string
  }[]
  completionOrWithdraw: {
    id: number,
    name: string
  }[]
}

export interface FormWouiListModel {
  formId: number
  color: string
  formStatus: string
  formStatusShortName: string
  formStatusColor?: string

  reportNo: string
  districtId: number[]
  teamId: number[]
  issueDate: Date

  submittedBy: string
  approvedBy: string
  updatedAt: Date
  parentReportNo: string
  rejectedBy: string
}

export interface FormWouListModel {
  formId: number
  color: string
  formStatus: string
  formStatusShortName: string
  formStatusColor?: string

  reportNo: string
  districtId: number[]
  teamId: number[]
  issueDate: Date
  workOrderNo: string
  typeOfWorks: boolean

  submittedBy: string
  approvedBy: string
  updatedAt: Date
}

export interface WouFilterOptions extends BaseFilterOptions {
  workOrderIdList: KeyValPair[]
  parentFormIdList: KeyValPair[]
}
